import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

// Helpers
import { ApiHelperFunction } from "../../utils/HttpClient";

let initialState = {
  status: "idle",
  qualificationData: {},
  isLoading: false,
  isSuccess: false,
  isError: false,
};

export const PostQualification = createAsyncThunk(
  "Qualification_post",
  async (data) => {
    let response = await ApiHelperFunction({
      urlPath: "admin/highestEducation",
      method: "POST",
      data: data,
    });
    console.log("contact_details", response);

    if (response.status === 200) {
      toast.success("Highest Qualification Added Successfully");
      return response.data;
    } else {
      toast.error(response?.response?.data?.message);
    }
  }
);

export const PostCourse = createAsyncThunk(
  "Course_post",
  async (data) => {
    let response = await ApiHelperFunction({
      urlPath: "admin/course",
      method: "POST",
      data: data,
    });
    // console.log("cont/act_details", response);

    if (response.status === 200) {
      toast.success("Course Added Successfully");
      return response.data;
    } else {
      toast.error(response?.response?.data?.message);
    }
  }
);

export const GetQualification = createAsyncThunk(
  "Qualification_get",
  async () => {
    let response = await ApiHelperFunction({
      urlPath: "admin/highestEducation",
      method: "GET",
    });
    console.log("contact_details", response);

    if (response.status === 200) {
      return response.data;
    } else {
      toast.error(response?.response?.data?.message);
    }
  }
);

export const UpdateQualification = createAsyncThunk(
  "Qualification_update",
  async ({ id, data }) => {
    console.log("updatedItem", data);
    let response = await ApiHelperFunction({
      urlPath: `admin/highestEducation/${id}`,
      method: "PUT",
      data: data,
    });
    console.log("contact_details", response);

    if (response.status === 200) {
      toast.success("Highest Qualification Updated Successfully");
      return response.data;
    } else {
      toast.error(response?.response?.data?.message);
    }
  }
);

export const UpdateCourse = createAsyncThunk(
  "Course_update",
  async ({ id, data }) => {
    // console.log("updatedItem", data);
    let response = await ApiHelperFunction({
      urlPath: `admin/course/${id}`,
      method: "PUT",
      data: data,
    });
    // console.log("contact_details", response);

    if (response.status === 200) {
      toast.success("Course Updated Successfully");
      return response.data;
    } else {
      toast.error(response?.response?.data?.message);
    }
  }
);

export const DeleteQualification = createAsyncThunk(
  "Qualification_Delete",
  async (id) => {
    let response = await ApiHelperFunction({
      urlPath: `admin/delete-qualification/${id}`,
      method: "PUT",
    });
    console.log("contact_details", response);

    if (response.status === 200) {
      toast.success("Highest Qualification Deleted Successfully");
      return response.data;
    } else {
      toast.error(response?.response?.data?.message);
    }
  }
);

export const QualificationSlice = createSlice({
  name: "Qualification_details_data",
  initialState,
  reducers: {
    clearState: (state) => {
      state.status = "idle";
      state.qualificationData = {};
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(PostQualification.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(PostQualification.fulfilled, (state) => {
        state.status = "success";
        state.isSuccess = true;
        // Optionally, you can update state after a successful post if needed
      })
      .addCase(PostQualification.rejected, (state) => {
        state.status = "failed";
        state.isError = true;
        state.isSuccess = false;
      })
      .addCase(GetQualification.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(GetQualification.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.isSuccess = true;
        console.log("payload", payload?.data);
        state.qualificationData = payload?.data;
      })
      .addCase(GetQualification.rejected, (state) => {
        state.status = "failed";
        state.isError = true;
        state.isSuccess = false;
      })
      .addCase(UpdateQualification.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(UpdateQualification.fulfilled, (state) => {
        state.status = "success";
        state.isSuccess = true;
      })
      .addCase(UpdateQualification.rejected, (state) => {
        state.status = "failed";
        state.isError = true;
        state.isSuccess = false;
      })
      .addCase(DeleteQualification.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(DeleteQualification.fulfilled, (state) => {
        state.status = "success";
        state.isSuccess = true;
      })
      .addCase(DeleteQualification.rejected, (state) => {
        state.status = "failed";
        state.isError = true;
        state.isSuccess = false;
      })
      .addCase(PostCourse.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(PostCourse.fulfilled, (state) => {
        state.status = "success";
        state.isSuccess = true;
        // Optionally, you can update state after a successful post if needed
      })
      .addCase(PostCourse.rejected, (state) => {
        state.status = "failed";
        state.isError = true;
        state.isSuccess = false;
      })
      .addCase(UpdateCourse.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(UpdateCourse.fulfilled, (state) => {
        state.status = "success";
        state.isSuccess = true;
      })
      .addCase(UpdateCourse.rejected, (state) => {
        state.status = "failed";
        state.isError = true;
        state.isSuccess = false;
      })
      ;
  },
});

export const { clearState } = QualificationSlice.actions;

export default QualificationSlice.reducer;

import React, { useEffect } from "react";
import HomeCard from "./HomeCard";
import "./Home.css";
import ChartBox from "./ChartBox";
import RecentUsersTable from "./RecentUsersTable";
import { PieChart } from "./PieChart";
import PieChartBox from "./PieChartsBox";
import GenderReportCount from "./GenderReportCount";
import CommunityReportCount from "./CommunityReportCount";
import SubCommunityCount from "./SubCommunityCount";
import Cast from "./CastCount";
import { useSelector, useDispatch } from "react-redux";
import { getProfileDetails } from "../../redux/AdminSlice/profileSlice";

const Index = () => {
  const dispatch = useDispatch();

  const { profile_data } = useSelector((state) => state.profileReducer);
  console.log("profile_data", profile_data?.userType);

  useEffect(() => {
    dispatch(getProfileDetails());
  }, []);
  return (
    <>
      <div className="main_wrap">
        .
        {profile_data?.userType === "Admin" ? (
          <div className="container-fluid">
            <h2 className="pageHad_line">Dashboard</h2>

            <HomeCard />

            <div className="row">
              <div className="col-md-6 col-12">
                <h4 className="chart_headline">Revenew Month Wise</h4>
                <ChartBox />
              </div>
              <div className="col-md-6 col-12">
                <h4 className="chart_headline">Gender Wise Statistic</h4>
                <GenderReportCount />
              </div>
              <div className="col-md-6 col-12">
                <h4 className="chart_headline">Religion Wise Statistic</h4>
                <PieChart />
              </div>
              <div className="col-md-6 col-12">
                <h4 className="chart_headline">Community wise Statistics</h4>
                <CommunityReportCount />
              </div>
              <div className="col-md-6 col-12">
                <h4 className="chart_headline">SubCommunity wise Statistics</h4>
                <SubCommunityCount />
              </div>
              <div className="col-md-6 col-12">
                <h4 className="chart_headline">Cast wise Statistics</h4>
                <Cast />
              </div>
            </div>

            {/* <div className="mt-4">
            <h4 className="chart_headline">Recent User</h4>
            <RecentUsersTable />
          </div> */}
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default Index;

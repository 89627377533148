import { combineReducers, configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";

import usersSlice from "./AdminSlice/ViewAllUsersSlice";
import authSlice from "./authSlice/AuthSlice";
import ChangePasswordSlice from "./authSlice/ChangePasswordSlice";
import ContactSlice from "./AdminSlice/ContactSlice";
import AboutUsSlice from "./AdminSlice/AboutSlice";
import ProfileForSlice from "./AdminSlice/ProfileForSlice";
import ReligionSlice from "./AdminSlice/ReligionSlice";
import CommunitySlice from "./AdminSlice/CommunitySlice";
import SubCommunitySlice from "./AdminSlice/SubCommunitySlice";
import CastSlice from "./AdminSlice/CastSlice";
import MaritalSlice from "./AdminSlice/MaritalSlice";
import HeightSlice from "./AdminSlice/HeightSlice";
import DietSlice from "./AdminSlice/DietSlice";
import ManageUserSlice from "./AdminSlice/ManageUserSlice";
import ComplexionSlice from "./AdminSlice/ComplexionSlice";
import QualificationSlice from "./AdminSlice/QualificationSlice";
import OcupationSlice from "./AdminSlice/OcupationSlice";
import AnnualIncomeSlice from "./AdminSlice/AnnualIncomeSlice";
import ZodiacSignSlice from "./AdminSlice/ZodiacSignSlice";
import AccountViewPriceSlice from "./AdminSlice/AccountViewPriceSlice";
import PrivacyPolicySlice from "./AdminSlice/PrivacyPolicySlice";
import FaqSlice from "./AdminSlice/FaqSlice";
import AddPagesSlice from "./AdminSlice/AddPagesSlice";
import CountrySlice from "./AdminSlice/CountrySlice";
import StateSlice from "./AdminSlice/StateSlice";
import CitySlice from "./AdminSlice/CitySlice";
/* ========= ========= ======   report ===== ====== ======== =========*/
import ReligionReportSlice from "./ReportSlice/ReligionReportSlice";
import AccountPurchaseSlice from "./ReportSlice/AccountPurchaseSlice";
import GenderReportSlice from "./ReportSlice/GenderReportSlice";
import CommunityReportSlice from "./ReportSlice/CommunityReportSlice";
import DemographySlice from "./ReportSlice/DemographySlice";
import UserReportSlice from "./ReportSlice/UserReportSlice";
/* ========= ========= ======   profile  ===== ====== ======== =========*/
import ProfileSlice from "./AdminSlice/profileSlice";

let rootReducer = combineReducers({
  userReducer: usersSlice,
  authReducer: authSlice,
  changePassReducer: ChangePasswordSlice,
  contactReducer: ContactSlice,
  aboutUsReducer: AboutUsSlice,
  profileIsForReducer: ProfileForSlice,
  religionReducer: ReligionSlice,
  communityReducer: CommunitySlice,
  subCommunityReducer: SubCommunitySlice,
  castReducer: CastSlice,
  maritalReducer: MaritalSlice,
  heightReducer: HeightSlice,
  dietReducer: DietSlice,
  manageuserReducer: ManageUserSlice,
  complexionReducer: ComplexionSlice,
  qualificationReducer: QualificationSlice,
  ocupationReducer: OcupationSlice,
  annualIncomereducer: AnnualIncomeSlice,
  zodiacSignReducer: ZodiacSignSlice,
  accountViewreducer: AccountViewPriceSlice,
  privacyPolicyReducer: PrivacyPolicySlice,
  faqReducer: FaqSlice,
  pagesReducer: AddPagesSlice,
  countryReducer: CountrySlice,
  stateReducer: StateSlice,
  cityReducer: CitySlice,
  /*================================= */
  religionReportReducer: ReligionReportSlice,
  accountPurchseReducer: AccountPurchaseSlice,
  genderReportReducer: GenderReportSlice,
  communityReportReducer: CommunityReportSlice,
  demographyReducer: DemographySlice,
  userReportReducer: UserReportSlice,
  /*==================================== */
  profileReducer: ProfileSlice,
});

const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== "production",
});

export const persistor = persistStore(store);

export default store;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

// Helpers
import { ApiHelperFunction } from "../../utils/HttpClient";

let initialState = {
  status: "idle",
  FaqData: {},
  isLoading: false,
  isSuccess: false,
  isError: false,
};

export const PostFaq = createAsyncThunk("faq_post", async (data) => {
  let response = await ApiHelperFunction({
    urlPath: "admin/add-faq",
    method: "POST",
    data: data,
  });
  console.log("Faq_details", response);

  if (response.status === 200) {
    toast.success("Faq added successfully");
    return response.data;
  } else {
    toast.error(response?.response?.data?.message);
  }
});

export const getFaq = createAsyncThunk("Faq_get", async () => {
  let response = await ApiHelperFunction({
    urlPath: "admin/view-faq",
    method: "GET",
  });
  // console.log("Comminuty_details_get", response);

  if (response.status === 200) {
    return response.data;
  } else {
    toast.error(response?.response?.data?.message);
  }
});

export const UpdateFaq = createAsyncThunk(
  "Faq_update",
  async ({ id, data }) => {
    console.log("updatedItem", data);
    let response = await ApiHelperFunction({
      urlPath: `admin/edit-faq/${id}`,
      method: "PUT",
      data: data,
    });
    console.log("Community_Details", response);

    if (response.status === 200) {
      toast.success("Faq Updated Successfully");
      return response.data;
    } else {
      toast.error(response?.response?.data?.message);
    }
  }
);

export const DeleteFaq = createAsyncThunk("faq_Delete", async (id) => {
  let response = await ApiHelperFunction({
    urlPath: `admin/delete-faq/${id}`,
    method: "PUT",
  });
  console.log("contact_details", response);

  if (response.status === 200) {
    toast.success("Faq Deleted Successfully");
    return response.data;
  } else {
    toast.error(response?.response?.data?.message);
  }
});

export const FaqSlice = createSlice({
  name: "Faq_details_data",
  initialState,
  reducers: {
    clearState: (state) => {
      state.status = "idle";
      state.FaqData = {};
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(PostFaq.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(PostFaq.fulfilled, (state) => {
        state.status = "success";
        state.isSuccess = true;
        // Optionally, you can update state after a successful post if needed
      })
      .addCase(PostFaq.rejected, (state) => {
        state.status = "failed";
        state.isError = true;
        state.isSuccess = false;
      })
      .addCase(getFaq.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(getFaq.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.status = "success";
        state.isSuccess = true;
        // console.log("payload", payload?.data);
        state.FaqData = payload?.data;
      })
      .addCase(getFaq.rejected, (state) => {
        state.isLoading = false;
        state.status = "failed";
        state.isError = true;
        state.isSuccess = false;
      })
      .addCase(UpdateFaq.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(UpdateFaq.fulfilled, (state) => {
        state.status = "success";
        state.isSuccess = true;
      })
      .addCase(UpdateFaq.rejected, (state) => {
        state.status = "failed";
        state.isError = true;
        state.isSuccess = false;
      })
      .addCase(DeleteFaq.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(DeleteFaq.fulfilled, (state) => {
        state.status = "success";
        state.isSuccess = true;
      })
      .addCase(DeleteFaq.rejected, (state) => {
        state.status = "failed";
        state.isError = true;
        state.isSuccess = false;
      });
  },
});

export const { clearState } = FaqSlice.actions;

export default FaqSlice.reducer;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  GetZodiacSign,
  PostZodiacSign,
  UpdateZodiacSign,
  UploadZodiacIcon,
  clearState,
} from "../../../redux/AdminSlice/ZodiacSignSlice";
import { GetReligian } from "../../../redux/AdminSlice/ReligionSlice";

const AddZodiacSign = () => {
  let initial = {
    name: "",
    icon: "",
    religionId: "",
  };
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [zodiacType, setZodiacType] = useState(initial);

  const ZodiacIcon = useSelector((state) => state.zodiacSignReducer.ZodiacIcon);
  const allreligion = useSelector(
    (state) => state.religionReducer.ReligianData
  );

  console.log("zodiacType", zodiacType);

  const changehandler = (e) => {
    setZodiacType((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const Imagehandler = (e) => {
    let file = e.target.files;
    for (let element of file) {
      let data = new FormData();
      data.append("image", element);
      dispatch(UploadZodiacIcon(data));
    }
  };

  useEffect(() => {
    if (location.state) {
      setZodiacType({
        name: location.state.name,
        icon: location.state.icon,
        religionId: location.state?.religionId,
      });
    }
    if (ZodiacIcon) {
      setZodiacType((prevzodiacType) => ({
        ...prevzodiacType,
        icon: ZodiacIcon,
      }));
    }
  }, [ZodiacIcon]);

  const submitHandler = (e) => {
    e.preventDefault();
    if (zodiacType.religionId === "") {
      toast.error("Religion Is Required");
      return false;
    }
    if (zodiacType.name === "") {
      toast.error("Zodiac Sign Is Required");
      return false;
    }
    // if (zodiacType.icon === "") {
    //   toast.error("Zodiac Sign icon Is Required");
    //   return false;
    // }
    let data = {
      name: zodiacType.name,
      icon: zodiacType.icon ? zodiacType.icon : ZodiacIcon,
      religionId: zodiacType.religionId,
    };
console.log("zodiacdata",data)
    return
    if (location.state) {
      const id = location.state._id;
      dispatch(UpdateZodiacSign({ id, data }));
      setZodiacType(initial);
      dispatch(clearState());
      dispatch(GetZodiacSign());
      navigate("/manageZodiacSign");
    } else {
      dispatch(PostZodiacSign(data));
      setZodiacType(initial);
      dispatch(clearState());
    }
  };

  useEffect(() => {
    dispatch(GetReligian());
  }, []);

  return (
    <div className="main_wrap">
      <div className="aboutUs_page">
        <h4 className="Pageheadline">
          {location.state ? "Edit Zodiac Sign" : "Add Zodiac Sign"}
        </h4>
        <form action="" onSubmit={(e) => submitHandler(e)}>
          <div className="row">
            {/* Religion */}
            <div className="" style={{ width: "22%", marginLeft: "10px" }}>
              <label htmlFor="religionId">Religion Name</label>
              <select
                class="form-select table_page_select"
                aria-label="Default select example"
                onChange={(e) => changehandler(e)}
                name="religionId"
                value={zodiacType.religionId}
              >
                <option selected value="">
                  Select Religion
                </option>

                {allreligion?.map((item) => {
                  return <option value={item?._id}>{item?.name}</option>;
                })}
              </select>
            </div>

            {/* zodiac sign */}
            <div className="col-md-3 col-12">
              <label htmlFor="">Zodiac Sign</label>

              <div className="ZodiacDiv">
                {/* <select
                  class="form-select"
                  aria-label="Default select example"
                  onChange={(e) => changehandler(e)}
                  name="name"
                  value={zodiacType.name}
                >
                  <option selected>Zodiac Sign</option>
                  <option value="Aries">Aries</option>
                  <option value="Taurus">Taurus</option>
                  <option value="Gemini">Gemini</option>
                  <option value="Cancer">Cancer</option>
                  <option value="Leo">Leo</option>
                  <option value="Virgo">Virgo</option>
                  <option value="Libra">Libra</option>
                  <option value="Scorpio">Scorpio</option>
                  <option value="Sagittarius">Sagittarius</option>
                  <option value="Capricorn">Capricorn</option>
                  <option value="Aquarius">Aquarius</option>
                  <option value="Pisces">Pisces</option>
                </select> */}
                <input
                  type="text"
                  name="name"
                  className="form-control"
                  placeholder="Enter Zodiac Sign Name"
                  value={zodiacType.name}
                  onChange={(e) => changehandler(e)}
                />
              </div>
            </div>

            {/* icon */}
            <div className="col-md-3 col-12">
              <label htmlFor=""> Zodiac Icon</label>
              <input
                type="file"
                className="form-control"
                name="icon"
                onChange={(e) => Imagehandler(e)}
                accept="image/*"
              />
            </div>
            <div
              className="col-md-3 col-12"
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "end",
              }}
            >
              {location.state ? (
                <button type="submit" className="btn btn-primary">
                  Submit
                </button>
              ) : (
                <button type="submit" className="btn btn-primary">
                  Add
                </button>
              )}
            </div>
          </div>
          {ZodiacIcon ? (
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                marginTop: "5px",
              }}
            >
              <img
                style={{ height: "100px", width: "100px", marginLeft: "100px" }}
                src={ZodiacIcon}
                alt=""
              />
              <i
                class="fa-solid fa-xmark"
                style={{ cursor: "pointer" }}
                onClick={() => dispatch(clearState())}
              ></i>
            </div>
          ) : location.state ? (
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                marginTop: "5px",
              }}
            >
              <img
                style={{ height: "100px", width: "100px", marginLeft: "100px" }}
                src={zodiacType.icon}
                alt=""
              />
            </div>
          ) : (
            ""
          )}
        </form>
      </div>
    </div>
  );
};

export default AddZodiacSign;

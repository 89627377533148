import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

// Helpers
import { ApiHelperFunction } from "../../utils/HttpClient";

let initialState = {
  status: "idle",
  contactData: {},
  isLoading: false,
  isSuccess: false,
  isError: false,
};

export const PostContactData = createAsyncThunk(
  "Contact_details_post",
  async (data) => {
    let response = await ApiHelperFunction({
      urlPath: "admin/add-contactUs",
      method: "POST",
      data: data,
    });
    console.log("contact_details", response);

    if (response.status === 200) {
      toast.success("Contact Details Added Successfully");
      return response.data;
    } else {
      toast.error(response?.response?.data?.message);
    }
  }
);

export const getContactData = createAsyncThunk(
  "Contact_details_get",
  async () => {
    let response = await ApiHelperFunction({
      urlPath: "admin/view-contactUs",
      method: "GET",
    });
    console.log("contact_details", response);

    if (response.status === 200) {
      return response.data;
    } else {
      toast.error(response?.response?.data?.message);
    }
  }
);

export const UpdateContactData = createAsyncThunk(
  "Contact_details_update",
  async ({ id, data }) => {
    console.log("updatedItem", data);
    let response = await ApiHelperFunction({
      urlPath: `admin/edit-contactUs/${id}`,
      method: "PUT",
      data: data,
    });
    console.log("contact_details", response);

    if (response.status === 200) {
      toast.success("Contact Details Updated Successfully");
      return response.data;
    } else {
      toast.error(response?.response?.data?.message);
    }
  }
);

export const DeleteContactData = createAsyncThunk(
  "Contact_details_Delete",
  async (id) => {
    let response = await ApiHelperFunction({
      urlPath: `admin/delete-contactUs/${id}`,
      method: "PUT",
    });
    console.log("contact_details", response);

    if (response.status === 200) {
      toast.success("Contact Details Deleted Successfully");
      return response.data;
    } else {
      toast.error(response?.response?.data?.message);
    }
  }
);

export const ContactSlice = createSlice({
  name: "contact_details_data",
  initialState,
  reducers: {
    clearState: (state) => {
      state.status = "idle";
      state.contactData = {};
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(PostContactData.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(PostContactData.fulfilled, (state) => {
        state.status = "success";
        state.isSuccess = true;
        // Optionally, you can update state after a successful post if needed
      })
      .addCase(PostContactData.rejected, (state) => {
        state.status = "failed";
        state.isError = true;
        state.isSuccess = false;
      })
      .addCase(getContactData.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(getContactData.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.status = "success";
        state.isSuccess = true;
        state.contactData = payload?.data;
        state.isLoading = false;
      })
      .addCase(getContactData.rejected, (state) => {
        state.isLoading = false;
        state.status = "failed";
        state.isError = true;
        state.isSuccess = false;
        state.isLoading = false;
      })
      .addCase(UpdateContactData.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(UpdateContactData.fulfilled, (state) => {
        state.status = "success";
        state.isSuccess = true;
      })
      .addCase(UpdateContactData.rejected, (state) => {
        state.status = "failed";
        state.isError = true;
        state.isSuccess = false;
      })
      .addCase(DeleteContactData.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(DeleteContactData.fulfilled, (state) => {
        state.status = "success";
        state.isSuccess = true;
      })
      .addCase(DeleteContactData.rejected, (state) => {
        state.status = "failed";
        state.isError = true;
        state.isSuccess = false;
      });
  },
});

export const { clearState } = ContactSlice.actions;

export default ContactSlice.reducer;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import {
  GetReligian,
  PostReligian,
  UpdateReligian,
  clearState,
  uploadReligionImage,
} from "../../../redux/AdminSlice/ReligionSlice";

const AddReligion = () => {
  const initial = {
    name: "",
    image: "",
  };
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [religion, setReligion] = useState(initial);

  const ReligionImage = useSelector(
    (state) => state.religionReducer.religionImage
  );

  const changehandler = (e) => {
    setReligion({
      ...religion,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    if (location.state) {
      setReligion({
        name: location.state.name,
        image: location.state.image,
      });
    }
    if (ReligionImage) {
      setReligion((prevDiet) => ({
        ...prevDiet,
        image: ReligionImage,
      }));
    }
  }, [ReligionImage]);

  const Imagehandler = (e) => {
    let file = e.target.files;

    for (let element of file) {
      let data = new FormData();
      data.append("image", element);
      dispatch(uploadReligionImage(data));
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();
    if (religion.name === "") {
      toast.error("Religion Name Is required");
      return false;
    }
    if (religion.image === "") {
      toast.error("Religion Image Is required");
      return false;
    }

    let data = {
      name: religion.name,
      image: religion.image,
    };

    if (location.state) {
      const id = location.state._id;
      dispatch(UpdateReligian({ id, data }));
      setReligion(initial);
      dispatch(GetReligian());
      dispatch(clearState());
      navigate("/addReligion");
    } else {
      dispatch(PostReligian(data));
      setReligion(initial);
      dispatch(clearState());
    }
  };

  return (
    <div className="main_wrap">
      <div className="aboutUs_page">
        <h4 className="Pageheadline">
          {location.state ? "Edit Religion" : "Add Religion"}
        </h4>
        <form action="" onSubmit={(e) => submitHandler(e)}>
          <div style={{ display: "flex" }}>
            <div className="col-md-5">
              <label htmlFor="">Religion Name</label>
              <input
                type="text"
                placeholder="Enter Religion Name"
                className="form-control"
                name="name"
                value={religion.name}
                onChange={(e) => changehandler(e)}
              />
            </div>
            <div className="col-md-5">
              <label htmlFor="">Icon</label>
              <input
                type="file"
                className="form-control"
                name="image"
                accept="image/*"
                onChange={(e) => Imagehandler(e)}
              />
            </div>
            <div
              className="col-md-2"
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "end",
              }}
            >
              {location.state ? (
                <button type="submit" className="btn btn-primary">
                  Submit
                </button>
              ) : (
                <button type="submit" className="btn btn-primary">
                  Add
                </button>
              )}
            </div>
          </div>

          {ReligionImage ? (
            <div
              style={{
                height: "100px",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                marginTop: "5px",
              }}
            >
              <img
                style={{
                  height: "100px",
                  width: "100px",
                  // marginLeft: "100px",
                }}
                src={ReligionImage}
                alt=""
              />

              <i
                class="fa-solid fa-xmark"
                style={{ cursor: "pointer", color: "red", height: "10px" }}
                onClick={() => dispatch(clearState())}
              ></i>
            </div>
          ) : location.state ? (
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                marginTop: "5px",
              }}
            >
              <img
                style={{ height: "100px", width: "100px", marginLeft: "100px" }}
                src={religion.image}
                alt=""
              />
            </div>
          ) : (
            ""
          )}
        </form>
      </div>
    </div>
  );
};

export default AddReligion;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { ApiHelperFunction, fileUpload } from "../../utils/HttpClient";

let initialState = {
  status: "idle",
  DietImage: "",
  DietData: null,
  isLoading: false,
  isSuccess: false,
  isError: false,
};

export const uploadDietImg = createAsyncThunk(
  "upload_diet_image",
  async (data) => {
    console.log("DietImage", data);
    let response = await fileUpload({
      urlPath: "admin/upload-dietImage",
      method: "POST",
      data: data,
    });
    console.log("Diet_icon", response);

    if (response.status === 200) {
      toast.success("Diet Image Uploaded successfully");
      return response.data;
    } else {
      toast.error(response.response.data.message);
    }
  }
);

export const PostDietdetails = createAsyncThunk(
  "add_diet_data",
  async (data) => {
    console.log("diet data", data);
    let response = await ApiHelperFunction({
      urlPath: "admin/add-diet",
      method: "POST",
      data: data,
    });
    console.log("Diet_Data", response);

    if (response.status === 200) {
      toast.success("Diet Type added successfully");
      return response.data;
    } else {
      toast.error(response.response.data.message);
    }
  }
);

export const GetDietdetails = createAsyncThunk("get_diet_data", async () => {
  let response = await ApiHelperFunction({
    urlPath: "admin/view-diet",
    method: "GET",
  });
  console.log("Diet_Data", response);

  if (response.status === 200) {
    return response.data;
  } else {
    toast.error(response.response.data.message);
  }
});

export const UpdateDietdetails = createAsyncThunk(
  "Update_diet_data",
  async ({ id, data }) => {
    console.log("diet data", data);
    let response = await ApiHelperFunction({
      urlPath: `admin/edit-diet/${id}`,
      method: "PUT",
      data: data,
    });
    console.log("Diet_Data", response);

    if (response.status === 200) {
      toast.success("Diet Type Updated successfully");
      return response.data;
    } else {
      toast.error(response.response.data.message);
    }
  }
);

export const DeleteDietdetails = createAsyncThunk(
  "delete_diet_data",
  async (id) => {
    let response = await ApiHelperFunction({
      urlPath: `admin/delete-diet/${id}`,
      method: "PUT",
    });
    console.log("Diet_Data", response);

    if (response.status === 200) {
      toast.success("Diet Type Deleted successfully");
      return response.data;
    } else {
      toast.error(response.response.data.message);
    }
  }
);

export const DietSlice = createSlice({
  name: "Diet_details_data",
  initialState,

  reducers: {
    clearState: (state) => {
      state.status = "idle";
      state.DietImage = "";
      state.DietData = null;
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(uploadDietImg.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(uploadDietImg.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.isSuccess = true;
        console.log("DietImage", payload);
        state.DietImage = payload.url;
      })
      .addCase(uploadDietImg.rejected, (state) => {
        state.status = "failed";
        state.isError = true;
        state.isSuccess = false;
      })
      .addCase(PostDietdetails.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(PostDietdetails.fulfilled, (state) => {
        state.status = "success";
        state.isSuccess = true;
      })
      .addCase(PostDietdetails.rejected, (state) => {
        state.status = "failed";
        state.isError = true;
        state.isSuccess = false;
      })
      .addCase(GetDietdetails.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(GetDietdetails.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.status = "success";
        state.isSuccess = true;
        state.DietData = payload.data;
      })
      .addCase(GetDietdetails.rejected, (state) => {
        state.isLoading = false;
        state.status = "failed";
        state.isError = true;
        state.isSuccess = false;
      })
      .addCase(UpdateDietdetails.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(UpdateDietdetails.fulfilled, (state) => {
        state.status = "success";
        state.isSuccess = true;
      })
      .addCase(UpdateDietdetails.rejected, (state) => {
        state.status = "failed";
        state.isError = true;
        state.isSuccess = false;
      })
      .addCase(DeleteDietdetails.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(DeleteDietdetails.fulfilled, (state) => {
        state.status = "success";
        state.isSuccess = true;
      })
      .addCase(DeleteDietdetails.rejected, (state) => {
        state.status = "failed";
        state.isError = true;
        state.isSuccess = false;
      });
  },
});

export const { clearState } = DietSlice.actions;

export default DietSlice.reducer;

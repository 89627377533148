import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  PostOcupation,
  UpdateOcupation,
  clearState,
  getOcupation,
} from "../../../redux/AdminSlice/OcupationSlice";

const AddOcupation = () => {
  let initial = {
    name: "",
  };

  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [ocupation, setOcupation] = useState(initial);

  const changehandler = (e) => {
    setOcupation((prevocupation) => ({
      ...prevocupation,
      name: e.target.value,
    }));
  };

  useEffect(() => {
    if (location.state) {
      setOcupation({
        name: location.state.name,
      });
    }
  }, []);

  const submitHandler = (e) => {
    e.preventDefault();
    if (ocupation.name === "") {
      toast.error("Occupation Is required");
      return false;
    }
    let data = {
      name: ocupation.name,
    };

    if (location.state) {
      const id = location.state._id;
      dispatch(UpdateOcupation({ id, data })).then(() => {
        setOcupation(initial);
        dispatch(clearState());
        dispatch(getOcupation());
        navigate("/manageOcupation");
      });
    } else {
      dispatch(PostOcupation(data)).then(() => {
        setOcupation(initial);
        dispatch(clearState());
      });
    }
  };
  return (
    <div className="main_wrap">
      <div className="aboutUs_page">
        <h4 className="Pageheadline">
          {location.state ? "Edit Occupation" : "Add Occupation"}
        </h4>
        <form action="" onSubmit={(e) => submitHandler(e)}>
          <div className="row">
            <div className="col-md-6 col-12">
              <label htmlFor="">Occupation</label>
              <input
                type="text"
                placeholder="Enter occupation"
                className="form-control"
                name="name"
                value={ocupation.name}
                onChange={(e) => changehandler(e)}
              />
            </div>
            <div
              className="col-md-6 col-12"
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "end",
              }}
            >
              {location.state ? (
                <button type="submit" className="btn btn-primary">
                  Submit
                </button>
              ) : (
                <button type="submit" className="btn btn-primary">
                  Add
                </button>
              )}
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddOcupation;

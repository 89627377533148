import React, { useEffect, useState } from "react";
import { Chart } from "react-google-charts";
import { ChartReligionData } from "../../redux/ReportSlice/ReligionReportSlice";
import { useDispatch, useSelector } from "react-redux";

export const options = {
  // title: "My Daily Activities",
  is3D: true,

  chartArea: {
    width: "80%",
    height: "80%",
  },
};

export function PieChart() {
  const dispatch = useDispatch();
  const [chartDataFormatted, setChartDataFormatted] = useState(null);

  const chartData = useSelector(
    (state) => state.religionReportReducer.ChartReligiondata
  );


  useEffect(() => {
    if (chartData) {
      console.log("chartData", chartData)
      const newdata = chartData?.reduce((prev, current) => {
        let data = [current.religionName, current.count];
        return [...prev, data];
      }, []);

      newdata.unshift(["Task", "Hours per Day"]);
      setChartDataFormatted(newdata);
    }
  }, [chartData]);

  useEffect(() => {
    dispatch(ChartReligionData());
  }, []);

  return (
    <>
      <section className="pieChart_area">
        {chartData ? (
          <>
            <Chart
              chartType="PieChart"
              data={chartDataFormatted}
              options={options}
              width={"100%"}
              height={"100%"}
            />
          </>
        ) : null}
      </section>
    </>
  );
}

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getProfileDetails } from "../redux/AdminSlice/profileSlice";
import {
  UpdateAdmin,
  uploadAdminProfileImage,
} from "../redux/authSlice/AuthSlice";
import { BASE_URL } from "../constants/config";

const ProfileModal = ({ closeModal }) => {
  const initial = {
    email: "",
    firstName: "",
    image: "",
    lastName: "",
    passwordCrypto: "",
  };
  const [changePass, setChangePass] = useState(initial);
  const dispatch = useDispatch();
  const [showNewPass, setShowNewPass] = useState(false);
  const [showConPass, setShowConPass] = useState(false);
  const [emailDisable, setEmailDisable] = useState(true);
  const [firstnameDisable, setFirstNameDisable] = useState(true);
  const [lastnameDisable, setLastNameDisable] = useState(true);

  const token = localStorage.getItem("Authorization");

  const { profile_data } = useSelector((state) => state.profileReducer);
  const { profileImg } = useSelector((state) => state.authReducer);

  console.log("BASE_URL", BASE_URL);

  const handleChange = (e) => {
    setChangePass({ ...changePass, [e.target.name]: e.target.value });
  };

  const Imagehandler = (e) => {
    let file = e.target.files;

    for (let element of file) {
      let data = new FormData();
      data.append("image", element);
      dispatch(uploadAdminProfileImage(data));
    }
  };

  const validation = () => {
    if (changePass.firstName === "") {
      toast.error("First Name is required");
      return false;
    }
    if (changePass.lastName === "") {
      toast.error("Last Name is required");
      return false;
    }
    if (changePass.email === "") {
      toast.error("email is required");
      return false;
    }
    return true;
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (validation()) {
      let data = {
        email: changePass.email,
        firstName: changePass.firstName,
        image: changePass.image,
        lastName: changePass.lastName,
      };
      console.log("data", data);
      dispatch(UpdateAdmin(data));

      closeModal(false);
      dispatch(getProfileDetails());
    }
  };

  useEffect(() => {
    dispatch(getProfileDetails(token));
  }, []);

  useEffect(() => {
    setChangePass({
      email: profile_data?.email,
      firstName: profile_data?.firstName,
      image: profile_data?.image,
      lastName: profile_data?.lastName,
      passwordCrypto: profile_data?.passwordCrypto,
    });
  }, [profile_data]);

  useEffect(() => {
    if (profileImg) {
      setChangePass((prevDiet) => ({
        ...prevDiet,
        image: profileImg,
      }));
    }
  }, [profileImg]);

  return (
    <>
      <section className="editProfile">
        <div className="editprofileInner">
          <div className="editprofileTop">
            <h3>Profile</h3>
            <i
              class="fa fa-times cross"
              style={{ cursor: "pointer" }}
              aria-hidden="true"
              onClick={() => closeModal(false)}
            ></i>
          </div>
          <div className="formheight">
            <form onSubmit={(e) => handleSubmit(e)}>
              <div class="avatar-upload">
                <div class="avatar-preview">
                  <img src={changePass.image} alt="profimeimg" />
                  <div className="inputpen">
                    <div class="avatar-edit">
                      <input
                        type="file"
                        id="imageUpload"
                        name="image"
                        accept="image/*"
                        onChange={(e) => Imagehandler(e)}
                      />
                      <label for="imageUpload"></label>
                    </div>
                    <div className="pensquare">
                      <i className="fa-solid fa-pen-to-square"></i>
                    </div>
                  </div>
                </div>
              </div>

              <div className="form-group">
                <label for="name">First Name</label>
                <div className="profileinputDiv">
                  <input
                    name="firstName"
                    type="text"
                    // value={userData?.subscription?.subscriberName}
                    value={changePass.firstName}
                    onChange={handleChange}
                    disabled={firstnameDisable}
                  />
                  <span>
                    <i
                      class="fa-regular fa-pen-to-square"
                      onClick={() => setFirstNameDisable(!firstnameDisable)}
                      style={{ cursor: "pointer" }}
                    ></i>
                  </span>
                </div>
              </div>
              <div className="form-group">
                <label for="name">Last Name</label>
                <div className="profileinputDiv">
                  <input
                    name="lastName"
                    type="text"
                    // value={userData?.subscription?.subscriberName}
                    value={changePass.lastName}
                    onChange={handleChange}
                    disabled={lastnameDisable}
                  />
                  <span>
                    <i
                      class="fa-regular fa-pen-to-square"
                      onClick={() => setLastNameDisable(!lastnameDisable)}
                      style={{ cursor: "pointer" }}
                    ></i>
                  </span>
                </div>
              </div>

              <div className="form-group">
                <label for="email">Email</label>
                <div className="profileinputDiv">
                  <input
                    type="email"
                    name="email"
                    value={changePass.email}
                    onChange={handleChange}
                    disabled={emailDisable}
                  />
                  <span>
                    <i
                      class="fa-regular fa-pen-to-square"
                      onClick={() => setEmailDisable(!emailDisable)}
                    ></i>
                  </span>
                </div>
              </div>

              {/* <div className="form-group">
              <label for="phone">Phone Number</label>
              <div className="profileinputDiv">
                <input
                  type="text"
                  name="mobileNumber"
                  // value={userData?.subscription?.mobileNumber}
                  //   value={data.mobileNumber}
                  //   onChange={handleChange}
                  //   disabled={emailDisable}
                />
                <span>
                  <i
                    class="fa-regular fa-pen-to-square"
                    // onClick={() => handleDisable()}
                  ></i>
                </span>
              </div>
            </div> */}

              <div className="form-group" id="editsubmitbtndiv">
                <button
                  className="editsubmitbtn"
                  // onClick={(e) => handleSubmit(e)}
                >
                  Update Profile
                </button>
              </div>
            </form>
          </div>
        </div>
        {/* <div className="renewbtnDiv">
          <button className="renewbtn" onClick={handleClick}>
            Renew
          </button>
        </div> */}
        {/* {modal && <RenewModal closeModal={setModal} />} */}
      </section>
    </>
  );
};

export default ProfileModal;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import {
  UpdateState,
  uploadStateImage,
  clearState,
  getState,
  postState,
} from "../../../redux/AdminSlice/StateSlice";
import { getCountry } from "../../../redux/AdminSlice/CountrySlice";

const AddState = () => {
  let initial = {
    name: "",
    countryId: "",
    image: "",
  };
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [state, setState] = useState(initial);

  console.log("state", state);

  const { stateImage } = useSelector((state) => state.stateReducer);

  const { countrydata } = useSelector((state) => state.countryReducer);

  const changehandler = (e) => {
    setState((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const Imagehandler = (e) => {
    let file = e.target.files;

    for (let element of file) {
      let data = new FormData();
      data.append("image", element);
      dispatch(uploadStateImage(data));
    }
  };

  useEffect(() => {
    if (location.state) {
      setState({
        name: location.state.name,
        image: location.state.image,
        countryId: location.state?.countryId,
      });
    }
    if (stateImage) {
      setState((prevstate) => ({
        ...prevstate,
        image: stateImage,
      }));
    }
  }, [stateImage]);

  const submitHandler = (e) => {
    e.preventDefault();
    if (state.countryId === "") {
      toast.error("Country Name Is Required");
      return false;
    }
    if (state.name === "") {
      toast.error("State Name Is Required");
      return false;
    }
    if (state.image === "") {
      toast.error("State image Is Required");
      return false;
    }
    let data = {
      name: state.name,
      image: state.image ? state.image : stateImage,
      countryId: state.countryId,
    };

    if (location.state) {
      const id = location.state._id;
      dispatch(UpdateState({ id, data }));
      setState(initial);
      dispatch(clearState());
      dispatch(getState());
      navigate("/manageState");
    } else {
      dispatch(postState(data));
      setState(initial);
      dispatch(clearState());
    }
  };

  useEffect(() => {
    dispatch(getCountry());
  }, []);

  return (
    <div className="main_wrap">
      <div className="aboutUs_page">
        <h4 className="Pageheadline">
          {location.state ? "State" : "Add State"}
        </h4>
        <form action="" onSubmit={(e) => submitHandler(e)}>
          <div className="row">
            {/* Country */}
            <div className="" style={{ width: "22%", marginLeft: "10px" }}>
              <label htmlFor="religionId">Country Name</label>
              <select
                class="form-select table_page_select"
                aria-label="Default select example"
                onChange={(e) => changehandler(e)}
                name="countryId"
                value={state.countryId}
              >
                <option selected value="">
                  Select Country
                </option>

                {countrydata?.map((item) => {
                  return <option value={item?._id}>{item?.name}</option>;
                })}
              </select>
            </div>

            {/* State */}
            <div className="col-md-3 col-12">
              <label htmlFor="">State Name</label>
              <input
                type="text"
                name="name"
                className="form-control"
                placeholder="Enter State Name"
                value={state.name}
                onChange={(e) => changehandler(e)}
              />
            </div>

            {/* image */}
            <div className="col-md-3 col-12">
              <label htmlFor=""> State image</label>
              <input
                type="file"
                className="form-control"
                name="image"
                onChange={(e) => Imagehandler(e)}
                accept="image/*"
              />
            </div>

            <div
              className="col-md-3 col-12"
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "end",
              }}
            >
              {location.state ? (
                <button type="submit" className="btn btn-primary">
                  Submit
                </button>
              ) : (
                <button type="submit" className="btn btn-primary">
                  Add
                </button>
              )}
            </div>
          </div>
          {stateImage ? (
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                marginTop: "5px",
              }}
            >
              <img
                style={{ height: "100px", width: "100px", marginLeft: "100px" }}
                src={stateImage}
                alt=""
              />
              <i
                class="fa-solid fa-xmark"
                style={{ cursor: "pointer" }}
                onClick={() => dispatch(clearState())}
              ></i>
            </div>
          ) : location.state ? (
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                marginTop: "5px",
              }}
            >
              <img
                style={{ height: "100px", width: "100px", marginLeft: "100px" }}
                src={state.image}
                alt=""
              />
            </div>
          ) : (
            ""
          )}
        </form>
      </div>
    </div>
  );
};

export default AddState;

import React, { useEffect, useMemo, useState } from "react";
import Table from "../../Table/Table";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { DeleteHeight, GetHeight } from "../../../redux/AdminSlice/HeightSlice";
import { DeleteConfirmModal } from "../../../DeleteAlert/DeleteAlert";

const ManageHeight = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchText, setsearchText] = useState("");

  const HeightData = useSelector((state) => state.heightReducer.HeightData);
  console.log("HeightData", HeightData);

  useEffect(() => {
    dispatch(GetHeight());
  }, []);

  const deleteProfile = (id) => {
    const del = async () => {
      dispatch(DeleteHeight(id)).then(() => {
        dispatch(GetHeight());
      });
    };

    DeleteConfirmModal(del);
  };

  const filterData = useMemo(() => {
    if (searchText !== "") {
      return HeightData?.filter((item) =>
        JSON.stringify(item).toLowerCase().includes(searchText.toLowerCase())
      );
    }
    return HeightData;
  }, [searchText, HeightData]);

  const handleView = (e, item) => {
    e.preventDefault();

    navigate(`/addHeight`, { state: item });
  };

  const tableData = useMemo(() => {
    return {
      columns: [
        {
          label: "SL",
          field: "sl",
          sort: "asc",
          width: 270,
        },
        {
          label: "Height In CM",
          field: "heightcm",
          sort: "asc",
          width: 150,
        },
        {
          label: "Height In FT",
          field: "heightft",
          sort: "asc",
          width: 150,
        },
        {
          label: "Height In Inch",
          field: "heightinch",
          sort: "asc",
          width: 150,
        },
        {
          label: "Action",
          field: "action",
          sort: "asc",
          width: 150,
        },
      ],
      rows:
        filterData?.length > 0
          ? filterData.map((item, idx) => {
              console.log("item", item);
              return {
                sl: idx + 1,
                heightcm: (
                  <div className="widget-content p-0" key={idx}>
                    <div className="widget-content-wrapper">
                      <div className="widget-content-left flex2">
                        <div className="widget-heading" style={{ opacity: 1 }}>
                          {item.name} cm
                        </div>
                      </div>
                    </div>
                  </div>
                ),
                heightft: (
                  <div className="widget-content p-0" key={idx}>
                    <div className="widget-content-wrapper">
                      <div className="widget-content-left flex2">
                        <div className="widget-heading" style={{ opacity: 1 }}>
                          {item.feet} “ft”
                        </div>
                      </div>
                    </div>
                  </div>
                ),
                heightinch: (
                  <div className="widget-content p-0" key={idx}>
                    <div className="widget-content-wrapper">
                      <div className="widget-content-left flex2">
                        <div className="widget-heading" style={{ opacity: 1 }}>
                          {item.inch} in”
                        </div>
                      </div>
                    </div>
                  </div>
                ),
                action: (
                  <div className="widget-content p-0" key={idx}>
                    <div className="widget-content-wrapper">
                      <div className="widget-content-center flex2">
                        <i
                          class="fa-solid fa-pencil"
                          style={{ color: "blue", cursor: "pointer" }}
                          onClick={(e) => handleView(e, item)}
                        ></i>
                        <i
                          class="fa-solid fa-trash-can"
                          style={{
                            color: "red",
                            marginLeft: "15px",
                            cursor: "pointer",
                          }}
                          onClick={() => deleteProfile(item._id)}
                        ></i>
                      </div>
                    </div>
                  </div>
                ),
              };
            })
          : [],
    };
  });
  return (
    <>
      <div className="main_wrap">
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <h4 className="Pageheadline">Manage Height</h4>
          <button
            className="btn btn-primary"
            style={{ height: "33px" }}
            onClick={() => navigate("/addHeight")}
          >
            Add
          </button>
        </div>
        <div style={{ overflowX: "auto" }} className="dataTableParDiv">
          <Table
            striped
            tableTitle="All abouts"
            data={tableData}
            onSearch={(e) => setsearchText(e.target.value)}
          />
        </div>
      </div>
    </>
  );
};

export default ManageHeight;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  GetCastData,
  PostCastData,
  UpdateCastData,
} from "../../../redux/AdminSlice/CastSlice";
import {
  GetCommunityBasedSubCommunity,
  GetSubCommunity,
} from "../../../redux/AdminSlice/SubCommunitySlice";
import {
  GetCommunity,
  ReligianbasedCommunity,
} from "../../../redux/AdminSlice/CommunitySlice";
import { GetReligian } from "../../../redux/AdminSlice/ReligionSlice";

const AddCast = () => {
  const initial = {
    name: "",
    communityName: "",
    subCommunityId: "",
    religionName: "",
  };
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [caste, setCaste] = useState(initial);

  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("id");

  const ProfileIsForData = useSelector(
    (state) => state.profileIsForReducer.ProfileIsForData
  );

  const allreligion = useSelector(
    (state) => state.religionReducer.ReligianData
  );
  const AllCommunity = useSelector(
    (state) => state.communityReducer.CommunityData
  );

  const AllsubCommunity = useSelector(
    (state) => state.subCommunityReducer?.SubCommunityData
  );
  console.log("caste", caste);

  const changehandler = (e) => {
    if (e.target.name === "religionName") {
      const selectedReligionId = e.target.value;

      if (selectedReligionId) {
        dispatch(ReligianbasedCommunity(selectedReligionId));
      }
    }

    if (e.target.name === "communityName") {
      const selectedCommunityId = e.target.value;

      if (selectedCommunityId) {
        dispatch(GetCommunityBasedSubCommunity(selectedCommunityId));
      }
    }

    setCaste({
      ...caste,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    if (location.state) {
      setCaste({
        name: location.state.name,
        communityName: location.state?.communityName,
        subCommunityId: location.state?.subCommunityId,
        religionName: location.state?.religionName,
      });
    }
  }, []);

  useEffect(() => {
    dispatch(GetReligian());
    dispatch(GetCommunity());
    dispatch(GetSubCommunity());
  }, []);

  const submitHandler = (e) => {
    e.preventDefault();
    if (caste.religionName === "") {
      toast.error("Religion name Is required");
      return false;
    }
    if (caste.communityName === "") {
      toast.error("Community name Is required");
      return false;
    }
    if (caste.subCommunityId === "") {
      toast.error("Sub Community name Is required");
      return false;
    }
    if (caste.name === "") {
      toast.error("Caste name Is required");
      return false;
    }
    let data = {
      name: caste.name,
      subCommunityId: caste.subCommunityId,
    };

    if (location.state) {
      const id = location.state._id;
      dispatch(UpdateCastData({ id, data }));
      setCaste(initial);
      dispatch(GetCastData());
      navigate("/manageCast");
    } else {
      dispatch(PostCastData(data));
      setCaste(initial);
    }
  };
  return (
    <div className="main_wrap">
      <div className="aboutUs_page">
        <h4 className="Pageheadline">
          {location.state ? "Edit Caste" : "Add Caste"}
        </h4>
        <form action="" onSubmit={(e) => submitHandler(e)}>
          <div className="row">
            {/* Religion */}
            <div className="" style={{ width: "22%", marginLeft: "10px" }}>
              <label htmlFor="religionName">Religion Name</label>
              <select
                class="form-select table_page_select"
                aria-label="Default select example"
                onChange={(e) => changehandler(e)}
                name="religionName"
                value={caste.religionName}
              >
                {" "}
                <option selected value="">
                  Select Religion
                </option>
                {/* {console.log("allreligion", allreligion)} */}
                {allreligion?.map((item) => {
                  return (
                    <option
                      // selected={item._id === caste.religionName}
                      value={item?.name}
                    >
                      {item?.name}
                    </option>
                  );
                })}
              </select>
            </div>
            {/* community */}
            <div className="" style={{ width: "21%", marginLeft: "5px" }}>
              <label htmlFor="communityName">Community Name</label>
              <select
                class="form-select table_page_select"
                aria-label="Default select example"
                onChange={(e) => changehandler(e)}
                name="communityName"
                value={caste.communityName}
              >
                {" "}
                <option selected value="">
                  Select Community
                </option>
                {console.log("AllCommunity", AllCommunity)}
                {AllCommunity?.map((item) => {
                  return (
                    <option
                      // selected={item.name === caste.communityName}
                      value={item?.name}
                    >
                      {item?.name}
                    </option>
                  );
                  // <option value={item?.name}>{item?.name}</option>;
                })}
                {/* {AllCommunity &&
                Array.isArray(AllCommunity) &&
                AllCommunity.map((item) => (
                  <option key={item.id} value={item.name}>
                    {item.name.toUpperCase()}
                  </option>
                ))} */}
              </select>
            </div>
            {/* sub community */}
            <div
              style={{ width: "230px", marginLeft: "5px", marginTop: "5px" }}
            >
              <label htmlFor="">Sub Community Name</label>
              <select
                class="form-select table_page_select"
                aria-label="Default select example"
                onChange={(e) => changehandler(e)}
                name="subCommunityId"
                value={caste.subCommunityId}
              >
                {" "}
                <option selected value="">
                  Select Sub Community
                </option>
                {AllsubCommunity?.map((item) => {
                  return <option value={item?._id}>{item?.name}</option>;
                })}
              </select>
            </div>
            {/* caste */}
            <div className="col-md-3 col-12">
              <label htmlFor="">Caste Name</label>
              <input
                type="text"
                placeholder="Enter Caste"
                className="form-control"
                name="name"
                value={caste.name}
                onChange={(e) => changehandler(e)}
              />
            </div>
            <div
              className="col-md-1 "
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "end",
              }}
            >
              {location.state ? (
                <button type="submit" className="btn btn-primary">
                  Submit
                </button>
              ) : (
                <button type="submit" className="btn btn-primary">
                  Add
                </button>
              )}
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddCast;

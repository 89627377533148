import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

import { ApiHelperFunction } from "../../utils/HttpClient";

const initialState = {
  status: "idle",
  password_Change_details: {},
  isLoading: false,
  isSuccess: false,
  isError: false,
};

export const changePassword = createAsyncThunk(
  "change_password",
  async (data) => {
    let response = await ApiHelperFunction({
      urlPath: "admin/change-password",
      method: "PUT",
      data: data,
    });
    console.log("response", response);
    if (response.status === 200) {
      toast.success("Password changed successfully");
      return response.data;
    } else {
      toast.error("password change failed");
    }
  }
);

export const ChangePasswordSlice = createSlice({
  name: "password_Change",
  initialState,
  reducers: {
    clearState: (state) => {
      state.password_Change_details = {};
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(changePassword.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(changePassword.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.isSuccess = true;
      })
      .addCase(changePassword.rejected, (state) => {
        state.status = "failed";
        state.isError = true;
        state.isSuccess = false;
      });
  },
});

export const { clearState } = ChangePasswordSlice.actions;

export default ChangePasswordSlice.reducer;

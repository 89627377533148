import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

// Helpers
import { ApiHelperFunction } from "../../utils/HttpClient";

let initialState = {
  allUsers: [],
  isLoading: false,
  isSuccess: false,
  isError: false,
};

export const getAllUsers = createAsyncThunk(
  "get_all_users",
  async (userType) => {
    let response = await ApiHelperFunction({
      urlPath: userType
        ? `/admin/get-users?userType=${userType}`
        : `/admin/get-users`,

      method: "GET",
    });
    console.log("allUsers", response?.data?.data);

    if (response.status === 200) {
      return response?.data?.data;
    } else {
      toast.error("Can't get data. Something went wrong");
    }
  }
);

export const AllUserSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    clearState: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllUsers.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(getAllUsers.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.isSuccess = true;
        // console.log('payload', payload);
        state.allUsers = payload;
      })
      .addCase(getAllUsers.rejected, (state) => {
        state.status = "failed";
        state.isError = true;
        state.isSuccess = false;
      });
  },
});

export const { clearState } = AllUserSlice.actions;

export default AllUserSlice.reducer;

import React, { useEffect, useState } from "react";
import { getAllUserCount } from "../../redux/AdminSlice/ManageUserSlice";
import { useDispatch, useSelector } from "react-redux";
import CustomLoader from "../../Loader/CustomLoader";

const HomeCard = () => {
  const dispatch = useDispatch();
  const [totalUsers, setTotalUsers] = useState("");
  const [todaysUsers, setTodaysUsers] = useState("");

  const userCountDetails = useSelector(
    (state) => state.manageuserReducer.userTotalCount
  );

  const isLoading = useSelector((state) => state.manageuserReducer.isLoading);
  console.log("userCountDetails", userCountDetails);

  function abbreviateNumber(number) {
    if (number >= 1000 && number < 1000000) {
      const abbreviated = (number / 1000).toFixed(1);
      return abbreviated.endsWith(".0")
        ? (number / 1000).toFixed(0) + "k"
        : abbreviated + "k";
    } else if (number >= 1000000) {
      return (number / 1000000).toFixed(1) + "M";
    } else {
      return number?.toString();
    }
  }

  useEffect(() => {
    dispatch(getAllUserCount());
    // .then(() => {
    //   const totalUsersValue = abbreviateNumber(userCountDetails?.totalUser);
    //   setTotalUsers(totalUsersValue);
    //   const todaysUsersValue = abbreviateNumber(
    //     userCountDetails?.todaysTotalUser
    //   );
    //   setTodaysUsers(todaysUsersValue);
    // });
  }, []);

  const card = [
    {
      id: 1,
      title: "Total Users",
      // amount: totalUsers,
      amount: userCountDetails?.totalUser,
      iconcolor: "rgb(236, 64, 122)",
      value: Number(userCountDetails?.lastWeakPercentage).toFixed(0),
    },
    {
      id: 2,
      title: "Total Revenue",
      amount: "₹2,338",
      iconcolor: "rgb(73, 163, 241)",
      value: 2.34,
    },
    {
      id: 3,
      title: "Today's Revenue",
      amount: "847",
      iconcolor: "rgb(102, 187, 106)",
      value: 2.34,
    },

    {
      id: 4,
      title: "Today New uers",
      amount: userCountDetails?.todaysTotalUser,
      iconcolor: "#6a3fcc",
      value: Number(userCountDetails?.lastDaysPercentage).toFixed(0),
    },
  ];

  console.log("todaysTotalUser", userCountDetails);

  return (
    <>
      <div className="HomeTop">
        <CustomLoader loading={isLoading} />
        <div className="row">
          {card.map((item, index) => {
            // console.log("value", item?.value)
            return (
              <div className="col-lg-3 col-md-6 col-12" key={index}>
                <div className="HomeCard">
                  <h5 className="HomeCardHead">{item.title}</h5>

                  <div className="alldata">
                    <div className="data-group">
                      <div className="HomeCardPara">{item.amount}</div>

                      <div
                        className="icon_box"
                        style={{ color: item.iconcolor }}
                      >
                        <i className="fa-solid fa-arrow-trend-up"></i>
                      </div>
                    </div>
                    <div className="info">
                      {item.value >= 4 ? (
                        <span className="change_up">
                          <i class="fa-solid fa-arrow-up mr-1"></i>
                          {item.value} %
                        </span>
                      ) : (
                        <span className="change_down">
                          <i class="fa-solid fa-arrow-down mr-1"></i>
                          {item.value} %
                        </span>
                      )}
                      <span> vs. last week</span>
                    </div>
                  </div>

                  {/* <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "end" }}>
                    <button
                      className="HomeCardViewbtn"
                    >
                      View
                    </button>
                  </div> */}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default HomeCard;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  GetAccountViewPrice,
  PostAccountViewPrice,
  UpdateAccountViewPrice,
} from "../../redux/AdminSlice/AccountViewPriceSlice";
const AddPayment = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const initial = { price: "", discount_percentage: "" };
  const [accountViewPricedata, setAccountViewPricedata] = useState(initial);

  const ProfileIsForData = useSelector(
    (state) => state.profileIsForReducer.ProfileIsForData
  );

  const changehandler = (e) => {
    setAccountViewPricedata({
      ...accountViewPricedata,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    if (location.state) {
      setAccountViewPricedata({
        price: location.state.price,
        discount_percentage: location.state.discount_percentage,
      });
    }
  }, []);

  const submitHandler = (e) => {
    e.preventDefault();
    if (accountViewPricedata.price === "") {
      toast.error("Account View Price Is required");
      return false;
    }
    let data = {
      price: accountViewPricedata.price,
      discount_percentage: accountViewPricedata.discount_percentage,
    };

    if (location.state) {
      const id = location.state._id;
      dispatch(UpdateAccountViewPrice({ id, data }));
      setAccountViewPricedata(initial);
      dispatch(GetAccountViewPrice());
      navigate("/managePayment");
    } else {
      dispatch(PostAccountViewPrice(data));
      setAccountViewPricedata(initial);
    }
  };
  return (
    <div className="main_wrap">
      <div className="aboutUs_page">
        <h4 className="Pageheadline">
          {location.state
            ? "Edit Account View Price "
            : "Add Account View Price"}
        </h4>
        <form action="" onSubmit={(e) => submitHandler(e)}>
          <div className="row">
            <div className="col-md-3 col-12">
              <label htmlFor="">Account View Price</label>
              <input
                type="text"
                placeholder="Enter Account View Price "
                className="form-control"
                name="price"
                value={accountViewPricedata.price}
                onChange={(e) => changehandler(e)}
              />
            </div>
            <div className="col-md-3 col-12">
              <label htmlFor="">Discount Price</label>
              <input
                type="number"
                placeholder="Enter Discount Price "
                className="form-control"
                name="discount_percentage"
                min={0}
                value={accountViewPricedata.discount_percentage}
                onChange={(e) => changehandler(e)}
              />
            </div>
            <div
              className="col-md-4 col-12"
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "end",
              }}
            >
              {location.state ? (
                <button type="submit" className="btn btn-primary">
                  Submit
                </button>
              ) : (
                <button type="submit" className="btn btn-primary">
                  Add
                </button>
              )}
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddPayment;

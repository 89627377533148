import React, { useState } from "react";
import { MDBDataTable } from "mdbreact";
import { useDispatch, useSelector } from "react-redux";
import { getSingleUserReports } from "../../redux/ReportSlice/UserReportSlice";

const Table = ({
  striped,
  data,
  paging = true,
  onSearch,
  onIdSearch,
  flag,
}) => {
  const [singleData, setSingleData] = useState("");
  const dispatch = useDispatch();

  const changeHandle = (e) => {
    setSingleData(e.target.value)
  };

  // console.log("singleData", singleData);

  const submitHandler = () => {
    dispatch(getSingleUserReports(singleData))
  };

  return (
    <>
      <div className="">
        <div className="dataTable">
          {!flag ? (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                margin: "0px 10px",
              }}
            >
              <input
                type="text"
                className="form-control"
                placeholder="Search"
                onChange={onSearch}
                style={{ width: "40%" }}
              />
            </div>
          ) : (
            <div className="Search_main_div">
              <div className="searchById">
                <input
                  type="text"
                  name="singleData"
                  placeholder="Enter user Id"
                  // onChange={onIdSearch}
                  onChange={(e) => changeHandle(e)}
                  value={singleData}
                />
              </div>
              <div>
                <button
                  className="searchById_btn"
                  onClick={() => {
                    submitHandler();
                  }}
                >
                  Search
                </button>
              </div>
            </div>
          )}
          {striped ? (
            <MDBDataTable
              striped
              data={data}
              searching={false}
              paging={paging}
            />
          ) : (
            <MDBDataTable data={data} />
          )}
        </div>
      </div>
    </>
  );
};

export default Table;

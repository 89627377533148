import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  PostAnnualIncome,
  UpdateAnnualIncome,
  clearState,
  getAnnualIncome,
} from "../../../redux/AdminSlice/AnnualIncomeSlice";

const AddAnnualIncome = () => {
  let initial = {
    currency: "",
    startSallery: "",
    endSallery: "",
    salleryUnit: "",
  };

  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [income, setIncome] = useState(initial);

  const changehandler = (e) => {
    setIncome({
      ...income,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    if (location.state) {
      setIncome({
        currency: location.state.currency,
        startSallery: location.state.startSallery,
        endSallery: location.state.endSallery,
        salleryUnit: location.state.salleryUnit,
      });
    }
  }, []);

  const submitHandler = (e) => {
    e.preventDefault();
    if (income.currency === "") {
      toast.error("Currency Is required");
      return false;
    }
    if (income.startSallery === "") {
      toast.error("Start Amount Range Is required");
      return false;
    }
    if (income.endSallery === "") {
      toast.error("End Amount Range Is required");
      return false;
    }
    if (income.salleryUnit === "") {
      toast.error("Salary Unit Is required");
      return false;
    }

    let data = {
      currency: income.currency,
      startSallery: Number(income.startSallery),
      endSallery: Number(income.endSallery),
      salleryUnit: income.salleryUnit,
    };

    if (location.state) {
      const id = location.state._id;
      dispatch(UpdateAnnualIncome({ id, data })).then(() => {
        setIncome(initial);
        dispatch(clearState());
        dispatch(getAnnualIncome());
        navigate("/manageAnualIncome");
      });
    } else {
      dispatch(PostAnnualIncome(data)).then(() => {
        setIncome(initial);
        dispatch(clearState());
      });
    }
  };
  return (
    <div className="main_wrap">
      <div className="aboutUs_page">
        <h4 className="Pageheadline">
          {location.state ? "Edit Annual income" : "Add Annual income"}
        </h4>
        <form action="" className="row m-0" onSubmit={(e) => submitHandler(e)}>
          <div className="col-md-6 col-12">
            <label htmlFor="">Currency</label>
            <div className="incomeSelectCurrency">
              <select
                className="form-select"
                style={{ padding: "8px" }}
                aria-label="Default select example"
                name="currency"
                value={income.currency}
                onChange={(e) => changehandler(e)}
              >
                <option selected>Currency</option>
                <option value="INR">INR</option>
              </select>
            </div>
          </div>
          <div className="col-md-6 col-12">
            <label htmlFor="">Income Start Range</label>
            <div className="incomeRange">
              <input
                type="text"
                placeholder="Enter Start Range"
                className="form-control"
                name="startSallery"
                value={income.startSallery}
                onChange={(e) => changehandler(e)}
              />
            </div>
          </div>
          <div className="col-md-4 col-12 mt-2">
            <label htmlFor="">Income End Range</label>
            <div className="incomeRange">
              <input
                type="text"
                placeholder="Enter End Range"
                className="form-control"
                name="endSallery"
                value={income.endSallery}
                onChange={(e) => changehandler(e)}
              />
            </div>
          </div>
          <div className="col-md-4 col-12 mt-2">
            <label htmlFor="">Salary Unit</label>
            <div className="incomeSelect">
              <select
                className="form-select"
                style={{ padding: "8px" }}
                aria-label="Default select example"
                name="salleryUnit"
                value={income.salleryUnit}
                onChange={(e) => changehandler(e)}
              >
                <option selected>Unit</option>
                <option value="LPA">LPA</option>
                <option value="CR">CR</option>
              </select>
            </div>
          </div>

          <div
          className="col-md-4 col-12"
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-start",
              alignItems: 'end'
            }}
          >
            {location.state ? (
              <button type="submit" className="btn btn-primary">
                Submit
              </button>
            ) : (
              <button type="submit" className="btn btn-primary">
                Add
              </button>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddAnnualIncome;

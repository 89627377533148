import React from "react";
import ManageChat from "./ManageChat";

const AddChat = () => {
    const handleSubmit = () => {}
  return (
    <div className="main_wrap">
      <h4 className="Pageheadline">Add Chats</h4>
      <form className="row m-0" onSubmit={(e) => handleSubmit(e)}>
        <div class="form-group col-md-12 col-12">
          <label for="AddressLine1">Add Chat </label>
          <input
            type="text"
            class="form-control"
            id="title"
            placeholder="Enter Chats"
            name="title"
            // value={addressDetails.addressLineOne}
            // onChange={(e) => handleChange(e)}
          />
        </div>

        <div className="col-md-6 col-12 m-auto">
          <button className="btn btn-primary">Add </button>
        </div>
      </form>

      <ManageChat />
    </div>
  );
};

export default AddChat;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

// Helpers
import { ApiHelperFunction } from "../../utils/HttpClient";

let initialState = {
  status: "idle",
  OcupationData: {},
  isLoading: false,
  isSuccess: false,
  isError: false,
};

export const PostOcupation = createAsyncThunk(
  "occupation_post",
  async (data) => {
    let response = await ApiHelperFunction({
      urlPath: "admin/add-occupation",
      method: "POST",
      data: data,
    });
    console.log("contact_details", response);

    if (response.status === 200) {
      toast.success("Occupation added successfully");
      return response.data;
    } else {
      toast.error(response?.response?.data?.message);
    }
  }
);

export const getOcupation = createAsyncThunk("occupation_get", async () => {
  let response = await ApiHelperFunction({
    urlPath: "admin/view-occupation",
    method: "GET",
  });
  console.log("contact_details", response);

  if (response.status === 200) {
    return response.data;
  } else {
    toast.error(response?.response?.data?.message);
  }
});

export const UpdateOcupation = createAsyncThunk(
  "occupation_update",
  async ({ id, data }) => {
    console.log("updatedItem", data);
    let response = await ApiHelperFunction({
      urlPath: `admin/edit-occupation/${id}`,
      method: "PUT",
      data: data,
    });
    console.log("contact_details", response);

    if (response.status === 200) {
      toast.success("Occupation Updated Successfully");
      return response.data;
    } else {
      toast.error(response?.response?.data?.message);
    }
  }
);

export const DeleteOcupation = createAsyncThunk(
  "occupation_Delete",
  async (id) => {
    let response = await ApiHelperFunction({
      urlPath: `admin/delete-occupation/${id}`,
      method: "PUT",
    });
    console.log("contact_details", response);

    if (response.status === 200) {
      toast.success("Occupation Deleted Successfully");
      return response.data;
    } else {
      toast.error(response?.response?.data?.message);
    }
  }
);

export const OcupationSlice = createSlice({
  name: "Occupation_details_data",
  initialState,
  reducers: {
    clearState: (state) => {
      state.status = "idle";
      state.OcupationData = {};
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(PostOcupation.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(PostOcupation.fulfilled, (state) => {
        state.status = "success";
        state.isSuccess = true;
        // Optionally, you can update state after a successful post if needed
      })
      .addCase(PostOcupation.rejected, (state) => {
        state.status = "failed";
        state.isError = true;
        state.isSuccess = false;
      })
      .addCase(getOcupation.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(getOcupation.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.status = "success";
        state.isSuccess = true;
        console.log("payload", payload?.data);
        state.OcupationData = payload?.data;
      })
      .addCase(getOcupation.rejected, (state) => {
        state.isLoading = false;
        state.status = "failed";
        state.isError = true;
        state.isSuccess = false;
      })
      .addCase(UpdateOcupation.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(UpdateOcupation.fulfilled, (state) => {
        state.status = "success";
        state.isSuccess = true;
      })
      .addCase(UpdateOcupation.rejected, (state) => {
        state.status = "failed";
        state.isError = true;
        state.isSuccess = false;
      })
      .addCase(DeleteOcupation.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(DeleteOcupation.fulfilled, (state) => {
        state.status = "success";
        state.isSuccess = true;
      })
      .addCase(DeleteOcupation.rejected, (state) => {
        state.status = "failed";
        state.isError = true;
        state.isSuccess = false;
      });
  },
});

export const { clearState } = OcupationSlice.actions;

export default OcupationSlice.reducer;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

// Helpers
import { ApiHelperFunction } from "../../utils/HttpClient";

let initialState = {
  status: "idle",
  AnnualIncomeData: {},
  isLoading: false,
  isSuccess: false,
  isError: false,
};

export const PostAnnualIncome = createAsyncThunk(
  "Annual_Income_post",
  async (data) => {
    let response = await ApiHelperFunction({
      urlPath: "admin/add-annual-income",
      method: "POST",
      data: data,
    });
    console.log("contact_details", response);

    if (response.status === 200) {
      toast.success("Annual income add successfully");
      return response.data;
    } else {
      toast.error(response?.response?.data?.message);
    }
  }
);

export const getAnnualIncome = createAsyncThunk(
  "Annual_Income_get",
  async () => {
    let response = await ApiHelperFunction({
      urlPath: "admin/view-annual-income",
      method: "GET",
    });
    console.log("contact_details", response);

    if (response.status === 200) {
      return response.data;
    } else {
      toast.error(response?.response?.data?.message);
    }
  }
);

export const UpdateAnnualIncome = createAsyncThunk(
  "Annual_income_update",
  async ({ id, data }) => {
    console.log("updatedItem", data);
    let response = await ApiHelperFunction({
      urlPath: `admin/edit-annual-income/${id}`,
      method: "PUT",
      data: data,
    });
    console.log("contact_details", response);

    if (response.status === 200) {
      toast.success("Annual Income Updated Successfully");
      return response.data;
    } else {
      toast.error(response?.response?.data?.message);
    }
  }
);

export const deleteAnnualIncome = createAsyncThunk(
  "Annual_Income_Delete",
  async (id) => {
    let response = await ApiHelperFunction({
      urlPath: `admin/delete-annual-income/${id}`,
      method: "PUT",
    });
    console.log("contact_details", response);

    if (response.status === 200) {
      toast.success("Annual Income Deleted Successfully");
      return response.data;
    } else {
      toast.error(response?.response?.data?.message);
    }
  }
);

export const AnnualIncomeSlice = createSlice({
  name: "Annual_income_data",
  initialState,
  reducers: {
    clearState: (state) => {
      state.status = "idle";
      state.AnnualIncomeData = {};
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(PostAnnualIncome.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(PostAnnualIncome.fulfilled, (state) => {
        state.status = "success";
        state.isSuccess = true;
        // Optionally, you can update state after a successful post if needed
      })
      .addCase(PostAnnualIncome.rejected, (state) => {
        state.status = "failed";
        state.isError = true;
        state.isSuccess = false;
      })
      .addCase(getAnnualIncome.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(getAnnualIncome.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.status = "success";
        state.isSuccess = true;
        console.log("payload", payload?.data);
        state.AnnualIncomeData = payload?.data;
      })
      .addCase(getAnnualIncome.rejected, (state) => {
        state.isLoading = false;
        state.status = "failed";
        state.isError = true;
        state.isSuccess = false;
      })
      .addCase(UpdateAnnualIncome.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(UpdateAnnualIncome.fulfilled, (state) => {
        state.status = "success";
        state.isSuccess = true;
      })
      .addCase(UpdateAnnualIncome.rejected, (state) => {
        state.status = "failed";
        state.isError = true;
        state.isSuccess = false;
      })
      .addCase(deleteAnnualIncome.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(deleteAnnualIncome.fulfilled, (state) => {
        state.status = "success";
        state.isSuccess = true;
      })
      .addCase(deleteAnnualIncome.rejected, (state) => {
        state.status = "failed";
        state.isError = true;
        state.isSuccess = false;
      });
  },
});

export const { clearState } = AnnualIncomeSlice.actions;

export default AnnualIncomeSlice.reducer;

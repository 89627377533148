import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  GetPagesData,
  PostPagesData,
  UpdatePagesData,
} from "../../../redux/AdminSlice/AddPagesSlice";
import { toast } from "react-toastify";

const AddPages = () => {
  const initial = {
    title: "",
    slug: "",
    meta_description: "",
    content: "",
  };
  const [pagesData, setPagesData] = useState(initial);
  console.log("pagesData", pagesData);

  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const createSlug = (str) => {
    return str
      .toLowerCase()
      .replace(/[^\w\s-]/g, "") // Remove non-word characters (except spaces and hyphens)
      .replace(/\s+/g, "-") // Replace spaces with a hyphen
      .replace(/--+/g, "-") // Replace multiple hyphens with a single one
      .trim(); // Trim leading/trailing spaces or hyphens
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setPagesData((prev) => ({
      ...prev,
      title: name === "title" ? value : prev.title,
      meta_description:
        name === "meta_description" ? value : prev.meta_description,
    }));
  };

  const handleSlugChange = (e) => {
    const slug = createSlug(e.target.value);
    setPagesData((prev) => ({
      ...prev,
      slug: slug,
    }));
  };
  const handleEditorChange = (event, editor) => {
    const plainText = editor.getData();
    setPagesData((prev) => ({
      ...prev,
      content: plainText,
    }));
  };

  const validation = () => {
    if (pagesData?.title === "") {
      toast.error("Page Title Is Required");
      return false;
    }
    if (pagesData?.slug === "") {
      toast.error("Page slug Is Required");
      return false;
    }
    if (pagesData?.meta_description === "") {
      toast.error("Page meta description Is Required");
      return false;
    }
    if (pagesData?.content === "") {
      toast.error("Page content Is Required");
      return false;
    }

    return true;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (validation()) {
      let data = {
        title: pagesData?.title,
        slug: pagesData?.slug,
        content: pagesData?.content.replace("<p>", "").replace("</p>", ""),
        meta_description: pagesData?.meta_description,
      };

      console.log("pages_data", data);

      if (location.state) {
        const id = location.state._id;
        dispatch(UpdatePagesData({ id, data })).then(() => {
          dispatch(GetPagesData());
          setPagesData(initial);
          navigate("/managePages");
        });
      } else {
        dispatch(PostPagesData(data));
        setPagesData(initial);
      }
    }
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    if (location.state) {
      setPagesData({
        title: location.state?.title,
        slug: location.state?.slug,
        meta_description: location.state?.meta_description,
        content: location.state?.content,
      });
    }
  }, []);

  return (
    <>
      <div className="main_wrap">
        <div className="aboutUs_page">
          <h4 className="Pageheadline">
            {location?.state ? "Edit Pages" : "Add Pages"}
          </h4>
          <form className="row m-0" onSubmit={(e) => handleSubmit(e)}>
            <div class="form-group col-md-12 col-12">
              <label for="title">Page Title</label>
              <input
                type="text"
                class="form-control"
                id="title"
                placeholder="Enter Page Title"
                name="title"
                value={pagesData.title}
                onChange={(e) => handleChange(e)}
              />
            </div>
            <div class="form-group col-md-12 col-12">
              <label for="slug">Slug</label>
              <input
                type="text"
                class="form-control"
                id="slug"
                placeholder="Enter Slug"
                name="slug"
                value={pagesData.slug}
                onChange={(e) => handleSlugChange(e)}
              />
            </div>
            <div class="form-group col-md-12 col-12">
              <label for="meta_description">Meta Description</label>
              <textarea
                name="meta_description"
                id="meta_description"
                cols="20"
                rows="4"
                class="form-control"
                placeholder="Enter meta Description"
                value={pagesData.meta_description}
                onChange={(e) => handleChange(e)}
              ></textarea>
            </div>
            <div class="form-group col-md-12 col-12">
              <label for="content">Content</label>
              <div>
                <CKEditor
                  editor={ClassicEditor}
                  data={pagesData.content}
                  onChange={(event, editor) =>
                    handleEditorChange(event, editor)
                  }
                />
              </div>
            </div>

            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <button className="btn btn-primary mt-2">Submit</button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default AddPages;

import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { changePassword } from "../../redux/authSlice/ChangePasswordSlice";
import { toast } from "react-toastify";

const ChangePassModal = ({ closeModal }) => {
  const initial = {
    oldPassword: "",
    newPassword: "",
    conPassword: "",
  };
  const [changePass, setChangePass] = useState(initial);
  const dispatch = useDispatch();
  const [showNewPass, setShowNewPass] = useState(false);
  const [showConPass, setShowConPass] = useState(false);

  const changeHandler = (e) => {
    setChangePass({ ...changePass, [e.target.name]: e.target.value });
  };

  const validation = () => {
    if (changePass.oldPassword === "") {
      toast.error("old pass is required");
      return false;
    }
    if (changePass.newPassword === "") {
      toast.error("new password is required");
      return false;
    }
    if (changePass.newPassword.length < 8) {
      toast.error("new password can not less than 8 character");
      return false;
    }

    if (changePass.conPassword === "") {
      toast.error("confirm password is required");
      return false;
    }

    if (changePass.newPassword !== changePass.conPassword) {
      toast.error("new password and confirm does not match");
      return false;
    }
    return true;
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    if (validation()) {
      let data = {
        oldPassword: changePass.oldPassword,
        newPassword: changePass.newPassword,
      };

      console.log("data", data);
      dispatch(changePassword(data))
        .then(() => {
          // After changePassword is fulfilled, dispatch closeModal
          closeModal(false);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  };

  return (
    <>
      <section className="changepassModal_main">
        <div className="changepass_modal">
          <div className="changepass_content">
            <p className="heding">Change Password</p>
            <div className="closemark" onClick={() => closeModal(false)}>
              <i class="fa-solid fa-xmark"></i>
            </div>

            <form action="" onSubmit={(e) => handleSubmit(e)}>
              <div className="form-group">
                <label htmlFor="oldPassword">Old Password</label>
                <input
                  name="oldPassword"
                  id="oldPassword"
                  placeholder="Enter Old Password"
                  type="password"
                  className="form-control"
                  value={changePass.oldPassword}
                  onChange={(e) => changeHandler(e)}
                />
              </div>

              <div className="form-group">
                <label htmlFor="newPassword">New Password</label>
                <div className="pass_input">
                  <input
                    name="newPassword"
                    id="newPassword"
                    placeholder="Enter New Password"
                    type={showNewPass ? "text" : "password"}
                    className="form-control"
                    value={changePass.newPassword}
                    onChange={(e) => changeHandler(e)}
                  />
                  <div className="eye_icon">
                    {showNewPass ? (
                      <i
                        className="fa-solid fa-eye"
                        onClick={() => setShowNewPass(!showNewPass)}
                      ></i>
                    ) : (
                      <i
                        class="fa-solid fa-eye-slash"
                        onClick={() => setShowNewPass(!showNewPass)}
                      ></i>
                    )}
                  </div>
                </div>
              </div>

              <div className="form-group">
                <label htmlFor="conPassword">Confirm Password</label>
                <div className="pass_input">
                  <input
                    name="conPassword"
                    id="conPassword"
                    placeholder="Enter Confirm Password"
                    type={showConPass ? "text" : "password"}
                    className="form-control"
                    value={changePass.conPassword}
                    onChange={(e) => changeHandler(e)}
                  />
                  <div className="eye_icon">
                    {showConPass ? (
                      <i
                        className="fa-solid fa-eye"
                        onClick={() => setShowConPass(!showConPass)}
                      ></i>
                    ) : (
                      <i
                        class="fa-solid fa-eye-slash"
                        onClick={() => setShowConPass(!showConPass)}
                      ></i>
                    )}
                  </div>
                </div>
              </div>

              <div className="Submit_btn">
                <button className="btn" type="submit">
                  Update Password
                </button>
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  );
};

export default ChangePassModal;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

// Helpers
import { ApiHelperFunction } from "../../utils/HttpClient";

let initialState = {
  status: "idle",
  AccountPurchaseData: {},
  isLoading: false,
  isSuccess: false,
  isError: false,
};

export const getAccountPurchaseData = createAsyncThunk("get_purchased_get", async () => {
  let response = await ApiHelperFunction({
    urlPath: "admin/user-account-purchase-reports",
    method: "GET",
  });
  // console.log("Comminuty_details_get", response);

  if (response.status === 200) {
    return response.data;
  } else {
    toast.error("Can't get data. Something went wrong");
  }
});

export const AccountPurchaseSlice = createSlice({
  name: "Purchased_account_data",
  initialState,
  reducers: {
    clearState: (state) => {
      state.status = "idle";
      state.AccountPurchaseData = {};
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAccountPurchaseData.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(getAccountPurchaseData.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.isSuccess = true;
        // console.log("payload", payload?.data);
        state.AccountPurchaseData = payload?.data;
      })
      .addCase(getAccountPurchaseData.rejected, (state) => {
        state.status = "failed";
        state.isError = true;
        state.isSuccess = false;
      });
  },
});

export const { clearState } = AccountPurchaseSlice.actions;

export default AccountPurchaseSlice.reducer;

import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Table from "../Table/Table";
import { getAccountPurchaseData } from "../../redux/ReportSlice/AccountPurchaseSlice";

const AccountPurchaseReports = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [searchText, setsearchText] = useState("");

  const AccountPurchaseDetails = useSelector(
    (state) => state.accountPurchseReducer.AccountPurchaseData
  );
  // console.log("AccountPurchaseDetails", AccountPurchaseDetails);

  useEffect(() => {
    dispatch(getAccountPurchaseData());
  }, []);

  const filterData = useMemo(() => {
    if (searchText !== "") {
      return AccountPurchaseDetails?.filter((item) =>
        JSON.stringify(item).toLowerCase().includes(searchText.toLowerCase())
      );
    }
    return AccountPurchaseDetails;
  }, [searchText, AccountPurchaseDetails]);

  const handleView = (e, item) => {
    e.preventDefault();

    navigate(`/addPayment`, { state: item });
  };

  const tableData = useMemo(() => {
    return {
      columns: [
        {
          label: "SL",
          field: "sl",
          sort: "asc",
          width: 270,
        },
        {
          label: "User Name",
          field: "username",
          sort: "asc",
          width: 150,
        },
        {
          label: "User Email",
          field: "useremail",
          sort: "asc",
          width: 150,
        },
        {
          label: "Phone No.",
          field: "phone",
          sort: "asc",
          width: 150,
        },
        {
          label: "Total Account Purchased",
          field: "totalaccountbought",
          sort: "asc",
          width: 150,
        },
        {
          label: "Total Account Sold",
          field: "totalaccountsold",
          sort: "asc",
          width: 150,
        },
        {
          label: "Action",
          field: "action",
          sort: "asc",
          width: 150,
        },
      ],
      rows:
        filterData?.length > 0
          ? filterData.map((item, idx) => {
              console.log("dtdt", item);
              return {
                sl: idx + 1,
                username: (
                  <div className="widget-content p-0" key={idx}>
                    <div className="widget-content-wrapper">
                      <div className="widget-content-left flex2">
                        <div className="widget-heading" style={{ opacity: 1 }}>
                          {item?.firstName + " " + item?.lastName}
                        </div>
                      </div>
                    </div>
                  </div>
                ),
                useremail: (
                  <div className="widget-content p-0" key={idx}>
                    <div className="widget-content-wrapper">
                      <div className="widget-content-left flex2">
                        <div className="widget-heading" style={{ opacity: 1 }}>
                          {item.email}
                        </div>
                      </div>
                    </div>
                  </div>
                ),
                phone: (
                  <div className="widget-content p-0" key={idx}>
                    <div className="widget-content-wrapper">
                      <div className="widget-content-left flex2">
                        <div className="widget-heading" style={{ opacity: 1 }}>
                          {item.mobile}
                        </div>
                      </div>
                    </div>
                  </div>
                ),
                totalaccountbought: (
                  <div className="widget-content p-0" key={idx}>
                    <div className="widget-content-wrapper">
                      <div className="widget-content-left flex2">
                        <div
                          className="widget-heading"
                          style={{ opacity: 1, textAlign: "center" }}
                        >
                          {item.totalBuyPeofileCount}
                        </div>
                      </div>
                    </div>
                  </div>
                ),
                totalaccountsold: (
                  <div className="widget-content p-0" key={idx}>
                    <div className="widget-content-wrapper">
                      <div className="widget-content-left flex2">
                        <div
                          className="widget-heading"
                          style={{ opacity: 1, textAlign: "center" }}
                        >
                          {item.totalSoldPeofileCount}
                        </div>
                      </div>
                    </div>
                  </div>
                ),
                action: (
                  <div className="widget-content p-0" key={idx}>
                    <div className="widget-content-wrapper">
                      <div className="widget-content-left flex2">
                        <div className="widget-heading" style={{ opacity: 1 }}>
                          <i
                            class="fa fa-eye"
                            aria-hidden="true"
                            style={{ color: "blue", cursor: "pointer" }}
                            onClick={() => {
                              navigate("/singleAccountpurchaseview", {
                                state: item,
                              });
                            }}
                          ></i>
                        </div>
                      </div>
                    </div>
                  </div>
                ),
              };
            })
          : [],
    };
  });
  return (
    <>
      <div className="main_wrap">
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <h4 className="Pageheadline">Account Purchase Report</h4>
        </div>
        <div style={{ overflowX: "auto" }} className="dataTableParDiv">
          <Table
            striped
            tableTitle="All abouts"
            data={tableData}
            onSearch={(e) => setsearchText(e.target.value)}
          />
        </div>
      </div>
    </>
  );
};

export default AccountPurchaseReports;

import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  PostAboutUsData,
  UpdateAboutUsData,
  getAboutUsData,
} from "../../../redux/AdminSlice/AboutSlice";

const AddAboutUs = () => {
  const initial = {
    title: "",
    description: "",
  };
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [aboutUs, setAboutUs] = useState(initial);

  console.log("sdes", location.state);

  const handleChange = (e) => {
    setAboutUs({
      ...aboutUs,
      [e.target.name]: e.target.value,
    });
  };

  const validation = () => {
    if (aboutUs.title === "") {
      toast.error("Company Bio is required");
      return false;
    }
    if (aboutUs.description === "") {
      toast.error("Company description is required");
      return false;
    }
    return true;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validation()) {
      let data = {
        title: aboutUs.title,
        description: aboutUs.description,
      };
      if (location.state) {
        const id = location.state._id;
        dispatch(UpdateAboutUsData({ id, data })).then(() => {
          setAboutUs(initial);
          dispatch(getAboutUsData());
          navigate("/manageaboutus");
        });
      } else {
        dispatch(PostAboutUsData(data)).then(setAboutUs(initial));
      }
    }
  };

  useEffect(() => {
    if (location.state) {
      setAboutUs({
        title: location.state.title,
        description: location.state.description,
      });
    }
  }, []);

  return (
    <>
      <div className="main_wrap">
        <div className="aboutUs_page">
          <h4 className="Pageheadline">
            {location?.state ? "Edit AboutUs" : "Add AboutUs"}
          </h4>
          <form className="row m-0" onSubmit={(e) => handleSubmit(e)}>
            <div class="form-group col-md-12 col-12">
              <label for="title">Company Bio</label>
              <input
                type="text"
                class="form-control"
                id="title"
                placeholder="Enter Bio"
                name="title"
                value={aboutUs.title}
                onChange={(e) => handleChange(e)}
              />
            </div>
            <div class="form-group col-md-12 col-12">
              <label for="description">Company Description</label>
              <textarea
                name="description"
                id="description"
                cols="30"
                rows="5"
                class="form-control"
                placeholder="Enter Description"
                value={aboutUs.description}
                onChange={(e) => handleChange(e)}
              ></textarea>
            </div>

            <div className="m-auto">
              {location.state ? (
                <button className="btn btn-primary">Submit </button>
              ) : (
                <button className="btn btn-primary">Add </button>
              )}
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default AddAboutUs;

import React, { useEffect, useMemo, useState } from "react";
import Table from "../Table/Table";
import ResponsivePagination from "react-responsive-pagination";
import "react-responsive-pagination/themes/classic.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  getAllUser,
  userStatusChange,
} from "../../redux/AdminSlice/ManageUserSlice";
import { toast } from "react-toastify";
import moment from "moment";
import CustomLoader from "../../Loader/CustomLoader";

const ManageUser = () => {
  const initial = {
    fromDate: "",
    toDate: "",
    isVerified: "",
    isActive: "",
  };
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [changeState, setChangeState] = useState(false);
  const [date, setDate] = useState(initial);
  const [searchText, setsearchText] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = 20;
  console.log("date", date);
  const [limit, setLimit] = useState(10);
  const [pageNo, setPageNo] = useState(1);

  const { usersData, isLoading } = useSelector(
    (state) => state.manageuserReducer
  );
  const totalUserDetailsCount = useSelector(
    (state) => state.manageuserReducer.UserTotalLength
  );

  console.log("f64g6f5",usersData)
  //no of records display on the page
  const [recordPerPage] = useState(10);
  const [recordPageNumber] = useState(10);

  const nPages = Math.ceil(totalUserDetailsCount / recordPerPage);
  // console.log("npages", totalUserDetailsCount, recordPerPage, nPages);

  const pageNumber = nPages ? [...Array(nPages + 1)?.keys()]?.slice(1) : [];
  // console.log("pagenumber", pageNumber);

  const changehandler = (e) => {
    setDate({
      ...date,
      [e.target.name]: e.target.value,
    });
  };

  // click on search
  const submitHandler = (e) => {
    e.preventDefault();
    if (date.fromDate === "") {
      toast.error("From Date Is required");
      return false;
    }
    if (date.toDate === "") {
      toast.error("To Date Is required");
      return false;
    }

    let data = {
      fromDate: date.fromDate,
      toDate: date.toDate,
      isVerified:
        date.isVerified === "true"
          ? true
          : date.isVerified === "false"
          ? false
          : null,
      isActive:
        date.isActive === "true"
          ? true
          : date.isActive === "false"
          ? false
          : null,
    };

    dispatch(getAllUser({ data }));
    setDate(initial);
  };

  useEffect(() => {
    dispatch(getAllUser({}));
    return () => setChangeState(!changeState);
  }, [changeState]);

  useEffect(() => {
    dispatch(getAllUser({}));
  }, []);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage); // Update currentPage state
    dispatch(getAllUser({ no: newPage, limit: recordPerPage }));
  };

  const filterData = useMemo(() => {
    if (searchText !== "") {
      return usersData?.filter((item) =>
        JSON.stringify(item).toLowerCase().includes(searchText.toLowerCase())
      );
    }
    return usersData;
  }, [searchText, usersData]);
  
  // const tableData = useMemo(() => {
  //   return {
  //     columns: [
  //       {
  //         label: "SL",
  //         field: "sl",
  //         sort: "asc",
  //         width: 150,
  //       },
  //       {
  //         label: "User Name",
  //         field: "username",
  //         sort: "asc",
  //         width: 80,
  //       },
  //       {
  //         label: "User Email",
  //         field: "useremail",
  //         sort: "asc",
  //         width: 250,
  //       },
  //       {
  //         label: "Religion",
  //         field: "religion",
  //         sort: "asc",
  //         width: 150,
  //       },
  //       {
  //         label: "Community",
  //         field: "community",
  //         sort: "asc",
  //         width: 150,
  //       },
  //       {
  //         label: "Sub Community",
  //         field: "subcommunity",
  //         sort: "asc",
  //         width: 150,
  //       },
  //       {
  //         label: "Caste",
  //         field: "cast",
  //         sort: "asc",
  //         width: 150,
  //       },
  //       {
  //         label: "Marital Status",
  //         field: "maritalstatus",
  //         sort: "asc",
  //         width: 150,
  //       },
  //       {
  //         label: "Id Verified",
  //         field: "idverified",
  //         sort: "asc",
  //         width: 150,
  //       },
  //       {
  //         label: "Status",
  //         field: "status",
  //         sort: "asc",
  //         width: 150,
  //       },
  //       {
  //         label: "Action",
  //         field: "action",
  //         sort: "asc",
  //         width: 150,
  //       },
  //     ],
  //     rows:
  //       filterData?.length > 0
  //         ? filterData?.map((item, idx) => {
  //             // console.log("users", item?.userDetails?.personalInfo);
  //             return {
  //               sl: idx + 1,
  //               username: (
  //                 <div className="widget-content p-0" key={idx}>
  //                   <div className="widget-content-wrapper">
  //                     <div className="widget-content-left flex2">
  //                       <div
  //                         className="widget-heading"
  //                         style={{ opacity: 1, fontSize: "13px" }}
  //                       >
  //                         {item.firstName + " " + item.lastName}
  //                       </div>
  //                     </div>
  //                   </div>
  //                 </div>
  //               ),
  //               useremail: (
  //                 <div className="widget-content p-0" key={idx}>
  //                   <div className="widget-content-wrapper">
  //                     <div className="widget-content-left flex2">
  //                       <div
  //                         className="widget-heading"
  //                         style={{ opacity: 1, fontSize: "13px" }}
  //                       >
  //                         {item?.email}
  //                       </div>
  //                       {/* <div className="widget-subheading opacity-7">UI Designer</div> */}
  //                     </div>
  //                   </div>
  //                 </div>
  //               ),
  //               /**/
  //               religion: (
  //                 <div className="widget-content p-0" key={idx}>
  //                   <div className="widget-content-wrapper">
  //                     <div className="widget-content-left flex2">
  //                       <div
  //                         className="widget-heading"
  //                         style={{ opacity: 1, fontSize: "13px" }}
  //                       >
  //                         {item?.userDetails?.personalInfo?.religion}
  //                       </div>
  //                     </div>
  //                   </div>
  //                 </div>
  //               ),
  //               community: (
  //                 <div className="widget-content p-0" key={idx}>
  //                   <div className="widget-content-wrapper">
  //                     <div className="widget-content-left flex2">
  //                       <div
  //                         className="widget-heading"
  //                         style={{ opacity: 1, fontSize: "13px" }}
  //                       >
  //                         {item?.userDetails?.personalInfo?.community}
  //                       </div>
  //                     </div>
  //                   </div>
  //                 </div>
  //               ),
  //               subcommunity: (
  //                 <div className="widget-content p-0" key={idx}>
  //                   <div className="widget-content-wrapper">
  //                     <div className="widget-content-left flex2">
  //                       <div
  //                         className="widget-heading"
  //                         style={{ opacity: 1, fontSize: "13px" }}
  //                       >
  //                         {item?.userDetails?.personalInfo?.subCommunity}
  //                       </div>
  //                     </div>
  //                   </div>
  //                 </div>
  //               ),
  //               cast: (
  //                 <div className="widget-content p-0" key={idx}>
  //                   <div className="widget-content-wrapper">
  //                     <div className="widget-content-left flex2">
  //                       <div
  //                         className="widget-heading"
  //                         style={{ opacity: 1, fontSize: "13px" }}
  //                       >
  //                         {item?.userDetails?.personalInfo?.caste}
  //                       </div>
  //                     </div>
  //                   </div>
  //                 </div>
  //               ),
  //               maritalstatus: (
  //                 <div className="widget-content p-0" key={idx}>
  //                   <div className="widget-content-wrapper">
  //                     <div className="widget-content-left flex2">
  //                       <div
  //                         className="widget-heading"
  //                         style={{ opacity: 1, fontSize: "13px" }}
  //                       >
  //                         {item?.userDetails?.personalInfo?.maritalStatus}
  //                       </div>
  //                     </div>
  //                   </div>
  //                 </div>
  //               ),
  //               /**/
  //               idverified: (
  //                 <div className="widget-content p-0" key={idx}>
  //                   <div className="widget-content-wrapper">
  //                     <div className="widget-content-center flex2">
  //                       {item?.isVerified ? (
  //                         <button className="singleViewApprovebtn">
  //                           Approved
  //                         </button>
  //                       ) : (
  //                         <button className="singleViewdisapprovedBtn">
  //                           Disapproved
  //                         </button>
  //                       )}
  //                     </div>
  //                   </div>
  //                 </div>
  //               ),
  //               status: (
  //                 <div className="widget-content p-0" key={idx}>
  //                   <div className="widget-content-wrapper">
  //                     <div className="widget-content-center flex2">
  //                       {item?.isActive ? (
  //                         <button
  //                           className="activebtn"
  //                           onClick={() => {
  //                             dispatch(
  //                               userStatusChange({
  //                                 id: item._id,
  //                                 data: { isActive: false },
  //                               })
  //                             ).then(() => {
  //                               setChangeState(!changeState);
  //                             });
  //                           }}
  //                         >
  //                           Active
  //                         </button>
  //                       ) : (
  //                         <button
  //                           className="inactivebtn"
  //                           onClick={() => {
  //                             dispatch(
  //                               userStatusChange({
  //                                 id: item._id,
  //                                 data: { isActive: true },
  //                               })
  //                             ).then(() => {
  //                               setChangeState(!changeState);
  //                             });
  //                           }}
  //                         >
  //                           Inactive
  //                         </button>
  //                       )}
  //                     </div>
  //                   </div>
  //                 </div>
  //               ),
  //               action: (
  //                 <div className="widget-content p-0" key={idx}>
  //                   <div className="widget-content-wrapper">
  //                     <div className="widget-content-center flex2">
  //                       <i
  //                         class="fa-solid fa-eye"
  //                         style={{ cursor: "pointer" }}
  //                         onClick={() => navigate(`/viewUser`, { state: item })}
  //                       ></i>
  //                       {/* <i class="fa-solid fa-trash-can"></i> */}
  //                     </div>
  //                   </div>
  //                 </div>
  //               ),
  //             };
  //           })
  //         : [],
  //   };
  // });

  const handleReset = () => {
    setDate(initial);
    dispatch(getAllUser({}));
  };

  return (
    <div className="main_wrap">
      <CustomLoader loading={isLoading} />
      <h4 className="Pageheadline">Manage User</h4>
      <div className="searchDiv d-flex py-3">
        <form action="" className="row px-3" onSubmit={(e) => submitHandler(e)}>
          {/* from date */}
          <div className="" style={{ width: "154px", marginLeft: "5px" }}>
            <label htmlFor="fromDate">From Date</label>
            <input
              type="date"
              className="form-control"
              name="fromDate"
              value={date.fromDate}
              onChange={(e) => changehandler(e)}
            />
          </div>
          {/* to date */}
          <div className="" style={{ width: "154px", marginLeft: "5px" }}>
            <label htmlFor="toDate">To Date</label>
            <input
              type="date"
              className="form-control"
              name="toDate"
              min={date.fromDate}
              value={date.toDate}
              onChange={(e) => changehandler(e)}
            />
          </div>
          {/* id proff status */}
          <div className="" style={{ width: "220px", marginLeft: "5px" }}>
            <label htmlFor=""> Id Proof Status</label>
            <select
              class="form-select table_page_select"
              aria-label="Default select example"
              onChange={(e) => changehandler(e)}
              name="isVerified"
              value={date?.isVerified}
              style={{ height: "44px" }}
            >
              <option selected value={""} disabled>
                Select Id Proof Status
              </option>
              <option value="true">Approved</option>
              <option value="false">Not-Approved</option>
            </select>
          </div>
          {/* status */}
          <div className="" style={{ width: "154px", marginLeft: "5px" }}>
            <label htmlFor="">Status</label>
            <select
              class="form-select table_page_select"
              aria-label="Default select example"
              onChange={(e) => changehandler(e)}
              name="isActive"
              value={date?.isActive}
              style={{ height: "44px" }}
            >
              <option selected value={""} disabled>
                Select Status
              </option>
              <option value={true}>Active</option>
              <option value={false}>Inactive</option>
            </select>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              // width: "29%",
            }}
          >
            <button
              type="submit"
              className="btn btn-primary table_btn"
              style={{ marginLeft: "12px" }}
            >
              Search
            </button>
          </div>
        </form>
        <div className="ml-3">
          <button
            type="submit"
            className="btn btn-danger table_btn ml-3"
            onClick={() => handleReset()}
          >
            Reset
          </button>
        </div>
      </div>

      <div style={{ overflowX: "auto" }} className="dataTableParDiv">
        <div className="manage_search_div">
          <input
            type="search"
            placeholder="Search here"
            value={searchText}
            onChange={(e) => setsearchText(e.target.value)}
          />
        </div>

        {/* table */}
        <div className="userTbl">
          <table>
            <thead>
              <tr>
                <th>SL</th>
                <th>Registration Date</th>
                <th>User Name</th>
                <th>User Email</th>
                <th>Religion</th>
                <th>Community</th>
                <th>Sub Community</th>
                <th>Caste</th>
                <th>Marital Status</th>
                <th>Id Proof Status</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {filterData
                ? filterData.map((item, idx) => {
                    return (
                      <tr key={idx}>
                        <td>{idx + 1}</td>
                        <td>{moment(item?.createdAt).format("DD-MM-YYYY")}</td>
                        <td>{item?.firstName + " " + item?.lastName}</td>
                        <td>{item?.email}</td>
                        <td>{item?.userDetails?.personalInfo?.religion}</td>
                        <td>{item?.userDetails?.personalInfo?.community}</td>
                        <td>{item?.userDetails?.personalInfo?.subCommunity}</td>
                        <td>{item?.userDetails?.personalInfo?.caste}</td>
                        <td>
                          {item?.userDetails?.personalInfo?.maritalStatus}
                        </td>
                        <td>
                          {" "}
                          {item?.isPhotoVerified ? (
                            <button className="singleViewApprovebtn">
                              Approved
                            </button>
                          ) : (
                            <button className="singleViewdisapprovedBtn">
                              Not-Approved
                            </button>
                          )}
                        </td>
                        <td>
                          {item?.isActive ? (
                            <button
                              className="activebtn"
                              onClick={() => {
                                dispatch(
                                  userStatusChange({
                                    id: item._id,
                                    data: { isActive: false },
                                  })
                                );
                                setChangeState(!changeState);
                              }}
                            >
                              Active
                            </button>
                          ) : (
                            <button
                              className="inactivebtn"
                              onClick={() => {
                                dispatch(
                                  userStatusChange({
                                    id: item._id,
                                    data: { isActive: true },
                                  })
                                );
                                setChangeState(!changeState);
                              }}
                            >
                              Inactive
                            </button>
                          )}
                        </td>
                        <td>
                          {
                            <i
                              class="fa-solid fa-eye"
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                navigate(`/viewUser`, { state: item })
                              }
                            ></i>
                          }
                        </td>
                      </tr>
                    );
                  })
                : null}
            </tbody>
          </table>

          {/* Pagination */}
          <div>
            {/* {products.length > itemsPerPage && (
              <ul className="pagination">
                {Array(Math.ceil(products.length / itemsPerPage))
                  .fill()
                  .map((_, index) => (
                    <li key={index}>
                      <button onClick={() => paginate(index + 1)}>
                        {index + 1}
                      </button>
                    </li>
                  ))}
              </ul>
            )} */}
          </div>
        </div>

        {/* pagination */}
        <div>
          <ResponsivePagination
            current={currentPage}
            // total={totalPages}
            total={nPages}
            // onPageChange={setCurrentPage}
            onPageChange={handlePageChange}
          />
        </div>
      </div>
    </div>
  );
};

export default ManageUser;

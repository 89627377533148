import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

// Helpers
import { ApiHelperFunction } from "../../utils/HttpClient";

let initialState = {
  status: "idle",
  AccountViewPricedata: {},
  isLoading: false,
  isSuccess: false,
  isError: false,
};

export const PostAccountViewPrice = createAsyncThunk(
  "Account_view_price_post",
  async (data) => {
    let response = await ApiHelperFunction({
      urlPath: "admin/add-price-management",
      method: "POST",
      data: data,
    });
    console.log("profile_isFor_Account_view", response);

    if (response.status === 200) {
      toast.success("Account View Price Added successfully");
      return response.data;
    } else {
      toast.error(response?.response?.data?.message);
    }
  }
);

export const GetAccountViewPrice = createAsyncThunk(
  "Account_view_price_get",
  async () => {
    let response = await ApiHelperFunction({
      urlPath: "admin/view-price-management",
      method: "GET",
    });
    // console.log("Comminuty_details_get", response);

    if (response.status === 200) {
      return response.data;
    } else {
      toast.error(response?.response?.data?.message);
    }
  }
);

export const UpdateAccountViewPrice = createAsyncThunk(
  "Account_view_update",
  async ({ id, data }) => {
    console.log("updatedItem", data);
    let response = await ApiHelperFunction({
      urlPath: `admin/edit-price-management/${id}`,
      method: "PUT",
      data: data,
    });
    console.log("Community_Details", response);

    if (response.status === 200) {
      toast.success("Account View Price Updated Successfully");
      return response.data;
    } else {
      toast.error(response?.response?.data?.message);
    }
  }
);

export const DeleteAccountViewPrice = createAsyncThunk(
  "Account_view_delete",
  async (id) => {
    let response = await ApiHelperFunction({
      urlPath: `admin/delete-price-management/${id}`,
      method: "PUT",
    });
    console.log("contact_details", response);

    if (response.status === 200) {
      toast.success("Account view Price Deleted Successfully");
      return response.data;
    } else {
      toast.error(response?.response?.data?.message);
    }
  }
);

export const AccountViewPriceSlice = createSlice({
  name: "Account_view_price_data",
  initialState,
  reducers: {
    clearState: (state) => {
      state.status = "idle";
      state.AccountViewPricedata = {};
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(PostAccountViewPrice.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(PostAccountViewPrice.fulfilled, (state) => {
        state.status = "success";
        state.isSuccess = true;
        // Optionally, you can update state after a successful post if needed
      })
      .addCase(PostAccountViewPrice.rejected, (state) => {
        state.status = "failed";
        state.isError = true;
        state.isSuccess = false;
      })
      .addCase(GetAccountViewPrice.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(GetAccountViewPrice.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.status = "success";
        state.isSuccess = true;
        // console.log("payload", payload?.data);
        state.AccountViewPricedata = payload?.data;
      })
      .addCase(GetAccountViewPrice.rejected, (state) => {
        state.isLoading = false;
        state.status = "failed";
        state.isError = true;
        state.isSuccess = false;
      })
      .addCase(UpdateAccountViewPrice.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(UpdateAccountViewPrice.fulfilled, (state) => {
        state.status = "success";
        state.isSuccess = true;
      })
      .addCase(UpdateAccountViewPrice.rejected, (state) => {
        state.status = "failed";
        state.isError = true;
        state.isSuccess = false;
      })
      .addCase(DeleteAccountViewPrice.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(DeleteAccountViewPrice.fulfilled, (state) => {
        state.status = "success";
        state.isSuccess = true;
      })
      .addCase(DeleteAccountViewPrice.rejected, (state) => {
        state.status = "failed";
        state.isError = true;
        state.isSuccess = false;
      });
  },
});

export const { clearState } = AccountViewPriceSlice.actions;

export default AccountViewPriceSlice.reducer;

// API url = BASE_URL + API_ENDPOINT_PATH + '/' + <ENDPOINT>
// export const BASE_URL = 'http://13.127.222.180:5051/';
export const BASE_URL = 'https://api.shadibiha.in/';
export const API_ENDPOINT_PATH = 'api/v1/';

// API_NOTIFICATION_MESSAGES
export const API_NOTIFICATION_MESSAGES = {
  loading: {
    title: 'Loading...',
    message: 'Data is being loaded. Please wait',
  },
  success: {
    title: 'Success',
    message: 'Data successfully loaded',
  },
  responseFailure: {
    title: 'Error',
    message: 'An error occured while fetching response from server. Please try again.',
  },
  requestFailue: {
    title: 'Error',
    message: 'An error occurred while parsing request data.',
  },
  networkError: {
    title: 'Error',
    message: 'Unable to connect with server. Please check internet connectivity and try again.',
  },
};

// API SERVICE CALL
// SAMPLE REQUEST
// NEED SERVICE CALL: {url: '/', method: 'POST/GET/PUT/DELETE', params: true/false, query: true/false, responseType}

// export const SERVICE_URLS = {
//   getAllUsers: { url: "/admin/get-users", method: "GET" },
// };

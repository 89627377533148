import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Table from "../Table/Table";
import { GetReligionReport } from "../../redux/ReportSlice/ReligionReportSlice";
import { toast } from "react-toastify";
import { GetReligian } from "../../redux/AdminSlice/ReligionSlice";

const ReligionReport = () => {
  const initial = {
    fromDate: "",
    toDate: "",
    religionName: "",
  };
  const [religionReport, setReligionReport] = useState(initial);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchText, setsearchText] = useState("");

  const ReligionReportDetails = useSelector(
    (state) => state.religionReportReducer.ReligionReportData
  );
  const allreligion = useSelector(
    (state) => state.religionReducer.ReligianData
  );
  console.log("allreligion", allreligion);

  useEffect(() => {
    dispatch(GetReligionReport());
    dispatch(GetReligian())
  }, []);

  const deleteProfile = (id) => {
    // dispatch(DeleteReligionReport(id)).then(() => {
    //   dispatch(GetReligionReport());
    // });
  };

  const filterData = useMemo(() => {
    if (searchText !== "") {
      return ReligionReportDetails?.filter((item) =>
        JSON.stringify(item).toLowerCase().includes(searchText.toLowerCase())
      );
    }
    return ReligionReportDetails;
  }, [searchText, ReligionReportDetails]);

  const handleView = (e, item) => {
    // e.preventDefault();
    // navigate(`/addPayment`, { state: item });
  };

  const changehandler = (e) => {
    setReligionReport({
      ...religionReport,
      [e.target.name]: e.target.value,
    });
  };

  const validation = () => {
    if (religionReport.fromDate === "") {
      toast.error("From date is required");
      return false;
    }
    if (religionReport.toDate === "") {
      toast.error("To date is required");
      return false;
    }

    return true;
  };

  const submitHandler = (e) => {
    e.preventDefault();
    if (validation()) {
      let data = {
        fromDate: religionReport.fromDate,
        toDate: religionReport.toDate,
        religionName: religionReport.religionName,
      };

      dispatch(GetReligionReport(data));
    }
  };

  const tableData = useMemo(() => {
    return {
      columns: [
        {
          label: "SL",
          field: "sl",
          sort: "asc",
          width: 270,
        },
        {
          label: "Registration Date",
          field: "startDate",
          sort: "asc",
          width: 150,
        },
        {
          label: "Religion Name",
          field: "religionname",
          sort: "asc",
          width: 150,
        },
        {
          label: "User Name",
          field: "username",
          sort: "asc",
          width: 150,
        },
        {
          label: "User Email",
          field: "useremail",
          sort: "asc",
          width: 150,
        },
        {
          label: "Contact No.",
          field: "contact",
          sort: "asc",
          width: 150,
        },
      ],
      rows:
        filterData?.length > 0
          ? filterData.map((item, idx) => {
              console.log("duifghidsg", item);
              return {
                sl: idx + 1,
                startDate: (
                  <div className="widget-content p-0" key={idx}>
                    <div className="widget-content-wrapper">
                      <div className="widget-content-left flex2">
                        <div className="widget-heading" style={{ opacity: 1 }}>
                          {item?.date}
                        </div>
                      </div>
                    </div>
                  </div>
                ),
                religionname: (
                  <div className="widget-content p-0" key={idx}>
                    <div className="widget-content-wrapper">
                      <div className="widget-content-left flex2">
                        <div className="widget-heading" style={{ opacity: 1 }}>
                          {item?.personalDetails?.personalInfo?.religion}
                        </div>
                      </div>
                    </div>
                  </div>
                ),
                username: (
                  <div className="widget-content p-0" key={idx}>
                    <div className="widget-content-wrapper">
                      <div className="widget-content-left flex2">
                        <div className="widget-heading" style={{ opacity: 1 }}>
                          {item?.firstName + " " + item?.lastName}
                        </div>
                      </div>
                    </div>
                  </div>
                ),
                useremail: (
                  <div className="widget-content p-0" key={idx}>
                    <div className="widget-content-wrapper">
                      <div className="widget-content-left flex2">
                        <div className="widget-heading" style={{ opacity: 1 }}>
                          {item?.email}
                        </div>
                      </div>
                    </div>
                  </div>
                ),
                contact: (
                  <div className="widget-content p-0" key={idx}>
                    <div className="widget-content-wrapper">
                      <div className="widget-content-left flex2">
                        <div className="widget-heading" style={{ opacity: 1 }}>
                          {item?.mobile}
                        </div>
                      </div>
                    </div>
                  </div>
                ),
              };
            })
          : [],
    };
  });
  return (
    <>
      <div className="main_wrap">
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <h4 className="Pageheadline"> Religion Report Details</h4>
        </div>
        <div className="searchDiv">
          <form
            action=""
            className="row m-auto"
            onSubmit={(e) => submitHandler(e)}
          >
            <div className="col-md-3">
              <label htmlFor="fromDate">From Date</label>
              <input
                type="date"
                className="form-control"
                name="fromDate"
                value={religionReport.fromDate}
                onChange={(e) => changehandler(e)}
              />
            </div>
            <div className="col-md-3">
              <label htmlFor="toDate">To Date</label>
              <input
                type="date"
                className="form-control"
                name="toDate"
                value={religionReport.toDate}
                min={religionReport.fromDate}
                onChange={(e) => changehandler(e)}
              />
            </div>
            <div className="col-md-3">
              <label htmlFor="">Religion Name</label>
              <select
                class="form-select table_page_select"
                aria-label="Default select example"
                onChange={(e) => changehandler(e)}
                name="religionName"
                value={religionReport.religionName}
              >
                {" "}
                <option selected value="">
                  select religion
                </option>
                {allreligion?.map((item) => {
                  return (
                    <option value={item.name}>{item.name.toUpperCase()}</option>
                  );
                })}
              </select>
            </div>

            <button type="submit" className="btn btn-primary table_btn">
              Search
            </button>
          </form>
        </div>
        <div style={{ overflowX: "auto" }} className="dataTableParDiv">
          <Table
            striped
            tableTitle="All abouts"
            data={tableData}
            onSearch={(e) => setsearchText(e.target.value)}
          />
        </div>
      </div>
    </>
  );
};

export default ReligionReport;

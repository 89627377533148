import React, { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component'
import { ApiHelperFunction } from '../../utils/HttpClient';
import CustomLoader from '../../Loader/CustomLoader';
import { userStatusChange } from '../../redux/AdminSlice/ManageUserSlice';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';

const AllUpdatedUser = () => {
  // >>>>Declaration of Coloumn<<<<
  const columns = [
    { name: "SL No.", selector: row => row.sln, sortable: true, wrap: true },
    { name: "User-Id", selector: row => row.customizedUserId, sortable: true, wrap: true },
    { name: "Name", selector: row => row.name, sortable: true },
    { name: "Email", selector: row => row.email, sortable: true, wrap: true },
    { name: "Phone", selector: row => row.phone, sortable: true, wrap: true },
    { name: "Country", selector: row => row.country, sortable: true, wrap: true },
    { name: "Id Status", selector: row => row.idStatus, sortable: true, wrap: true },
    { name: "Status", selector: row => row.status, sortable: true, wrap: true },
    { name: "Action", selector: row => row.action, sortable: true, wrap: true }
  ];
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [updatedUser, setUpdatedUser] = useState([])
  const [searchQuery, setSearchQuery] = useState('');
  const [isLoading, setIsLoading] = useState(false)
  const [changeState, setChangeState] = useState(false);

  // >>>>Fetch All Deleted USer Data<<<<
  const fetchUpdatedUser = async () => {
    setIsLoading(true)
    try {
      const response = await ApiHelperFunction({
        urlPath: "admin/all-updated-user-list",
        method: "GET"
      });
      console.log("DatafrohfjfghmAPI:", response?.data?.data);
      if (response?.data && response?.data?.status) {
        setIsLoading(false)
        let arr = response?.data?.data?.map((ele, ind) => {
          return {
            sln: ind + 1,
            customizedUserId: ele?.customizedUserId,
            name: `${ele?.firstName} ${ele?.lastName}`,
            email: ele?.email,
            phone: ele?.mobile,
            country: ele?.country,
            status: (
              <span>
                {ele?.isActive ? (
                  <button
                    className="activebtn"
                    onClick={() => {
                      dispatch(
                        userStatusChange({
                          id: ele._id,
                          data: { isActive: false },
                        })
                      );
                      setChangeState(!changeState);
                    }}
                  >
                    Active
                  </button>
                ) : (
                  <button
                    className="inactivebtn"
                    onClick={() => {
                      dispatch(
                        userStatusChange({
                          id: ele._id,
                          data: { isActive: true },
                        })
                      );
                      setChangeState(!changeState);
                    }}
                  >
                    Inactive
                  </button>
                )}
              </span>
            ),
            idStatus: (
              <span>
                {ele?.isPhotoVerified ? (
                  <button className="singleViewApprovebtn">
                    Approved
                  </button>
                ) : (
                  <button className="singleViewdisapprovedBtn">
                    Not-Approved
                  </button>
                )}
              </span>
            ),
            action: (
              <span>
                {
                  <i
                    class="fa-solid fa-eye"
                    style={{ cursor: "pointer" }
                    }
                    onClick={() =>
                      navigate(`/viewUser`, { state: ele })
                    }
                  ></i>
                }
              </span>
            )
          }
        })
        setUpdatedUser(arr)
      }

      // Handle the data received from the API
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  // >>>> Handle search user<<<<
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  // >>>>Handle Search filter<<<<
  const filteredUsers = updatedUser.filter(user =>
    user?.name?.toLowerCase()?.includes(searchQuery.toLowerCase())
  );

  console.log("d4g5f31", filteredUsers, updatedUser)

  useEffect(() => {
    fetchUpdatedUser()
  }, [])
  return (
    <div className='main_wrap'>
      <CustomLoader loading={isLoading} />
      <h4 className="Pageheadline">Manage All Updated User</h4>
      <div className='userTbl dataTableParDiv'>

        <div className="Search_box d-flex justify-content-end " >
          <form className="form-inline" style={{ boxShadow: "0 4px 8px rgba(0, 0, 0, 0.5)" }}>
            <input
              className="form-control "
              type="search"
              placeholder="Search by name..."
              aria-label="Search"
              value={searchQuery}
              onChange={handleSearchChange}
            />
            <button className="Search_icon" type="submit">
              <i className="fa-solid fa-magnifying-glass"></i>
            </button>
          </form>
        </div>

        <div className='mt-2'>
          <DataTable
            columns={columns}
            data={filteredUsers}
            pagination={true}
            responsive={true}
            highlightOnHover={true}
          />
        </div>
      </div>
    </div>
  )
}

export default AllUpdatedUser
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Sidebar from "./Sidebar";
import profileP from "../Images/dp2.jpeg";
import NotificationModal from "../Component/Modal/NotificationModal";
import ChangePassModal from "../Component/Modal/ChangePassModal";
import { useDispatch, useSelector } from "react-redux";
import { clearState } from "../redux/authSlice/AuthSlice";
import { getProfileDetails } from "../redux/AdminSlice/profileSlice";
import ProfileModal from "../Component/ProfileModal";
import dp2 from "../Images/dp2.jpeg";

const Header = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [notificationmodal, setNotificationmodal] = useState(false);
  const token = localStorage.getItem("Authorization");
  const { profile_data } = useSelector((state) => state.profileReducer);
  // console.log("profile_data", profile_data);

  const notificationModal = () => {
    setNotificationmodal(true);
  };

  const [changepasswordmodal, setchangepasswordmodal] = useState(false);
  const [profileModal, setProfileModal] = useState(false);

  const openchangePassword = () => {
    setchangepasswordmodal(true);
  };

  const onchangeProfileModal = () => {
    setProfileModal(true);
  };

  const [Searchmodal, setSearchmodal] = useState(false);
  const responSearch = () => {
    setSearchmodal(!Searchmodal);
  };

  const handClick = () => {
    if (document.querySelector("#responsiveMenu").click) {
      document.querySelector(".sidebarwrap").classList.add("show");
    } else {
      document.querySelector(".sidebarwrap").classList.remove("show");
    }
  };

  const handleLogOut = () => {
    localStorage.clear();
    dispatch(clearState());
    navigate("/login");
    // dispatch(clearPofileState());
  };

  useEffect(() => {
    dispatch(getProfileDetails(token));
  }, []);

  return (
    <>
      <section className="mainheader_sec">
        <button
          className="responsive_menu"
          id="responsiveMenu"
          onClick={handClick}
        >
          <i className="fa-solid fa-bars"></i>
        </button>
        <button className="responsSearch_btn" onClick={responSearch}>
          <i className="fa-solid fa-magnifying-glass"></i>
        </button>

        <div className="Search_box">
          <form className="form-inline">
            {/* <input
              className="form-control"
              type="search"
              placeholder="Search"
              aria-label="Search"
            /> */}
            {/* <button className="Search_icon" type="submit">
              <i className="fa-solid fa-magnifying-glass"></i>
            </button> */}
          </form>
        </div>

        <div className="rightcontent">
          <div className="actionBtn_wrap">
            {/* <div className="notification_btn">
              <button className="btn" onClick={notificationModal}>
                <i className="fa-regular fa-bell"></i>
              </button>
              <div className="active">
                <i className="fa-solid fa-circle"></i>
              </div>
            </div> */}

            {/* account Details */}
            <div className="Accountdetails">
              <div className="profile_pic">
                <img
                  src={profile_data?.image ? profile_data?.image : dp2}
                  className="img-fluid"
                  alt="user"
                />
              </div>
              <div className="namearea">
                <div className="dropdown">
                  <Link
                    className="dropdown-toggle"
                    href="#"
                    id="accountDropdown"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <strong>
                      {profile_data?.firstName + " " + profile_data?.lastName}
                    </strong>
                    <span>{profile_data?.email}</span>
                  </Link>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="accountDropdown"
                  >
                    {profile_data?.userType === "Admin" ? (
                      <>
                        <Link
                          className="dropdown-item"
                          onClick={onchangeProfileModal}
                        >
                          <i className="fa-solid fa-user mr-1"></i> Profile
                        </Link>
                        <Link
                          className="dropdown-item"
                          onClick={openchangePassword}
                        >
                          <i className="fa-solid fa-lock mr-1"></i> Change
                          Password
                        </Link>{" "}
                      </>
                    ) : (
                      ""
                    )}

                    <div
                      className="mt-1"
                      style={{ borderTop: "1px solid #ccc" }}
                      onClick={handleLogOut}
                    >
                      <Link className="dropdown-item">
                        <i className="fa-solid fa-right-from-bracket mr-1"></i>{" "}
                        Log Out
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {Searchmodal && (
        <div className="responsiveSearch_box">
          <form className="form-inline">
            <input
              className="form-control"
              type="search"
              placeholder="Search here...."
              aria-label="Search"
            />
            <button
              className="Search_icon"
              type="submit"
              onClick={() => setSearchmodal(false)}
            >
              <i className="fa-solid fa-magnifying-glass"></i>
            </button>
          </form>
        </div>
      )}

      {notificationmodal && (
        <NotificationModal closeModal={setNotificationmodal} />
      )}

      {changepasswordmodal && (
        <ChangePassModal closeModal={setchangepasswordmodal} />
      )}

      {profileModal && <ProfileModal closeModal={setProfileModal} />}

      <Sidebar />
    </>
  );
};

export default Header;

import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { useDispatch, useSelector } from "react-redux";
import { genderTotalCount } from "../../redux/ReportSlice/GenderReportSlice";

const GenderReportCount = () => {
  const dispatch = useDispatch();
  const genderCountData = useSelector(
    (state) => state.genderReportReducer.genderCount
  );
  console.log("genderCountData", genderCountData);

  const [chartState, setChartState] = useState({
    series: [],
 
    options: {
      chart: {
        type: "donut",
      },
      plotOptions: {
        pie: {
          customScale: 0.8,
          size: "60%"
        }
      },
      legend: {
        position: 'top'
      },
      labels: [],
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
  });

  useEffect(() => {
    if (genderCountData && genderCountData.length > 0) {
      const GenderDetails = genderCountData.map((data) => data.count);
      console.log("GenderDetails", GenderDetails)
      setChartState((prevState) => ({
        ...prevState,
        series: GenderDetails,
      }));
      
      const GenderName = genderCountData.map((data) => data.genderName);
      console.log("GenderName", GenderName)
      setChartState((prevState) => ({
        ...prevState,
        options: {
            ...prevState.options,
            labels: GenderName,
          },
      }));
   
    }
  }, [genderCountData]);

  useEffect(() => {
    dispatch(genderTotalCount());
  }, []);

  return (
    <>
      <section className="piechartsBox_area">
        <div id="chart">
          <ReactApexChart
            options={chartState.options}
            series={chartState.series}
            type="donut"
          />
        </div>
      </section>
    </>
  );
};

export default GenderReportCount;

import React, { useEffect, useMemo, useState } from "react";
import Table from "../Table/Table";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { GetReligian } from "../../redux/AdminSlice/ReligionSlice";
import {
  GetCommunity,
  ReligianbasedCommunity,
} from "../../redux/AdminSlice/CommunitySlice";
import {
  GetCommunityBasedSubCommunity,
  GetSubCommunity,
} from "../../redux/AdminSlice/SubCommunitySlice";
import { GetCastData } from "../../redux/AdminSlice/CastSlice";
import { GetMaritalStatus } from "../../redux/AdminSlice/MaritalSlice";
import {
  getAllUserReports,
  getSingleUserReports,
} from "../../redux/ReportSlice/UserReportSlice";
import moment from "moment";
import CustomLoader from "../../Loader/CustomLoader";

const Usersreports = () => {
  const initial = {
    fromDate: "",
    toDate: "",
    castName: "",
    communityName: "",
    subCommunityName: "",
    religionName: "",
    genderName: "",
    maritalStatusName: "",
  };
  const [date, setDate] = useState(initial);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [changeState, setChangeState] = useState(false);
  const [searchText, setsearchText] = useState("");

  const { userReportsdata, isLoading } = useSelector(
    (state) => state.userReportReducer
  );

  const allreligion = useSelector(
    (state) => state.religionReducer.ReligianData
  );
  const AllCommunity = useSelector(
    (state) => state.communityReducer.CommunityData
  );

  const AllsubCommunity = useSelector(
    (state) => state.subCommunityReducer?.SubCommunityData
  );
  const Allcast = useSelector((state) => state.castReducer?.CastData);

  const AllMaritalStatus = useSelector(
    (state) => state.maritalReducer.MaritalStatusData
  );
  // console.log("userReportsdata", userReportsdata);

  const changehandler = (e) => {
    if (e.target.name === "religionName") {
      const selectedReligionId = e.target.value;

      if (selectedReligionId) {
        dispatch(ReligianbasedCommunity(selectedReligionId));
      }
    }

    if (e.target.name === "communityName") {
      const selectedCommunityId = e.target.value;

      if (selectedCommunityId) {
        dispatch(GetCommunityBasedSubCommunity(selectedCommunityId));
      }
    }

    setDate({
      ...date,
      [e.target.name]: e.target.value,
    });
  };

  const submitHandler = (e) => {
    e.preventDefault();
    if (date.fromDate === "") {
      toast.error("From Date Is required");
      return false;
    }
    if (date.toDate === "") {
      toast.error("To Date Is required");
      return false;
    }

    let data = {
      fromDate: date.fromDate,
      toDate: date.toDate,
      castName: date.castName,
      communityName: date.communityName,
      subCommunityName: date.subCommunityName,
      religionName: date.religionName,
      genderName: date.genderName,
      maritalStatusName: date.maritalStatusName,
    };

    dispatch(getAllUserReports(data));
  };

  useEffect(() => {
    dispatch(GetReligian());
    dispatch(GetCommunity());
    dispatch(GetSubCommunity());
    dispatch(GetCastData());
    dispatch(GetMaritalStatus());
    dispatch(getAllUserReports());

    window.scrollTo({
      top: "0",
      left: "0",
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    dispatch(getAllUserReports());
    return () => setChangeState(false);
  }, [changeState]);

  const filterData = useMemo(() => {
    if (searchText !== "") {
      return userReportsdata?.filter((item) =>
        JSON.stringify(item).toLowerCase().includes(searchText.toLowerCase())
      );
    }
    return userReportsdata;
  }, [searchText, userReportsdata]);

  const tableData = useMemo(() => {
    return {
      columns: [
        {
          label: "SL",
          field: "sl",
          sort: "asc",
          width: 150,
        },
        {
          label: "Registration Date",
          field: "regdate",
          sort: "asc",
          width: 80,
        },
        {
          label: "User ID",
          field: "userid",
          sort: "asc",
          width: 80,
        },
        {
          label: "User Name",
          field: "username",
          sort: "asc",
          width: 80,
        },
        {
          label: "User Email",
          field: "useremail",
          sort: "asc",
          width: 250,
        },
        {
          label: "Religion",
          field: "religion",
          sort: "asc",
          width: 150,
        },
        {
          label: "Community",
          field: "community",
          sort: "asc",
          width: 150,
        },
        {
          label: "Sub Community",
          field: "subcommunity",
          sort: "asc",
          width: 150,
        },
        {
          label: "Caste",
          field: "cast",
          sort: "asc",
          width: 150,
        },
        {
          label: "Marital Status",
          field: "maritalstatus",
          sort: "asc",
          width: 150,
        },

        {
          label: "Action",
          field: "action",
          sort: "asc",
          width: 150,
        },
      ],
      rows:
        filterData?.length > 0
          ? filterData.map((item, idx) => {
              console.log("users", item);
              return {
                sl: idx + 1,
                regdate: (
                  <div className="widget-content p-0" key={idx}>
                    <div className="widget-content-wrapper">
                      <div className="widget-content-left flex2">
                        <div
                          className="widget-heading"
                          style={{ opacity: 1, fontSize: "13px" }}
                        >
                          {moment(item?.createdAt).format("DD-MM-YYYY")}
                        </div>
                      </div>
                    </div>
                  </div>
                ),
                userid: (
                  <div className="widget-content p-0" key={idx}>
                    <div className="widget-content-wrapper">
                      <div className="widget-content-left flex2">
                        <div
                          className="widget-heading"
                          style={{ opacity: 1, fontSize: "13px" }}
                        >
                          {item?.customizedUserId}
                        </div>
                      </div>
                    </div>
                  </div>
                ),
                username: (
                  <div className="widget-content p-0" key={idx}>
                    <div className="widget-content-wrapper">
                      <div className="widget-content-left flex2">
                        <div
                          className="widget-heading"
                          style={{ opacity: 1, fontSize: "13px" }}
                        >
                          {item.firstName + " " + item.lastName}
                        </div>
                      </div>
                    </div>
                  </div>
                ),
                useremail: (
                  <div className="widget-content p-0" key={idx}>
                    <div className="widget-content-wrapper">
                      <div className="widget-content-left flex2">
                        <div
                          className="widget-heading"
                          style={{ opacity: 1, fontSize: "13px" }}
                        >
                          {item?.email}
                        </div>
                        {/* <div className="widget-subheading opacity-7">UI Designer</div> */}
                      </div>
                    </div>
                  </div>
                ),
                /**/
                religion: (
                  <div className="widget-content p-0" key={idx}>
                    <div className="widget-content-wrapper">
                      <div className="widget-content-left flex2">
                        <div
                          className="widget-heading"
                          style={{ opacity: 1, fontSize: "13px" }}
                        >
                          {item?.personalDetails?.personalInfo?.religion}
                        </div>
                      </div>
                    </div>
                  </div>
                ),
                community: (
                  <div className="widget-content p-0" key={idx}>
                    <div className="widget-content-wrapper">
                      <div className="widget-content-left flex2">
                        <div
                          className="widget-heading"
                          style={{ opacity: 1, fontSize: "13px" }}
                        >
                          {item?.personalDetails?.personalInfo?.community}
                        </div>
                      </div>
                    </div>
                  </div>
                ),
                subcommunity: (
                  <div className="widget-content p-0" key={idx}>
                    <div className="widget-content-wrapper">
                      <div className="widget-content-left flex2">
                        <div
                          className="widget-heading"
                          style={{ opacity: 1, fontSize: "13px" }}
                        >
                          {item?.personalDetails?.personalInfo?.subCommunity}
                        </div>
                      </div>
                    </div>
                  </div>
                ),
                cast: (
                  <div className="widget-content p-0" key={idx}>
                    <div className="widget-content-wrapper">
                      <div className="widget-content-left flex2">
                        <div
                          className="widget-heading"
                          style={{ opacity: 1, fontSize: "13px" }}
                        >
                          {item?.personalDetails?.personalInfo?.caste}
                        </div>
                      </div>
                    </div>
                  </div>
                ),
                maritalstatus: (
                  <div className="widget-content p-0" key={idx}>
                    <div className="widget-content-wrapper">
                      <div className="widget-content-left flex2">
                        <div
                          className="widget-heading"
                          style={{ opacity: 1, fontSize: "13px" }}
                        >
                          {item?.personalDetails?.personalInfo?.maritalStatus}
                        </div>
                      </div>
                    </div>
                  </div>
                ),
                /**/
                action: (
                  <div className="widget-content p-0" key={idx}>
                    <div className="widget-content-wrapper">
                      <div className="widget-content-center flex2">
                        <i
                          class="fa-solid fa-eye"
                          style={{ cursor: "pointer", color: "blue" }}
                          onClick={() => {
                            dispatch(getSingleUserReports(item._id));
                            navigate(`/ViewSingleUserReport`);
                          }}
                        ></i>
                        {/* <i class="fa-solid fa-trash-can"></i> */}
                      </div>
                    </div>
                  </div>
                ),
              };
            })
          : [],
    };
  });

  const handleReset = () => {
    setDate(initial);
    dispatch(getAllUserReports());
  };

  return (
    <div className="main_wrap">
      <CustomLoader loading={isLoading} />
      <h4 className="Pageheadline">Manage User Report</h4>
      <div className="searchDiv d-flex py-3">
        <form action="" className="row px-3" onSubmit={(e) => submitHandler(e)}>
          {/* from date */}
          <div className="" style={{ width: "20%", marginLeft: "5px" }}>
            <label htmlFor="fromDate">From Date</label>
            <input
              type="date"
              className="form-control"
              name="fromDate"
              value={date.fromDate}
              onChange={(e) => changehandler(e)}
            />
          </div>
          {/* to date */}
          <div className="" style={{ width: "20%", marginLeft: "10px" }}>
            <label htmlFor="toDate">To Date</label>
            <input
              type="date"
              className="form-control"
              name="toDate"
              min={date.fromDate}
              value={date.toDate}
              onChange={(e) => changehandler(e)}
            />
          </div>
          {/* Religion */}
          <div className="" style={{ width: "22%", marginLeft: "10px" }}>
            <label htmlFor="religionName">Religion Name</label>
            <select
              class="form-select table_page_select"
              aria-label="Default select example"
              onChange={(e) => changehandler(e)}
              name="religionName"
              value={date.religionName}
            >
              {" "}
              <option selected value="">
                Select Religion
              </option>
              {/* {console.log("allreligion", allreligion)} */}
              {allreligion?.map((item) => {
                return (
                  <option
                    // selected={item._id === date.religionName}
                    value={item?.name}
                  >
                    {item?.name}
                  </option>
                );
              })}
            </select>
          </div>
          {/* community */}
          <div className="" style={{ width: "30%", marginLeft: "5px" }}>
            <label htmlFor="communityName">Community Name</label>
            <select
              class="form-select table_page_select"
              aria-label="Default select example"
              onChange={(e) => changehandler(e)}
              name="communityName"
              value={date.communityName}
            >
              {" "}
              <option selected value="">
                Select Community Name
              </option>
              {console.log("AllCommunity", AllCommunity)}
              {AllCommunity?.map((item) => {
                return (
                  <option
                    // selected={item.name === date.communityName}
                    value={item?.name}
                  >
                    {item?.name}
                  </option>
                );
                // <option value={item?.name}>{item?.name}</option>;
              })}
              {/* {AllCommunity &&
                Array.isArray(AllCommunity) &&
                AllCommunity.map((item) => (
                  <option key={item.id} value={item.name}>
                    {item.name.toUpperCase()}
                  </option>
                ))} */}
            </select>
          </div>

          {/* sub community */}
          <div style={{ width: "270px", marginLeft: "5px", marginTop: "5px" }}>
            <label htmlFor="">Sub Community Name</label>
            <select
              class="form-select table_page_select"
              aria-label="Default select example"
              onChange={(e) => changehandler(e)}
              name="subCommunityName"
              value={date.subCommunityName}
            >
              {" "}
              <option selected value="">
                Select Sub Community
              </option>
              {AllsubCommunity?.map((item) => {
                return <option value={item?.name}>{item?.name}</option>;
              })}
            </select>
          </div>

          {/* Caste */}
          <div style={{ width: "230px", marginLeft: "5px", marginTop: "5px" }}>
            <label htmlFor="">Caste Name</label>
            <select
              class="form-select table_page_select"
              aria-label="Default select example"
              onChange={(e) => changehandler(e)}
              name="castName"
              value={date.castName}
            >
              {" "}
              <option selected value="">
                Select Caste
              </option>
              {Allcast?.map((item) => {
                return <option value={item?.name}>{item?.name}</option>;
              })}
            </select>
          </div>

          {/* marital Status */}
          <div style={{ width: "250px", marginLeft: "5px", marginTop: "5px" }}>
            <label htmlFor="">MaritalStatus</label>
            <select
              class="form-select table_page_select"
              aria-label="Default select example"
              onChange={(e) => changehandler(e)}
              name="maritalStatusName"
              value={date.maritalStatusName}
            >
              {" "}
              <option selected value="">
                Select Marital Status
              </option>
              {AllMaritalStatus?.map((item) => {
                return <option value={item.name}>{item.name}</option>;
              })}
            </select>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "flex-end",
            }}
          >
            <button
              type="submit"
              className="btn btn-primary table_btn"
              style={{ marginTop: "41px" }}
            >
              Search
            </button>
            {/* reset button */}
          </div>
        </form>
        <div
          className="ml-3"
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "flex-end",
          }}
        >
          <button
            type="submit"
            className="btn btn-danger table_btn ml-3"
            onClick={() => handleReset()}
          >
            Reset
          </button>
        </div>
      </div>
      <div style={{ overflowX: "auto" }} className="dataTableParDiv">
        <Table
          striped
          tableTitle="View All Users "
          data={tableData}
          onSearch={(e) => setsearchText(e.target.value)}
          flag={true}
        />
      </div>
    </div>
  );
};

export default Usersreports;

import axios from "axios";
import { toast } from "react-toastify";

// Constants
import { BASE_URL, API_ENDPOINT_PATH } from "../constants/config";

const API_URL = BASE_URL + API_ENDPOINT_PATH;

let token = localStorage.getItem("Authorization");

export async function requestData(url, method, data = {}) {
  let apiUrl = API_URL + url;
  var myHeaders = new Headers();

  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", token);
  myHeaders.append("Access-Control-Allow-Origin", BASE_URL);
  var raw = JSON.stringify(data);

  var requestOptions = {
    method: method,
    headers: myHeaders,
    redirect: "follow",
  };

  if (method != "GET") {
    requestOptions.body = raw;
  }

  return await fetch(apiUrl, requestOptions)
    .then((response) => response.json())
    .then((result) => {
      return result;
    })
    .catch((error) => console.log("error", error));
}

// export async function fileUpload(url, method, file) {
//   let token = localStorage.getItem("Authorization");
//   let user_type = localStorage.getItem("userType");
//   let apiEndpoint = API_URL + url;
//   let headers = {
//     Authorization: token,
//     userType: user_type,
//   };

//   return await fetch(apiEndpoint, {
//     method: method,
//     body: file,
//     redirect: "follow",
//     headers: headers,
//   })
//     .then((response) => {
//       // console.log('responseee', response, headers);
//       return response.json();
//     })
//     .then(
//       (result) => {
//         // console.log(result);
//         return result;
//       },
//       (error) => {
//         return error;
//       }
//     );
// }

export const fileUpload = async (props) => {
  const token = localStorage.getItem("Authorization");
  const userType = localStorage.getItem("userType");
  const {urlPath, method, data} = props
  console.log("popo",urlPath, method, data)
  // const formData = new FormData();
  // formData.append('file', file);

  let config = {
  method: `${method}`,
  url: `${API_URL}${urlPath}`,
  headers: {
    // "Content-Type": "application/json",
    Authorization: token,
    userType: userType,
  },
  data: data,
};

let responseData;
await axios(config)
.then(function (res) {
  responseData = res;
})
.catch(function (error) {
  if (error?.response?.status === 401) {
    toast.error("Unauthorized");
    responseData = error;
  } else {
    responseData = error;
  }
});

return responseData;
}

export const ApiHelperFunction = async (props) => {
  let token = localStorage.getItem("Authorization");
  let userType = localStorage.getItem("userType");
  const { urlPath, method, data } = props;
  let config = {
    method: `${method}`,
    url: `${API_URL}${urlPath}`,
    headers: {
      // "Content-Type": "application/json",
      Authorization: token,
      userType: userType,
    },
    data: data,
  };
  let responseData;

  await axios(config)
    .then(function (res) {
      responseData = res;
    })
    .catch(function (error) {
      if (error?.response?.status === 401) {
        toast.error("Unauthorized");
        responseData = error;
      } else {
        responseData = error;
      }
    });

  return responseData;
};

export { API_URL };

import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  UpdateSubAdmin,
  adminRegisterSubAdmin,
  getAllSubAdmin,
} from "../../redux/authSlice/AuthSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { IoIosEye, IoIosEyeOff } from "react-icons/io";

const AddEmployee = () => {
  var numRegXp = /^[0-9]+$/;
  var emailReg =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  const location = useLocation();
  const navigate = useNavigate();

  const createErrors = {
    firstName: "",
    lastName: "",
    email: "",
    mobile: "",
    password: "",
  };

  const INITIAL = {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    mobile: "",
    tabPermission: [],
  };

  const INITIAL_CHECKBOX = {
    dashboard: false,
    /*-------------------------- */
    profileIdFor: false,
    addProfile: false,
    manageProfile: false,
    managaeProfileEdit: false,
    managaeProfileDelete: false,
    /*-------------------------- */
    religion: false,
    addReligion: false,
    manageReligion: false,
    manageReligionEdit: false,
    manageReligionDelete: false,
    /*-------------------------- */
    community: false,
    addCommunity: false,
    manageCommunity: false,
    manageCommunityEdit: false,
    manageCommunityDelete: false,
    /*-------------------------- */
    subCommunity: false,
    addSubCommunity: false,
    manageSubCommunity: false,
    manageSubCommunityEdit: false,
    manageSubCommunityDelete: false,
    /*-------------------------- */
    caste: false,
    addcaste: false,
    manageCaste: false,
    manageCasteEdit: false,
    manageCasteDelete: false,
    /*-------------------------- */
    maritalStatus: false,
    addMaritalStatus: false,
    manageMaritalStatus: false,
    manageMaritalStatusEdit: false,
    manageMaritalStatusDelete: false,
    /*-------------------------- */
    diet: false,
    adddiet: false,
    managediet: false,
    managedietEdit: false,
    managedietDelete: false,
    /*-------------------------- */
    complexion: false,
    addcomplexion: false,
    managecomplexion: false,
    managecomplexionEdit: false,
    managecomplexionDelete: false,
    /*-------------------------- */
    highestQualification: false,
    addhighestQualification: false,
    managehighestQualification: false,
    managehighestQualificationEdit: false,
    managehighestQualificationDelete: false,
    /*-------------------------- */
    occupation: false,
    addoccupation: false,
    manageoccupation: false,
    manageoccupationEdit: false,
    manageoccupationDelete: false,
    /*-------------------------- */
    zodiacSign: false,
    addzodiacSign: false,
    managezodiacSign: false,
    managezodiacSignEdit: false,
    managezodiacSignDelete: false,
    /*-------------------------- */
    country: false,
    addcountry: false,
    managecountry: false,
    managecountryEdit: false,
    managecountryDelete: false,
    /*-------------------------- */
    state: false,
    addstate: false,
    managestate: false,
    managestateEdit: false,
    managestateDelete: false,
    /*-------------------------- */
    city: false,
    addcity: false,
    managecity: false,
    managecityEdit: false,
    managecityDelete: false,
    /*-------------------------- */
    manageUser: false,
    /*-------------------------- */
    accessManagement: false,
    addaccessManagement: false,
    manageaccessManagement: false,
    manageaccessManagementEdit: false,
    manageaccessManagementDelete: false,
    /*-------------------------- */
    paymentHistory: false,
    /*-------------------------- */
    accountViewPrice: false,
    addaccountViewPrice: false,
    manageaccountViewPrice: false,
    manageaccountViewPriceEdit: false,
    manageaccountViewPriceDelete: false,
    /*-------------------------- */
    contactUs: false,
    /*-------------------------- */
    pages: false,
    addpages: false,
    managepages: false,
    managepagesEdit: false,
    managepagesDelete: false,
    /*-------------------------- */
    faq: false,
    addFaq: false,
    manageFaq: false,
    manageFaqEdit: false,
    manageFaqDelete: false,
    /*-------------------------- */
    userReport: false,
    /*-------------------------- */
    demographyReport: false,
    /*-------------------------- */
    acccountPurchaseReport: false,
  };
  const dispatch = useDispatch();
  const [employee, setEmployee] = useState({ ...INITIAL });
  const [checkboxVal, setCheckboxVal] = useState({ ...INITIAL_CHECKBOX });
  const [enableRadio, setEnableRadio] = useState(false);
  const [enableCheckboxes, setEnableCheckboxes] = useState(false);
  /**------------------------------------------------------------- */
  const [profileisForView, setprofileisForView] = useState(false);
  const [viewProfile, setViewprofile] = useState(false);

  const [religionView, setreligionView] = useState(false);
  const [viewReligion, setViewReligion] = useState(false);

  const [communityView, setcommunityView] = useState(false);
  const [viewCommunity, setViewCommunity] = useState(false);

  const [subCommunityView, setSubCommunityView] = useState(false);
  const [viewSubCommunity, setViewSubCommunity] = useState(false);

  const [casteView, setCasteView] = useState(false);
  const [viewCast, setViewCast] = useState(false);

  const [maritalView, setMaritalView] = useState(false);
  const [viewMarital, setViewMarital] = useState(false);

  const [dietView, setDietView] = useState(false);
  const [viewDiet, setViewDiet] = useState(false);

  const [complexionView, setComplexionView] = useState(false);
  const [viewComplexion, setViewComplexion] = useState(false);

  const [qualificationView, setQualificationView] = useState(false);
  const [viewQualification, setViewQualification] = useState(false);

  const [occupationView, setOccupationView] = useState(false);
  const [viewOccupation, setViewOccupation] = useState(false);

  const [zodiacView, setZodiacView] = useState(false);
  const [viewZodiac, setViewZodiac] = useState(false);

  const [countryView, setCountryView] = useState(false);
  const [viewCountry, setViewCountry] = useState(false);

  const [stateView, setStateView] = useState(false);
  const [viewState, setViewState] = useState(false);

  const [cityView, setCityView] = useState(false);
  const [viewCity, setViewCity] = useState(false);
  /**-------------------------------------------------------------------- */
  const [accessManagementView, setAccessManagementView] = useState(false);

  const [accountPriceView, setAccountPriceView] = useState(false);
  const [viewAccountPrice, setViewAccountPrice] = useState(false);

  const [pagesView, setPagesView] = useState(false);
  const [viewPages, setViewPages] = useState(false);

  const [faqView, setFaqView] = useState(false);
  const [viewfaq, setViewFaq] = useState(false);

  /**-------------------------------------------------------------------- */
  const [erros, seterros] = useState(createErrors);
  const [show, setShow] = useState(false);
  const [editdlt, setEditdlt] = useState(false);
  console.log("sdsdsdsdsd", location.state);

  const validator = ({ firstName, lastName, email, mobile, password }) => {
    // if (firstName?.trim().length < 2) {
    if (firstName === "") {
      // toast.error("Length of firstname cannot be less than 2 characters");
      seterros({
        ...createErrors,
        // firstName: "Length of firstname cannot be less than 2 characters",
        firstName: "first name is required",
      });
      return false;
    }

    if (lastName === "") {
      // toast.error("Length of lastname cannot be less than 2 characters");
      seterros({
        ...createErrors,
        lastName: "Last name is required",
      });
      return false;
    }

    if (!emailReg.test(email)) {
      // toast.error("Please enter email in correct format");
      seterros({
        ...createErrors,
        email: "Please enter email in correct format",
      });
      return false;
    }

    if (password?.length < 8) {
      // toast.error("Length of password cannot be less than 9 characters");
      seterros({
        ...createErrors,
        password: "Length of password cannot be less than 8 characters",
      });
      return false;
    }

    if (mobile?.length < 10) {
      // toast.error("Length of password cannot be less than 9 characters");
      seterros({
        ...createErrors,
        mobile: "Length of Phone No cannot be less than 10 digits",
      });
      return false;
    }

    return true;
  };

  const changeHandler = (e) => {
    if (e.target.name === "mobile") {
      if (!e.target.value.match(numRegXp)) {
        e.target.value = "";
      }
    }

    setEmployee((oldState) => {
      oldState[e.target.name] = e.target.value;
      return { ...oldState };
    });
  };

  const checkBoxChangehandler = (e) => {
    const { name, value, type, checked } = e.target;
    // console.log("checkbox", e.target.value);
    if (type === "checkbox") {
      setCheckboxVal({ ...checkboxVal, [name]: checked });
    }
  };

  useEffect(() => {
    if (location.state) {
      setEmployee({
        firstName: location.state?.firstName,
        lastName: location.state?.lastName,
        email: location.state?.email,
        password: location.state?.passwordCrypto,
        mobile: location.state?.mobile,
      });

      setCheckboxVal({
        dashboard: location.state?.tabPermission?.[0]?.dashboard,
        /*-------------------------- */
        profileIdFor: location.state?.tabPermission?.[0]?.profileIdFor,
        addProfile: location.state?.tabPermission?.[0]?.addProfile,
        manageProfile: location.state?.tabPermission?.[0]?.manageProfile,
        managaeProfileEdit:
          location.state?.tabPermission?.[0]?.managaeProfileEdit,
        managaeProfileDelete:
          location.state?.tabPermission?.[0]?.managaeProfileDelete,
        /*-------------------------- */
        religion: location.state?.tabPermission?.[0]?.religion,
        addReligion: location.state?.tabPermission?.[0]?.addReligion,
        manageReligion: location.state?.tabPermission?.[0]?.manageReligion,
        manageReligionEdit:
          location.state?.tabPermission?.[0]?.manageReligionEdit,
        manageReligionDelete:
          location.state?.tabPermission?.[0]?.manageReligionDelete,
        /*-------------------------- */
        community: location.state?.tabPermission?.[0]?.community,
        addCommunity: location.state?.tabPermission?.[0]?.addCommunity,
        manageCommunity: location.state?.tabPermission?.[0]?.manageCommunity,
        manageCommunityEdit:
          location.state?.tabPermission?.[0]?.manageCommunityEdit,
        manageCommunityDelete:
          location.state?.tabPermission?.[0]?.manageCommunityDelete,
        /*-------------------------- */
        subCommunity: location.state?.tabPermission?.[0]?.subCommunity,
        addSubCommunity: location.state?.tabPermission?.[0]?.addSubCommunity,
        manageSubCommunity:
          location.state?.tabPermission?.[0]?.manageSubCommunity,
        manageSubCommunityEdit:
          location.state?.tabPermission?.[0]?.manageSubCommunityEdit,
        manageSubCommunityDelete:
          location.state?.tabPermission?.[0]?.manageSubCommunityDelete,
        /*-------------------------- */
        caste: location.state?.tabPermission?.[0]?.caste,
        addcaste: location.state?.tabPermission?.[0]?.addcaste,
        manageCaste: location.state?.tabPermission?.[0]?.manageCaste,
        manageCasteEdit: location.state?.tabPermission?.[0]?.manageCasteEdit,
        manageCasteDelete:
          location.state?.tabPermission?.[0]?.manageCasteDelete,
        /*-------------------------- */
        maritalStatus: location.state?.tabPermission?.[0]?.maritalStatus,
        addMaritalStatus: location.state?.tabPermission?.[0]?.addMaritalStatus,
        manageMaritalStatus:
          location.state?.tabPermission?.[0]?.manageMaritalStatus,
        manageMaritalStatusEdit:
          location.state?.tabPermission?.[0]?.manageMaritalStatusEdit,
        manageMaritalStatusDelete:
          location.state?.tabPermission?.[0]?.manageMaritalStatusDelete,
        /*-------------------------- */
        diet: location.state?.tabPermission?.[0]?.diet,
        adddiet: location.state?.tabPermission?.[0]?.adddiet,
        managediet: location.state?.tabPermission?.[0]?.managediet,
        managedietEdit: location.state?.tabPermission?.[0]?.managedietEdit,
        managedietDelete: location.state?.tabPermission?.[0]?.managedietDelete,
        /*-------------------------- */
        complexion: location.state?.tabPermission?.[0]?.complexion,
        addcomplexion: location.state?.tabPermission?.[0]?.addcomplexion,
        managecomplexion: location.state?.tabPermission?.[0]?.managecomplexion,
        managecomplexionEdit:
          location.state?.tabPermission?.[0]?.managecomplexionEdit,
        managecomplexionDelete:
          location.state?.tabPermission?.[0]?.managecomplexionDelete,
        /*-------------------------- */
        highestQualification:
          location.state?.tabPermission?.[0]?.highestQualification,
        addhighestQualification:
          location.state?.tabPermission?.[0]?.addhighestQualification,
        managehighestQualification:
          location.state?.tabPermission?.[0]?.managehighestQualification,
        managehighestQualificationEdit:
          location.state?.tabPermission?.[0]?.managehighestQualificationEdit,
        managehighestQualificationDelete:
          location.state?.tabPermission?.[0]?.managehighestQualificationDelete,
        /*-------------------------- */
        occupation: location.state?.tabPermission?.[0]?.occupation,
        addoccupation: location.state?.tabPermission?.[0]?.addoccupation,
        manageoccupation: location.state?.tabPermission?.[0]?.manageoccupation,
        manageoccupationEdit:
          location.state?.tabPermission?.[0]?.manageoccupationEdit,
        manageoccupationDelete:
          location.state?.tabPermission?.[0]?.manageoccupationDelete,
        /*-------------------------- */
        zodiacSign: location.state?.tabPermission?.[0]?.zodiacSign,
        addzodiacSign: location.state?.tabPermission?.[0]?.addzodiacSign,
        managezodiacSign: location.state?.tabPermission?.[0]?.managezodiacSign,
        managezodiacSignEdit:
          location.state?.tabPermission?.[0]?.managezodiacSignEdit,
        managezodiacSignDelete:
          location.state?.tabPermission?.[0]?.managezodiacSignDelete,
        /*-------------------------- */
        manageUser: location.state?.tabPermission?.[0]?.manageUser,
        /*-------------------------- */
        accessManagement: location.state?.tabPermission?.[0]?.accessManagement,
        addaccessManagement:
          location.state?.tabPermission?.[0]?.addaccessManagement,
        manageaccessManagement:
          location.state?.tabPermission?.[0]?.manageaccessManagement,
        manageaccessManagementEdit:
          location.state?.tabPermission?.[0]?.manageaccessManagementEdit,
        manageaccessManagementDelete:
          location.state?.tabPermission?.[0]?.manageaccessManagementDelete,
        /*-------------------------- */
        paymentHistory: location.state?.tabPermission?.[0]?.paymentHistory,
        /*-------------------------- */
        accountViewPrice: location.state?.tabPermission?.[0]?.accountViewPrice,
        addaccountViewPrice:
          location.state?.tabPermission?.[0]?.addaccountViewPrice,
        manageaccountViewPrice:
          location.state?.tabPermission?.[0]?.manageaccountViewPrice,
        manageaccountViewPriceEdit:
          location.state?.tabPermission?.[0]?.manageaccountViewPriceEdit,
        manageaccountViewPriceDelete:
          location.state?.tabPermission?.[0]?.manageaccountViewPriceDelete,
        /*-------------------------- */
        contactUs: location.state?.tabPermission?.[0]?.contactUs,
        /*-------------------------- */
        pages: location.state?.tabPermission?.[0]?.pages,
        addpages: location.state?.tabPermission?.[0]?.addpages,
        managepages: location.state?.tabPermission?.[0]?.managepages,
        managepagesEdit: location.state?.tabPermission?.[0]?.managepagesEdit,
        managepagesDelete:
          location.state?.tabPermission?.[0]?.managepagesDelete,
        /*-------------------------- */
        faq: location.state?.tabPermission?.[0]?.faq,
        addFaq: location.state?.tabPermission?.[0]?.addFaq,
        manageFaq: location.state?.tabPermission?.[0]?.manageFaq,
        manageFaqEdit: location.state?.tabPermission?.[0]?.manageFaqEdit,
        manageFaqDelete: location.state?.tabPermission?.[0]?.manageFaqDelete,
        /*-------------------------- */
        userReport: location.state?.tabPermission?.[0]?.userReport,
        /*-------------------------- */
        demographyReport: location.state?.tabPermission?.[0]?.demographyReport,
        /*-------------------------- */
        acccountPurchaseReport:
          location.state?.tabPermission?.[0]?.acccountPurchaseReport,
      });

      setEnableRadio(true);
      if (location.state?.tabPermission?.[0]?.profileIdFor) {
        setprofileisForView(true);
      }
      if (location.state?.tabPermission?.[0]?.religion) {
        setreligionView(true);
      }
      if (location.state?.tabPermission?.[0]?.community) {
        setcommunityView(true);
      }
      if (location.state?.tabPermission?.[0]?.subCommunity) {
        setSubCommunityView(true);
      }
      if (location.state?.tabPermission?.[0]?.caste) {
        setCasteView(true);
      }
      if (location.state?.tabPermission?.[0]?.maritalStatus) {
        setMaritalView(true);
      }
      if (location.state?.tabPermission?.[0]?.diet) {
        setDietView(true);
      }
      if (location.state?.tabPermission?.[0]?.complexion) {
        setComplexionView(true);
      }
      if (location.state?.tabPermission?.[0]?.highestQualification) {
        setQualificationView(true);
      }
      if (location.state?.tabPermission?.[0]?.occupation) {
        setOccupationView(true);
      }
      if (location.state?.tabPermission?.[0]?.zodiacSign) {
        setZodiacView(true);
      }

      if (location.state?.tabPermission?.[0]?.accessManagement) {
        setAccessManagementView(true);
      }
      if (location.state?.tabPermission?.[0]?.accountViewPrice) {
        setAccountPriceView(true);
      }
      if (location.state?.tabPermission?.[0]?.pages) {
        setPagesView(true);
      }
      if (location.state?.tabPermission?.[0]?.faq) {
        setFaqView(true);
      }
    }
  }, []);

  const submitHandler = (e) => {
    e.preventDefault();

    const validation = validator(employee);
    if (!validation) return;

    // const updatedEmployee = {
    //   ...employee,
    //   tabPermission: [...employee.tabPermission, checkboxVal],
    // };

    const updatedEmployee = {
      ...employee,
      tabPermission: [{ ...checkboxVal }],
    };

    if (location.state) {
      const id = location.state?._id;
      const data = updatedEmployee;

      dispatch(UpdateSubAdmin({ data, id }));
      setEmployee(INITIAL);
      setCheckboxVal(INITIAL_CHECKBOX);
      setEnableRadio(false);
      dispatch(getAllSubAdmin());
      navigate("/manageEmployee");
    } else {
      dispatch(adminRegisterSubAdmin(updatedEmployee));
      setEmployee(INITIAL);
      setCheckboxVal(INITIAL_CHECKBOX);
      setEnableRadio(false);
    }

    // console.log("updatedEmployee", updatedEmployee);
  };

  return (
    <div className="main_wrap">
      <div className="subAdmin_form">
        <form autoComplete="off" onSubmit={(e) => submitHandler(e)}>
          <h3 className="Pageheadline">
            {location.state ? "Edit Employee" : "Add Employee"}
          </h3>
          <div className="row" style={{ marginTop: "10px" }}>
            {/* First name* */}
            <div className="form-group col-md-6 col-12">
              <label className="font-weight-bold" htmlFor="exampleInputEmail1">
                First name*
              </label>
              <input
                type="text"
                name="firstName"
                className="form-control"
                id="exampleInputEmail12"
                placeholder="Enter First Name"
                autoComplete="off"
                onChange={changeHandler}
                value={employee?.firstName}
              />
              {erros.firstName && (
                <span className="errorMessageText text-danger">
                  {erros.firstName}
                </span>
              )}
            </div>
            {/* Last name* */}
            <div className="form-group col-md-6 col-12">
              <label
                className="font-weight-bold"
                htmlFor="exampleInputPassword1"
              >
                Last name*
              </label>
              <input
                type="text"
                name="lastName"
                className="form-control"
                id="exampleInputPassword14"
                placeholder="Enter Last Name"
                autoComplete="off"
                onChange={changeHandler}
                value={employee?.lastName}
              />
              {erros.lastName && (
                <span className="errorMessageText text-danger">
                  {erros.lastName}
                </span>
              )}
            </div>
            {/* Email ID* */}
            <div className="form-group col-md-6 col-12">
              <label
                className="font-weight-bold"
                htmlFor="exampleInputPassword1"
              >
                Email ID*
              </label>
              <input
                type="email"
                name="email"
                className="form-control"
                id="exampleInputPassword16"
                placeholder="johnwatkins@yahoo.com, joh.watkings@gmail.com"
                autoComplete="off"
                onChange={changeHandler}
                value={employee?.email}
              />
              {erros.email && (
                <span className="errorMessageText text-danger">
                  {erros.email}
                </span>
              )}
            </div>
            {/* Password* */}
            <div className="form-group col-md-6 col-12">
              <label
                className="font-weight-bold"
                htmlFor="exampleInputPassword1667"
              >
                Password*
              </label>
              <div className="pass_input">
                <input
                  type={show ? "text" : "password"}
                  name="password"
                  className="form-control"
                  id="exampleInputPassword1667"
                  placeholder="Enter Password"
                  autoComplete="off"
                  onChange={changeHandler}
                  value={employee?.password}
                />
                <div className="eye_icon">
                  {!show ? (
                    <IoIosEyeOff
                      style={{ fontSize: "26px" }}
                      onClick={() => setShow(!show)}
                    />
                  ) : (
                    <IoIosEye
                      style={{ fontSize: "26px" }}
                      onClick={() => setShow(!show)}
                    />
                  )}
                </div>
              </div>

              {erros.password && (
                <span className="errorMessageText text-danger">
                  {erros.password}
                </span>
              )}
            </div>
            {/* Mobile Number* */}
            <div className="form-group col-md-6 col-12">
              <label className="font-weight-bold" htmlFor="mobile">
                Mobile Number*
              </label>
              <div className="d-flex">
                {/* <PhoneInput
                international
                className="col-md-3"
                defaultCountry="DE"
                value={phonecode}
                onChange={setPhoneCode}
              /> */}
                <input
                  type="text"
                  name="mobile"
                  className="form-control"
                  placeholder="Enter Mobile Number"
                  id="mobile"
                  autoComplete="off"
                  onChange={changeHandler}
                  value={employee?.mobile}
                  // required={true}
                />
              </div>
              {erros.mobile && (
                <span className="errorMessageText text-danger">
                  {erros.mobile}
                </span>
              )}
            </div>
          </div>

          {/* Login Access */}
          <div
            className="form-check form-switch mb-2 "
            style={{
              paddingLeft: "13px",
              display: "flex",
              gap: "5px",
              alignItems: "center",
            }}
          >
            <label
              className="form-check-label font-weight-bold"
              htmlFor="flexSwitchCheckDefault1"
            >
              Login Access
            </label>
            <input
              onClick={(e) => {
                setEnableRadio(e.target.checked);
              }}
              className="loginAccess_input"
              type="checkbox"
              role="switch"
              id="flexSwitchCheckDefault1"
              checked={enableRadio}
            />
          </div>

          {enableRadio && (
            <div className="mb-3 fade-in myAll_checkbox">
              {/* Dashboard */}
              <div
                style={{ display: "flex", gap: "5px", alignItems: "center" }}
              >
                <input
                  style={{
                    display: "flex",
                    alignSelf: "self-start",
                    marginTop: "4px",
                  }}
                  onChange={checkBoxChangehandler}
                  type="checkbox"
                  id="dashboard"
                  name="dashboard"
                  checked={checkboxVal.dashboard}
                />
                <label className="mb-0" htmlFor="dashboard">
                  <b>Dashboard</b>
                </label>
              </div>
              {/* ===============   Master  ============================= */}
              {/* Profile is for */}
              <div>
                <div
                  style={{
                    display: "flex",
                    gap: "5px",
                    alignItems: "center",
                  }}
                >
                  <input
                    onChange={checkBoxChangehandler}
                    onClick={() => setprofileisForView(!profileisForView)}
                    type="checkbox"
                    id="profileIdFor"
                    name="profileIdFor"
                    checked={checkboxVal.profileIdFor}
                  />
                  <label htmlFor="account">
                    <b>profile isFor</b>
                  </label>
                </div>

                {profileisForView && (
                  <>
                    {/* add */}
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginLeft: "17px",
                      }}
                    >
                      <input
                        type="checkbox"
                        id="addProfile"
                        name="addProfile"
                        checked={checkboxVal.addProfile}
                        onChange={(e) => checkBoxChangehandler(e)}
                      />
                      <label
                        className="mb-0"
                        htmlFor="addProfile"
                        style={{ fontSize: "12px" }}
                      >
                        Add Profile Is For
                      </label>
                    </div>
                    {/* view */}
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginLeft: "17px",
                      }}
                    >
                      <input
                        type="checkbox"
                        id="manageProfile"
                        name="manageProfile"
                        checked={checkboxVal.manageProfile}
                        onChange={(e) => checkBoxChangehandler(e)}
                        onClick={() => {
                          // setEditdlt(e.target.checked);
                          setViewprofile(!viewProfile);
                        }}
                      />
                      <label
                        className="mb-0"
                        htmlFor="manageProfile"
                        style={{ fontSize: "12px" }}
                      >
                        View Profile Is For
                      </label>
                    </div>

                    {viewProfile && (
                      <div>
                        {/* edit */}
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginLeft: "17px",
                          }}
                        >
                          <input
                            type="checkbox"
                            id="managaeProfileEdit"
                            name="managaeProfileEdit"
                            checked={checkboxVal.managaeProfileEdit}
                            onChange={(e) => checkBoxChangehandler(e)}
                          />
                          <label
                            className="mb-0"
                            htmlFor="managaeProfileEdit"
                            style={{ fontSize: "12px" }}
                          >
                            Edit Profile Is For
                          </label>
                        </div>
                        {/* delete */}
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginLeft: "17px",
                          }}
                        >
                          <input
                            type="checkbox"
                            id="managaeProfileDelete"
                            name="managaeProfileDelete"
                            checked={checkboxVal.managaeProfileDelete}
                            onChange={(e) => checkBoxChangehandler(e)}
                          />
                          <label
                            className="mb-0"
                            htmlFor="managaeProfileDelete"
                            style={{ fontSize: "12px" }}
                          >
                            Delete Profile Is For
                          </label>
                        </div>
                      </div>
                    )}
                  </>
                )}
              </div>

              {/* Religion */}
              <div>
                <div
                  style={{
                    display: "flex",
                    gap: "5px",
                    alignItems: "center",
                  }}
                >
                  <input
                    onChange={checkBoxChangehandler}
                    onClick={() => setreligionView(!religionView)}
                    type="checkbox"
                    id="religion"
                    name="religion"
                    checked={checkboxVal.religion}
                  />
                  <label htmlFor="account">
                    <b>Religion</b>
                  </label>
                </div>

                {religionView && (
                  <>
                    {/* add */}
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginLeft: "17px",
                      }}
                    >
                      <input
                        type="checkbox"
                        id="addReligion"
                        name="addReligion"
                        checked={checkboxVal.addReligion}
                        onChange={(e) => checkBoxChangehandler(e)}
                      />
                      <label
                        className="mb-0"
                        htmlFor="addReligion"
                        style={{ fontSize: "12px" }}
                      >
                        Add Religion
                      </label>
                    </div>
                    {/* view */}
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginLeft: "17px",
                      }}
                    >
                      <input
                        type="checkbox"
                        id="manageReligion"
                        name="manageReligion"
                        checked={checkboxVal.manageReligion}
                        onChange={(e) => checkBoxChangehandler(e)}
                        onClick={(e) => {
                          setViewReligion(!viewReligion);
                        }}
                      />
                      <label
                        className="mb-0"
                        htmlFor="manageReligion"
                        style={{ fontSize: "12px" }}
                      >
                        View Religion
                      </label>
                    </div>
                    {viewReligion && (
                      <div>
                        {/* edit */}
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginLeft: "17px",
                          }}
                        >
                          <input
                            type="checkbox"
                            id="manageReligionEdit"
                            name="manageReligionEdit"
                            checked={checkboxVal.manageReligionEdit}
                            onChange={(e) => checkBoxChangehandler(e)}
                          />
                          <label
                            className="mb-0"
                            htmlFor="manageReligionEdit"
                            style={{ fontSize: "12px" }}
                          >
                            Edit Religion
                          </label>
                        </div>
                        {/* delete */}
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginLeft: "17px",
                          }}
                        >
                          <input
                            type="checkbox"
                            id="manageReligionDelete"
                            name="manageReligionDelete"
                            checked={checkboxVal.manageReligionDelete}
                            onChange={(e) => checkBoxChangehandler(e)}
                          />
                          <label
                            className="mb-0"
                            htmlFor="manageReligionDelete"
                            style={{ fontSize: "12px" }}
                          >
                            Delete Religion
                          </label>
                        </div>
                      </div>
                    )}
                  </>
                )}
              </div>

              {/* Community */}
              <div>
                <div
                  style={{
                    display: "flex",
                    gap: "5px",
                    alignItems: "center",
                  }}
                >
                  <input
                    onChange={checkBoxChangehandler}
                    onClick={() => setcommunityView(!communityView)}
                    type="checkbox"
                    id="community"
                    name="community"
                    checked={checkboxVal.community}
                  />
                  <label htmlFor="community">
                    <b>Community</b>
                  </label>
                </div>

                {communityView && (
                  <>
                    {/* add */}
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginLeft: "17px",
                      }}
                    >
                      <input
                        type="checkbox"
                        id="addCommunity"
                        name="addCommunity"
                        checked={checkboxVal.addCommunity}
                        onChange={(e) => checkBoxChangehandler(e)}
                      />
                      <label
                        className="mb-0"
                        htmlFor="addCommunity"
                        style={{ fontSize: "12px" }}
                      >
                        Add Community
                      </label>
                    </div>
                    {/* view */}
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginLeft: "17px",
                      }}
                    >
                      <input
                        type="checkbox"
                        id="manageCommunity"
                        name="manageCommunity"
                        checked={checkboxVal.manageCommunity}
                        onChange={(e) => checkBoxChangehandler(e)}
                        onClick={(e) => {
                          setViewCommunity(!viewCommunity);
                        }}
                      />
                      <label
                        className="mb-0"
                        htmlFor="manageCommunity"
                        style={{ fontSize: "12px" }}
                      >
                        View Community
                      </label>
                    </div>
                    {viewCommunity && (
                      <div>
                        {/* edit */}
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginLeft: "17px",
                          }}
                        >
                          <input
                            type="checkbox"
                            id="manageCommunityEdit"
                            name="manageCommunityEdit"
                            checked={checkboxVal.manageCommunityEdit}
                            onChange={(e) => checkBoxChangehandler(e)}
                          />
                          <label
                            className="mb-0"
                            htmlFor="manageCommunityEdit"
                            style={{ fontSize: "12px" }}
                          >
                            Edit Community
                          </label>
                        </div>
                        {/* delete */}
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginLeft: "17px",
                          }}
                        >
                          <input
                            type="checkbox"
                            id="manageCommunityDelete"
                            name="manageCommunityDelete"
                            checked={checkboxVal.manageCommunityDelete}
                            onChange={(e) => checkBoxChangehandler(e)}
                          />
                          <label
                            className="mb-0"
                            htmlFor="manageCommunityDelete"
                            style={{ fontSize: "12px" }}
                          >
                            Delete Community
                          </label>
                        </div>
                      </div>
                    )}
                  </>
                )}
              </div>

              {/* Sub Community */}
              <div>
                <div
                  style={{
                    display: "flex",
                    gap: "5px",
                    alignItems: "center",
                  }}
                >
                  <input
                    onChange={checkBoxChangehandler}
                    onClick={() => setSubCommunityView(!subCommunityView)}
                    type="checkbox"
                    id="subCommunity"
                    name="subCommunity"
                    checked={checkboxVal.subCommunity}
                  />
                  <label htmlFor="subCommunity">
                    <b>Sub Community</b>
                  </label>
                </div>

                {subCommunityView && (
                  <>
                    {/* add */}
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginLeft: "17px",
                      }}
                    >
                      <input
                        type="checkbox"
                        id="addSubCommunity"
                        name="addSubCommunity"
                        checked={checkboxVal.addSubCommunity}
                        onChange={(e) => checkBoxChangehandler(e)}
                      />
                      <label
                        className="mb-0"
                        htmlFor="addSubCommunity"
                        style={{ fontSize: "12px" }}
                      >
                        Add Sub Community
                      </label>
                    </div>
                    {/* view */}
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginLeft: "17px",
                      }}
                    >
                      <input
                        type="checkbox"
                        id="manageSubCommunity"
                        name="manageSubCommunity"
                        checked={checkboxVal.manageSubCommunity}
                        onChange={(e) => checkBoxChangehandler(e)}
                        onClick={(e) => {
                          setViewSubCommunity(!viewSubCommunity);
                        }}
                      />
                      <label
                        className="mb-0"
                        htmlFor="manageSubCommunity"
                        style={{ fontSize: "12px" }}
                      >
                        View Sub Community
                      </label>
                    </div>
                    {viewSubCommunity && (
                      <div>
                        {/* edit */}
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginLeft: "17px",
                          }}
                        >
                          <input
                            type="checkbox"
                            id="manageSubCommunityEdit"
                            name="manageSubCommunityEdit"
                            checked={checkboxVal.manageSubCommunityEdit}
                            onChange={(e) => checkBoxChangehandler(e)}
                          />
                          <label
                            className="mb-0"
                            htmlFor="manageSubCommunityEdit"
                            style={{ fontSize: "12px" }}
                          >
                            Edit Sub Community
                          </label>
                        </div>
                        {/* delete */}
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginLeft: "17px",
                          }}
                        >
                          <input
                            type="checkbox"
                            id="manageSubCommunityDelete"
                            name="manageSubCommunityDelete"
                            checked={checkboxVal.manageSubCommunityDelete}
                            onChange={(e) => checkBoxChangehandler(e)}
                          />
                          <label
                            className="mb-0"
                            htmlFor="manageSubCommunityDelete"
                            style={{ fontSize: "12px" }}
                          >
                            Delete SubCommunity
                          </label>
                        </div>
                      </div>
                    )}
                  </>
                )}
              </div>

              {/* Caste */}
              <div>
                <div
                  style={{
                    display: "flex",
                    gap: "5px",
                    alignItems: "center",
                  }}
                >
                  <input
                    onChange={checkBoxChangehandler}
                    onClick={() => setCasteView(!casteView)}
                    type="checkbox"
                    id="caste"
                    name="caste"
                    checked={checkboxVal.caste}
                  />
                  <label htmlFor="caste">
                    <b>Caste</b>
                  </label>
                </div>

                {casteView && (
                  <>
                    {/* add */}
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginLeft: "17px",
                      }}
                    >
                      <input
                        type="checkbox"
                        id="addcaste"
                        name="addcaste"
                        checked={checkboxVal.addcaste}
                        onChange={(e) => checkBoxChangehandler(e)}
                      />
                      <label
                        className="mb-0"
                        htmlFor="addcaste"
                        style={{ fontSize: "12px" }}
                      >
                        Add Caste
                      </label>
                    </div>
                    {/* view */}
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginLeft: "17px",
                      }}
                    >
                      <input
                        type="checkbox"
                        id="manageCaste"
                        name="manageCaste"
                        checked={checkboxVal.manageCaste}
                        onChange={(e) => checkBoxChangehandler(e)}
                        onClick={(e) => {
                          setViewCast(!viewCast);
                        }}
                      />
                      <label
                        className="mb-0"
                        htmlFor="manageCaste"
                        style={{ fontSize: "12px" }}
                      >
                        View Caste
                      </label>
                    </div>
                    {viewCast && (
                      <div>
                        {/* edit */}
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginLeft: "17px",
                          }}
                        >
                          <input
                            type="checkbox"
                            id="manageCasteEdit"
                            name="manageCasteEdit"
                            checked={checkboxVal.manageCasteEdit}
                            onChange={(e) => checkBoxChangehandler(e)}
                          />
                          <label
                            className="mb-0"
                            htmlFor="manageCasteEdit"
                            style={{ fontSize: "12px" }}
                          >
                            Edit Caste
                          </label>
                        </div>
                        {/* delete */}
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginLeft: "17px",
                          }}
                        >
                          <input
                            type="checkbox"
                            id="manageCasteDelete"
                            name="manageCasteDelete"
                            checked={checkboxVal.manageCasteDelete}
                            onChange={(e) => checkBoxChangehandler(e)}
                          />
                          <label
                            className="mb-0"
                            htmlFor="manageCasteDelete"
                            style={{ fontSize: "12px" }}
                          >
                            Delete Caste
                          </label>
                        </div>
                      </div>
                    )}
                  </>
                )}
              </div>

              {/* Marital Status */}
              <div>
                <div
                  style={{
                    display: "flex",
                    gap: "5px",
                    alignItems: "center",
                  }}
                >
                  <input
                    onChange={checkBoxChangehandler}
                    onClick={() => setMaritalView(!maritalView)}
                    type="checkbox"
                    id="maritalStatus"
                    name="maritalStatus"
                    checked={checkboxVal.maritalStatus}
                  />
                  <label htmlFor="maritalStatus">
                    <b>Marital Status</b>
                  </label>
                </div>

                {maritalView && (
                  <>
                    {/* add */}
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginLeft: "17px",
                      }}
                    >
                      <input
                        type="checkbox"
                        id="addMaritalStatus"
                        name="addMaritalStatus"
                        checked={checkboxVal.addMaritalStatus}
                        onChange={(e) => checkBoxChangehandler(e)}
                      />
                      <label
                        className="mb-0"
                        htmlFor="addMaritalStatus"
                        style={{ fontSize: "12px" }}
                      >
                        Add Marital Status
                      </label>
                    </div>
                    {/* view */}
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginLeft: "17px",
                      }}
                    >
                      <input
                        type="checkbox"
                        id="manageMaritalStatus"
                        name="manageMaritalStatus"
                        checked={checkboxVal.manageMaritalStatus}
                        onChange={(e) => checkBoxChangehandler(e)}
                        onClick={(e) => {
                          setViewMarital(!viewMarital);
                        }}
                      />
                      <label
                        className="mb-0"
                        htmlFor="manageMaritalStatus"
                        style={{ fontSize: "12px" }}
                      >
                        View Marital Status
                      </label>
                    </div>
                    {viewMarital && (
                      <div>
                        {/* edit */}
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginLeft: "17px",
                          }}
                        >
                          <input
                            type="checkbox"
                            id="manageMaritalStatusEdit"
                            name="manageMaritalStatusEdit"
                            checked={checkboxVal.manageMaritalStatusEdit}
                            onChange={(e) => checkBoxChangehandler(e)}
                          />
                          <label
                            className="mb-0"
                            htmlFor="manageMaritalStatusEdit"
                            style={{ fontSize: "12px" }}
                          >
                            Edit Marital Status
                          </label>
                        </div>
                        {/* delete */}
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginLeft: "17px",
                          }}
                        >
                          <input
                            type="checkbox"
                            id="manageMaritalStatusDelete"
                            name="manageMaritalStatusDelete"
                            checked={checkboxVal.manageMaritalStatusDelete}
                            onChange={(e) => checkBoxChangehandler(e)}
                          />
                          <label
                            className="mb-0"
                            htmlFor="manageMaritalStatusDelete"
                            style={{ fontSize: "12px" }}
                          >
                            Delete Marital Status
                          </label>
                        </div>
                      </div>
                    )}
                  </>
                )}
              </div>

              {/* Diet */}
              <div>
                <div
                  style={{
                    display: "flex",
                    gap: "5px",
                    alignItems: "center",
                  }}
                >
                  <input
                    onChange={checkBoxChangehandler}
                    onClick={() => setDietView(!dietView)}
                    type="checkbox"
                    id="diet"
                    name="diet"
                    checked={checkboxVal.diet}
                  />
                  <label htmlFor="diet">
                    <b>Diet</b>
                  </label>
                </div>

                {dietView && (
                  <>
                    {/* add */}
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginLeft: "17px",
                      }}
                    >
                      <input
                        type="checkbox"
                        id="adddiet"
                        name="adddiet"
                        checked={checkboxVal.adddiet}
                        onChange={(e) => checkBoxChangehandler(e)}
                        onClick={(e) => {
                          setEditdlt(e.target.checked);
                        }}
                      />
                      <label
                        className="mb-0"
                        htmlFor="adddiet"
                        style={{ fontSize: "12px" }}
                      >
                        Add Diet
                      </label>
                    </div>
                    {/* view */}
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginLeft: "17px",
                      }}
                    >
                      <input
                        type="checkbox"
                        id="managediet"
                        name="managediet"
                        checked={checkboxVal.managediet}
                        onChange={(e) => checkBoxChangehandler(e)}
                        onClick={() => setViewDiet(!viewDiet)}
                      />
                      <label
                        className="mb-0"
                        htmlFor="managediet"
                        style={{ fontSize: "12px" }}
                      >
                        View Diet
                      </label>
                    </div>
                    {viewDiet && (
                      <div>
                        {/* edit */}
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginLeft: "17px",
                          }}
                        >
                          <input
                            type="checkbox"
                            id="managedietEdit"
                            name="managedietEdit"
                            checked={checkboxVal.managedietEdit}
                            onChange={(e) => checkBoxChangehandler(e)}
                          />
                          <label
                            className="mb-0"
                            htmlFor="managedietEdit"
                            style={{ fontSize: "12px" }}
                          >
                            Edit Diet
                          </label>
                        </div>
                        {/* delete */}
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginLeft: "17px",
                          }}
                        >
                          <input
                            type="checkbox"
                            id="managedietDelete"
                            name="managedietDelete"
                            checked={checkboxVal.managedietDelete}
                            onChange={(e) => checkBoxChangehandler(e)}
                          />
                          <label
                            className="mb-0"
                            htmlFor="managedietDelete"
                            style={{ fontSize: "12px" }}
                          >
                            Delete Diet
                          </label>
                        </div>
                      </div>
                    )}
                  </>
                )}
              </div>

              {/* Complexion */}
              <div>
                <div
                  style={{
                    display: "flex",
                    gap: "5px",
                    alignItems: "center",
                  }}
                >
                  <input
                    onChange={checkBoxChangehandler}
                    onClick={() => setComplexionView(!complexionView)}
                    type="checkbox"
                    id="complexion"
                    name="complexion"
                    checked={checkboxVal.complexion}
                  />
                  <label htmlFor="complexion">
                    <b>Complexion</b>
                  </label>
                </div>

                {complexionView && (
                  <>
                    {/* add */}
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginLeft: "17px",
                      }}
                    >
                      <input
                        type="checkbox"
                        id="addcomplexion"
                        name="addcomplexion"
                        checked={checkboxVal.addcomplexion}
                        onChange={(e) => checkBoxChangehandler(e)}
                      />
                      <label
                        className="mb-0"
                        htmlFor="addcomplexion"
                        style={{ fontSize: "12px" }}
                      >
                        Add Complexion
                      </label>
                    </div>
                    {/* view */}
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginLeft: "17px",
                      }}
                    >
                      <input
                        type="checkbox"
                        id="managecomplexion"
                        name="managecomplexion"
                        checked={checkboxVal.managecomplexion}
                        onChange={(e) => checkBoxChangehandler(e)}
                        onClick={(e) => {
                          setViewComplexion(!viewComplexion);
                        }}
                      />
                      <label
                        className="mb-0"
                        htmlFor="managecomplexion"
                        style={{ fontSize: "12px" }}
                      >
                        View Complexion
                      </label>
                    </div>
                    {viewComplexion && (
                      <div>
                        {/* edit */}
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginLeft: "17px",
                          }}
                        >
                          <input
                            type="checkbox"
                            id="managecomplexionEdit"
                            name="managecomplexionEdit"
                            checked={checkboxVal.managecomplexionEdit}
                            onChange={(e) => checkBoxChangehandler(e)}
                          />
                          <label
                            className="mb-0"
                            htmlFor="managecomplexionEdit"
                            style={{ fontSize: "12px" }}
                          >
                            Edit Complexion
                          </label>
                        </div>
                        {/* delete */}
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginLeft: "17px",
                          }}
                        >
                          <input
                            type="checkbox"
                            id="managecomplexionDelete"
                            name="managecomplexionDelete"
                            checked={checkboxVal.managecomplexionDelete}
                            onChange={(e) => checkBoxChangehandler(e)}
                          />
                          <label
                            className="mb-0"
                            htmlFor="managecomplexionDelete"
                            style={{ fontSize: "12px" }}
                          >
                            Delete Complexion
                          </label>
                        </div>
                      </div>
                    )}
                  </>
                )}
              </div>

              {/* highest Qualification */}
              <div>
                <div
                  style={{
                    display: "flex",
                    gap: "5px",
                    alignItems: "center",
                  }}
                >
                  <input
                    onChange={checkBoxChangehandler}
                    onClick={() => setQualificationView(!qualificationView)}
                    type="checkbox"
                    id="highestQualification"
                    name="highestQualification"
                    checked={checkboxVal.highestQualification}
                  />
                  <label htmlFor="highestQualification">
                    <b>Highest Qualification</b>
                  </label>
                </div>

                {qualificationView && (
                  <>
                    {/* add */}
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginLeft: "17px",
                      }}
                    >
                      <input
                        type="checkbox"
                        id="addhighestQualification"
                        name="addhighestQualification"
                        checked={checkboxVal.addhighestQualification}
                        onChange={(e) => checkBoxChangehandler(e)}
                      />
                      <label
                        className="mb-0"
                        htmlFor="addhighestQualification"
                        style={{ fontSize: "12px" }}
                      >
                        Add Highest Qualification
                      </label>
                    </div>
                    {/* view */}
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginLeft: "17px",
                      }}
                    >
                      <input
                        type="checkbox"
                        id="managehighestQualification"
                        name="managehighestQualification"
                        checked={checkboxVal.managehighestQualification}
                        onChange={(e) => checkBoxChangehandler(e)}
                        onClick={(e) => {
                          setViewQualification(!viewQualification);
                        }}
                      />
                      <label
                        className="mb-0"
                        htmlFor="managehighestQualification"
                        style={{ fontSize: "12px" }}
                      >
                        View Highest Qualification
                      </label>
                    </div>
                    {viewQualification && (
                      <div>
                        {/* edit */}
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginLeft: "17px",
                          }}
                        >
                          <input
                            type="checkbox"
                            id="managehighestQualificationEdit"
                            name="managehighestQualificationEdit"
                            checked={checkboxVal.managehighestQualificationEdit}
                            onChange={(e) => checkBoxChangehandler(e)}
                          />
                          <label
                            className="mb-0"
                            htmlFor="managehighestQualificationEdit"
                            style={{ fontSize: "12px" }}
                          >
                            Edit Highest Qualification
                          </label>
                        </div>
                        {/* delete */}
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginLeft: "17px",
                          }}
                        >
                          <input
                            type="checkbox"
                            id="managehighestQualificationDelete"
                            name="managehighestQualificationDelete"
                            checked={
                              checkboxVal.managehighestQualificationDelete
                            }
                            onChange={(e) => checkBoxChangehandler(e)}
                          />
                          <label
                            className="mb-0"
                            htmlFor="managehighestQualificationDelete"
                            style={{ fontSize: "12px" }}
                          >
                            Delete Highest Qualification
                          </label>
                        </div>
                      </div>
                    )}
                  </>
                )}
              </div>

              {/* Occupation */}
              <div>
                <div
                  style={{
                    display: "flex",
                    gap: "5px",
                    alignItems: "center",
                  }}
                >
                  <input
                    onChange={checkBoxChangehandler}
                    onClick={() => setOccupationView(!occupationView)}
                    type="checkbox"
                    id="occupation"
                    name="occupation"
                    checked={checkboxVal.occupation}
                  />
                  <label htmlFor="occupation">
                    <b>Occupation</b>
                  </label>
                </div>

                {occupationView && (
                  <>
                    {/* add */}
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginLeft: "17px",
                      }}
                    >
                      <input
                        type="checkbox"
                        id="addoccupation"
                        name="addoccupation"
                        checked={checkboxVal.addoccupation}
                        onChange={(e) => checkBoxChangehandler(e)}
                      />
                      <label
                        className="mb-0"
                        htmlFor="addoccupation"
                        style={{ fontSize: "12px" }}
                      >
                        Add Occupation
                      </label>
                    </div>
                    {/* view */}
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginLeft: "17px",
                      }}
                    >
                      <input
                        type="checkbox"
                        id="manageoccupation"
                        name="manageoccupation"
                        checked={checkboxVal.manageoccupation}
                        onChange={(e) => checkBoxChangehandler(e)}
                        onClick={(e) => {
                          setViewOccupation(!viewOccupation);
                        }}
                      />
                      <label
                        className="mb-0"
                        htmlFor="manageoccupation"
                        style={{ fontSize: "12px" }}
                      >
                        View Occupation
                      </label>
                    </div>
                    {viewOccupation && (
                      <div>
                        {/* edit */}
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginLeft: "17px",
                          }}
                        >
                          <input
                            type="checkbox"
                            id="manageoccupationEdit"
                            name="manageoccupationEdit"
                            checked={checkboxVal.manageoccupationEdit}
                            onChange={(e) => checkBoxChangehandler(e)}
                          />
                          <label
                            className="mb-0"
                            htmlFor="manageoccupationEdit"
                            style={{ fontSize: "12px" }}
                          >
                            Edit Occupation
                          </label>
                        </div>
                        {/* delete */}
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginLeft: "17px",
                          }}
                        >
                          <input
                            type="checkbox"
                            id="manageoccupationDelete"
                            name="manageoccupationDelete"
                            checked={checkboxVal.manageoccupationDelete}
                            onChange={(e) => checkBoxChangehandler(e)}
                          />
                          <label
                            className="mb-0"
                            htmlFor="manageoccupationDelete"
                            style={{ fontSize: "12px" }}
                          >
                            Delete Occupation
                          </label>
                        </div>
                      </div>
                    )}
                  </>
                )}
              </div>

              {/* Zodiac sign */}
              <div>
                <div
                  style={{
                    display: "flex",
                    gap: "5px",
                    alignItems: "center",
                  }}
                >
                  <input
                    onChange={checkBoxChangehandler}
                    onClick={() => setZodiacView(!zodiacView)}
                    type="checkbox"
                    id="zodiacSign"
                    name="zodiacSign"
                    checked={checkboxVal.zodiacSign}
                  />
                  <label htmlFor="zodiacSign">
                    <b>Zodiac Sign</b>
                  </label>
                </div>

                {zodiacView && (
                  <>
                    {/* add */}
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginLeft: "17px",
                      }}
                    >
                      <input
                        type="checkbox"
                        id="addzodiacSign"
                        name="addzodiacSign"
                        checked={checkboxVal.addzodiacSign}
                        onChange={(e) => checkBoxChangehandler(e)}
                      />
                      <label
                        className="mb-0"
                        htmlFor="addzodiacSign"
                        style={{ fontSize: "12px" }}
                      >
                        Add Zodiac Sign
                      </label>
                    </div>
                    {/* view */}
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginLeft: "17px",
                      }}
                    >
                      <input
                        type="checkbox"
                        id="manageoccupation"
                        name="manageoccupation"
                        checked={checkboxVal.manageoccupation}
                        onChange={(e) => checkBoxChangehandler(e)}
                        onClick={(e) => {
                          setViewZodiac(!viewZodiac);
                        }}
                      />
                      <label
                        className="mb-0"
                        htmlFor="manageoccupation"
                        style={{ fontSize: "12px" }}
                      >
                        View Zodiac Sign
                      </label>
                    </div>
                    {viewZodiac && (
                      <div>
                        {/* edit */}
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginLeft: "17px",
                          }}
                        >
                          <input
                            type="checkbox"
                            id="managezodiacSignEdit"
                            name="managezodiacSignEdit"
                            checked={checkboxVal.managezodiacSignEdit}
                            onChange={(e) => checkBoxChangehandler(e)}
                          />
                          <label
                            className="mb-0"
                            htmlFor="managezodiacSignEdit"
                            style={{ fontSize: "12px" }}
                          >
                            Edit Zodiac Sign
                          </label>
                        </div>
                        {/* delete */}
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginLeft: "17px",
                          }}
                        >
                          <input
                            type="checkbox"
                            id="managezodiacSignDelete"
                            name="managezodiacSignDelete"
                            checked={checkboxVal.managezodiacSignDelete}
                            onChange={(e) => checkBoxChangehandler(e)}
                          />
                          <label
                            className="mb-0"
                            htmlFor="managezodiacSignDelete"
                            style={{ fontSize: "12px" }}
                          >
                            Delete Zodiac Sign
                          </label>
                        </div>
                      </div>
                    )}
                  </>
                )}
              </div>

              {/* Country */}
              <div>
                <div
                  style={{
                    display: "flex",
                    gap: "5px",
                    alignItems: "center",
                  }}
                >
                  <input
                    onChange={checkBoxChangehandler}
                    onClick={() => setCountryView(!countryView)}
                    type="checkbox"
                    id="country"
                    name="country"
                    checked={checkboxVal.country}
                  />
                  <label htmlFor="country">
                    <b>Country</b>
                  </label>
                </div>

                {countryView && (
                  <>
                    {/* add */}
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginLeft: "17px",
                      }}
                    >
                      <input
                        type="checkbox"
                        id="addcountry"
                        name="addcountry"
                        checked={checkboxVal.addcountry}
                        onChange={(e) => checkBoxChangehandler(e)}
                      />
                      <label
                        className="mb-0"
                        htmlFor="addcountry"
                        style={{ fontSize: "12px" }}
                      >
                        Add Country
                      </label>
                    </div>
                    {/* view */}
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginLeft: "17px",
                      }}
                    >
                      <input
                        type="checkbox"
                        id="managecountry"
                        name="managecountry"
                        checked={checkboxVal.managecountry}
                        onChange={(e) => checkBoxChangehandler(e)}
                        onClick={(e) => {
                          setViewCountry(!viewCountry);
                        }}
                      />
                      <label
                        className="mb-0"
                        htmlFor="managecountry"
                        style={{ fontSize: "12px" }}
                      >
                        View Country
                      </label>
                    </div>
                    {viewCountry && (
                      <div>
                        {/* edit */}
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginLeft: "17px",
                          }}
                        >
                          <input
                            type="checkbox"
                            id="managecountryEdit"
                            name="managecountryEdit"
                            checked={checkboxVal.managecountryEdit}
                            onChange={(e) => checkBoxChangehandler(e)}
                          />
                          <label
                            className="mb-0"
                            htmlFor="managecountryEdit"
                            style={{ fontSize: "12px" }}
                          >
                            Edit Country
                          </label>
                        </div>
                        {/* delete */}
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginLeft: "17px",
                          }}
                        >
                          <input
                            type="checkbox"
                            id="managecountryDelete"
                            name="managecountryDelete"
                            checked={checkboxVal.managecountryDelete}
                            onChange={(e) => checkBoxChangehandler(e)}
                          />
                          <label
                            className="mb-0"
                            htmlFor="managecountryDelete"
                            style={{ fontSize: "12px" }}
                          >
                            Delete Country
                          </label>
                        </div>
                      </div>
                    )}
                  </>
                )}
              </div>

              {/* State */}
              <div>
                <div
                  style={{
                    display: "flex",
                    gap: "5px",
                    alignItems: "center",
                  }}
                >
                  <input
                    onChange={checkBoxChangehandler}
                    onClick={() => setStateView(!stateView)}
                    type="checkbox"
                    id="state"
                    name="state"
                    checked={checkboxVal.state}
                  />
                  <label htmlFor="state">
                    <b>State</b>
                  </label>
                </div>

                {stateView && (
                  <>
                    {/* add */}
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginLeft: "17px",
                      }}
                    >
                      <input
                        type="checkbox"
                        id="addstate"
                        name="addstate"
                        checked={checkboxVal.addstate}
                        onChange={(e) => checkBoxChangehandler(e)}
                      />
                      <label
                        className="mb-0"
                        htmlFor="addstate"
                        style={{ fontSize: "12px" }}
                      >
                        Add State
                      </label>
                    </div>
                    {/* view */}
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginLeft: "17px",
                      }}
                    >
                      <input
                        type="checkbox"
                        id="managestate"
                        name="managestate"
                        checked={checkboxVal.managestate}
                        onChange={(e) => checkBoxChangehandler(e)}
                        onClick={(e) => {
                          setViewState(!viewState);
                        }}
                      />
                      <label
                        className="mb-0"
                        htmlFor="managestate"
                        style={{ fontSize: "12px" }}
                      >
                        View state
                      </label>
                    </div>
                    {viewState && (
                      <div>
                        {/* edit */}
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginLeft: "17px",
                          }}
                        >
                          <input
                            type="checkbox"
                            id="managestateEdit"
                            name="managestateEdit"
                            checked={checkboxVal.managestateEdit}
                            onChange={(e) => checkBoxChangehandler(e)}
                          />
                          <label
                            className="mb-0"
                            htmlFor="managestateEdit"
                            style={{ fontSize: "12px" }}
                          >
                            Edit state
                          </label>
                        </div>
                        {/* delete */}
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginLeft: "17px",
                          }}
                        >
                          <input
                            type="checkbox"
                            id="managestateDelete"
                            name="managestateDelete"
                            checked={checkboxVal.managestateDelete}
                            onChange={(e) => checkBoxChangehandler(e)}
                          />
                          <label
                            className="mb-0"
                            htmlFor="managestateDelete"
                            style={{ fontSize: "12px" }}
                          >
                            Delete State
                          </label>
                        </div>
                      </div>
                    )}
                  </>
                )}
              </div>

              {/* City */}
              <div>
                <div
                  style={{
                    display: "flex",
                    gap: "5px",
                    alignItems: "center",
                  }}
                >
                  <input
                    onChange={checkBoxChangehandler}
                    onClick={() => setCityView(!cityView)}
                    type="checkbox"
                    id="city"
                    name="city"
                    checked={checkboxVal.city}
                  />
                  <label htmlFor="city">
                    <b>City</b>
                  </label>
                </div>

                {cityView && (
                  <>
                    {/* add */}
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginLeft: "17px",
                      }}
                    >
                      <input
                        type="checkbox"
                        id="addcity"
                        name="addcity"
                        checked={checkboxVal.addcity}
                        onChange={(e) => checkBoxChangehandler(e)}
                      />
                      <label
                        className="mb-0"
                        htmlFor="addcity"
                        style={{ fontSize: "12px" }}
                      >
                        Add City
                      </label>
                    </div>
                    {/* view */}
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginLeft: "17px",
                      }}
                    >
                      <input
                        type="checkbox"
                        id="managecity"
                        name="managecity"
                        checked={checkboxVal.managecity}
                        onChange={(e) => checkBoxChangehandler(e)}
                        onClick={(e) => {
                          setViewCity(!viewCity);
                        }}
                      />
                      <label
                        className="mb-0"
                        htmlFor="managecity"
                        style={{ fontSize: "12px" }}
                      >
                        View City
                      </label>
                    </div>
                    {viewCity && (
                      <div>
                        {/* edit */}
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginLeft: "17px",
                          }}
                        >
                          <input
                            type="checkbox"
                            id="managecityEdit"
                            name="managecityEdit"
                            checked={checkboxVal.managecityEdit}
                            onChange={(e) => checkBoxChangehandler(e)}
                          />
                          <label
                            className="mb-0"
                            htmlFor="managecityEdit"
                            style={{ fontSize: "12px" }}
                          >
                            Edit City
                          </label>
                        </div>
                        {/* delete */}
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginLeft: "17px",
                          }}
                        >
                          <input
                            type="checkbox"
                            id="managecityDelete"
                            name="managecityDelete"
                            checked={checkboxVal.managecityDelete}
                            onChange={(e) => checkBoxChangehandler(e)}
                          />
                          <label
                            className="mb-0"
                            htmlFor="managecityDelete"
                            style={{ fontSize: "12px" }}
                          >
                            Delete city
                          </label>
                        </div>
                      </div>
                    )}
                  </>
                )}
              </div>

              {/* ================== manage User ============================== */}
              <div>
                <div
                  style={{
                    display: "flex",
                    gap: "5px",
                    alignItems: "center",
                  }}
                >
                  <input
                    onChange={checkBoxChangehandler}
                    type="checkbox"
                    id="manageUser"
                    name="manageUser"
                    checked={checkboxVal.manageUser}
                  />
                  <label htmlFor="manageUser">
                    <b>Manage User</b>
                  </label>
                </div>
              </div>

              {/* ==================  Payment History ========================= */}
              <div
                style={{ display: "flex", gap: "5px", alignItems: "center" }}
              >
                <input
                  style={{
                    display: "flex",
                    alignSelf: "self-start",
                    marginTop: "4px",
                  }}
                  onChange={checkBoxChangehandler}
                  type="checkbox"
                  id="paymentHistory"
                  name="paymentHistory"
                  checked={checkboxVal.paymentHistory}
                />
                <label className="mb-0" htmlFor="paymentHistory">
                  <b>Payment History</b>
                </label>
              </div>
              {/* ==================  Settings ========================= */}
              <div>
                <div
                  style={{
                    display: "flex",
                    gap: "5px",
                    alignItems: "center",
                  }}
                >
                  <input
                    onChange={checkBoxChangehandler}
                    onClick={() => setAccountPriceView(!accountPriceView)}
                    type="checkbox"
                    id="accountViewPrice"
                    name="accountViewPrice"
                    checked={checkboxVal.accountViewPrice}
                  />
                  <label htmlFor="accountViewPrice">
                    <b>Account View Price</b>
                  </label>
                </div>

                {accountPriceView && (
                  <>
                    {/* add */}
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginLeft: "17px",
                      }}
                    >
                      <input
                        type="checkbox"
                        id="addaccountViewPrice"
                        name="addaccountViewPrice"
                        checked={checkboxVal.addaccountViewPrice}
                        onChange={(e) => checkBoxChangehandler(e)}
                      />
                      <label
                        className="mb-0"
                        htmlFor="addaccountViewPrice"
                        style={{ fontSize: "12px" }}
                      >
                        Add Account View Price
                      </label>
                    </div>
                    {/* view */}
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginLeft: "17px",
                      }}
                    >
                      <input
                        type="checkbox"
                        id="manageaccountViewPrice"
                        name="manageaccountViewPrice"
                        checked={checkboxVal.manageaccountViewPrice}
                        onChange={(e) => checkBoxChangehandler(e)}
                        onClick={(e) => {
                          setViewAccountPrice(!viewAccountPrice);
                        }}
                      />
                      <label
                        className="mb-0"
                        htmlFor="manageaccountViewPrice"
                        style={{ fontSize: "12px" }}
                      >
                        View Account View Price
                      </label>
                    </div>
                    {viewAccountPrice && (
                      <div>
                        {/* edit */}
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginLeft: "17px",
                          }}
                        >
                          <input
                            type="checkbox"
                            id="manageaccountViewPriceEdit"
                            name="manageaccountViewPriceEdit"
                            checked={checkboxVal.manageaccountViewPriceEdit}
                            onChange={(e) => checkBoxChangehandler(e)}
                          />
                          <label
                            className="mb-0"
                            htmlFor="manageaccountViewPriceEdit"
                            style={{ fontSize: "12px" }}
                          >
                            Edit Account View Price
                          </label>
                        </div>
                        {/* delete */}
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginLeft: "17px",
                          }}
                        >
                          <input
                            type="checkbox"
                            id="manageaccountViewPriceDelete"
                            name="manageaccountViewPriceDelete"
                            checked={checkboxVal.manageaccountViewPriceDelete}
                            onChange={(e) => checkBoxChangehandler(e)}
                          />
                          <label
                            className="mb-0"
                            htmlFor="manageaccountViewPriceDelete"
                            style={{ fontSize: "12px" }}
                          >
                            Delete Account View Price
                          </label>
                        </div>
                      </div>
                    )}
                  </>
                )}
              </div>
              {/* ==================  CMS ========================= */}
              {/* contact us */}
              <div
                style={{ display: "flex", gap: "5px", alignItems: "center" }}
              >
                <input
                  style={{
                    display: "flex",
                    alignSelf: "self-start",
                    marginTop: "4px",
                  }}
                  onChange={checkBoxChangehandler}
                  type="checkbox"
                  id="contactUs"
                  name="contactUs"
                  checked={checkboxVal.contactUs}
                />
                <label className="mb-0" htmlFor="contactUs">
                  <b>Contact Us</b>
                </label>
              </div>

              {/* Pages */}
              <div>
                <div
                  style={{
                    display: "flex",
                    gap: "5px",
                    alignItems: "center",
                  }}
                >
                  <input
                    onChange={checkBoxChangehandler}
                    onClick={() => setPagesView(!pagesView)}
                    type="checkbox"
                    id="pages"
                    name="pages"
                    checked={checkboxVal.pages}
                  />
                  <label htmlFor="pages">
                    <b>Pages</b>
                  </label>
                </div>

                {pagesView && (
                  <>
                    {/* add */}
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginLeft: "17px",
                      }}
                    >
                      <input
                        type="checkbox"
                        id="addpages"
                        name="addpages"
                        checked={checkboxVal.addpages}
                        onChange={(e) => checkBoxChangehandler(e)}
                      />
                      <label
                        className="mb-0"
                        htmlFor="addpages"
                        style={{ fontSize: "12px" }}
                      >
                        Add pages
                      </label>
                    </div>
                    {/* view */}
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginLeft: "17px",
                      }}
                    >
                      <input
                        type="checkbox"
                        id="managepages"
                        name="managepages"
                        checked={checkboxVal.managepages}
                        onChange={(e) => checkBoxChangehandler(e)}
                        onClick={(e) => {
                          setViewPages(!viewPages);
                        }}
                      />
                      <label
                        className="mb-0"
                        htmlFor="managepages"
                        style={{ fontSize: "12px" }}
                      >
                        View Pages
                      </label>
                    </div>
                    {viewPages && (
                      <div>
                        {/* edit */}
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginLeft: "17px",
                          }}
                        >
                          <input
                            type="checkbox"
                            id="managepagesEdit"
                            name="managepagesEdit"
                            checked={checkboxVal.managepagesEdit}
                            onChange={(e) => checkBoxChangehandler(e)}
                          />
                          <label
                            className="mb-0"
                            htmlFor="managepagesEdit"
                            style={{ fontSize: "12px" }}
                          >
                            Edit Pages
                          </label>
                        </div>
                        {/* delete */}
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginLeft: "17px",
                          }}
                        >
                          <input
                            type="checkbox"
                            id="managepagesDelete"
                            name="managepagesDelete"
                            checked={checkboxVal.managepagesDelete}
                            onChange={(e) => checkBoxChangehandler(e)}
                          />
                          <label
                            className="mb-0"
                            htmlFor="managepagesDelete"
                            style={{ fontSize: "12px" }}
                          >
                            Delete Pages
                          </label>
                        </div>
                      </div>
                    )}
                  </>
                )}
              </div>

              {/* Faq */}
              <div>
                <div
                  style={{
                    display: "flex",
                    gap: "5px",
                    alignItems: "center",
                  }}
                >
                  <input
                    onChange={checkBoxChangehandler}
                    onClick={() => setFaqView(!faqView)}
                    type="checkbox"
                    id="faq"
                    name="faq"
                    checked={checkboxVal.faq}
                  />
                  <label htmlFor="faq">
                    <b>FAQ</b>
                  </label>
                </div>

                {faqView && (
                  <>
                    {/* add */}
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginLeft: "17px",
                      }}
                    >
                      <input
                        type="checkbox"
                        id="addFaq"
                        name="addFaq"
                        checked={checkboxVal.addFaq}
                        onChange={(e) => checkBoxChangehandler(e)}
                      />
                      <label
                        className="mb-0"
                        htmlFor="addFaq"
                        style={{ fontSize: "12px" }}
                      >
                        Add Faq
                      </label>
                    </div>
                    {/* view */}
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginLeft: "17px",
                      }}
                    >
                      <input
                        type="checkbox"
                        id="manageFaq"
                        name="manageFaq"
                        checked={checkboxVal.manageFaq}
                        onChange={(e) => checkBoxChangehandler(e)}
                        onClick={(e) => {
                          setViewFaq(!viewfaq);
                        }}
                      />
                      <label
                        className="mb-0"
                        htmlFor="manageFaq"
                        style={{ fontSize: "12px" }}
                      >
                        View Faq
                      </label>
                    </div>
                    {viewfaq && (
                      <div>
                        {/* edit */}
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginLeft: "17px",
                          }}
                        >
                          <input
                            type="checkbox"
                            id="manageFaqEdit"
                            name="manageFaqEdit"
                            checked={checkboxVal.manageFaqEdit}
                            onChange={(e) => checkBoxChangehandler(e)}
                          />
                          <label
                            className="mb-0"
                            htmlFor="manageFaqEdit"
                            style={{ fontSize: "12px" }}
                          >
                            Edit Faq
                          </label>
                        </div>
                        {/* delete */}
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginLeft: "17px",
                          }}
                        >
                          <input
                            type="checkbox"
                            id="manageFaqDelete"
                            name="manageFaqDelete"
                            checked={checkboxVal.manageFaqDelete}
                            onChange={(e) => checkBoxChangehandler(e)}
                          />
                          <label
                            className="mb-0"
                            htmlFor="manageFaqDelete"
                            style={{ fontSize: "12px" }}
                          >
                            Delete Faq
                          </label>
                        </div>
                      </div>
                    )}
                  </>
                )}
              </div>

              {/* ==================  Reports ========================= */}
              {/* user report */}
              <div
                style={{ display: "flex", gap: "5px", alignItems: "center" }}
              >
                <input
                  style={{
                    display: "flex",
                    alignSelf: "self-start",
                    marginTop: "4px",
                  }}
                  onChange={checkBoxChangehandler}
                  type="checkbox"
                  id="userReport"
                  name="userReport"
                  checked={checkboxVal.userReport}
                />
                <label className="mb-0" htmlFor="userReport">
                  <b>User Report</b>
                </label>
              </div>

              {/* Demography report */}
              <div
                style={{ display: "flex", gap: "5px", alignItems: "center" }}
              >
                <input
                  style={{
                    display: "flex",
                    alignSelf: "self-start",
                    marginTop: "4px",
                  }}
                  onChange={checkBoxChangehandler}
                  type="checkbox"
                  id="demographyReport"
                  name="demographyReport"
                  checked={checkboxVal.demographyReport}
                />
                <label className="mb-0" htmlFor="demographyReport">
                  <b>Demography Report</b>
                </label>
              </div>

              {/* Account Purchase report */}
              <div
                style={{ display: "flex", gap: "5px", alignItems: "center" }}
              >
                <input
                  style={{
                    display: "flex",
                    alignSelf: "self-start",
                    marginTop: "4px",
                  }}
                  onChange={checkBoxChangehandler}
                  type="checkbox"
                  id="acccountPurchaseReport"
                  name="acccountPurchaseReport"
                  checked={checkboxVal.acccountPurchaseReport}
                />
                <label className="mb-0" htmlFor="acccountPurchaseReport">
                  <b>Account Purchase Report</b>
                </label>
              </div>
            </div>
          )}

          <button
            className="btn btn-primary btn-lg font-weight-bold mt-4"
            style={{ marginLeft: "auto", display: "table" }}
          >
            SUBMIT
          </button>
        </form>
      </div>
    </div>
  );
};

export default AddEmployee;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

// Helpers
import { ApiHelperFunction } from "../../utils/HttpClient";

let initialState = {
  status: "idle",
  GenderReportData: {},
  genderCount: null,
  isLoading: false,
  isSuccess: false,
  isError: false,
};

export const getGenderReport = createAsyncThunk(
  "gender_wise_report_get",
  async (data) => {
    let response = await ApiHelperFunction({
      urlPath: "admin/gender-wise-reports",
      method: "POST",
      data: data ? data : "",
    });
    console.log("gender_report_get", response);

    if (response.status === 200) {
      return response.data;
    } else {
      toast.error("Can't get data. Something went wrong");
    }
  }
);

export const genderTotalCount = createAsyncThunk(
  "gender_count_get",
  async () => {
    let response = await ApiHelperFunction({
      urlPath: "admin/total-gender-statistic-reports",
      method: "GET",
    });
    console.log("gender_count_get", response);

    if (response.status === 200) {
      return response.data;
    } else {
      toast.error("Can't get data. Something went wrong");
    }
  }
);

export const ReligionReportSlice = createSlice({
  name: "Gender_details_get_data",
  initialState,
  reducers: {
    clearState: (state) => {
      state.status = "idle";
      state.GenderReportData = {};
      state.genderCount = null;
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getGenderReport.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(getGenderReport.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.isSuccess = true;
        // console.log("payload", payload?.data);
        state.GenderReportData = payload?.data;
      })
      .addCase(getGenderReport.rejected, (state) => {
        state.status = "failed";
        state.isError = true;
        state.isSuccess = false;
      })
      .addCase(genderTotalCount.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(genderTotalCount.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.isSuccess = true;
        // console.log("payload", payload?.data);
        state.genderCount = payload?.data;
      })
      .addCase(genderTotalCount.rejected, (state) => {
        state.status = "failed";
        state.isError = true;
        state.isSuccess = false;
      })
  },
});

export const { clearState } = ReligionReportSlice.actions;

export default ReligionReportSlice.reducer;

import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Table from "../../Table/Table";
import { DeleteFaq, getFaq } from "../../../redux/AdminSlice/FaqSlice";
import { DeleteConfirmModal } from "../../../DeleteAlert/DeleteAlert";
import { getProfileDetails } from "../../../redux/AdminSlice/profileSlice";

const ManageFaq = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchText, setsearchText] = useState("");
  const token = localStorage.getItem("Authorization");
  const { profile_data } = useSelector((state) => state.profileReducer);

  const Faqdetails = useSelector((state) => state.faqReducer.FaqData);

  useEffect(() => {
    dispatch(getFaq());
    dispatch(getProfileDetails(token));
  }, []);

  const deletePrivacyPolicy = (id) => {
    const del = async () => {
      dispatch(DeleteFaq(id)).then(() => {
        dispatch(getFaq());
      });
    };

    DeleteConfirmModal(del);
  };

  const filterData = useMemo(() => {
    if (searchText !== "") {
      return Faqdetails.filter((item) =>
        JSON.stringify(item).toLowerCase().includes(searchText.toLowerCase())
      );
    }
    return Faqdetails;
  }, [searchText, Faqdetails]);

  const tableData = useMemo(() => {
    return {
      columns: [
        {
          label: "SL",
          field: "sl",
          sort: "asc",
          width: 270,
        },
        {
          label: "Faq Title",
          field: "faqtitle",
          sort: "asc",
          width: 150,
        },
        {
          label: "Faq Question",
          field: "faqquestion",
          sort: "asc",
          width: 150,
        },
        {
          label: "Faq Answer",
          field: "faqanswer",
          sort: "asc",
          width: 150,
        },
        {
          label: "Action",
          field: "action",
          sort: "asc",
          width: 150,
        },
      ],
      rows:
        filterData?.length > 0
          ? filterData.map((item, idx) => {
              console.log("item", item);
              return {
                sl: idx + 1,
                faqtitle: (
                  <div className="widget-content p-0" key={idx}>
                    <div className="widget-content-wrapper">
                      <div className="widget-content-left flex2">
                        <div
                          className="widget-heading"
                          style={{ opacity: 1, fontSize: "13px" }}
                        >
                          {item.title}
                        </div>
                      </div>
                    </div>
                  </div>
                ),
                faqquestion: (
                  <div className="widget-content p-0" key={idx}>
                    <div className="widget-content-wrapper">
                      <div className="widget-content-left flex2">
                        <div
                          className="widget-heading"
                          style={{ opacity: 1, fontSize: "13px" }}
                        >
                          {item?.question}
                        </div>
                      </div>
                    </div>
                  </div>
                ),
                faqanswer: (
                  <div className="widget-content p-0" key={idx}>
                    <div className="widget-content-wrapper">
                      <div className="widget-content-left flex2">
                        <div
                          className="widget-heading"
                          style={{ opacity: 1, fontSize: "13px" }}
                        >
                          {item?.answer}
                        </div>
                      </div>
                    </div>
                  </div>
                ),
                action: (
                  <div className="widget-content p-0" key={idx}>
                    <div className="widget-content-wrapper">
                      <div className="widget-content-center flex2">
                        {profile_data?.tabPermission?.[0]?.manageFaqEdit && (
                          <i
                            class="fa-solid fa-pen"
                            style={{ color: "blue", cursor: "pointer" }}
                            onClick={() => {
                              navigate("/addFaq", { state: item });
                            }}
                          ></i>
                        )}

                        {profile_data?.tabPermission?.[0]?.manageFaqDelete && (
                          <i
                            class="fa-solid fa-trash-can"
                            style={{
                              color: "red",
                              marginLeft: "15px",
                              cursor: "pointer",
                            }}
                            onClick={() => deletePrivacyPolicy(item._id)}
                          ></i>
                        )}
                      </div>
                    </div>
                  </div>
                ),
              };
            })
          : [],
    };
  });

  return (
    <>
      <div className="main_wrap">
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <h4 className="Pageheadline">Manage Faq</h4>
          {profile_data?.tabPermission?.[0]?.addFaq && (
            <button
              className="btn btn-primary"
              style={{ height: "33px" }}
              onClick={() => navigate("/addFaq")}
            >
              Add
            </button>
          )}
        </div>
        <div style={{ overflowX: "auto" }} className="dataTableParDiv">
          <Table
            striped
            tableTitle="All abouts"
            data={tableData}
            onSearch={(e) => setsearchText(e.target.value)}
          />
        </div>
      </div>
    </>
  );
};

export default ManageFaq;

import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { GetCommunityCount } from "../../redux/AdminSlice/CommunitySlice";
import { useDispatch, useSelector } from "react-redux";

const CommunityReportCount = () => {
  const dispatch = useDispatch();
  const communityCountdata = useSelector(
    (state) => state.communityReducer.communityCount
  );
  console.log("communityCountdata", communityCountdata);

  const [chartState, setChartState] = useState({
    series: [],
    options: {
      chart: {
        type: "donut",
      },
      plotOptions: {
        pie: {
          customScale: 0.8,
          size: "60%"
        }
      },
      legend: {
        position: 'top'
      },
      labels: [],
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
  });

  useEffect(() => {
    if (communityCountdata && communityCountdata.length > 0) {
      const communityCount = communityCountdata.map((data) => data.count);
      console.log("communityCount", communityCount)
      setChartState((prevState) => ({
        ...prevState,
        series: communityCount,
      }));
      
      const communityName = communityCountdata.map((data) => data.communityName);
      console.log("communityName", communityName)
      setChartState((prevState) => ({
        ...prevState,
        options: {
            ...prevState.options,
            labels: communityName,
          },
      }));
   
    }
  }, [communityCountdata]);

  useEffect(() => {
    dispatch(GetCommunityCount());
  }, []);



  return (
    <>
      <section className="piechartsBox_area">
        <div id="chart">
          <ReactApexChart
            options={chartState.options}
            series={chartState.series}
            type="donut"
          />
        </div>
      </section>
    </>
  );
};

export default CommunityReportCount;

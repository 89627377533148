import React, { useEffect, useMemo, useState } from "react";
import Table from "../../Table/Table";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  DeleteZodiacSign,
  GetZodiacSign,
} from "../../../redux/AdminSlice/ZodiacSignSlice";
import { DeleteConfirmModal } from "../../../DeleteAlert/DeleteAlert";
import { getProfileDetails } from "../../../redux/AdminSlice/profileSlice";
import { DeleteCity, GetCity } from "../../../redux/AdminSlice/CitySlice";

const ManageCity = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchText, setsearchText] = useState("");
  const token = localStorage.getItem("Authorization");
  const { profile_data } = useSelector((state) => state.profileReducer);

  const { CityData } = useSelector((state) => state.cityReducer);
  //   cityimage

  useEffect(() => {
    dispatch(GetCity());
    dispatch(getProfileDetails(token));
  }, []);

  const DeleteState = (id) => {
    const del = async () => {
      dispatch(DeleteCity(id));
      dispatch(GetCity());
    };

    DeleteConfirmModal(del);
  };

  const filterData = useMemo(() => {
    if (searchText !== "") {
      return CityData?.filter((item) =>
        JSON.stringify(item).toLowerCase().includes(searchText.toLowerCase())
      );
    }
    return CityData;
  }, [searchText, CityData]);

  const handleView = (e, item) => {
    e.preventDefault();

    navigate(`/addCity`, { state: item });
  };

  const tableData = useMemo(() => {
    return {
      columns: [
        {
          label: "SL",
          field: "sl",
          sort: "asc",
          width: 270,
        },
        {
          label: "Country Name",
          field: "countryname",
          sort: "asc",
          width: 150,
        },
        {
          label: "State Name",
          field: "statename",
          sort: "asc",
          width: 150,
        },
        {
          label: "City Image",
          field: "cityimage",
          sort: "asc",
          width: 150,
        },
        {
          label: "City Name",
          field: "cityname",
          sort: "asc",
          width: 150,
        },
        {
          label: "Action",
          field: "action",
          sort: "asc",
          width: 150,
        },
      ],
      rows:
        filterData?.length > 0
          ? filterData.map((item, idx) => {
              console.log("dfsdfvsv", item);
              return {
                sl: idx + 1,
                countryname: (
                  <div className="widget-content p-0" key={idx}>
                    <div className="widget-content-wrapper">
                      <div className="widget-content-left flex2">
                        <div className="widget-heading" style={{ opacity: 1 }}>
                          {item?.countryName}
                        </div>
                      </div>
                    </div>
                  </div>
                ),
                statename: (
                  <div className="widget-content p-0" key={idx}>
                    <div className="widget-content-wrapper">
                      <div className="widget-content-left flex2">
                        <div className="widget-heading" style={{ opacity: 1 }}>
                          {item?.stateName}
                        </div>
                      </div>
                    </div>
                  </div>
                ),
                cityimage: (
                  <div className="widget-content p-0" key={idx}>
                    <div className="widget-content-wrapper">
                      <div className="widget-content-left flex2">
                        <div className="widget-heading" style={{ opacity: 1 }}>
                          <img
                            style={{
                              height: "50px",
                              width: "50px",
                              borderRadius: "50%",
                            }}
                            src={item?.image}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ),
                cityname: (
                  <div className="widget-content p-0" key={idx}>
                    <div className="widget-content-wrapper">
                      <div className="widget-content-left flex2">
                        <div className="widget-heading" style={{ opacity: 1 }}>
                          {item?.name}
                        </div>
                      </div>
                    </div>
                  </div>
                ),
                action: (
                  <div className="widget-content p-0" key={idx}>
                    <div className="widget-content-wrapper">
                      <div className="widget-content-center flex2">
                        {profile_data?.tabPermission?.[0]
                          ?.managezodiacSignEdit && (
                          <i
                            class="fa-solid fa-pencil"
                            style={{ color: "blue", cursor: "pointer" }}
                            onClick={(e) => handleView(e, item)}
                          ></i>
                        )}

                        {profile_data?.tabPermission?.[0]
                          ?.managezodiacSignDelete && (
                          <i
                            class="fa-solid fa-trash-can"
                            style={{
                              color: "red",
                              marginLeft: "15px",
                              cursor: "pointer",
                            }}
                            onClick={() => DeleteState(item._id)}
                          ></i>
                        )}
                      </div>
                    </div>
                  </div>
                ),
              };
            })
          : [],
    };
  });

  return (
    <>
      <div className="main_wrap">
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <h4 className="Pageheadline">Manage City</h4>
          {profile_data?.tabPermission?.[0]?.city && (
            <button
              className="btn btn-primary"
              style={{ height: "33px" }}
              onClick={() => navigate("/addCity")}
            >
              Add
            </button>
          )}
        </div>
        <div style={{ overflowX: "auto" }} className="dataTableParDiv">
          <Table
            striped
            tableTitle="All abouts"
            data={tableData}
            onSearch={(e) => setsearchText(e.target.value)}
          />
        </div>
      </div>
    </>
  );
};

export default ManageCity;

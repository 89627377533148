import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  GetMaritalStatus,
  PostMaritalStatus,
  UpdateMaritalStatus,
} from "../../../redux/AdminSlice/MaritalSlice";

const AddMaritalStatus = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [maritalStatus, setMaritalStatus] = useState({ name: "" });

  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("id");

  const ProfileIsForData = useSelector(
    (state) => state.profileIsForReducer.ProfileIsForData
  );

  const changehandler = (e) => {
    setMaritalStatus({
      ...maritalStatus,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    if (location.state) {
      setMaritalStatus({
        name: location.state.name,
      });
    }
  }, []);

  const submitHandler = (e) => {
    e.preventDefault();
    if (maritalStatus.name === "") {
      toast.error("Marital Status Is required");
      return false;
    }
    let data = {
      name: maritalStatus.name,
    };

    if (location.state) {
      const id = location.state._id;
      dispatch(UpdateMaritalStatus({ id, data }));
      setMaritalStatus({ name: "" });
      dispatch(GetMaritalStatus());
      navigate("/manageMarital");
    } else {
      dispatch(PostMaritalStatus(data));
      setMaritalStatus({
        name: "",
      });
    }
  };
  return (
    <div className="main_wrap">
      <div className="aboutUs_page">
        <h4 className="Pageheadline">
          {location.state ? "Edit Marital Status" : "Add Marital Status"}
        </h4>
        <form action="" onSubmit={(e) => submitHandler(e)}>
          <div className="row">
            <div className="col-md-6">
              <label htmlFor="">Add Marital Status</label>
              <input
                type="text"
                placeholder="Enter Marital Status"
                className="form-control"
                name="name"
                value={maritalStatus.name}
                onChange={(e) => changehandler(e)}
              />
            </div>
            <div
              className="col-md-6"
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "end",
              }}
            >
              {location.state ? (
                <button type="submit" className="btn btn-primary">
                  Submit
                </button>
              ) : (
                <button type="submit" className="btn btn-primary">
                  Add
                </button>
              )}
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddMaritalStatus;

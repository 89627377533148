import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { GetCastCountData } from "../../redux/AdminSlice/CastSlice";
import { useDispatch, useSelector } from "react-redux";

const Cast = () => {
  const dispatch = useDispatch();
  const getCasteCount = useSelector((state) => state.castReducer.casteCount);
  console.log("getCasteCount", getCasteCount);

  const [chartState, setChartState] = useState({
    series: [],
    options: {
      chart: {
        type: "donut",
      },
      plotOptions: {
        pie: {
          customScale: 0.8,
          size: "60%"
        }
      },
      legend: {
        position: 'top'
      },
      labels: [],
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
  });

  useEffect(() => {
    if (getCasteCount && getCasteCount.length > 0) {
      const CasteCount = getCasteCount.map((data) => data.count);
      console.log("CasteCount", CasteCount);
      setChartState((prevState) => ({
        ...prevState,
        series: CasteCount,
      }));

      const CasteName = getCasteCount.map((data) => data.castName);
      console.log("CasteName", CasteName);
      setChartState((prevState) => ({
        ...prevState,
        options: {
          ...prevState.options,
          labels: CasteName,
        },
      }));
    }
  }, [getCasteCount]);

  useEffect(() => {
    dispatch(GetCastCountData());
  }, []);
  return (
    <>
      <section className="piechartsBox_area">
        <div id="chart">
          <ReactApexChart
            options={chartState.options}
            series={chartState.series}
            type="donut"
          />
        </div>
      </section>
    </>
  );
};

export default Cast;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

// Helpers
import { ApiHelperFunction, fileUpload } from "../../utils/HttpClient";

let initialState = {
  status: "idle",
  ReligianData: null,
  religionImage: "",
  isLoading: false,
  isSuccess: false,
  isError: false,
};

export const PostReligian = createAsyncThunk("Religian_post", async (data) => {
  let response = await ApiHelperFunction({
    urlPath: "admin/add-religion",
    method: "POST",
    data: data,
  });
  console.log("religion", response);

  if (response.status === 200) {
    toast.success("Religion added successfully");
    return response.data;
  } else {
    // toast.error("Can't add Religion. Something went wrong");
    toast.error(response?.response?.data?.message);
  }
});

export const GetReligian = createAsyncThunk("Religian_data_get", async () => {
  let response = await ApiHelperFunction({
    urlPath: "admin/view-religion",
    method: "GET",
  });
  console.log("Religian_details_get", response);

  if (response.status === 200) {
    return response.data;
  } else {
    toast.error(response?.response?.data?.message);
  }
});

export const UpdateReligian = createAsyncThunk(
  "Religian_details_update",
  async ({ id, data }) => {
    console.log("updatedItem", data);
    let response = await ApiHelperFunction({
      urlPath: `admin/edit-religion/${id}`,
      method: "PUT",
      data: data,
    });
    console.log("religion", response);

    if (response.status === 200) {
      toast.success("Religion Updated Successfully");
      return response.data;
    } else {
      toast.error(response?.response?.data?.message);
    }
  }
);

export const DeleteReligian = createAsyncThunk(
  "Religian_details_Delete",
  async (id) => {
    let response = await ApiHelperFunction({
      urlPath: `admin/delete-religion/${id}`,
      method: "PUT",
    });
    console.log("contact_details", response);

    if (response.status === 200) {
      toast.success("Religion Deleted Successfully");
      return response.data;
    } else {
      toast.error(response?.response?.data?.message);
    }
  }
);

export const uploadReligionImage = createAsyncThunk(
  "upload_religion_image",
  async (data) => {
    console.log("Religion", data);
    let response = await fileUpload({
      urlPath: "admin/upload-religion-image",
      method: "POST",
      data: data,
    });
    console.log("religion_image", response);

    if (response.status === 200) {
      toast.success("Religion Icon Uploaded successfully");
      return response.data;
    } else {
      toast.error(response?.response?.data?.message);
    }
  }
);

export const ReligionSlice = createSlice({
  name: "Religian_data",
  initialState,
  reducers: {
    clearState: (state) => {
      state.status = "idle";
      state.ReligianData = null;
      state.religionImage = "";
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(PostReligian.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(PostReligian.fulfilled, (state) => {
        state.status = "success";
        state.isSuccess = true;
        state.isLoading = false;
        // Optionally, you can update state after a successful post if needed
      })
      .addCase(PostReligian.rejected, (state) => {
        state.status = "failed";
        state.isError = true;
        state.isSuccess = false;
        state.isLoading = false;
      })
      .addCase(GetReligian.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(GetReligian.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.isSuccess = true;
        state.isLoading = false;
        console.log("payload", payload?.data);
        state.ReligianData = payload?.data;
      })
      .addCase(GetReligian.rejected, (state) => {
        state.status = "failed";
        state.isError = true;
        state.isSuccess = false;
        state.isLoading = false;
      })
      .addCase(UpdateReligian.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(UpdateReligian.fulfilled, (state) => {
        state.status = "success";
        state.isSuccess = true;
      })
      .addCase(UpdateReligian.rejected, (state) => {
        state.status = "failed";
        state.isError = true;
        state.isSuccess = false;
      })
      .addCase(DeleteReligian.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(DeleteReligian.fulfilled, (state) => {
        state.status = "success";
        state.isSuccess = true;
      })
      .addCase(DeleteReligian.rejected, (state) => {
        state.status = "failed";
        state.isError = true;
        state.isSuccess = false;
      })

      .addCase(uploadReligionImage.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(uploadReligionImage.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.isSuccess = true;
        state.religionImage = payload?.url;
      })
      .addCase(uploadReligionImage.rejected, (state) => {
        state.status = "failed";
        state.isError = true;
        state.isSuccess = false;
      });
  },
});

export const { clearState } = ReligionSlice.actions;

export default ReligionSlice.reducer;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

// Helpers
import { ApiHelperFunction, fileUpload } from "../../utils/HttpClient";

let initialState = {
  status: "idle",
  ZodiacSignData: null,
  ZodiacIcon: "",
  isLoading: false,
  isSuccess: false,
  isError: false,
};

export const UploadZodiacIcon = createAsyncThunk(
  "upload_Zodiac_icon_as",
  async (data) => {
    console.log("DietImage", data);
    let response = await fileUpload({
      urlPath: "admin/upload-zodiaic-icon",
      method: "POST",
      data: data,
    });
    console.log("Zodiac_icon", response);

    if (response.status === 200) {
      toast.success("Zodiac icon Uploaded successfully");
      return response.data;
    } else {
      toast.error("Can't Upload Image. Something went wrong");
    }
  }
);

export const PostZodiacSign = createAsyncThunk(
  "Zodiac_sign_post",
  async (data) => {
    let response = await ApiHelperFunction({
      urlPath: "admin/add-zodiac-sign",
      method: "POST",
      data: data,
    });
    console.log("Zodiac_details", response);

    if (response.status === 200) {
      toast.success("Zodiac Sign added successfully");
      return response.data;
    } else {
      toast.error(response?.response?.data?.message);
    }
  }
);

export const GetZodiacSign = createAsyncThunk("Zodiac_Sign_get", async () => {
  let response = await ApiHelperFunction({
    urlPath: "admin/view-zodiac-sign",
    method: "GET",
  });
  console.log("contact_details", response);

  if (response.status === 200) {
    return response.data;
  } else {
    toast.error("Can't get data. Something went wrong");
  }
});

export const UpdateZodiacSign = createAsyncThunk(
  "Zodiac_Sign_update",
  async ({ id, data }) => {
    console.log("updatedItem", data);
    let response = await ApiHelperFunction({
      urlPath: `admin/edit-zodiac-sign/${id}`,
      method: "PUT",
      data: data,
    });
    console.log("contact_details", response);

    if (response.status === 200) {
      toast.success("Zodiac Sign Updated Successfully");
      return response.data;
    } else {
      toast.error("Can't Update data. Something went wrong");
    }
  }
);

export const DeleteZodiacType = createAsyncThunk(
  "Zodiac_sign_Delete",
  async (id) => {
    let response = await ApiHelperFunction({
      urlPath: `admin/delete-zodiac-sign/${id}`,
      method: "PUT",
    });
    //   console.log("contact_details", response);

    if (response.status === 200) {
      toast.success("Zodiac Sign Deleted Successfully");
      return response.data;
    } else {
      toast.error("Can't Deleted data. Something went wrong");
    }
  }
);

export const ZodiacSignSlice = createSlice({
  name: "Zodiac_sign_details_data",
  initialState,
  reducers: {
    clearState: (state) => {
      state.status = "idle";
      state.ZodiacSignData = null;
      state.ZodiacIcon = "";
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(UploadZodiacIcon.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(UploadZodiacIcon.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.isSuccess = true;
        console.log("ZodiacIcon", payload);
        state.ZodiacIcon = payload.url;
      })
      .addCase(UploadZodiacIcon.rejected, (state) => {
        state.status = "failed";
        state.isError = true;
        state.isSuccess = false;
      })
      .addCase(PostZodiacSign.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(PostZodiacSign.fulfilled, (state) => {
        state.status = "success";
        state.isSuccess = true;
        // Optionally, you can update state after a successful post if needed
      })
      .addCase(PostZodiacSign.rejected, (state) => {
        state.status = "failed";
        state.isError = true;
        state.isSuccess = false;
      })
      .addCase(GetZodiacSign.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(GetZodiacSign.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.isSuccess = true;
        console.log("payload", payload?.data);
        state.ZodiacSignData = payload?.data;
      })
      .addCase(GetZodiacSign.rejected, (state) => {
        state.status = "failed";
        state.isError = true;
        state.isSuccess = false;
      })
      .addCase(UpdateZodiacSign.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(UpdateZodiacSign.fulfilled, (state) => {
        state.status = "success";
        state.isSuccess = true;
      })
      .addCase(UpdateZodiacSign.rejected, (state) => {
        state.status = "failed";
        state.isError = true;
        state.isSuccess = false;
      })
      .addCase(DeleteZodiacType.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(DeleteZodiacType.fulfilled, (state) => {
        state.status = "success";
        state.isSuccess = true;
      })
      .addCase(DeleteZodiacType.rejected, (state) => {
        state.status = "failed";
        state.isError = true;
        state.isSuccess = false;
      });
  },
});

export const { clearState } = ZodiacSignSlice.actions;

export default ZodiacSignSlice.reducer;

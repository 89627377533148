import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  GetCommunity,
  PostCommunity,
  UpdateCommunity,
} from "../../../redux/AdminSlice/CommunitySlice";
import { GetReligian } from "../../../redux/AdminSlice/ReligionSlice";

const AddCommunity = () => {
  const initial = {
    name: "",
    religionId: "",
  };
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [community, setCommunity] = useState(initial);
  //   const [religion, setReligion] = useState("");

  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("id");

  const CommunityDetails = useSelector(
    (state) => state.communityReducer.CommunityData
  );
  const religionData = useSelector(
    (state) => state.religionReducer.ReligianData
  );
  console.log("community", community);

  const changehandler = (e) => {
    setCommunity({
      ...community,
      [e.target.name]: e.target.value,
    });
  };

  const singleProfileIsFor = () => {
    let data = CommunityDetails.filter((item) => {
      return item._id === id;
    });
    console.log("data", data?.[0]);

    if (data?.length > 0) {
      setCommunity({
        name: data?.[0]?.name,
        religionId: data?.[0]?.religionId,
      });
    } else {
      setCommunity(initial);
    }
  };

  useEffect(() => {
    if (id) {
      singleProfileIsFor();
    }
  }, [id]);

  useEffect(() => {
    dispatch(GetReligian());
  }, []);

  const submitHandler = (e) => {
    e.preventDefault();
    if (community.religionId === "") {
      toast.error("Religion Name Is required");
      return false;
    }
    if (community.name === "") {
      toast.error("Community Name Is required");
      return false;
    }
    let data = {
      name: community.name,
      religionId: community.religionId,
    };

    if (id) {
      dispatch(UpdateCommunity({ id, data })).then(() => {
        setCommunity(initial);
        dispatch(GetCommunity());
        navigate(-1);
      });
    } else {
      dispatch(PostCommunity(data)).then(() => {
        setCommunity(initial);
      });
    }
  };

  return (
    <div className="main_wrap">
      <div className="aboutUs_page">
        <h4 className="Pageheadline">
          {id ? "Edit Community" : "Add Community"}
        </h4>
        <form action="" onSubmit={(e) => submitHandler(e)}>
          <div className="row">
            <div className="col-md-5">
              <label htmlFor="">Select Religion</label>
              <br />
              <select
                class="form-select form-select-lg form-control"
                aria-label=".form-select-lg example"
                name="religionId"
                value={community.religionId}
                onChange={(e) => changehandler(e)}
              >
                <option value="">Select Religion</option>
                {religionData?.length > 0 &&
                  religionData?.map((item) => {
                    return (
                      <option
                        selected={item._id === community.religionId}
                        value={item._id}
                      >
                        {item.name}
                      </option>
                    );
                  })}
              </select>
            </div>
            <div className="col-md-5">
              <label htmlFor="">Community</label>
              <input
                type="text"
                placeholder="Enter Community"
                className="form-control"
                name="name"
                value={community.name}
                onChange={(e) => changehandler(e)}
              />
            </div>
            <div
              className="col-md-2"
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "end",
              }}
            >
              {id ? (
                <button type="submit" className="btn btn-primary">
                  Submit
                </button>
              ) : (
                <button type="submit" className="btn btn-primary">
                  Add
                </button>
              )}
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddCommunity;

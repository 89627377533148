import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

// Helpers
import { ApiHelperFunction } from "../../utils/HttpClient";

let initialState = {
  status: "idle",
  PageData: {},
  IsStatus: "",
  isLoading: false,
  isSuccess: false,
  isError: false,
};

export const PostPagesData = createAsyncThunk("page_add", async (data) => {
  let response = await ApiHelperFunction({
    urlPath: "admin/add-page",
    method: "POST",
    data: data,
  });
  console.log("page_added", response);

  if (response.status === 200) {
    toast.success("Page added successfully");
    return response.data;
  } else {
    toast.error(response?.response?.data?.message);
  }
});

export const GetPagesData = createAsyncThunk("Page_lists_get", async () => {
  let response = await ApiHelperFunction({
    urlPath: "admin/page-list",
    method: "GET",
  });
  console.log("page-list-get", response);

  if (response.status === 200) {
    return response.data;
  } else {
    toast.error(response?.response?.data?.message);
  }
});

export const UpdatePagesData = createAsyncThunk(
  "PageData_update",
  async ({ id, data }) => {
    console.log("updatedItem", data);
    let response = await ApiHelperFunction({
      urlPath: `admin/edit-page/${id}`,
      method: "POST",
      data: data,
    });
    console.log("page Details", response);

    if (response.status === 200) {
      toast.success("Page Details Updated Successfully");
      return response.data;
    } else {
      toast.error(response?.response?.data?.message);
    }
  }
);

export const DeletepagesData = createAsyncThunk("Page_Delete", async (id) => {
  let response = await ApiHelperFunction({
    urlPath: `admin/delete-page/${id}`,
    method: "PUT",
  });
  console.log("contact_details", response);

  if (response.status === 200) {
    toast.success("Page Deleted Successfully");
    return response.data;
  } else {
    toast.error(response?.response?.data?.message);
  }
});


export const PagesStatusChange = createAsyncThunk(
  "Pages_status_update",
  async ({ id, data }) => {
    console.log("updatedItem", data);
    let response = await ApiHelperFunction({
      urlPath: `admin/update-page-status/${id}`,
      method: "POST",
      data: data,
    });
    console.log("Ststus_change_details", response);

    if (response.status === 200) {
      toast.success("Status Changed Successfully");
      return response.data;
    } else {
      toast.error(response?.response?.data?.message);
    }
  }
);

export const AddPagesSlice = createSlice({
  name: "Pages_added_data",
  initialState,
  reducers: {
    clearState: (state) => {
      state.status = "idle";
      state.PageData = {};
      state.IsStatus = "";
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(PostPagesData.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(PostPagesData.fulfilled, (state) => {
        state.status = "success";
        state.isSuccess = true;
        // Optionally, you can update state after a successful post if needed
      })
      .addCase(PostPagesData.rejected, (state) => {
        state.status = "failed";
        state.isError = true;
        state.isSuccess = false;
      })
      .addCase(PagesStatusChange.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(PagesStatusChange.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.isSuccess = true;
        // console.log('payload', payload?.data);
        state.IsStatus = payload?.data;
      })
      .addCase(PagesStatusChange.rejected, (state) => {
        state.status = "failed";
        state.isError = true;
        state.isSuccess = false;
      })
      .addCase(GetPagesData.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(GetPagesData.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.status = "success";
        state.isSuccess = true;
        console.log("payload", payload?.data);
        state.PageData = payload?.data;
      })
      .addCase(GetPagesData.rejected, (state) => {
        state.isLoading = false;
        state.status = "failed";
        state.isError = true;
        state.isSuccess = false;
      })
      .addCase(UpdatePagesData.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(UpdatePagesData.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.isSuccess = true;
        // console.log('payload', payload?.data);
        state.PageData = payload?.data;
      })
      .addCase(UpdatePagesData.rejected, (state) => {
        state.status = "failed";
        state.isError = true;
        state.isSuccess = false;
      })
      .addCase(DeletepagesData.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(DeletepagesData.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.isSuccess = true;
        // console.log('payload', payload?.data);
        // state.PageData = payload?.data;
      })
      .addCase(DeletepagesData.rejected, (state) => {
        state.status = "failed";
        state.isError = true;
        state.isSuccess = false;
      })
  },
});

export const { clearState } = AddPagesSlice.actions;

export default AddPagesSlice.reducer;

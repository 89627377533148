import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { PostFaq, UpdateFaq, getFaq } from "../../../redux/AdminSlice/FaqSlice";

const AddFaq = () => {
  const initial = {
    title: "",
    question: "",
    answer: "",
  };
  const [faq, setFaq] = useState(initial);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const handleChange = (e) => {
    setFaq({
      ...faq,
      [e.target.name]: e.target.value,
    });
  };

  const validation = () => {
    if (faq.title === "") {
      toast.error("Faq Title Is required");
      return false;
    }
    if (faq.question === "") {
      toast.error("Faq question Is required");
      return false;
    }
    if (faq.answer === "") {
      toast.error("Faq answer Is required");
      return false;
    }

    return true;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (validation()) {
      let data = {
        title: faq.title,
        question: faq.question,
        answer: faq.answer,
      };

      if (location.state) {
        const id = location.state._id;
        dispatch(UpdateFaq({ id, data })).then(() => {
          dispatch(getFaq());
          setFaq(initial);
          navigate("/manageFaq");
        });
      } else {
        dispatch(PostFaq(data));
        setFaq(initial);
      }
    }
  };

  useEffect(() => {
    if (location.state) {
      setFaq({
        title: location.state.title,
        question: location.state.question,
        answer: location.state.answer,
      });
    }
  }, []);

  return (
    <>
      <div className="main_wrap">
        <div className="contauctUs_page">
          <h4 className="Pageheadline">
            {location.state ? "Edit Faq " : "Add Faq "}
          </h4>
          <form className="row m-0" onSubmit={(e) => handleSubmit(e)}>
            <div class="form-group col-md-3 col-12">
              <label for="AddressLine1">Faq Title</label>
              <input
                type="text"
                class="form-control"
                id="AddressLine1"
                placeholder="Enter faq Title"
                name="title"
                value={faq.title}
                onChange={(e) => handleChange(e)}
              />
            </div>
            <div class="form-group col-md-3 col-12">
              <label for="AddressLine2">Faq Question</label>
              <input
                type="text"
                name="question"
                id="question"
                className="form-control"
                placeholder="Enter Faq Question"
                value={faq.question}
                onChange={(e) => handleChange(e)}
              ></input>
            </div>
            <div class="form-group col-md-3 col-12">
              <label for="AddressLine2">Faq Answer</label>
              <input
                type="text"
                name="answer"
                id="answer"
                className="form-control"
                placeholder="Enter Faq Answer"
                value={faq.answer}
                onChange={(e) => handleChange(e)}
              ></input>
            </div>

            <div className="col-md-3 col-12 m-auto">
              {location.state ? (
                <button className="btn btn-primary">Submit </button>
              ) : (
                <button className="btn btn-primary">Add </button>
              )}
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default AddFaq;

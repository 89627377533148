import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  GetProfileIsForData,
  PostProfileIdForData,
  UpdateProfileIsForData,
} from "../../../redux/AdminSlice/ProfileForSlice";

const AddProfileIsFor = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [profileFor, setProfileFor] = useState({ name: "" });

  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("id");
  // console.log(first)
  const { ProfileIsForData, isloading } = useSelector(
    (state) => state.profileIsForReducer
  );

  const changehandler = (e) => {
    setProfileFor({
      ...profileFor,
      [e.target.name]: e.target.value,
    });
  };

  const singleProfileIsFor = () => {
    console.log("ProfileIsForData", ProfileIsForData);
    let data = ProfileIsForData.filter((item) => {
      return item._id === id;
    });
    console.log("data", data?.[0]?.name);

    if (data.length > 0) {
      setProfileFor({
        name: data?.[0]?.name,
      });
    } else {
      setProfileFor({ name: "" });
    }
  };

  useEffect(() => {
    if (id) {
      singleProfileIsFor();
    }
  }, [id]);

  const submitHandler = (e) => {
    e.preventDefault();
    if (profileFor.name === "") {
      toast.error("Profile Is For Is required");
      return false;
    }
    let data = {
      name: profileFor.name,
    };

    if (id) {
      dispatch(UpdateProfileIsForData({ id, data }));
      setProfileFor({ name: "" });
      dispatch(GetProfileIsForData());
      navigate(-1);
    } else {
      dispatch(PostProfileIdForData(data));
      setProfileFor({
        name: "",
      });
    }
  };
  return (
    <div className="main_wrap">
      <div className="aboutUs_page">
        <h4 className="Pageheadline">
          {id ? "Edit Profile Is For" : "Add Profile Is for"}
        </h4>
        <form action="" onSubmit={(e) => submitHandler(e)}>
          <div className="row">
            <div className="col-md-6">
              <label htmlFor="">Profile Is For</label>
              <input
                type="text"
                placeholder="Enter Profile Is For"
                className="form-control"
                name="name"
                value={profileFor.name}
                onChange={(e) => changehandler(e)}
              />
            </div>
            <div
              className="col-md-6"
              style={{
                width: "100%",

                display: "flex",
                justifyContent: "flex-start",
                alignItems: "end",
              }}
            >
              {id ? (
                <button type="submit" className="btn btn-primary">
                  Submit
                </button>
              ) : (
                <button type="submit" className="btn btn-primary">
                  Add
                </button>
              )}
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddProfileIsFor;

import React, { useState } from "react";
import { useDispatch } from "react-redux";
import ManageBanner from "./ManageBanner";

const AddBanner = () => {
  const initial = {
    images: [],
  };
  const [logo, setLogo] = useState(initial);
  const dispatch = useDispatch();

  const ImageHandler = async (e) => {
    let arr = [];
    let file = e.target.files;

    for (let element of file) {
      let data = new FormData();
      data.append("image", element);
      dispatch();

      //   let res = await fileUpload("admin/upload-companyLogo", data);
      //   if (res && res?.status) {
      //     toast.success("Uploaded Successfully");
      //     arr.push(res?.url);
      //   } else {
      //     toast.error(res?.message);
      //   }
    }

    // setProductData({
    //     ...productData,[e?.target?.name]:arr
    // })

    // setIsLoading(false)
    // file && setImageData(arr);
  };

  return (
    <>
      <div className="main_wrap">
        <div className="logo-sec">
          <h4 className="Pageheadline">Add Banner</h4>
          <form action="">
            <div>
              <label htmlFor="">Add Banner</label>
              <input
                type="file"
                name="image"
                className="form-control"
                onChange={(e) => ImageHandler(e)}
              />
            </div>
            <div className="subAllBtn">
              <button className="btn btn-primary">Submit</button>
            </div>
            <div></div>
          </form>
        </div>
        <ManageBanner />
      </div>
    </>
  );
};

export default AddBanner;

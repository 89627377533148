// Hooks
import React, { useEffect, useState } from "react";
import { Link, redirect, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import "./Authentication.css";
import loginbg from "../Images/LoginBG.jpeg";

// Helpers
import { toast } from "react-toastify";

// API functions
// import { JSON_API } from "../services/api/jsonApiCallWithInterceptor";

import { useDispatch } from "react-redux";
import { FaEye } from "react-icons/fa";
import { FaEyeSlash } from "react-icons/fa";
import { adminSignin } from "../redux/authSlice/AuthSlice";

let regInitValues = {
  email: "",
  password: "",
};

export default function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [user, setuser] = useState(regInitValues);
  const [view, setView] = useState(false);

  const loginStatus = useSelector((state) => state.authReducer.isSuccess);
  console.log("loginStatus", loginStatus);

  var settings = {
    dots: true,
    infinite: true,
    speed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };

  const handleChange = (event) => {
    event.preventDefault();
    // console.log(event.target.name, event.target.value);
    setuser({ ...user, [event.target.name]: event.target.value });
  };

  const handleValidation = () => {
    const { email, password, cnf_password } = user;
    if (email == "") {
      toast.error("Email is required.");

      return false;
    }

    if (password === "") {
      toast.error("Password is required.");

      return false;
    }
    if (password.length < 6) {
      toast.error("Password should be minimum of 6 characters.");

      return false;
    }

    return true;
  };

  useEffect(() => {
    if (loginStatus) {
      navigate("/");
    }
  }, [loginStatus]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    // console.log("user_data", user);
    if (handleValidation()) {
      dispatch(adminSignin(user));
    }
  };

  return (
    <>
      <section
        className="LoginPage"
        style={{ backgroundImage: `url(${loginbg})` }}
      >
        <div className="LoginBgOverlay"></div>
        <div className="LoginForm">
          <div className="LoginTop">
            <div
              style={{
                width: "100%",
                maxWidth: "150px",
                margin: "0 auto 15px",
              }}
            ></div>
            <h5 className="LoginHead">Sign in</h5>
            {/* <div className="LoginTopIcon">
              <i class="fa-brands fa-square-facebook"></i>
              <i class="fa-brands fa-google"></i>
            </div> */}
          </div>
          <div className="LoginBtm">
            <form>
              <div className="form-group">
                <input
                  name="email"
                  id="exampleEmail"
                  placeholder="Email here..."
                  type="email"
                  className="form-control"
                  value={user.email}
                  onChange={(e) => handleChange(e)}
                />
              </div>

              <div className="form-group pass_input">
                <input
                  name="password"
                  id="examplePassword"
                  placeholder="Password here..."
                  type={view ? "text" : "password"}
                  className="form-control"
                  value={user.password}
                  onChange={(e) => handleChange(e)}
                />
                <div className="eye_icon">
                  {view ? (
                    <FaEye onClick={() => setView(!view)} />
                  ) : (
                    <FaEyeSlash onClick={() => setView(!view)} />
                  )}
                </div>
              </div>

              {/* <div className="form-group">
                <input type="Checkbox" />
                <span className="LoginRem">Remember Me</span>
              </div> */}

              <button
                className="LoginBtn"
                type="submit"
                onClick={(e) => handleSubmit(e)}
              >
                SIGN IN
              </button>
            </form>
          </div>
        </div>
      </section>
    </>
  );
}

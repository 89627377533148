import React, { useEffect, useMemo, useState } from "react";
import Table from "../../Table/Table";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  DeletePrivacyPolicy,
  GetPrivacyPolicy,
} from "../../../redux/AdminSlice/PrivacyPolicySlice";

const ManagePrivacyPolicy = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchText, setsearchText] = useState("");

  const PrivacyPolicyData = useSelector(
    (state) => state.privacyPolicyReducer.privacyPolicyData
  );

  useEffect(() => {
    dispatch(GetPrivacyPolicy());
  }, []);

  const deletePrivacyPolicy = (id) => {
    dispatch(DeletePrivacyPolicy(id)).then(() => {
      dispatch(GetPrivacyPolicy());
    });
  };

  const filterData = useMemo(() => {
    if (searchText !== "") {
      return PrivacyPolicyData.filter((item) =>
        JSON.stringify(item).toLowerCase().includes(searchText.toLowerCase())
      );
    }
    return PrivacyPolicyData;
  }, [searchText, PrivacyPolicyData]);

  const tableData = useMemo(() => {
    return {
      columns: [
        {
          label: "SL",
          field: "sl",
          sort: "asc",
          width: 270,
        },
        {
          label: "Privacy Title",
          field: "privacyTitle",
          sort: "asc",
          width: 150,
        },
        {
          label: "Privacy Description",
          field: "privacyDescription",
          sort: "asc",
          width: 150,
        },
        {
          label: "Action",
          field: "action",
          sort: "asc",
          width: 150,
        },
      ],
      rows:
        filterData?.length > 0
          ? filterData.map((item, idx) => {
              console.log("privacy", item);
              return {
                sl: idx + 1,
                privacyTitle: (
                  <div className="widget-content p-0" key={idx}>
                    <div className="widget-content-wrapper">
                      <div className="widget-content-left flex2">
                        <div
                          className="widget-heading"
                          style={{ opacity: 1, fontSize: "13px" }}
                        >
                          {item.title}
                        </div>
                      </div>
                    </div>
                  </div>
                ),
                privacyDescription: (
                  <div className="widget-content p-0" key={idx}>
                    <div className="widget-content-wrapper">
                      <div className="widget-content-left flex2">
                        <div
                          className="widget-heading"
                          style={{ opacity: 1, fontSize: "13px" }}
                        >
                          {item?.description}
                        </div>
                      </div>
                    </div>
                  </div>
                ),

                action: (
                  <div className="widget-content p-0" key={idx}>
                    <div className="widget-content-wrapper">
                      <div className="widget-content-center flex2">
                        <i
                          class="fa-solid fa-pen"
                          style={{ color: "blue", cursor: "pointer" }}
                          onClick={() => {
                            navigate("/addPrivacy", { state: item });
                          }}
                        ></i>
                        <i
                          class="fa-solid fa-trash-can"
                          style={{
                            color: "red",
                            marginLeft: "15px",
                            cursor: "pointer",
                          }}
                          onClick={() => deletePrivacyPolicy(item._id)}
                        ></i>
                      </div>
                    </div>
                  </div>
                ),
              };
            })
          : [],
    };
  });

  return (
    <>
      <div className="main_wrap">
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <h4 className="Pageheadline">Manage Privacy Policy</h4>
          <button
            className="btn btn-primary"
            style={{ height: "33px" }}
            onClick={() => navigate("/addPrivacy")}
          >
            Add
          </button>
        </div>
        <div style={{ overflowX: "auto" }} className="dataTableParDiv">
          <Table
            striped
            tableTitle="All abouts"
            data={tableData}
            onSearch={(e) => setsearchText(e.target.value)}
          />
        </div>
      </div>
    </>
  );
};

export default ManagePrivacyPolicy;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  GetDietdetails,
  PostDietdetails,
  UpdateDietdetails,
  clearState,
  uploadDietImg,
} from "../../../redux/AdminSlice/DietSlice";

const AddDiet = () => {
  let initial = {
    name: "",
    icon: "",
  };
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [diet, setDiet] = useState(initial);

  const dietImage = useSelector((state) => state.dietReducer.DietImage);

  console.log("diet", diet);

  const changehandler = (e) => {
    setDiet((prevDiet) => ({
      ...prevDiet,
      name: e.target.value,
    }));
  };

  const Imagehandler = (e) => {
    let file = e.target.files;

    for (let element of file) {
      let data = new FormData();
      data.append("image", element);
      dispatch(uploadDietImg(data));
    }
  };

  useEffect(() => {
    if (location.state) {
      setDiet({
        name: location.state.name,
        icon: location.state.icon,
      });
    }
    if (dietImage) {
      setDiet((prevDiet) => ({
        ...prevDiet,
        icon: dietImage,
      }));
    }
  }, [dietImage]);

  const submitHandler = (e) => {
    e.preventDefault();
    if (diet.name === "") {
      toast.error("Diet Type Is required");
      return false;
    }
    if (diet.icon === "") {
      toast.error("Diet icon Is required");
      return false;
    }
    let data = {
      name: diet.name,
      icon: diet.icon ? diet.icon : dietImage,
    };

    if (location.state) {
      const id = location.state._id;
      dispatch(UpdateDietdetails({ id, data }));
      setDiet(initial);
      dispatch(clearState());
      dispatch(GetDietdetails());
      navigate("/manageDiet");
    } else {
      dispatch(PostDietdetails(data));
      setDiet(initial);
      dispatch(clearState());
    }
  };
  return (
    <div className="main_wrap">
      <div className="aboutUs_page">
        <h4 className="Pageheadline">
          {location.state ? "Edit diet" : "Add diet"}
        </h4>
        <form action="" onSubmit={(e) => submitHandler(e)}>
          <div style={{ display: "flex" }}>
            <div className="col-md-5 col-12">
              <label htmlFor="">Diet Type</label>
              <input
                type="text"
                placeholder="Enter Diet Type"
                className="form-control"
                name="name"
                value={diet.name}
                onChange={(e) => changehandler(e)}
              />
            </div>
            <div className="col-md-5 col-12">
              <label htmlFor="">Icon</label>
              <input
                type="file"
                className="form-control"
                name="icon"
                accept="image/*"
                onChange={(e) => Imagehandler(e)}
              />
            </div>
            <div
              className="col-md-2 col-12"
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "end",
              }}
            >
              {location.state ? (
                <button type="submit" className="btn btn-primary">
                  Submit
                </button>
              ) : (
                <button type="submit" className="btn btn-primary">
                  Add
                </button>
              )}
            </div>
          </div>
          {dietImage ? (
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                marginTop: "5px",
              }}
            >
              <img
                style={{ height: "100px", width: "100px"}}
                src={dietImage}
                alt=""
              />
              <i
                class="fa-solid fa-xmark"
                style={{ cursor: "pointer" }}
                onClick={() => dispatch(clearState())}
              ></i>
            </div>
          ) : location.state ? (
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                marginTop: "5px",
              }}
            >
              <img
                style={{ height: "100px", width: "100px", marginLeft: "100px" }}
                src={diet.icon}
                alt=""
              />
            </div>
          ) : (
            ""
          )}
        </form>
      </div>
    </div>
  );
};

export default AddDiet;

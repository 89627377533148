import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Table from "../Table/Table";
import { toast } from "react-toastify";
import CountryStateCitydata from "../../CountryCityState/index.json";
import { getDemogrPhydetails } from "../../redux/ReportSlice/DemographySlice";
import { getSingleUserReports } from "../../redux/ReportSlice/UserReportSlice";
import {
  getStateByCountryId,
  getStateByCountryName,
} from "../../redux/AdminSlice/StateSlice";
import { getCountry } from "../../redux/AdminSlice/CountrySlice";
import { GetCityByStateName } from "../../redux/AdminSlice/CitySlice";

const DemographyReport = () => {
  const initial = {
    fromDate: "",
    toDate: "",
    countryName: "",
    stateName: "",
    cityName: "",
  };
  const [demography, setDemography] = useState(initial);
  const [cityData, setCityData] = useState([]);
  const [stateData, setFilterState] = useState([]);
  const navigate = useNavigate();
  const [countryId, setCountryId] = useState("");
  const [stateId, setStateId] = useState("");
  const dispatch = useDispatch();

  const [searchText, setsearchText] = useState("");
  const { countrydata } = useSelector((state) => state.countryReducer);
  const { stateDataByCountry } = useSelector((state) => state.stateReducer);
  const { CityData } = useSelector((state) => state.cityReducer);

  const demographyReportDetails = useSelector(
    (state) => state.demographyReducer?.DemographyData
  );

  console.log("demographyReportDetails", demographyReportDetails);

  useEffect(() => {
    dispatch(getDemogrPhydetails());
    dispatch(getCountry());
  }, []);

  const filterData = useMemo(() => {
    if (searchText !== "") {
      return demographyReportDetails?.filter((item) =>
        JSON.stringify(item).toLowerCase().includes(searchText.toLowerCase())
      );
    }
    return demographyReportDetails;
  }, [searchText, demographyReportDetails]);

  const changehandler = (e) => {
    if (e.target.name === "countryName") {
      dispatch(getStateByCountryName(e.target.value));
    }
    if (e.target.name === "stateName") {
      dispatch(GetCityByStateName(e.target.value));
    }
    setDemography({
      ...demography,
      [e.target.name]: e.target.value,
    });
  };

  const countryChange = (e) => {
    if (e.target.name === "countryName") {
      const cityName = e.target.value;

      // Filter the data and store in state
      if (cityName) {
        const filteredResult = CountryStateCitydata.filter(
          (item) => item.name === cityName
        );
        setFilterState(filteredResult?.[0]?.states);
      } else {
        // Clear filtered data if no value is selected
        setFilterState([]);
      }
    }
    if (e.target.name === "stateName") {
      const stateName = e.target.value;
      if (stateName) {
        const filterCity = stateData.filter((item) => item.name === stateName);
        setCityData(filterCity?.[0]?.cities);
      } else {
        // Clear filtered data if no value is selected
        setCityData([]);
      }
    }
  };

  const validation = () => {
    if (demography.fromDate === "") {
      toast.error("From date is required");
      return false;
    }
    if (demography.toDate === "") {
      toast.error("To date is required");
      return false;
    }

    return true;
  };

  const submitHandler = (e) => {
    e.preventDefault();
    if (validation()) {
      let data = {
        fromDate: demography.fromDate,
        toDate: demography.toDate,
        countryName: demography.countryName,
        stateName: demography.stateName,
        cityName: demography.cityName,
      };

      dispatch(getDemogrPhydetails(data));
    }
  };

  const handleReset = () => {
    setDemography(initial);
    dispatch(getDemogrPhydetails());
  };

  const tableData = useMemo(() => {
    return {
      columns: [
        {
          label: "SL",
          field: "sl",
          sort: "asc",
          width: 120,
        },
        {
          label: "Registration Date",
          field: "regdate",
          sort: "asc",
          width: 150,
        },
        {
          label: "User Id ",
          field: "userid",
          sort: "asc",
          width: 150,
        },
        {
          label: "Country ",
          field: "country",
          sort: "asc",
          width: 150,
        },
        {
          label: "State",
          field: "state",
          sort: "asc",
          width: 150,
        },
        {
          label: "City",
          field: "city",
          sort: "asc",
          width: 150,
        },
        {
          label: "User Name",
          field: "username",
          sort: "asc",
          width: 150,
        },
        {
          label: "User Email",
          field: "useremail",
          sort: "asc",
          width: 150,
        },
        {
          label: "Contact No.",
          field: "contact",
          sort: "asc",
          width: 150,
        },
        {
          label: "Action.",
          field: "action",
          sort: "asc",
          width: 150,
        },
      ],
      rows:
        filterData?.length > 0
          ? filterData.map((item, idx) => {
            console.log("xsxsxs", item);
            return {
              sl: idx + 1,
              regdate: (
                <div className="widget-content p-0" key={idx}>
                  <div className="widget-content-wrapper">
                    <div className="widget-content-left flex2">
                      <div
                        className="widget-heading"
                        style={{ opacity: 1, fontSize: "13px" }}
                      >
                        {item.date}
                      </div>
                    </div>
                  </div>
                </div>
              ),
              userid: (
                <div className="widget-content p-0" key={idx}>
                  <div className="widget-content-wrapper">
                    <div className="widget-content-left flex2">
                      <div
                        className="widget-heading"
                        style={{ opacity: 1, fontSize: "13px" }}
                      >
                        {item.customizedUserId}
                      </div>
                    </div>
                  </div>
                </div>
              ),
              country: (
                <div className="widget-content p-0" key={idx}>
                  <div className="widget-content-wrapper">
                    <div className="widget-content-left flex2">
                      <div
                        className="widget-heading"
                        style={{ opacity: 1, fontSize: "13px" }}
                      >
                        {item?.personalDetails?.personalInfo?.country}
                      </div>
                    </div>
                  </div>
                </div>
              ),
              state: (
                <div className="widget-content p-0" key={idx}>
                  <div className="widget-content-wrapper">
                    <div className="widget-content-left flex2">
                      <div
                        className="widget-heading"
                        style={{ opacity: 1, fontSize: "13px" }}
                      >
                        {item?.personalDetails?.personalInfo?.state}
                      </div>
                    </div>
                  </div>
                </div>
              ),
              city: (
                <div className="widget-content p-0" key={idx}>
                  <div className="widget-content-wrapper">
                    <div className="widget-content-left flex2">
                      <div
                        className="widget-heading"
                        style={{ opacity: 1, fontSize: "13px" }}
                      >
                        {item?.personalDetails?.personalInfo?.city}
                      </div>
                    </div>
                  </div>
                </div>
              ),
              username: (
                <div className="widget-content p-0" key={idx}>
                  <div className="widget-content-wrapper">
                    <div className="widget-content-left flex2">
                      <div
                        className="widget-heading"
                        style={{ opacity: 1, fontSize: "13px" }}
                      >
                        {item?.firstName + " " + item?.lastName}
                      </div>
                    </div>
                  </div>
                </div>
              ),
              useremail: (
                <div className="widget-content p-0" key={idx}>
                  <div className="widget-content-wrapper">
                    <div className="widget-content-left flex2">
                      <div
                        className="widget-heading"
                        style={{ opacity: 1, fontSize: "13px" }}
                      >
                        {item.email}
                      </div>
                    </div>
                  </div>
                </div>
              ),
              contact: (
                <div className="widget-content p-0" key={idx}>
                  <div className="widget-content-wrapper">
                    <div className="widget-content-left flex2">
                      <div
                        className="widget-heading"
                        style={{ opacity: 1, fontSize: "13px" }}
                      >
                        {item.mobile}
                      </div>
                    </div>
                  </div>
                </div>
              ),
              action: (
                <div className="widget-content p-0" key={idx}>
                  <div className="widget-content-wrapper">
                    <div className="widget-content-left flex2">
                      <div className="widget-heading" style={{ opacity: 1 }}>
                        <i
                          class="fa-solid fa-eye"
                          style={{ cursor: "pointer", color: "blue" }}
                          onClick={() => {
                            dispatch(getSingleUserReports(item._id));
                            navigate(`/viewSingleUserReport`);
                          }}
                        ></i>
                      </div>
                    </div>
                  </div>
                </div>
              ),
            };
          })
          : [],
    };
  });

  return (
    <>
      <div className="main_wrap">
        {/* <CustomLoader loading={isLoading} /> */}
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <h4 className="Pageheadline">Demography Wise Report</h4>
        </div>
        <div className="searchDiv py-3">
          <form
            action=""
            className="row px-3"
            onSubmit={(e) => submitHandler(e)}
          >
            {/* from date */}
            <div className="" style={{ marginRight: "8px", width: "145px" }}>
              <label htmlFor="fromDate">Form Date</label>
              <input
                type="date"
                className="form-control"
                name="fromDate"
                value={demography.fromDate}
                onChange={(e) => changehandler(e)}
              />
            </div>
            {/* to date */}
            <div className="" style={{ marginRight: "8px", width: "145px" }}>
              <label htmlFor="toDate">To Date</label>
              <input
                type="date"
                className="form-control"
                name="toDate"
                value={demography.toDate}
                min={demography.fromDate}
                onChange={(e) => changehandler(e)}
              />
            </div>

            {/* country */}
            <div className="" style={{ marginRight: "8px", width: "155px" }}>
              <label htmlFor="country">Country Name</label>
              <select
                class="form-select table_page_select"
                aria-label="Default select example"
                onChange={(e) => {
                  changehandler(e);
                  countryChange(e);
                }}
                name="countryName"
                value={demography.countryName}
              >
                {" "}
                <option selected value="">
                  Select Country
                </option>
                {/* {CountryStateCitydata?.map((item) => {
                  return (
                    <option key={item.id} value={item.name}>
                      {item?.name}
                    </option>
                  );
                })} */}
                {countrydata?.map((item) => {
                  return <option value={item?.name}>{item?.name}</option>;
                })}
              </select>
            </div>

            {/* state */}
            <div className="" style={{ marginRight: "8px", width: "200px" }}>
              <label htmlFor="">State Name</label>
              <select
                class="form-select table_page_select"
                aria-label="Default select example"
                onChange={(e) => {
                  changehandler(e);
                  countryChange(e);
                }}
                name="stateName"
                value={demography.stateName}
              >
                {" "}
                <option selected value="">
                  Select State{" "}
                </option>
                {/* {stateData?.map((item) => {
                  console.log("fdfdfdfd", item);
                  return (
                    <option key={item.id} value={item?.name}>
                      {item?.name}
                    </option>
                  );
                })} */}
                {stateDataByCountry?.map((item) => {
                  return <option value={item?.name}>{item?.name}</option>;
                })}
              </select>
            </div>

            {/* city */}
            <div className="" style={{ marginRight: "8px", width: "155px" }}>
              <label htmlFor="">City Name</label>
              <select
                class="form-select table_page_select"
                aria-label="Default select example"
                onChange={(e) => changehandler(e)}
                name="cityName"
                value={demography.cityName}
              >
                {" "}
                <option selected value="">
                  Select City
                </option>
                {CityData?.map((item) => {
                  return (
                    <option key={item?.name} value={item?.name}>
                      {item?.name}
                    </option>
                  );
                })}
              </select>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                // width: "17%",
                alignItems: "flex-end",
              }}
            >
              <button type="submit" className="btn btn-primary table_btn">
                Search
              </button>
            </div>
          </form>
          <div
            style={{
              width: "98%",
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            <button
              type="submit"
              className="btn btn-danger table_btn ml-3"
              onClick={() => handleReset()}
            >
              Reset
            </button>
          </div>
        </div>
        <div style={{ overflowX: "auto" }} className="dataTableParDiv">
          <Table
            striped
            tableTitle="All abouts"
            data={tableData}
            onSearch={(e) => setsearchText(e.target.value)}
          />
        </div>
      </div>
    </>
  );
};

export default DemographyReport;

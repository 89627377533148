import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Table from "../Table/Table";
import { toast } from "react-toastify";
import { getGenderReport } from "../../redux/ReportSlice/GenderReportSlice";

const GenderWiseReport = () => {
  const initial = {
    fromDate: "",
    toDate: "",
    genderType: "",
  };
  const [gender, setGender] = useState(initial);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [searchText, setsearchText] = useState("");

  const GenderReportDetails = useSelector(
    (state) => state.genderReportReducer.GenderReportData
  );

  useEffect(() => {
    dispatch(getGenderReport());
  }, []);

  const filterData = useMemo(() => {
    if (searchText !== "") {
      return GenderReportDetails?.filter((item) =>
        JSON.stringify(item).toLowerCase().includes(searchText.toLowerCase())
      );
    }
    return GenderReportDetails;
  }, [searchText, GenderReportDetails]);

  const changehandler = (e) => {
    setGender({
      ...gender,
      [e.target.name]: e.target.value,
    });
  };

  const validation = () => {
    if (gender.fromDate === "") {
      toast.error("From date is required");
      return false;
    }
    if (gender.toDate === "") {
      toast.error("To date is required");
      return false;
    }

    return true;
  };

  const submitHandler = (e) => {
    e.preventDefault();
    if (validation()) {
      let data = {
        fromDate: gender.fromDate,
        toDate: gender.toDate,
        genderName: gender.genderName,
      };

      dispatch(getGenderReport(data));
    }
  };

  const tableData = useMemo(() => {
    return {
      columns: [
        {
          label: "SL",
          field: "sl",
          sort: "asc",
          width: 270,
        },
        {
          label: "Registration Date",
          field: "regdate",
          sort: "asc",
          width: 150,
        },
        {
          label: "Gender Type",
          field: "genderType",
          sort: "asc",
          width: 150,
        },
        {
          label: "User Name",
          field: "username",
          sort: "asc",
          width: 150,
        },
        {
          label: "User Email",
          field: "useremail",
          sort: "asc",
          width: 150,
        },
        {
          label: "Contact No.",
          field: "contact",
          sort: "asc",
          width: 150,
        },
      ],
      rows:
        filterData?.length > 0
          ? filterData.map((item, idx) => {
              console.log("sssssss", item);
              return {
                sl: idx + 1,
                regdate: (
                  <div className="widget-content p-0" key={idx}>
                    <div className="widget-content-wrapper">
                      <div className="widget-content-left flex2">
                        <div className="widget-heading" style={{ opacity: 1 }}>
                          {item.date}
                        </div>
                      </div>
                    </div>
                  </div>
                ),
                genderType: (
                  <div className="widget-content p-0" key={idx}>
                    <div className="widget-content-wrapper">
                      <div className="widget-content-left flex2">
                        <div className="widget-heading" style={{ opacity: 1 }}>
                          {item.gender}
                        </div>
                      </div>
                    </div>
                  </div>
                ),
                username: (
                  <div className="widget-content p-0" key={idx}>
                    <div className="widget-content-wrapper">
                      <div className="widget-content-left flex2">
                        <div className="widget-heading" style={{ opacity: 1 }}>
                          {item?.firstName + " " + item?.lastName}
                        </div>
                      </div>
                    </div>
                  </div>
                ),
                useremail: (
                  <div className="widget-content p-0" key={idx}>
                    <div className="widget-content-wrapper">
                      <div className="widget-content-left flex2">
                        <div className="widget-heading" style={{ opacity: 1 }}>
                          {item.email}
                        </div>
                      </div>
                    </div>
                  </div>
                ),
                contact: (
                  <div className="widget-content p-0" key={idx}>
                    <div className="widget-content-wrapper">
                      <div className="widget-content-left flex2">
                        <div className="widget-heading" style={{ opacity: 1 }}>
                          {item.mobile}
                        </div>
                      </div>
                    </div>
                  </div>
                ),
              };
            })
          : [],
    };
  });
  return (
    <>
      <div className="main_wrap">
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <h4 className="Pageheadline">Gender Wise Report</h4>
        </div>
        <div className="searchDiv">
          <form
            action=""
            className="row m-auto"
            onSubmit={(e) => submitHandler(e)}
          >
            <div className="col-md-3">
              <label htmlFor="fromDate">Form Date</label>
              <input
                type="date"
                className="form-control"
                name="fromDate"
                value={gender.fromDate}
                onChange={(e) => changehandler(e)}
              />
            </div>
            <div className="col-md-3">
              <label htmlFor="toDate">To Date</label>
              <input
                type="date"
                className="form-control"
                name="toDate"
                value={gender.toDate}
                onChange={(e) => changehandler(e)}
              />
            </div>
            <div className="col-md-3">
              <label htmlFor="">Gender Type</label>
              <select
                class="form-select table_page_select"
                aria-label="Default select example"
                onChange={(e) => changehandler(e)}
                name="genderType"
                value={gender.genderType}
              >
                {" "}
                <option selected value="">Select Gender</option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
                <option value="Other">Other</option>
              </select>
            </div>

            <button type="submit" className="btn btn-primary table_btn">
              Search
            </button>
          </form>
        </div>
        <div style={{ overflowX: "auto" }} className="dataTableParDiv">
          <Table
            striped
            tableTitle="All abouts"
            data={tableData}
            onSearch={(e) => setsearchText(e.target.value)}
          />
        </div>
      </div>
    </>
  );
};

export default GenderWiseReport;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  GetComplexiondetails,
  PostComplexiondetails,
  UpdateComplexiondetails,
  clearState,
  uploadComplexionIcon,
} from "../../../redux/AdminSlice/ComplexionSlice";
import { toast } from "react-toastify";

const AddComplexion = () => {
  let initial = {
    name: "",
    icon: "",
  };

  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [complexion, setComplexion] = useState(initial);

  const complexionImage = useSelector(
    (state) => state.complexionReducer.complexionIcon
  );

  const changehandler = (e) => {
    setComplexion((prevcomplexion) => ({
      ...prevcomplexion,
      name: e.target.value,
    }));
  };

  const Imagehandler = (e) => {
    let file = e.target.files;

    for (let element of file) {
      let data = new FormData();
      data.append("image", element);
      dispatch(uploadComplexionIcon(data));
    }
  };

  useEffect(() => {
    if (location.state) {
      setComplexion({
        name: location.state.name,
        icon: location.state.icon,
      });
    }
    if (complexionImage) {
      setComplexion((prevcomplexion) => ({
        ...prevcomplexion,
        icon: complexionImage,
      }));
    }
  }, [complexionImage]);

  const submitHandler = (e) => {
    e.preventDefault();
    if (complexion.name === "") {
      toast.error("Complexion Type Is Required");
      return false;
    }
    if (complexion.icon === "") {
      toast.error("Complexion Type Icon Is Required");
      return false;
    }
    let data = {
      name: complexion.name,
      icon: complexion?.icon ? complexion?.icon : complexionImage,
    };

    if (location.state) {
      const id = location.state._id;
      dispatch(UpdateComplexiondetails({ id, data }));
      setComplexion(initial);
      dispatch(clearState());
      dispatch(GetComplexiondetails());
      navigate("/managecomplexion");
    } else {
      dispatch(PostComplexiondetails(data));
      setComplexion(initial);
      dispatch(clearState());
    }
  };
  return (
    <div className="main_wrap">
      <div className="aboutUs_page">
        <h4 className="Pageheadline">
          {location.state ? "Edit Complexion" : "Add Complexion"}
        </h4>
        <form action="" onSubmit={(e) => submitHandler(e)}>
          <div className="row">
            <div className="col-md-5 col-12">
              <label htmlFor="">Complexion Type</label>
              <input
                type="text"
                placeholder="Enter Complexion Type"
                className="form-control"
                name="name"
                value={complexion.name}
                onChange={(e) => changehandler(e)}
              />
            </div>
            <div className="col-md-5 col-12">
              <label htmlFor="">Icon</label>
              <input
                type="file"
                className="form-control"
                name="icon"
                accept="image/*"
                onChange={(e) => Imagehandler(e)}
              />
            </div>
            <div
              className="col-md-2 col-12"
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "end",
              }}
            >
              {location.state ? (
                <button type="submit" className="btn btn-primary">
                  Submit
                </button>
              ) : (
                <button type="submit" className="btn btn-primary">
                  Add
                </button>
              )}
            </div>
          </div>
          {complexionImage ? (
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                marginTop: "5px",
              }}
            >
              <img
                style={{ height: "100px", width: "100px" }}
                src={complexionImage}
                alt=""
              />
              <i
                class="fa-solid fa-xmark"
                style={{ cursor: "pointer", color: "red", marginLeft: "10px" }}
                onClick={() => dispatch(clearState())}
              ></i>
            </div>
          ) : location.state ? (
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                marginTop: "5px",
              }}
            >
              <img
                style={{ height: "100px", width: "100px", marginLeft: "100px" }}
                src={complexion.icon}
                alt=""
              />
            </div>
          ) : (
            ""
          )}
        </form>
      </div>
    </div>
  );
};

export default AddComplexion;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

// Helpers
import { ApiHelperFunction } from "../../utils/HttpClient";

let initialState = {
  status: "idle",
  SubCommunityData: null,
  SubCommunityReportData: null,
  subCommunityCount: null,
  isLoading: false,
  isSuccess: false,
  isError: false,
};

export const PostSubCommunity = createAsyncThunk(
  "Sub_Community_post",
  async (data) => {
    let response = await ApiHelperFunction({
      urlPath: "admin/add-sub-community",
      method: "POST",
      data: data,
    });
    console.log("sub_community_response", response);

    if (response.status === 200) {
      toast.success("Sub Community Added successfully");
      return response.data;
    } else {
      toast.error(response?.response?.data?.message);
    }
  }
);

export const GetSubCommunity = createAsyncThunk(
  "Sub_Community_get",
  async () => {
    let response = await ApiHelperFunction({
      urlPath: "admin/view-sub-community",
      method: "GET",
    });
    console.log("Comminuty_details_get", response);

    if (response.status === 200) {
      return response.data;
    } else {
      toast.error(response?.response?.data?.message);
    }
  }
);

export const UpdateSubCommunity = createAsyncThunk(
  "Sub_Community_update",
  async ({ id, data }) => {
    console.log("updatedItem", data);
    let response = await ApiHelperFunction({
      urlPath: `admin/edit-sub-community/${id}`,
      method: "PUT",
      data: data,
    });
    console.log("Community_Details", response);

    if (response.status === 200) {
      toast.success("Sub Community Updated Successfully");
      return response.data;
    } else {
      toast.error(response?.response?.data?.message);
    }
  }
);

export const DeleteSubCommunity = createAsyncThunk(
  "Sub_Community_Delete",
  async (id) => {
    let response = await ApiHelperFunction({
      urlPath: `admin/delete-sub-community/${id}`,
      method: "PUT",
    });
    console.log("contact_details", response);

    if (response.status === 200) {
      toast.success("Sub Community Deleted Successfully");
      return response.data;
    } else {
      toast.error(response?.response?.data?.message);
    }
  }
);

export const getSubCommunityReport = createAsyncThunk(
  "Sub_Community_Wise_report_get",
  async (data) => {
    let response = await ApiHelperFunction({
      urlPath: "admin/sub-community-wise-reports",
      method: "POST",
      data: data ? data : "",
    });
    console.log("subCommunity_details_report_get", response?.data?.data);

    if (response.status === 200) {
      return response?.data?.data;
    } else {
      toast.error(response?.response?.data?.message);
    }
  }
);

export const GetSubCommunityCount = createAsyncThunk(
  "Sub_Community_count_get",
  async () => {
    let response = await ApiHelperFunction({
      urlPath: "admin/total-sub-community-statistic-reports",
      method: "GET",
    });
    console.log("Comminuty_details_get", response);

    if (response.status === 200) {
      return response.data;
    } else {
      toast.error(response?.response?.data?.message);
    }
  }
);

export const GetCommunityBasedSubCommunity = createAsyncThunk(
  "Community_based_SubCommunity_get",
  async (data) => {
    let response = await ApiHelperFunction({
      urlPath: `admin/view-subcommunity-bycommunity-name/${data}`,
      method: "GET",
    });
    console.log("community_based_SubCommunity", response);

    if (response.status === 200) {
      return response.data;
    } else {
      toast.error(response?.response?.data?.message);
    }
  }
);

export const SubCommunitySlice = createSlice({
  name: "Sub_community_data",
  initialState,
  reducers: {
    clearState: (state) => {
      state.status = "idle";
      state.SubCommunityData = null;
      state.SubCommunityReportData = null;
      state.subCommunityCount = null;
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(PostSubCommunity.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(PostSubCommunity.fulfilled, (state) => {
        state.status = "success";
        state.isSuccess = true;
        // Optionally, you can update state after a successful post if needed
      })
      .addCase(PostSubCommunity.rejected, (state) => {
        state.status = "failed";
        state.isError = true;
        state.isSuccess = false;
      })
      .addCase(GetSubCommunity.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(GetSubCommunity.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.isSuccess = true;
        // console.log("payload", payload?.data);
        state.SubCommunityData = payload?.data;
      })
      .addCase(GetSubCommunity.rejected, (state) => {
        state.status = "failed";
        state.isError = true;
        state.isSuccess = false;
      })
      .addCase(UpdateSubCommunity.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(UpdateSubCommunity.fulfilled, (state) => {
        state.status = "success";
        state.isSuccess = true;
      })
      .addCase(UpdateSubCommunity.rejected, (state) => {
        state.status = "failed";
        state.isError = true;
        state.isSuccess = false;
      })
      .addCase(DeleteSubCommunity.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(DeleteSubCommunity.fulfilled, (state) => {
        state.status = "success";
        state.isSuccess = true;
      })
      .addCase(DeleteSubCommunity.rejected, (state) => {
        state.status = "failed";
        state.isError = true;
        state.isSuccess = false;
      })
      .addCase(getSubCommunityReport.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(getSubCommunityReport.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.isSuccess = true;
        console.log("popo", payload);
        state.SubCommunityReportData = payload;
      })
      .addCase(getSubCommunityReport.rejected, (state) => {
        state.status = "failed";
        state.isError = true;
        state.isSuccess = false;
      })
      .addCase(GetSubCommunityCount.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(GetSubCommunityCount.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.isSuccess = true;
        // console.log("payload", payload?.data);
        state.subCommunityCount = payload?.data;
      })
      .addCase(GetSubCommunityCount.rejected, (state) => {
        state.status = "failed";
        state.isError = true;
        state.isSuccess = false;
      })

      .addCase(GetCommunityBasedSubCommunity.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(
        GetCommunityBasedSubCommunity.fulfilled,
        (state, { payload }) => {
          state.status = "success";
          state.isSuccess = true;
          state.isLoading = false;
          state.SubCommunityData = payload?.data;
        }
      )
      .addCase(GetCommunityBasedSubCommunity.rejected, (state) => {
        state.status = "failed";
        state.isError = true;
        state.isSuccess = false;
        state.isLoading = false;
      });
  },
});

export const { clearState } = SubCommunitySlice.actions;

export default SubCommunitySlice.reducer;

import React, { useEffect, useState } from "react";

const ImageModal = (props) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [modalImageSrc, setModalImageSrc] = useState("");
  const [modalImageAlt, setModalImageAlt] = useState("");

  console.log("isModalOpen", props?.url);

  useEffect(() => {
    if (props?.url) {
      setModalImageSrc(props?.url);
      setModalImageAlt(props?.url ? "" : "img");
      setModalOpen(true);
    }
  }, [props?.url]);

  const closeModal = () => {
    setModalOpen(false);
    props?.setClickedImageUrl("");
  };

  return (
    <div>
      {/* The Modal */}
      {isModalOpen && (
        <>
          <div className="img_modal" onClick={closeModal}>
            <span className="close">&times;</span>
            <img
              className="modal-content"
              src={modalImageSrc}
              alt={modalImageAlt}
            />
            <div id="caption">{modalImageAlt}</div>
          </div>
        </>
      )}
    </div>
  );
};

export default ImageModal;

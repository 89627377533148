import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ManageLogo from "./ManageLogo";
import { uploadCompanyLogo } from "../../../redux/AdminSlice/LogoAndBannerSlice";


const AddLogo = () => {
  const initial = {
    image: "",
  };
  const [logo, setLogo] = useState(initial);
  const dispatch = useDispatch();

  const ImageHandler = async (e) => {
    const file = e.target.files[0]; // Get the first file from the selected files
  
    if (file) {
      const data = new FormData();
      data.append("image", file);

      console.log("FormData_keys:", Array.from(data.keys()));
      console.log("FormData_entries:", Array.from(data.entries()));

      dispatch(uploadCompanyLogo(data));
    }
  };

  return (
    <>
      <div className="main_wrap">
        <div className="logo-sec">
          <h4 className="Pageheadline">Add Logo</h4>
          <form action="">
            <div>
              <label htmlFor="">Add Logo</label>
              <input
                type="file"
                name="image"
                className="form-control"
                accept="image/*"
                onChange={(e) => ImageHandler(e)}
              />
            </div>
            <div className="subAllBtn">
              <button className="btn btn-primary">Submit</button>
            </div>
            <div></div>
          </form>
        </div>
        <ManageLogo />
      </div>
    </>
  );
};

export default AddLogo;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

// Helpers
import { ApiHelperFunction } from "../../utils/HttpClient";

let initialState = {
  status: "idle",
  userReportsdata: {},
  userTotalCount: {},
  isActive: "",
  isVerified: "",
  isLoading: false,
  isSuccess: false,
  isError: false,
};

export const getAllUserReports = createAsyncThunk(
  "All_Users_report_get",
  async (data) => {
    let response = await ApiHelperFunction({
      urlPath: "admin/all-type-wise-reports",
      method: "POST",
      data: data,
    });
    console.log("user_details", response);

    if (response.status === 200) {
      return response.data;
    } else {
      toast.error(response?.response?.data?.message);
    }
  }
);

export const getSingleUserReports = createAsyncThunk(
  "Single_Users_report_get",
  async (id) => {
    let response = await ApiHelperFunction({
      urlPath: `admin/single-user-details/${id}`,
      method: "GET",
    });
    console.log("user_details", response);

    if (response.status === 200) {
      return response.data;
    } else {
      toast.error(response?.response?.data?.message);
    }
  }
);

// export const userStatusChange = createAsyncThunk(
//   "user_status_update",
//   async ({ id, data }) => {
//     console.log("updatedItem", data);
//     let response = await ApiHelperFunction({
//       urlPath: `admin/update-user-activeStatus/${id}`,
//       method: "PUT",
//       data: data,
//     });
//     console.log("Ststus_change_details", response);

//     if (response.status === 200) {
//       toast.success("Status Change Successfully");
//       return response.data;
//     } else {
//       toast.error("Can't Update data. Something went wrong");
//     }
//   }
// );

// export const userIdProffChange = createAsyncThunk(
//   "user_idProff_update",
//   async ({ id, data }) => {
//     console.log("update_id_proff", id, data);
//     let response = await ApiHelperFunction({
//       urlPath: `admin/update-user-document-approve-status/${id}`,
//       method: "POST",
//       data: data,
//     });
//     console.log("Ststus_change_details", response);

//     if (response.status === 200) {
//       toast.success("id approval Status Change Successfully");
//       // return response.data;
//     } else {
//       toast.error("Can't Update data. Something went wrong");
//     }
//   }
// );

// export const getAllUserReportsCount = createAsyncThunk("All_User_Count", async () => {

//   let response = await ApiHelperFunction({
//     urlPath: "admin/user-registration-analize-reports",
//     method: "GET",

//   });
//   console.log("user_details", response);

//   if (response.status === 200) {
//     return response.data;
//   } else {
//     toast.error("Can't get data. Something went wrong");
//   }
// });

export const UserReportSlice = createSlice({
  name: "User_report_details_data",
  initialState,
  reducers: {
    clearState: (state) => {
      state.status = "idle";
      state.userReportsdata = {};
      state.userTotalCount = {};
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
    },
  },
  extraReducers: (builder) => {
    builder

      .addCase(getAllUserReports.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(getAllUserReports.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.isSuccess = true;
        state.isLoading = false;
        console.log("payload", payload?.data);
        state.userReportsdata = payload?.data;
      })
      .addCase(getAllUserReports.rejected, (state) => {
        state.status = "failed";
        state.isError = true;
        state.isSuccess = false;
        state.isLoading = false;
      })
      .addCase(getSingleUserReports.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(getSingleUserReports.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.isSuccess = true;
        console.log("payload", payload?.data);
        state.isLoading = false;
        state.userReportsdata = payload?.data;
      })
      .addCase(getSingleUserReports.rejected, (state) => {
        state.status = "failed";
        state.isError = true;
        state.isSuccess = false;
        state.isLoading = false;
      });
    //   .addCase(userStatusChange.pending, (state) => {
    //     state.status = "loading";
    //     state.isLoading = true;
    //   })
    //   .addCase(userStatusChange.fulfilled, (state, { payload }) => {
    //     state.status = "success";
    //     state.isSuccess = true;
    //     // console.log('payload', payload?.data);
    //     state.isActive = payload?.data;
    //   })
    //   .addCase(userStatusChange.rejected, (state) => {
    //     state.status = "failed";
    //     state.isError = true;
    //     state.isSuccess = false;
    //   })
    //   .addCase(userIdProffChange.pending, (state) => {
    //     state.status = "loading";
    //     state.isLoading = true;
    //   })
    //   .addCase(userIdProffChange.fulfilled, (state, { payload }) => {
    //     state.status = "success";
    //     state.isSuccess = true;
    //     state.isVerified = payload?.data;
    //   })
    //   .addCase(userIdProffChange.rejected, (state) => {
    //     state.status = "failed";
    //     state.isError = true;
    //     state.isSuccess = false;
    //   })
    //   .addCase(getAllUserReportsCount.pending, (state) => {
    //     state.status = "loading";
    //     state.isLoading = true;
    //   })
    //   .addCase(getAllUserReportsCount.fulfilled, (state, { payload }) => {
    //     state.status = "success";
    //     state.isSuccess = true;
    //     state.userTotalCount = payload?.data;
    //   })
    //   .addCase(getAllUserReportsCount.rejected, (state) => {
    //     state.status = "failed";
    //     state.isError = true;
    //     state.isSuccess = false;
    //   })
  },
});

export const { clearState } = UserReportSlice.actions;

export default UserReportSlice.reducer;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { ApiHelperFunction, fileUpload } from "../../utils/HttpClient";

let initialState = {
  status: "idle",
  complexionIcon: "",
  complexionData: null,
  isLoading: false,
  isSuccess: false,
  isError: false,
};

export const uploadComplexionIcon = createAsyncThunk(
  "upload_complexion_image",
  async (data) => {
    // console.log("complexionIcon", data);
    let response = await fileUpload({
      urlPath: "admin/upload-complexionImage",
      method: "POST",
      data: data,
    });
    console.log("Complexion_image", response);

    if (response.status === 200) {
      toast.success("Complexion Image Uploaded successfully");
      return response.data;
    } else {
      toast.error(response?.response?.data?.error);
    }
  }
);

export const PostComplexiondetails = createAsyncThunk(
  "add_Complexion_data",
  async (data) => {
    // console.log("diet data", data);
    let response = await ApiHelperFunction({
      urlPath: "admin/add-complexion",
      method: "POST",
      data: data,
    });
    console.log("Diet_Data", response);

    if (response.status === 200) {
      toast.success("Complexion Type Added Successfully");
      return response.data;
    } else {
      console.log("posrerror", response);
      toast.error(response?.response?.data?.message);
    }
  }
);

export const GetComplexiondetails = createAsyncThunk(
  "get_Complexion_data",
  async () => {
    let response = await ApiHelperFunction({
      urlPath: "admin/view-complexion",
      method: "GET",
    });
    console.log("Diet_Data", response);

    if (response.status === 200) {
      return response.data;
    } else {
      toast.error(response?.response?.data?.message);
    }
  }
);

export const UpdateComplexiondetails = createAsyncThunk(
  "Update_complexion_data",
  async ({ id, data }) => {
    console.log("diet data", data);
    let response = await ApiHelperFunction({
      urlPath: `admin/edit-complexion/${id}`,
      method: "PUT",
      data: data,
    });
    console.log("Diet_Data", response);

    if (response.status === 200) {
      toast.success("Complexion Type Updated Successfully");
      return response.data;
    } else {
      toast.error(response?.response?.data?.message);
    }
  }
);

export const DeleteComplexiondetails = createAsyncThunk(
  "delete_complexion_data",
  async (id) => {
    let response = await ApiHelperFunction({
      urlPath: `admin/delete-complexion/${id}`,
      method: "PUT",
    });
    console.log("Diet_Data", response);

    if (response.status === 200) {
      toast.success("Complexion Type Deleted successfully");
      return response.data;
    } else {
      toast.error(response?.response?.data?.message);
    }
  }
);

export const ComplexionSlice = createSlice({
  name: "Complexion_details_data",
  initialState,

  reducers: {
    clearState: (state) => {
      state.status = "idle";
      state.complexionIcon = "";
      state.complexionData = null;
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(uploadComplexionIcon.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(uploadComplexionIcon.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.status = "success";
        state.isSuccess = true;
        console.log("complexionIcon", payload);
        state.complexionIcon = payload.url;
      })
      .addCase(uploadComplexionIcon.rejected, (state) => {
        state.isLoading = false;
        state.status = "failed";
        state.isError = true;
        state.isSuccess = false;
      })
      .addCase(PostComplexiondetails.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(PostComplexiondetails.fulfilled, (state) => {
        state.isLoading = false;
        state.status = "success";
        state.isSuccess = true;
      })
      .addCase(PostComplexiondetails.rejected, (state) => {
        state.isLoading = false;
        state.status = "failed";
        state.isError = true;
        state.isSuccess = false;
      })
      .addCase(GetComplexiondetails.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(GetComplexiondetails.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.status = "success";
        state.isSuccess = true;
        state.complexionData = payload.data;
      })
      .addCase(GetComplexiondetails.rejected, (state) => {
        state.isLoading = false;
        state.status = "failed";
        state.isError = true;
        state.isSuccess = false;
      })
      .addCase(UpdateComplexiondetails.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(UpdateComplexiondetails.fulfilled, (state) => {
        state.isLoading = false;
        state.status = "success";
        state.isSuccess = true;
      })
      .addCase(UpdateComplexiondetails.rejected, (state) => {
        state.isLoading = false;
        state.status = "failed";
        state.isError = true;
        state.isSuccess = false;
      })
      .addCase(DeleteComplexiondetails.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(DeleteComplexiondetails.fulfilled, (state) => {
        state.status = "success";
        state.isSuccess = true;
      })
      .addCase(DeleteComplexiondetails.rejected, (state) => {
        state.status = "failed";
        state.isError = true;
        state.isSuccess = false;
      });
  },
});

export const { clearState } = ComplexionSlice.actions;

export default ComplexionSlice.reducer;

import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { useDispatch, useSelector } from "react-redux";
import { GetSubCommunityCount } from "../../redux/AdminSlice/SubCommunitySlice";

const SubCommunityCount = () => {
  const dispatch = useDispatch();
  const getSubCommunityCount = useSelector(
    (state) => state.subCommunityReducer.subCommunityCount
  );
  console.log("getSubCommunityCount", getSubCommunityCount);

  const [chartState, setChartState] = useState({
    series: [],
    options: {
      chart: {
        type: "donut",
      },
      plotOptions: {
        pie: {
          customScale: 0.8,
          size: "60%"
        }
      },
      legend: {
        position: 'top'
      },
      labels: [],
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
  });

  useEffect(() => {
    if (getSubCommunityCount && getSubCommunityCount.length > 0) {
      const SubCommunityCount = getSubCommunityCount.map((data) => data.count);
      console.log("SubCommunityCount", SubCommunityCount)
      setChartState((prevState) => ({
        ...prevState,
        series: SubCommunityCount,
      }));
      
      const subCommunityName = getSubCommunityCount.map((data) => data.subCommunityName);
      console.log("subCommunityName", subCommunityName)
      setChartState((prevState) => ({
        ...prevState,
        options: {
            ...prevState.options,
            labels: subCommunityName,
          },
      }));
   
    }
  }, [getSubCommunityCount]);

  useEffect(() => {
    dispatch(GetSubCommunityCount());
  }, []);


  return (
    <>
      <section className="piechartsBox_area">
        <div id="chart">
          <ReactApexChart
            options={chartState.options}
            series={chartState.series}
            type="donut"
          />
        </div>
      </section>
    </>
  );
};

export default SubCommunityCount;

import React, { useEffect, useState } from "react";
import ManageContact from "../contactUs/ManageContact";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { PostContactData } from "../../../redux/AdminSlice/ContactSlice";
import { UpdateContactData } from "../../../redux/AdminSlice/ContactSlice";
import { getContactData } from "../../../redux/AdminSlice/ContactSlice";
import CustomLoader from "../../../Loader/CustomLoader";

const AddContactUS = () => {
  const initial = {
    addressLineOne: "",
    addressLineTwo: "",
    country: "",
    city: "",
    state: "",
    pincode: "",
    email: "",
    phone: "",
  };

  const navigate = useNavigate();

  const [addressDetails, setAddressdetails] = useState(initial);
  const dispatch = useDispatch();

  const { contactData, isLoading } = useSelector(
    (state) => state.contactReducer
  );
  console.log("contactData", contactData);

  useEffect(() => {
    if (contactData) {
      setAddressdetails({
        ...addressDetails,
        addressLineOne: contactData?.[0]?.addressLineOne,
        addressLineTwo: contactData?.[0]?.addressLineTwo,
        country: contactData?.[0]?.country,
        city: contactData?.[0]?.city,
        state: contactData?.[0]?.state,
        pincode: contactData?.[0]?.pincode,
        email: contactData?.[0]?.email,
        phone: contactData?.[0]?.phone,
      });
    }
  }, []);

  useEffect(() => {
    dispatch(getContactData());
  }, []);

  const handleChange = (e) => {
    setAddressdetails({
      ...addressDetails,
      [e.target.name]: e.target.value,
    });
  };

  const validation = () => {
    if (addressDetails.addressLineOne === "") {
      toast.error("Address One is required");
      return false;
    }
    if (addressDetails.addressLineTwo === "") {
      toast.error("Address Two is required");
      return false;
    }
    if (addressDetails.country === "") {
      toast.error("Country is required");
      return false;
    }
    if (addressDetails.city === "") {
      toast.error("City is required");
      return false;
    }
    if (addressDetails.state === "") {
      toast.error("State is required");
      return false;
    }
    if (addressDetails.pincode === "") {
      toast.error("pinCode is required");
      return false;
    }
    if (addressDetails.email === "") {
      toast.error("email is required");
      return false;
    }
    if (addressDetails.phone === "") {
      toast.error("phone is required");
      return false;
    }
    if (addressDetails.phone.length < 10) {
      toast.error("Enter 10 digit phone number");
      return false;
    }

    return true;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validation()) {
      let data = {
        addressLineOne: addressDetails.addressLineOne,
        addressLineTwo: addressDetails.addressLineTwo,
        country: addressDetails.country,
        city: addressDetails.city,
        state: addressDetails.state,
        pincode: addressDetails.pincode,
        email: addressDetails.email,
        phone: addressDetails.phone,
      };
      console.log("contact", data);

      if (contactData) {
        const id = contactData?.[0]?._id;
        dispatch(UpdateContactData({ id, data })).then(() => {
          dispatch(getContactData());
        });
      } else {
        dispatch(PostContactData(data));
      }
    }
  };
  return (
    <>
      <div className="main_wrap">
        <CustomLoader loading={isLoading} />
        <div className="contauctUs_page">
          <h4 className="Pageheadline">
            {contactData ? "Edit Address" : "Add Address"}
          </h4>
          <form className="row m-0" onSubmit={(e) => handleSubmit(e)}>
            {/* address line 1 */}
            <div class="form-group col-md-12 col-12">
              <label for="AddressLine1">Address Line 1</label>
              <input
                type="text"
                class="form-control"
                id="AddressLine1"
                placeholder="Enter address 1"
                name="addressLineOne"
                value={addressDetails.addressLineOne}
                onChange={(e) => handleChange(e)}
              />
            </div>
            {/* address line 2 */}
            <div class="form-group col-md-12 col-12">
              <label for="AddressLine2">Address Line 2</label>
              <input
                type="text"
                class="form-control"
                id="AddressLine2"
                placeholder="Enter Address 2"
                name="addressLineTwo"
                value={addressDetails.addressLineTwo}
                onChange={(e) => handleChange(e)}
              />
            </div>
            {/* country */}
            <div class="form-group col-md-4 col-12">
              <label for="CountryText">Country</label>
              <input
                type="text"
                class="form-control"
                id="CountryText"
                placeholder="Enter Country"
                name="country"
                value={addressDetails.country}
                onChange={(e) => handleChange(e)}
              />
            </div>
            {/* city */}
            <div class="form-group col-md-4 col-12">
              <label for="CityText">City</label>
              <input
                type="text"
                class="form-control"
                id="CityText"
                placeholder="Enter city"
                name="city"
                value={addressDetails.city}
                onChange={(e) => handleChange(e)}
              />
            </div>
            {/* state */}
            <div class="form-group col-md-4 col-12">
              <label for="StateText">State</label>
              <input
                type="text"
                class="form-control"
                id="StateText"
                placeholder="Enter state"
                name="state"
                value={addressDetails.state}
                onChange={(e) => handleChange(e)}
              />
            </div>
            {/* pincode */}
            <div class="form-group col-md-4 col-12">
              <label for="PinCode">Pin Code</label>
              <input
                type="text"
                class="form-control"
                id="PinCode"
                placeholder="Enter Pincode"
                name="pincode"
                value={addressDetails.pincode}
                onChange={(e) => handleChange(e)}
              />
            </div>
            {/* email */}
            <div class="form-group col-md-4 col-12">
              <label for="email">Email</label>
              <input
                type="email"
                class="form-control"
                id="email"
                placeholder="Enter email"
                name="email"
                value={addressDetails.email}
                onChange={(e) => handleChange(e)}
              />
            </div>
            {/* phone no */}
            <div class="form-group col-md-4 col-12">
              <label for="phone">Phone No.</label>
              <input
                type="text"
                class="form-control"
                id="phone"
                placeholder="Enter Phone No"
                name="phone"
                value={addressDetails.phone}
                onChange={(e) => handleChange(e)}
              />
            </div>

            <div className="col-md-12 col-12 m-auto">
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <button className="btn btn-primary">Submit</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default AddContactUS;

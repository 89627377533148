import React from "react";
import { Outlet } from "react-router-dom";
import { Navigate } from "react-router-dom";

const PrivateRoutes = () => {

    const token = localStorage.getItem("userType")
    console.log("auth", token)
    return token ? <Outlet /> : <Navigate to={"/login"} />;

}

export default PrivateRoutes
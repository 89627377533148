import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Table from "../Table/Table";
import { toast } from "react-toastify";
import { GetSubCommunity, getSubCommunityReport } from "../../redux/AdminSlice/SubCommunitySlice";

const SubsubCommunityReport = () => {
  const initial = {
    fromDate: "",
    toDate: "",
    subCommunityName: "",
  };
  const [subCommunity, setSubsubCommunity] = useState(initial);
  const dispatch = useDispatch();

  const [searchText, setsearchText] = useState("");

  const subCommunityReportDetails = useSelector(
    (state) => state.subCommunityReducer?.SubCommunityReportData
  );

  const AllsubCommunity = useSelector(
    (state) => state.subCommunityReducer?.SubCommunityData
  );
  console.log("rrttrrtt", AllsubCommunity);

  useEffect(() => {
    dispatch(getSubCommunityReport());
    dispatch(GetSubCommunity());
  }, []);

  const filterData = useMemo(() => {
    if (searchText !== "") {
      return subCommunityReportDetails?.filter((item) =>
        JSON.stringify(item).toLowerCase().includes(searchText.toLowerCase())
      );
    }
    return subCommunityReportDetails;
  }, [searchText, subCommunityReportDetails]);

  const changehandler = (e) => {
    setSubsubCommunity({
      ...subCommunity,
      [e.target.name]: e.target.value,
    });
  };

  const validation = () => {
    if (subCommunity.fromDate === "") {
      toast.error("From date is required");
      return false;
    }
    if (subCommunity.toDate === "") {
      toast.error("To date is required");
      return false;
    }

    return true;
  };

  const submitHandler = (e) => {
    e.preventDefault();
    if (validation()) {
      let data = {
        fromDate: subCommunity.fromDate,
        toDate: subCommunity.toDate,
        subCommunityName: subCommunity.subCommunityName,
      };

      dispatch(getSubCommunityReport(data));
    }
  };

  const tableData = useMemo(() => {
    return {
      columns: [
        {
          label: "SL",
          field: "sl",
          sort: "asc",
          width: 270,
        },
        {
          label: "Registration Date",
          field: "regdate",
          sort: "asc",
          width: 150,
        },
        {
          label: "SubCommunity Name",
          field: "subCommunityType",
          sort: "asc",
          width: 150,
        },
        {
          label: "User Name",
          field: "username",
          sort: "asc",
          width: 150,
        },
        {
          label: "User Email",
          field: "useremail",
          sort: "asc",
          width: 150,
        },
        {
          label: "Contact No.",
          field: "contact",
          sort: "asc",
          width: 150,
        },
      ],
      rows:
        filterData?.length > 0
          ? filterData.map((item, idx) => {
              console.log("xsxsxs", item);
              return {
                sl: idx + 1,
                regdate: (
                  <div className="widget-content p-0" key={idx}>
                    <div className="widget-content-wrapper">
                      <div className="widget-content-left flex2">
                        <div className="widget-heading" style={{ opacity: 1 }}>
                          {item.date}
                        </div>
                      </div>
                    </div>
                  </div>
                ),
                subCommunityType: (
                  <div className="widget-content p-0" key={idx}>
                    <div className="widget-content-wrapper">
                      <div className="widget-content-left flex2">
                        <div className="widget-heading" style={{ opacity: 1 }}>
                          {item?.personalDetails?.personalInfo?.subCommunity}
                        </div>
                      </div>
                    </div>
                  </div>
                ),
                username: (
                  <div className="widget-content p-0" key={idx}>
                    <div className="widget-content-wrapper">
                      <div className="widget-content-left flex2">
                        <div className="widget-heading" style={{ opacity: 1 }}>
                          {item?.firstName + " " + item?.lastName}
                        </div>
                      </div>
                    </div>
                  </div>
                ),
                useremail: (
                  <div className="widget-content p-0" key={idx}>
                    <div className="widget-content-wrapper">
                      <div className="widget-content-left flex2">
                        <div className="widget-heading" style={{ opacity: 1 }}>
                          {item.email}
                        </div>
                      </div>
                    </div>
                  </div>
                ),
                contact: (
                  <div className="widget-content p-0" key={idx}>
                    <div className="widget-content-wrapper">
                      <div className="widget-content-left flex2">
                        <div className="widget-heading" style={{ opacity: 1 }}>
                          {item.mobile}
                        </div>
                      </div>
                    </div>
                  </div>
                ),
              };
            })
          : [],
    };
  });

  return (
    <>
      <div className="main_wrap">
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <h4 className="Pageheadline">Sub Community Wise Report</h4>
        </div>
        <div className="searchDiv">
          <form
            action=""
            className="row m-auto"
            onSubmit={(e) => submitHandler(e)}
          >
            <div className="col-md-3">
              <label htmlFor="fromDate">Form Date</label>
              <input
                type="date"
                className="form-control"
                name="fromDate"
                value={subCommunity.fromDate}
                onChange={(e) => changehandler(e)}
              />
            </div>
            <div className="col-md-3">
              <label htmlFor="toDate">To Date</label>
              <input
                type="date"
                className="form-control"
                name="toDate"
                value={subCommunity.toDate}
                min={subCommunity.fromDate}
                onChange={(e) => changehandler(e)}
              />
            </div>
            <div className="col-md-4">
              <label htmlFor="">Sub Community Name</label>
              <select
                class="form-select table_page_select"
                aria-label="Default select example"
                onChange={(e) => changehandler(e)}
                name="subCommunityName"
                value={subCommunity.subCommunityName}
              >
                {" "}
                <option selected value="">
                  Select SubCommunity Name
                </option>
                {AllsubCommunity?.map((item) => {
                  return <option value={item?.name}>{item?.name}</option>
                })}
              </select>
            </div>

            <button type="submit" className="btn btn-primary table_btn">
              Search
            </button>
          </form>
        </div>
        <div style={{ overflowX: "auto" }} className="dataTableParDiv">
          <Table
            striped
            tableTitle="All abouts"
            data={tableData}
            onSearch={(e) => setsearchText(e.target.value)}
          />
        </div>
      </div>
    </>
  );
};

export default SubsubCommunityReport;

import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  GetQualification,
  PostQualification,
  UpdateQualification,
  clearState,
} from "../../../redux/AdminSlice/QualificationSlice";

const AddHighestQualification = () => {
  let initial = {
    name: "",
  };

  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [qualification, SetQualification] = useState(initial);

  const changehandler = (e) => {
    SetQualification((prevqualification) => ({
      ...prevqualification,
      name: e.target.value,
    }));
  };

  useEffect(() => {
    if (location.state) {
      SetQualification({
        name: location.state.name,
      });
    }
  }, []);

  const submitHandler = (e) => {
    e.preventDefault();
    if (qualification.name === "") {
      toast.error("Highest Qualification Is required");
      return false;
    }
    let data = {
      name: qualification.name,
    };

    if (location.state) {
      const id = location.state._id;
      dispatch(UpdateQualification({ id, data })).then(() => {
        SetQualification(initial);
        dispatch(clearState());
        dispatch(GetQualification());
        navigate("/managequalification");
      });
    } else {
      dispatch(PostQualification(data)).then(() => {
        SetQualification(initial);
        dispatch(clearState());
      });
    }
  };
  return (
    <div className="main_wrap">
      <div className="aboutUs_page">
        <h4 className="Pageheadline">
          {location.state
            ? "Edit Highest Qualification"
            : "Add Highest Qualification"}
        </h4>
        <form action="" onSubmit={(e) => submitHandler(e)}>
          <div className="row">
            <div className="col-md-6 col-12">
              <label htmlFor="">Highest Qualification</label>
              <input
                type="text"
                placeholder="Enter Highest Qualification"
                className="form-control"
                name="name"
                value={qualification.name}
                onChange={(e) => changehandler(e)}
              />
            </div>
            <div
              className="col-md-6 col-12"
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "end",
              }}
            >
              {location.state ? (
                <button type="submit" className="btn btn-primary">
                  Submit
                </button>
              ) : (
                <button type="submit" className="btn btn-primary">
                  Add
                </button>
              )}
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddHighestQualification;

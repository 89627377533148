import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

// Helpers
import { ApiHelperFunction } from "../../utils/HttpClient";

let initialState = {
  status: "idle",
  MaritalStatusData: null,
  isLoading: false,
  isSuccess: false,
  isError: false,
};

export const PostMaritalStatus = createAsyncThunk(
  "Marital_Status_post",
  async (data) => {
    let response = await ApiHelperFunction({
      urlPath: "admin/add-martial-status",
      method: "POST",
      data: data,
    });
    console.log("marital_status_response", response);

    if (response.status === 200) {
      toast.success("Marital Status added successfully");
      return response.data;
    } else {
      toast.error(response?.response?.data?.message);
    }
  }
);

export const GetMaritalStatus = createAsyncThunk(
  "Marital_Status_get",
  async () => {
    let response = await ApiHelperFunction({
      urlPath: "admin/view-martial-status",
      method: "GET",
    });
    // console.log("Comminuty_details_get", response);

    if (response.status === 200) {
      return response.data;
    } else {
      toast.error(response?.response?.data?.message);
    }
  }
);

export const UpdateMaritalStatus = createAsyncThunk(
  "Marital_Status_update",
  async ({ id, data }) => {
    console.log("updatedItem", data);
    let response = await ApiHelperFunction({
      urlPath: `admin/edit-martial-status/${id}`,
      method: "PUT",
      data: data,
    });
    console.log("Community_Details", response);

    if (response.status === 200) {
      toast.success("Marital Status Updated Successfully");
      return response.data;
    } else {
      toast.error(response?.response?.data?.message);
    }
  }
);

export const DeleteMaritalStatus = createAsyncThunk(
  "Delete_Marital_Status",
  async (id) => {
    let response = await ApiHelperFunction({
      urlPath: `admin/delete-martial-status/${id}`,
      method: "PUT",
    });
    console.log("contact_details", response);

    if (response.status === 200) {
      toast.success("Marital Status Deleted Successfully");
      return response.data;
    } else {
      toast.error(response?.response?.data?.message);
    }
  }
);

export const MaritalSlice = createSlice({
  name: "Marital_Status_Data",
  initialState,
  reducers: {
    clearState: (state) => {
      state.status = "idle";
      state.MaritalStatusData = null;
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(PostMaritalStatus.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(PostMaritalStatus.fulfilled, (state) => {
        state.status = "success";
        state.isSuccess = true;
        // Optionally, you can update state after a successful post if needed
      })
      .addCase(PostMaritalStatus.rejected, (state) => {
        state.status = "failed";
        state.isError = true;
        state.isSuccess = false;
      })
      .addCase(GetMaritalStatus.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(GetMaritalStatus.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.status = "success";
        state.isSuccess = true;
        // console.log("payload", payload?.data);
        state.MaritalStatusData = payload?.data;
      })
      .addCase(GetMaritalStatus.rejected, (state) => {
        state.isLoading = false;
        state.status = "failed";
        state.isError = true;
        state.isSuccess = false;
      })
      .addCase(UpdateMaritalStatus.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(UpdateMaritalStatus.fulfilled, (state) => {
        state.status = "success";
        state.isSuccess = true;
      })
      .addCase(UpdateMaritalStatus.rejected, (state) => {
        state.status = "failed";
        state.isError = true;
        state.isSuccess = false;
      })
      .addCase(DeleteMaritalStatus.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(DeleteMaritalStatus.fulfilled, (state) => {
        state.status = "success";
        state.isSuccess = true;
      })
      .addCase(DeleteMaritalStatus.rejected, (state) => {
        state.status = "failed";
        state.isError = true;
        state.isSuccess = false;
      });
  },
});

export const { clearState } = MaritalSlice.actions;

export default MaritalSlice.reducer;

import React, { useEffect, useMemo, useState } from "react";
import Table from "../../Table/Table";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  DeleteSubCommunity,
  GetSubCommunity,
} from "../../../redux/AdminSlice/SubCommunitySlice";
import { DeleteConfirmModal } from "../../../DeleteAlert/DeleteAlert";
import { getProfileDetails } from "../../../redux/AdminSlice/profileSlice";

const ManageSubCommunity = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchText, setsearchText] = useState("");
  const token = localStorage.getItem("Authorization");
  const { profile_data } = useSelector((state) => state.profileReducer);

  const subCommunityData = useSelector(
    (state) => state.subCommunityReducer.SubCommunityData
  );
  // console.log("subCommunityData", subCommunityData);

  useEffect(() => {
    dispatch(GetSubCommunity());
    dispatch(getProfileDetails(token));
  }, []);

  const deleteProfile = (id) => {
    const del = async () => {
      dispatch(DeleteSubCommunity(id)).then(() => {
        dispatch(GetSubCommunity());
      });
    };

    DeleteConfirmModal(del);
  };

  const filterData = useMemo(() => {
    if (searchText !== "") {
      return subCommunityData?.filter((item) =>
        JSON.stringify(item).toLowerCase().includes(searchText.toLowerCase())
      );
    }
    return subCommunityData;
  }, [searchText, subCommunityData]);

  const handleView = (e, item, id) => {
    e.preventDefault();
    navigate("/addSubCommunity", { state: item });
  };

  const tableData = useMemo(() => {
    return {
      columns: [
        {
          label: "SL",
          field: "sl",
          sort: "asc",
          width: 270,
        },
        {
          label: "Religion",
          field: "religion",
          sort: "asc",
          width: 150,
        },
        {
          label: "Community",
          field: "community",
          sort: "asc",
          width: 150,
        },
        {
          label: "Sub Community",
          field: "subcommunity",
          sort: "asc",
          width: 150,
        },
        {
          label: "Action",
          field: "action",
          sort: "asc",
          width: 150,
        },
      ],
      rows:
        filterData?.length > 0
          ? filterData.map((item, idx) => {
              return {
                sl: idx + 1,
                religion: (
                  <div className="widget-content p-0" key={idx}>
                    <div className="widget-content-wrapper">
                      <div className="widget-content-left flex2">
                        <div className="widget-heading" style={{ opacity: 1 }}>
                          {item.religionName}
                        </div>
                      </div>
                    </div>
                  </div>
                ),
                community: (
                  <div className="widget-content p-0" key={idx}>
                    <div className="widget-content-wrapper">
                      <div className="widget-content-left flex2">
                        <div className="widget-heading" style={{ opacity: 1 }}>
                          {item.communityName}
                        </div>
                      </div>
                    </div>
                  </div>
                ),
                subcommunity: (
                  <div className="widget-content p-0" key={idx}>
                    <div className="widget-content-wrapper">
                      <div className="widget-content-left flex2">
                        <div className="widget-heading" style={{ opacity: 1 }}>
                          {item.name}
                        </div>
                      </div>
                    </div>
                  </div>
                ),
                action: (
                  <div className="widget-content p-0" key={idx}>
                    <div className="widget-content-wrapper">
                      <div className="widget-content-center flex2">
                        {profile_data?.tabPermission?.[0]
                          ?.manageSubCommunityEdit && (
                          <i
                            class="fa-solid fa-pencil"
                            style={{ color: "blue", cursor: "pointer" }}
                            onClick={(e) => handleView(e, item, item._id)}
                          ></i>
                        )}

                        {profile_data?.tabPermission?.[0]
                          ?.manageSubCommunityDelete && (
                          <i
                            class="fa-solid fa-trash-can"
                            style={{
                              color: "red",
                              marginLeft: "15px",
                              cursor: "pointer",
                            }}
                            onClick={() => deleteProfile(item._id)}
                          ></i>
                        )}
                      </div>
                    </div>
                  </div>
                ),
              };
            })
          : [],
    };
  });
  return (
    <>
      <div className="main_wrap">
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <h4 className="Pageheadline">Manage Sub Community</h4>
          {profile_data?.tabPermission?.[0]?.addSubCommunity && (
            <button
              className="btn btn-primary"
              style={{ height: "33px" }}
              onClick={() => navigate("/addSubCommunity")}
            >
              Add
            </button>
          )}
        </div>
        <div style={{ overflowX: "auto" }} className="dataTableParDiv">
          <Table
            striped
            tableTitle="All abouts"
            data={tableData}
            onSearch={(e) => setsearchText(e.target.value)}
          />
        </div>
      </div>
    </>
  );
};

export default ManageSubCommunity;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { getCountry } from "../../../redux/AdminSlice/CountrySlice";
import {
  GetCity,
  PostCity,
  UpdateCity,
  UploadCityImage,
  clearState,
} from "../../../redux/AdminSlice/CitySlice";
import { getStateByCountryId } from "../../../redux/AdminSlice/StateSlice";

const AddCity = () => {
  let initial = {
    name: "",
    countryId: "",
    image: "",
    stateId: "",
  };
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [city, setCity] = useState(initial);

  const { countrydata } = useSelector((state) => state.countryReducer);
  const { stateDataByCountry } = useSelector((state) => state.stateReducer);
  const { cityimage } = useSelector((state) => state.cityReducer);

  console.log("city", city);

  const changehandler = (e) => {
    if (e.target.name === "countryId") {
      dispatch(getStateByCountryId(e.target.value));
    }

    setCity((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const Imagehandler = (e) => {
    let file = e.target.files;
    for (let element of file) {
      let data = new FormData();
      data.append("image", element);
      dispatch(UploadCityImage(data));
    }
  };

  useEffect(() => {
    console.log("location_state", location.state);
    if (location.state) {
      setCity({
        name: location.state?.name,
        image: location.state?.image,
        stateId: location.state?.stateId,
        countryId: location.state?.countryId,
      });
    }
    if (cityimage) {
      setCity((prevstate) => ({
        ...prevstate,
        image: cityimage,
      }));
    }
  }, [cityimage]);

  const submitHandler = (e) => {
    e.preventDefault();
    if (city.countryId === "") {
      toast.error("Country Name Is Required");
      return false;
    }
    if (city.stateId === "") {
      toast.error("State Name Is Required");
      return false;
    }
    if (city.name === "") {
      toast.error("City Name Is Required");
      return false;
    }
    // if (city.image === "") {
    //   toast.error("City image Is Required");
    //   return false;
    // }
    let data = {
      name: city.name,
      image: city.image ? city.image : cityimage,
      stateId: city.stateId,
    };

    if (location.state) {
      const id = location.state._id;
      dispatch(UpdateCity({ id, data }));
      setCity(initial);
      dispatch(clearState());
      dispatch(GetCity());
      navigate("/manageCity");
    } else {
      dispatch(PostCity(data));
      setCity(initial);
      dispatch(clearState());
    }
  };

  useEffect(() => {
    dispatch(getCountry());
  }, []);

  return (
    <div className="main_wrap">
      <div className="aboutUs_page">
        <h4 className="Pageheadline">
          {location.state ? "Edit City" : "Add City"}
        </h4>
        <form action="" onSubmit={(e) => submitHandler(e)}>
          <div className="row">
            {/* Country */}
            <div className="" style={{ width: "19%", marginLeft: "10px" }}>
              <label htmlFor="religionId">Country Name</label>
              <select
                class="form-select table_page_select"
                aria-label="Default select example"
                onChange={(e) => changehandler(e)}
                name="countryId"
                value={city.countryId}
              >
                <option selected value="">
                  Select Country
                </option>

                {countrydata?.map((item) => {
                  return <option value={item?._id}>{item?.name}</option>;
                })}
              </select>
            </div>

            {/* state */}
            <div className="" style={{ width: "19%", marginLeft: "10px" }}>
              <label htmlFor="religionId">State Name</label>
              <select
                class="form-select table_page_select"
                aria-label="Default select example"
                onChange={(e) => changehandler(e)}
                name="stateId"
                value={city.stateId}
              >
                <option selected value="">
                  Select State
                </option>
                {stateDataByCountry?.map((item) => {
                  return <option value={item?._id}>{item?.name}</option>;
                })}
              </select>
            </div>

            {/* city */}
            <div className="col-md-3 col-12">
              <label htmlFor="">City</label>
              <input
                type="text"
                name="name"
                className="form-control"
                placeholder="Enter State Name"
                value={city.name}
                onChange={(e) => changehandler(e)}
              />
            </div>

            {/* image */}
            <div className="col-md-3 col-12">
              <div>
                {" "}
                <label htmlFor=""> City image</label>
                <input
                  type="file"
                  className="form-control"
                  name="image"
                  onChange={(e) => Imagehandler(e)}
                  accept="image/*"
                />
              </div>
            </div>

            <div
              className="col-md-1 col-12"
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "end",
              }}
            >
              {location.state ? (
                <button type="submit" className="btn btn-primary">
                  Submit
                </button>
              ) : (
                <button type="submit" className="btn btn-primary">
                  Add
                </button>
              )}
            </div>
          </div>
          {cityimage ? (
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                marginTop: "5px",
              }}
            >
              <img
                style={{ height: "100px", width: "100px", marginLeft: "45%" }}
                src={cityimage}
                alt=""
              />
              <i
                class="fa-solid fa-xmark"
                style={{ cursor: "pointer" }}
                onClick={() => dispatch(clearState())}
              ></i>
            </div>
          ) : location.state ? (
            <div
              style={{
                width: "77%",
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "5px",
              }}
            >
              <img
                style={{ height: "100px", width: "100px", marginLeft: "100px" }}
                src={city.image}
                alt=""
              />
            </div>
          ) : (
            ""
          )}
        </form>
      </div>
    </div>
  );
};

export default AddCity;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

// Helpers
import { ApiHelperFunction } from "../../utils/HttpClient";

let initialState = {
  status: "idle",
  HeightData: null,
  isLoading: false,
  isSuccess: false,
  isError: false,
};

export const PostHeight = createAsyncThunk(
  "Height_post",
  async (data) => {
    let response = await ApiHelperFunction({
      urlPath: "admin/add-height",
      method: "POST",
      data: data,
    });
    console.log("profile_isFor_details", response);

    if (response.status === 200) {
      toast.success("Height added successfully");
      return response.data;
    } else {
      toast.error("Can't add data. Something went wrong");
    }
  }
);

export const GetHeight = createAsyncThunk(
  "Height_get",
  async () => {
    let response = await ApiHelperFunction({
      urlPath: "admin/view-height",
      method: "GET",
    });
    // console.log("Comminuty_details_get", response);

    if (response.status === 200) {
      return response.data;
    } else {
      toast.error("Can't get data. Something went wrong");
    }
  }
);

export const UpdateHeight = createAsyncThunk(
  "Height_update",
  async ({ id, data }) => {
    console.log("updatedItem", data);
    let response = await ApiHelperFunction({
      urlPath: `admin/edit-height/${id}`,
      method: "PUT",
      data: data,
    });
    console.log("Community_Details", response);

    if (response.status === 200) {
      toast.success("Height Updated Successfully");
      return response.data;
    } else {
      toast.error("Can't Update data. Something went wrong");
    }
  }
);

export const DeleteHeight = createAsyncThunk(
  "Height_Delete",
  async (id) => {
    let response = await ApiHelperFunction({
      urlPath: `admin/delete-height/${id}`,
      method: "PUT",
    });
    console.log("contact_details", response);

    if (response.status === 200) {
      toast.success("Height Deleted Successfully");
      return response.data;
    } else {
      toast.error("Can't Deleted data. Something went wrong");
    }
  }
);

export const HeightSlice = createSlice({
  name: "Height_details_data",
  initialState,
  reducers: {
    clearState: (state) => {
      state.status = "idle";
      state.HeightData = null;
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(PostHeight.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(PostHeight.fulfilled, (state) => {
        state.status = "success";
        state.isSuccess = true;
        // Optionally, you can update state after a successful post if needed
      })
      .addCase(PostHeight.rejected, (state) => {
        state.status = "failed";
        state.isError = true;
        state.isSuccess = false;
      })
      .addCase(GetHeight.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(GetHeight.fulfilled, (state, { payload }) => {
         state.isLoading = false;
        state.status = "success";
        state.isSuccess = true;
        // console.log("payload", payload?.data);
        state.HeightData = payload?.data;
      })
      .addCase(GetHeight.rejected, (state) => {
         state.isLoading = false;
        state.status = "failed";
        state.isError = true;
        state.isSuccess = false;
      })
      .addCase(UpdateHeight.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(UpdateHeight.fulfilled, (state) => {
        state.status = "success";
        state.isSuccess = true;
      })
      .addCase(UpdateHeight.rejected, (state) => {
        state.status = "failed";
        state.isError = true;
        state.isSuccess = false;
      })
      .addCase(DeleteHeight.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(DeleteHeight.fulfilled, (state) => {
        state.status = "success";
        state.isSuccess = true;
      })
      .addCase(DeleteHeight.rejected, (state) => {
        state.status = "failed";
        state.isError = true;
        state.isSuccess = false;
      });
  },
});

export const { clearState } = HeightSlice.actions;

export default HeightSlice.reducer;

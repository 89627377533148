import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

// Helpers
import { ApiHelperFunction } from "../../utils/HttpClient";

let initialState = {
  status: "idle",
  CommunityData: null,
  communityCount: null,
  isLoading: false,
  isSuccess: false,
  isError: false,
};

export const PostCommunity = createAsyncThunk(
  "Community_post",
  async (data) => {
    let response = await ApiHelperFunction({
      urlPath: "admin/add-community",
      method: "POST",
      data: data,
    });
    console.log("profile_isFor_details", response);

    if (response.status === 200) {
      toast.success("Community Added successfully");
      return response.data;
    } else {
      toast.error(response?.response?.data?.message);
    }
  }
);

export const GetCommunity = createAsyncThunk("Community_get", async () => {
  let response = await ApiHelperFunction({
    urlPath: "admin/view-community",
    method: "GET",
  });
  console.log("Comminuty_details_get", response);

  if (response.status === 200) {
    return response.data;
  } else {
    toast.error(response?.response?.data?.message);
  }
});

export const ReligianbasedCommunity = createAsyncThunk(
  "Religion_based_Community_get",
  async (data) => {
    let response = await ApiHelperFunction({
      urlPath: `admin/view-community-byreligion-name/${data}`,
      method: "GET",
    });
    console.log("religin_based_community", response);

    if (response.status === 200) {
      return response.data;
    } else {
      toast.error(response?.response?.data?.message);
    }
  }
);

export const ReligianIdbasedCommunity = createAsyncThunk(
  "Religion_Id_based_Community_get",
  async (id) => {
    let response = await ApiHelperFunction({
      urlPath: `admin/view-community-byreligion/${id}`,
      method: "GET",
    });
    console.log("religin_based_community", response);

    if (response.status === 200) {
      return response.data;
    } else {
      toast.error(response?.response?.data?.message);
    }
  }
);

export const UpdateCommunity = createAsyncThunk(
  "Community_update",
  async ({ id, data }) => {
    console.log("updatedItem", data);
    let response = await ApiHelperFunction({
      urlPath: `admin/edit-community/${id}`,
      method: "PUT",
      data: data,
    });
    console.log("Community_Details", response);

    if (response.status === 200) {
      toast.success("Community Updated Successfully");
      return response.data;
    } else {
      toast.error(response?.response?.data?.message);
    }
  }
);

export const DeteCommunity = createAsyncThunk(
  "Community_Delete",
  async (id) => {
    let response = await ApiHelperFunction({
      urlPath: `admin/delete-community/${id}`,
      method: "PUT",
    });
    // console.log("contact_details", response);

    if (response.status === 200) {
      toast.success("Community Deleted Successfully");
      return response.data;
    } else {
      toast.error(response?.response?.data?.message);
    }
  }
);

export const GetCommunityCount = createAsyncThunk(
  "Community_count_get",
  async () => {
    let response = await ApiHelperFunction({
      urlPath: "admin/total-community-statistic-reports",
      method: "GET",
    });
    // console.log("Comminuty_details_get", response);

    if (response.status === 200) {
      return response.data;
    } else {
      toast.error(response?.response?.data?.message);
    }
  }
);

export const CommunitySlice = createSlice({
  name: "Community_count_data",
  initialState,
  reducers: {
    clearState: (state) => {
      state.status = "idle";
      state.CommunityData = null;
      state.communityCount = null;
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(PostCommunity.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(PostCommunity.fulfilled, (state) => {
        state.status = "success";
        state.isSuccess = true;
        // Optionally, you can update state after a successful post if needed
      })
      .addCase(PostCommunity.rejected, (state) => {
        state.status = "failed";
        state.isError = true;
        state.isSuccess = false;
      })
      .addCase(GetCommunity.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(GetCommunity.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.status = "success";
        state.isSuccess = true;
        console.log("Community_payload", payload?.data);
        state.CommunityData = payload?.data;
      })
      .addCase(GetCommunity.rejected, (state) => {
        state.isLoading = false;
        state.status = "failed";
        state.isError = true;
        state.isSuccess = false;
      })
      /** =======----religion name base community get-------======= */
      .addCase(ReligianbasedCommunity.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(ReligianbasedCommunity.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.status = "success";
        state.isSuccess = true;
        console.log("Religion_Community_payload", payload?.data);
        state.CommunityData = payload?.data;
      })
      .addCase(ReligianbasedCommunity.rejected, (state) => {
        state.isLoading = false;
        state.status = "failed";
        state.isError = true;
        state.isSuccess = false;
      })
      /** =======----Religion id base Sub community get-------======= */
      .addCase(ReligianIdbasedCommunity.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(ReligianIdbasedCommunity.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.status = "success";
        state.isSuccess = true;
        console.log("Religion_Community_payload", payload?.data);
        state.CommunityData = payload?.data;
      })
      .addCase(ReligianIdbasedCommunity.rejected, (state) => {
        state.isLoading = false;
        state.status = "failed";
        state.isError = true;
        state.isSuccess = false;
      })

      .addCase(UpdateCommunity.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(UpdateCommunity.fulfilled, (state) => {
        state.status = "success";
        state.isSuccess = true;
      })
      .addCase(UpdateCommunity.rejected, (state) => {
        state.status = "failed";
        state.isError = true;
        state.isSuccess = false;
      })
      .addCase(DeteCommunity.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(DeteCommunity.fulfilled, (state) => {
        state.status = "success";
        state.isSuccess = true;
      })
      .addCase(DeteCommunity.rejected, (state) => {
        state.status = "failed";
        state.isError = true;
        state.isSuccess = false;
      })

      .addCase(GetCommunityCount.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(GetCommunityCount.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.status = "success";
        state.isSuccess = true;
        state.communityCount = payload?.data;
      })
      .addCase(GetCommunityCount.rejected, (state) => {
        state.isLoading = false;
        state.status = "failed";
        state.isError = true;
        state.isSuccess = false;
      });
  },
});

export const { clearState } = CommunitySlice.actions;

export default CommunitySlice.reducer;

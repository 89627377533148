import React from "react";
import { useLocation } from "react-router-dom";

const SingleAccountPurchaseDetails = () => {
  const location = useLocation();

  console.log("drdr", location.state);

  return (
    <div className="main_wrap">
      {/* profile image */}
      <div
        style={{
          marginBottom: "20px",
          display: "flex",
          justifyContent: "left",
          alignItems: "center",
          height: "100px",
          width: "100%",
          backgroundColor: "#e9e9e9",
        }}
      >
        <img
          src={location.state?.profilePhoto?.[0]}
          alt="logo"
          style={{ height: "80px", width: "80px", borderRadius: "50%" }}
        />
        <div>
          <h6 style={{ marginLeft: "10px" }}>
            <span style={{ marginRight: "15px" }}>
              {location.state?.firstName + " " + " " + location.state?.lastName}'s
            </span>
            {""}
            Account Purchased and Sold Details
          </h6>
          <p className="mx-2">{location.state?.customizedUserId ? location.state?.customizedUserId : ""}</p>
        </div>
      </div>

      {/* account purchase and sold */}
      <div className="singlemain">
        <div className="singlemain_left">
          <div style={{ textAlign: "center", padding: "4px" }}>
            <h6>Total Account Purchased By users</h6>
            <hr
              style={{
                width: "99%",
                border: "1px solid blue",
                color: "blue",
              }}
            />
          </div>

          <div>
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">Profile</th>
                  <th scope="col">Name</th>
                  <th scope="col">Email</th>
                </tr>
              </thead>
              {location.state?.totalBuyPeofileDetails.length > 0 ? (
                location.state?.totalBuyPeofileDetails?.map((item) => {
                  console.log("buyAccount", item);
                  return (
                    <tbody>
                      <tr>
                        <td scope="row">
                          <img
                            src={item?.userDetails?.profilePhoto?.[0]}
                            alt="profile"
                            style={{
                              height: "70px",
                              width: "70px",
                              borderRadius: "50%",
                            }}
                          />
                        </td>
                        <td>
                          {item?.userDetails?.firstName +
                            " " +
                            item?.userDetails?.lastName}
                        </td>
                        <td>{item?.userDetails?.email}</td>
                      </tr>
                    </tbody>
                  );
                })
              ) : (
                <p style={{ color: "red", textAlign: "center" }}>
                  No User Found
                </p>
              )}
            </table>
          </div>
        </div>
        <div className="singlemain_right">
          <div style={{ textAlign: "center", padding: "4px" }}>
            <h6>Total Account Sold By users</h6>
            <hr
              style={{
                width: "99%",
                border: "1px solid blue",
                color: "blue",
              }}
            />
          </div>

          <div>
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">Profile</th>
                  <th scope="col">Name</th>
                  <th scope="col">Email</th>
                </tr>
              </thead>
              {location.state?.totalSoldPeofileDetails.length > 0 ? (
                location.state?.totalSoldPeofileDetails?.map((item) => {
                  console.log("fdfdf", item);
                  return (
                    <tbody>
                      <tr>
                        <td scope="row">
                          <img
                            src={item?.userDetails?.profilePhoto?.[0]}
                            alt="profile"
                            style={{
                              height: "70px",
                              width: "70px",
                              borderRadius: "50%",
                            }}
                          />
                        </td>
                        <td>
                          {item?.userDetails?.firstName +
                            " " +
                            item?.userDetails?.lastName}
                        </td>
                        <td>{item?.userDetails?.email}</td>
                      </tr>
                    </tbody>
                  );
                })
              ) : (
                <p style={{ color: "red", textAlign: "center" }}>
                  No User Found
                </p>
              )}
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleAccountPurchaseDetails;

import moment from "moment";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  clearState,
  getAllUser,
  getProfilePhotoList,
  userIdProffChange,
  userPhotoApproved,
} from "../../redux/AdminSlice/ManageUserSlice";
import { MdDelete } from "react-icons/md";
import { DeleteConfirmModal } from "../../DeleteAlert/DeleteAlert";
import dp2 from "../../Images/dp2.jpeg";
import ImageModal from "../../Component/Modal/ImageModal";

const ViewSingleUser = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const initial = {
    firstName: "",
    middleName: "",
    lastName: "",
    email: "",
    countryCode: "",
    mobile: "",
    DOB: "",
    gender: "",
    bio: "",
    profilePhoto: "",
    disapproveProfilePhoto: "",
    accountFor: "",
    isReadyToMarryOtherCaste: "",
    // personal info
    religion: "",
    community: "",
    subCommunity: "",
    caste: "",
    country: "",
    state: "",
    city: "",
    maritalStatus: "",
    diet: "",
    height: "",
    complexion: "",
    jointFamily: "",
    age: "",
    // familyInfo
    fatherName: "",
    fatherOccupation: "",
    motherName: "",
    motherOccupation: "",
    sisterName: "",
    sisterMaritalStatus: "",
    brotherName: "",
    brotherMaritalStatus: "",
    brother: [],
    sister: [],
    // careerinfo
    education: "",
    occupationType: "",
    occupationDetails: "",
    serviceType: "",
    subSector: "",
    sector: "",
    companyName: "",
    designation: "",
    income: "",
    hobbies: {},
    creativity: {},
    enjoymentActivities: {},
    //zodiacInfo
    zodiacSign: "",
    timeOfBirth: "",
    placeOfBirth: "",
    isMangalik: "",
    //id Proff
    image: {},
    //image
  };
  const [userData, setUserData] = useState(initial);
  const [allPhoto, setAllPhoto] = useState([]);
  const [deletePhoto, setDeletePhoto] = useState([]);
  const [zoom, setZoom] = useState(false);
  const [clickedImageUrl, setClickedImageUrl] = useState("");
  const locationField = location?.state?.field
  const locationState = location?.state?.ele
  const { profile_data } = useSelector((state) => state.profileReducer);
  const customisedID = locationState?.customizedUserId ? locationState?.customizedUserId : location?.state?.customizedUserId
  const ocuupation = locationField === "delete" ? locationState?.userDetails?.familyDetails?.mother?.occupation : location.state?.userDetails?.familyDetails?.mother?.occupation
  console.log("fbdzfdfgfd", location?.state, ocuupation, locationField, locationState);

  useEffect(() => {
    if (locationField === "delete") {
      setUserData((prev) => ({
        firstName: locationState?.firstName,
        middleName: locationState?.middleName,
        lastName: locationState?.lastName,
        email: locationState?.email,
        countryCode: locationState?.countryCode,
        mobile: locationState?.mobile,
        DOB: moment(locationState?.DOB).format("DD-MM-YYYY"),
        gender: locationState?.gender,
        bio: locationState?.bio,
        profilePhoto: locationState?.profilePhoto,
        disapproveProfilePhoto: locationState?.disapproveProfilePhoto,
        accountFor: locationState?.accountFor,
        isReadyToMarryOtherCaste: locationState?.isReadyToMarryOtherCaste,
        // personal info
        religion: locationState?.userDetails?.personalInfo?.religion,
        community: locationState?.userDetails?.personalInfo?.community,
        subCommunity: locationState?.userDetails?.personalInfo?.subCommunity,
        caste: locationState?.userDetails?.personalInfo?.caste,
        country: locationState?.country,
        state: locationState?.state,
        city: locationState?.city,
        maritalStatus: locationState?.userDetails?.personalInfo?.maritalStatus,
        diet: locationState?.userDetails?.personalInfo?.diet,
        height: locationState?.userDetails?.personalInfo?.height,
        complexion: locationState?.userDetails?.personalInfo?.complexion,
        jointFamily: locationState?.userDetails?.personalInfo?.jointFamily,
        age: locationState?.ownAge,
        // familyInfo
        fatherName: locationState?.userDetails?.familyDetails?.father?.name,
        fatherOccupation:
          locationState?.userDetails?.familyDetails?.father?.occupation,
        motherName: locationState?.userDetails?.familyDetails?.mother?.name,
        motherOccupation:
          locationState?.userDetails?.familyDetails?.mother?.occupation,
        sisterName: locationState?.userDetails?.familyDetails?.sister?.name,
        sisterMaritalStatus:
          locationState?.userDetails?.familyDetails?.sister?.maritalStatus,
        brotherName: locationState?.userDetails?.familyDetails?.brother?.name,
        brotherMaritalStatus:
          locationState?.userDetails?.familyDetails?.brother?.maritalStatus,
        // // careerinfo
        education: locationState?.userDetails?.highestQualification,
        occupationType: locationState?.userDetails?.occupation?.occupationType,
        /**-------------------------------------- */
        brother: locationState?.userDetails?.familyDetails?.brother,
        sister: locationState?.userDetails?.familyDetails?.sister,
        /**-------------------------------------- */
        // occupationDetails:
        //   location.state?.userDetails?.occupation?.occupationDetails.serviceType,
        serviceType:
          locationState?.userDetails?.occupation?.occupationDetails
            ?.serviceType,
        subSector:
          locationState?.userDetails?.occupation?.occupationDetails?.subSector,
        sector:
          locationState?.userDetails?.occupation?.occupationDetails?.sector,
        companyName:
          locationState?.userDetails?.occupation?.occupationDetails
            ?.companyName,
        designation:
          locationState?.userDetails?.occupation?.occupationDetails
            ?.designation,
        income: locationState?.userDetails?.income,
        hobbies: locationState?.userDetails?.hobbyList ? location.state?.userDetails?.hobbyList?.join(', ') : "",
        creativity: locationState?.creativityList ? location.state?.creativityList?.join(', ') : "",
        enjoymentActivities:
          locationState?.enjoymentActivityList ? location.state?.enjoymentActivityList?.join(', ') : "",
        // //zodiacInfo
        zodiacSign: locationState?.userDetails?.zodiacSign,
        timeOfBirth: locationState?.userDetails?.horoscopeDetails?.timeOfBirth,
        placeOfBirth:
          locationState?.userDetails?.horoscopeDetails?.placeOfBirth,
        isMangalik: locationState?.userDetails?.horoscopeDetails?.isMangalik,
        //id proff
        image: locationState?.userDocumentDetails,
        /*  ===========================   */
        // acccount purchase and sold  details

        purchaseImg:
          locationState?.totalBuyPeofileDetails?.userDetails
            ?.profilePhoto?.[0],
        purchaseFirstName:
          locationState?.totalBuyPeofileDetails?.userDetails?.firstName,
        purchaseLastName:
          locationState?.totalBuyPeofileDetails?.userDetails?.lastName,
        purchaseDob: locationState?.totalBuyPeofileDetails?.userDetails?.DOB,
        purchaseEmail:
          locationState?.totalBuyPeofileDetails?.userDetails?.email,
        purchaseMobile:
          locationState?.totalBuyPeofileDetails?.userDetails?.mobile,
        /*----------*/
      }));
    } else {
      setUserData((prev) => ({
        firstName: location.state?.firstName,
        middleName: location.state?.middleName,
        lastName: location.state?.lastName,
        email: location.state?.email,
        countryCode: location.state?.countryCode,
        mobile: location.state?.mobile,
        DOB: moment(location.state?.DOB).format("DD-MM-YYYY"),
        gender: location.state?.gender,
        bio: location.state?.bio,
        profilePhoto: location.state?.profilePhoto,
        disapproveProfilePhoto: location.state?.disapproveProfilePhoto,
        accountFor: location.state?.accountFor,
        isReadyToMarryOtherCaste: location?.state?.isReadyToMarryOtherCaste,
        // personal info
        religion: location.state?.userDetails?.personalInfo?.religion,
        community: location.state?.userDetails?.personalInfo?.community,
        subCommunity: location.state?.userDetails?.personalInfo?.subCommunity,
        caste: location.state?.userDetails?.personalInfo?.caste,
        country: location.state?.country,
        state: location.state?.state,
        city: location.state?.city,
        maritalStatus: location.state?.userDetails?.personalInfo?.maritalStatus,
        diet: location.state?.userDetails?.personalInfo?.diet,
        height: location.state?.userDetails?.personalInfo?.height,
        complexion: location.state?.userDetails?.personalInfo?.complexion,
        jointFamily: location.state?.userDetails?.personalInfo?.jointFamily,
        age: location.state?.ownAge,
        // familyInfo
        fatherName: location.state?.userDetails?.familyDetails?.father?.name,
        fatherOccupation:
          location.state?.userDetails?.familyDetails?.father?.occupation,
        motherName: location.state?.userDetails?.familyDetails?.mother?.name,
        motherOccupation:
          location.state?.userDetails?.familyDetails?.mother?.occupation,
        sisterName: location.state?.userDetails?.familyDetails?.sister?.name,
        sisterMaritalStatus:
          location.state?.userDetails?.familyDetails?.sister?.maritalStatus,
        brotherName: location.state?.userDetails?.familyDetails?.brother?.name,
        brotherMaritalStatus:
          location.state?.userDetails?.familyDetails?.brother?.maritalStatus,
        // // careerinfo
        education: location.state?.userDetails?.highestQualification,
        occupationType: location.state?.userDetails?.occupation?.occupationType,
        /**-------------------------------------- */
        brother: location.state?.userDetails?.familyDetails?.brother,
        sister: location.state?.userDetails?.familyDetails?.sister,
        /**-------------------------------------- */
        // occupationDetails:
        //   location.state?.userDetails?.occupation?.occupationDetails.serviceType,
        serviceType:
          location.state?.userDetails?.occupation?.occupationDetails
            ?.serviceType,
        subSector:
          location.state?.userDetails?.occupation?.occupationDetails?.subSector,
        sector:
          location.state?.userDetails?.occupation?.occupationDetails?.sector,
        companyName:
          location.state?.userDetails?.occupation?.occupationDetails
            ?.companyName,
        designation:
          location.state?.userDetails?.occupation?.occupationDetails
            ?.designation,
        income: location.state?.userDetails?.income,
        hobbies: location.state?.userDetails?.hobbyList ? location.state?.userDetails?.hobbyList?.join(', ') : "",
        creativity: location.state?.creativityList ? location.state?.creativityList?.join(', ') : "",
        enjoymentActivities:
          location.state?.enjoymentActivityList ? location.state?.enjoymentActivityList?.join(', ') : "",
        // //zodiacInfo
        zodiacSign: location.state?.userDetails?.zodiacSign,
        timeOfBirth: location.state?.userDetails?.horoscopeDetails?.timeOfBirth,
        placeOfBirth:
          location.state?.userDetails?.horoscopeDetails?.placeOfBirth,
        isMangalik: location.state?.userDetails?.horoscopeDetails?.isMangalik,
        //id proff
        image: location.state?.userDocumentDetails,
        /*  ===========================   */
        // acccount purchase and sold  details

        purchaseImg:
          location.state?.totalBuyPeofileDetails?.userDetails
            ?.profilePhoto?.[0],
        purchaseFirstName:
          location.state?.totalBuyPeofileDetails?.userDetails?.firstName,
        purchaseLastName:
          location.state?.totalBuyPeofileDetails?.userDetails?.lastName,
        purchaseDob: location.state?.totalBuyPeofileDetails?.userDetails?.DOB,
        purchaseEmail:
          location.state?.totalBuyPeofileDetails?.userDetails?.email,
        purchaseMobile:
          location.state?.totalBuyPeofileDetails?.userDetails?.mobile,
        /*----------*/
      }));
    }
  }, [location.state, locationState, locationField]);

  const handleDelete = (index) => {
    const deleteProfilePhoto = allPhoto[index];

    const del = (async) => {
      const updatedPhotoArray = [
        ...allPhoto.slice(0, index),
        ...allPhoto.slice(index + 1),
      ];

      setDeletePhoto((prevDeletePhotos) => [
        ...prevDeletePhotos,
        deleteProfilePhoto,
      ]);

      setAllPhoto(updatedPhotoArray);
    };

    DeleteConfirmModal(del);
  };

  const handlePhotoApproved = (e) => {
    e.preventDefault();
    const id = location.state?._id;
    let data = {
      isPhotoVerified: true,
      image: allPhoto,
      // image: allPhoto?.[0],
      disapproveProfilePhoto: deletePhoto,
    };
    console.log("dfff445f", data)
    // return
    dispatch(userPhotoApproved({ data, id }));
    dispatch(clearState());
    // navigate("/manageUsers");
    navigate("/manageUsers");
    dispatch(getAllUser());
  };

  const handleImageClick = (imageUrl) => {
    setClickedImageUrl(imageUrl);
    setZoom(true);
  };

  useEffect(() => {
    setAllPhoto(location.state?.profilePhoto);
    setDeletePhoto(location.state?.disapproveProfilePhoto || []);
  }, [location.state?.profilePhoto, location.state?.disapproveProfilePhoto]);

  return (
    <div className="main_wrap">
      <div style={{ padding: "13px 0px" }}>
        <div
          style={{
            marginBottom: "20px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            height: "100px",
            width: "100%",
            backgroundColor: "#e9e9e9",
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            {userData?.profilePhoto ? (
              <img
                src={userData?.profilePhoto?.[0]}
                alt="logo"
                style={{ height: "80px", width: "80px", borderRadius: "50%" }}
              ></img>
            ) : (
              <img
                src={dp2}
                alt="logo"
                style={{ height: "80px", width: "80px", borderRadius: "50%" }}
              />
            )}

            <div>
              <h6 style={{ marginLeft: "10px" }}>
                <span style={{ marginRight: "15px" }}>
                  {userData?.firstName + " " + userData?.lastName}
                </span>{" "}
                Profile
              </h6>
              <p className="mx-3">{customisedID ? customisedID : ""}</p>
            </div>
          </div>
          <div>
            <button id="prnt_btn" onClick={() => window.print()}>
              Print
            </button>
          </div>
        </div>

        <form className="row m-0">
          {/* First Name */}
          <div class="form-group col-md-4 col-12">
            <label for="firstName">First Name</label>
            <input
              type="text"
              class="form-control"
              id="firstName"
              name="firstName"
              value={userData.firstName}
              disabled
            />
          </div>
          {/* Middle Name */}
          <div class="form-group col-md-4 col-12">
            <label for="middleName">Middle Name</label>
            <input
              type="text"
              class="form-control"
              id="middleName"
              name="middleName"
              value={userData.middleName}
              disabled
            />
          </div>
          {/* Last Name */}
          <div class="form-group col-md-4 col-12">
            <label for="lastName">Last Name</label>
            <input
              type="text"
              class="form-control"
              id="lastName"
              name="lastName"
              value={userData.lastName}
              disabled
            />
          </div>
          {/* Email */}
          {profile_data?.userType === "subAdmin" ? (
            ""
          ) : (
            <div class="form-group col-md-6 col-12">
              <label for="email">Email</label>
              <input
                type="text"
                class="form-control"
                id="email"
                name="email"
                value={userData.email}
                disabled
              />
            </div>
          )}

          {/* Country code */}
          {profile_data?.userType === "subAdmin" ? (
            ""
          ) : (
            <div class="form-group col-md-2 col-12">
              <label for="countryCode">Country code</label>
              <input
                type="text"
                class="form-control"
                id="countryCode"
                name="countryCode"
                value={userData.countryCode}
                disabled
              />
            </div>
          )}

          {/* Mobile No */}
          {profile_data?.userType === "subAdmin" ? (
            ""
          ) : (
            <div class="form-group col-md-4 col-12">
              <label for="mobile">Mobile No</label>
              <input
                type="text"
                class="form-control"
                id="mobile"
                name="mobile"
                value={userData.mobile}
                disabled
              />
            </div>
          )}

          {/* DOB */}
          <div class="form-group col-md-4 col-12">
            <label for="DOB">DOB</label>
            <input
              type="text"
              class="form-control"
              id="DOB"
              placeholder="Enter Bio"
              name="DOB"
              value={userData.DOB}
              disabled
            />
          </div>
          {/* Gender */}
          <div class="form-group col-md-4 col-12">
            <label for="description">Gender</label>
            <input
              type="text"
              class="form-control"
              id="description"
              name="description"
              value={userData.gender}
              disabled
            />
          </div>
          {/* bio */}
          <div class="form-group col-md-4 col-12">
            <label for="bio">Bio</label>
            <input
              type="text"
              class="form-control"
              id="bio"
              name="bio"
              value={userData.bio}
              disabled
            />
          </div>
          {/* Account For */}
          <div class="form-group col-md-6 col-12">
            <label for="description">Account For</label>
            <input
              type="text"
              class="form-control"
              id="description"
              name="description"
              value={userData.accountFor}
              disabled
            />
          </div>

          <div class="form-group col-md-6 col-12">
            <label for="description">Open To Marry Other Cast</label>
            <input
              type="text"
              class="form-control"
              id="description"
              name="isReadyToMarryOtherCaste"
              value={userData.isReadyToMarryOtherCaste}
              disabled
            />
          </div>
          {/* ===================================== */}
          {/* ===========  personal info  ==========*/}
          {/* ===================================== */}
          <div className="row ml-1">
            {/* Religion */}
            <div class="form-group col-md-4 col-12">
              <label for="title">Religion</label>
              <input
                type="text"
                class="form-control"
                id="title"
                name="title"
                value={userData.religion}
                disabled
              />
            </div>
            {/* community */}
            <div class="form-group col-md-4 col-12">
              <label for="community">Community</label>
              <input
                type="text"
                class="form-control"
                id="community"
                name="community"
                value={userData.community}
                disabled
              />
            </div>
            {/* Sub community */}
            <div class="form-group col-md-4 col-12">
              <label for="subCommunity">Sub Community</label>
              <input
                type="text"
                class="form-control"
                id="subCommunity"
                name="subCommunity"
                value={userData.subCommunity}
                disabled
              />
            </div>
          </div>
          {/* Caste */}
          <div class="form-group col-md-4 col-12">
            <label for="caste">Caste</label>
            <input
              type="text"
              class="form-control"
              id="caste"
              name="caste"
              value={userData.caste}
              disabled
            />
          </div>
          {/* Country */}
          {profile_data?.userType === "subAdmin" ? (
            ""
          ) : (
            <div class="form-group col-md-4 col-12">
              <label for="country">Country</label>
              <input
                type="text"
                class="form-control"
                id="country"
                name="country"
                value={userData.country}
                disabled
              />
            </div>
          )}

          {/* State */}
          {profile_data?.userType === "subAdmin" ? (
            ""
          ) : (
            <div class="form-group col-md-4 col-12">
              <label for="subCommunity">State</label>
              <input
                type="text"
                class="form-control"
                id="state"
                name="state"
                value={userData.state}
                disabled
              />
            </div>
          )}

          {/* City */}
          {profile_data?.userType === "subAdmin" ? (
            ""
          ) : (
            <div class="form-group col-md-4 col-12">
              <label for="city">City</label>
              <input
                type="text"
                class="form-control"
                id="city"
                name="city"
                value={userData.city}
                disabled
              />
            </div>
          )}

          {/* marital Status */}
          <div class="form-group col-md-4 col-12">
            <label for="community">Marital Status</label>
            <input
              type="text"
              class="form-control"
              id="community"
              name="community"
              value={userData.maritalStatus}
              disabled
            />
          </div>
          {/* Diet */}
          <div class="form-group col-md-4 col-12">
            <label for="diet">Diet</label>
            <input
              type="text"
              class="form-control"
              id="diet"
              name="diet"
              value={userData.diet}
              disabled
            />
          </div>
          {/* Height */}
          <div class="form-group col-md-3 col-12">
            <label for="height">Height</label>
            <input
              type="text"
              class="form-control"
              id="height"
              name="height"
              value={userData.height}
              disabled
            />
          </div>
          {/* complexion */}
          <div class="form-group col-md-3 col-12">
            <label for="complexion">Complexion</label>
            <input
              type="text"
              class="form-control"
              id="complexion"
              name="complexion"
              value={userData.complexion}
              disabled
            />
          </div>
          {/* Joint Family */}
          <div class="form-group col-md-3 col-12">
            <label for="jointFamily">Joint Family</label>
            <input
              type="text"
              class="form-control"
              id="jointFamily"
              name="jointFamily"
              value={userData.jointFamily}
              disabled
            />
          </div>
          {/* age */}
          <div class="form-group col-md-3 col-12">
            <label for="age">Age</label>
            <input
              type="text"
              class="form-control"
              id="age"
              name="age"
              value={userData.age}
              disabled
            />
          </div>

          {/* ===================================== */}
          {/* ===========  Family info  ==========*/}
          {/* ===================================== */}

          {/* father name */}
          <div class="form-group col-md-6 col-12">
            <label for="fatherName">Father Name</label>
            <input
              type="text"
              class="form-control"
              id="fatherName"
              name="fatherName"
              value={userData.fatherName}
              disabled
            />
          </div>
          {/* father occupation */}
          <div class="form-group col-md-6 col-12">
            <label for="fatherOccupation">Father Occupation</label>
            <input
              type="text"
              class="form-control"
              id="fatherOccupation"
              name="fatherOccupation"
              value={userData.fatherOccupation}
              disabled
            />
          </div>
          {/* Mother name */}
          <div class="form-group col-md-6 col-12">
            <label for="motherName">Mother Name</label>
            <input
              type="text"
              class="form-control"
              id="motherName"
              name="motherName"
              value={userData.motherName}
              disabled
            />
          </div>
          {/* Mother Occupation */}
          <div class="form-group col-md-6 col-12">
            <label for="motherOccupation">Mother Occupation</label>
            <input
              type="text"
              class="form-control"
              id="motherOccupation"
              name="motherOccupation"
              value={userData?.motherOccupation}
              disabled
            />
          </div>

          {userData?.sister?.length > 0 &&
            userData?.sister?.map((item) => {
              console.log("sister", item);
              return (
                <>
                  {/* Sister Name */}
                  <div class="form-group col-md-6 col-12">
                    <label for="sisterName">Sister Name</label>
                    <input
                      type="text"
                      class="form-control"
                      id="sisterName"
                      name="sisterName"
                      value={item?.name}
                      disabled
                    />
                  </div>
                  {/* Sister Marital Status */}
                  <div class="form-group col-md-6 col-12">
                    <label for="age">Sister Marital Status</label>
                    <input
                      type="text"
                      class="form-control"
                      id="age"
                      name="age"
                      value={item?.maritalStatus}
                      disabled
                    />
                  </div>
                </>
              );
            })}

          {userData?.brother?.length > 0 &&
            userData?.brother?.map((item) => {
              console.log("brother", item);
              return (
                <>
                  {/* Brother Name */}
                  <div class="form-group col-md-6 col-12">
                    <label for="brotherName">Brother Name</label>
                    <input
                      type="text"
                      class="form-control"
                      id="brotherName"
                      name="brotherName"
                      value={item?.name}
                      disabled
                    />
                  </div>
                  {/* Brother Marital Status */}
                  <div class="form-group col-md-6 col-12">
                    <label for="brotherMaritalStatus">
                      Brother Marital Status
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="brotherMaritalStatus"
                      name="brotherMaritalStatus"
                      value={item?.maritalStatus}
                      disabled
                    />
                  </div>
                </>
              );
            })}

          {/* ===================================== */}
          {/* ===========  occupation info  ==========*/}
          {/* ===================================== */}

          {/* Education */}
          <div class="form-group col-md-6 col-12">
            <label for="education">Education</label>
            <input
              type="text"
              class="form-control"
              id="education"
              name="education"
              value={userData.education}
              disabled
            />
          </div>
          {/* occupation type */}
          {profile_data?.userType === "subAdmin" ? (
            ""
          ) : (
            <div class="form-group col-md-6 col-12">
              <label for="occupationType">Occupation type</label>
              <input
                type="text"
                class="form-control"
                id="occupationType"
                name="occupationType"
                value={userData.occupationType}
                disabled
              />
            </div>
          )}

          {/* Service Type */}
          {profile_data?.userType === "subAdmin" ? (
            ""
          ) : (
            <div class="form-group col-md-6 col-12">
              <label for="serviceType">Service Type</label>
              <input
                type="text"
                class="form-control"
                id="serviceType"
                name="serviceType"
                value={userData.serviceType}
                disabled
              />
            </div>
          )}

          {/* Sector */}
          {profile_data?.userType === "subAdmin" ? (
            ""
          ) : (
            <div class="form-group col-md-6 col-12">
              <label for="sector">Sector</label>
              <input
                type="text"
                class="form-control"
                id="sector"
                name="sector"
                value={userData.sector}
                disabled
              />
            </div>
          )}

          {/* Sub Sector */}
          {profile_data?.userType === "subAdmin" ? (
            ""
          ) : (
            <div class="form-group col-md-6 col-12">
              <label for="subSector">Sub Sector</label>
              <input
                type="text"
                class="form-control"
                id="subSector"
                name="subSector"
                value={userData.subSector}
                disabled
              />
            </div>
          )}

          {/* Company Name */}
          {profile_data?.userType === "subAdmin" ? (
            ""
          ) : (
            <div class="form-group col-md-6 col-12">
              <label for="companyName">Company Name</label>
              <input
                type="text"
                class="form-control"
                id="companyName"
                name="companyName"
                value={userData.companyName}
                disabled
              />
            </div>
          )}

          {/* Designation */}
          {profile_data?.userType === "subAdmin" ? (
            ""
          ) : (
            <div class="form-group col-md-6 col-12">
              <label for="designation">Designation</label>
              <input
                type="text"
                class="form-control"
                id="designation"
                name="designation"
                value={userData.designation}
                disabled
              />
            </div>
          )}

          {/*income */}
          <div class="form-group col-md-6 col-12">
            <label for="income">Income</label>
            <input
              type="text"
              class="form-control"
              id="income"
              name="income"
              value={userData.income}
              disabled
            />
          </div>

          {/* ===================================== */}
          {/* ===========  user Interest info  ==========*/}
          {/* ===================================== */}

          {/* Hobbies */}
          <div class="form-group col-md-12 col-12">
            <label for="hobbies">Hobbies</label>
            <input
              type="text"
              class="form-control"
              id="hobbies"
              name="hobbies"
              value={userData.hobbies}
              disabled
            />
          </div>
          {/* Creativity */}
          <div class="form-group col-md-6 col-12">
            <label for="creativity">Creativity</label>
            <input
              type="text"
              class="form-control"
              id="creativity"
              name="creativity"
              value={userData.creativity}
              disabled
            />
          </div>
          {/* Enjoyment Activities */}
          <div class="form-group col-md-6 col-12">
            <label for="enjoymentActivities">Enjoyment Activities</label>
            <input
              type="text"
              class="form-control"
              id="enjoymentActivities"
              name="enjoymentActivities"
              value={userData.enjoymentActivities}
              disabled
            />
          </div>
          {/* Zodiac Sign */}
          <div class="form-group col-md-6 col-12">
            <label for="zodiacSign">Zodiac Sign</label>
            <input
              type="text"
              class="form-control"
              id="zodiacSign"
              name="zodiacSign"
              value={userData.zodiacSign}
              disabled
            />
          </div>
          {/* Time Of Birth */}
          <div class="form-group col-md-6 col-12">
            <label for="timeOfBirth">Time Of Birth</label>
            <input
              type="text"
              class="form-control"
              id="timeOfBirth"
              name="timeOfBirth"
              value={userData.timeOfBirth}
              disabled
            />
          </div>
          {/* Place of birth */}
          <div class="form-group col-md-6 col-12">
            <label for="placeOfBirth">Place of birth</label>
            <input
              type="text"
              class="form-control"
              id="placeOfBirth"
              name="placeOfBirth"
              value={userData.placeOfBirth}
              disabled
            />
          </div>
          {/* Mangalik */}
          <div class="form-group col-md-6 col-12">
            <label for="isMangalik">Mangalik</label>
            <input
              type="text"
              class="form-control"
              id="isMangalik"
              name="isMangalik"
              value={userData.isMangalik}
              disabled
            />
          </div>

          {/* =====   id proff image  ===== */}
          <div className="idproff_main">
            <div>
              <h4 className="mb-3">
                {userData?.image?.documentType ? "Document Type" : ""}
              </h4>
              <h5>{userData?.image?.documentType}</h5>
            </div>

            <div className="row my-3">
              {userData?.image?.img1 && (
                <div class="col-md-4 col-12">
                  <img
                    src={userData?.image?.img1}
                    alt=""
                    style={{ height: "250px", width: "100%" }}
                    onClick={() => handleImageClick(userData?.image?.img1)}
                  ></img>
                </div>
              )}
              {userData?.image?.img2 && (
                <div class=" col-md-4 col-12">
                  <img
                    src={userData?.image?.img2}
                    alt=""
                    style={{ height: "250px", width: "100%" }}
                    onClick={() => handleImageClick(userData?.image?.img2)}
                  ></img>
                </div>
              )}
            </div>

            {/* approve and disapproved button */}
            {userData?.image?.img1 || userData?.image?.img2 ? (
              <div className="row m-auto">
                {location.state?.isVerified ? (
                  <button
                    className="singleViewdisapprovedBtn"
                    onClick={(e) => {
                      e.preventDefault();
                      dispatch(
                        userIdProffChange({
                          id: location.state._id,
                          data: { isVerified: false },
                        })
                      ).then(() => {
                        navigate("/manageUsers");
                      });
                    }}
                  >
                    {" "}
                    Disapprove
                  </button>
                ) : (
                  <button
                    className="singleViewApprovebtn mb-3"
                    onClick={(e) => {
                      e.preventDefault();
                      dispatch(
                        userIdProffChange({
                          id: location.state._id,
                          data: { isVerified: true },
                        })
                      ).then(() => {
                        navigate("/manageUsers");
                      });
                    }}
                  >
                    {" "}
                    Approve
                  </button>
                )}
              </div>
            ) : (
              ""
            )}
          </div>

          {/* <!-- The Modal --> */}

          {zoom && (
            <ImageModal
              url={clickedImageUrl}
              setClickedImageUrl={setClickedImageUrl}
            />
          )}

          {/* =====   Profile  image Varification  ===== */}
          <div style={{ marginTop: "10px", width: "100%" }}>
            <h5>Profile Picture</h5>
            <div className="prflpicimgdiv">
              {allPhoto?.length > 0 &&
                allPhoto?.map((item, index) => {
                  console.log("item", item);
                  return (
                    <>
                      <div key={index} className="image_item">
                        <img
                          // style={{ height: "200px", width: "200px" }}
                          src={item}
                          alt={`Image ${index + 1}`}
                        />
                        <div className="image_delete">
                          <MdDelete
                            style={{
                              color: "red",
                              fontSize: "20px",
                              cursor: "pointer",
                            }}
                            onClick={() => handleDelete(index)}
                          />
                        </div>
                      </div>
                    </>
                  );
                })}
            </div>

            <div className="">
              {/* {allPhoto?.length > 0 && ( */}
              {!locationField && <button
                className="singleViewApprovebtn mt-3"
                onClick={(e) => handlePhotoApproved(e)}
              >
                Approve
              </button>}
              {/* // )} */}
            </div>
          </div>

          {/* =====   Profile  Disapproved image  ===== */}
          <div style={{ marginTop: "20px", width: "100%" }}>
            <h5>
              {userData?.disapproveProfilePhoto?.length > 0
                ? "Disapproved Profile Picture"
                : ""}
            </h5>
            <div className="prflpicimgdiv">
              {userData?.disapproveProfilePhoto?.length > 0 &&
                userData?.disapproveProfilePhoto?.map((item, index) => {
                  console.log("item", item);
                  return (
                    <>
                      <div key={index} className="image_item">
                        <img src={item} alt={`Image ${index + 1}`} />
                      </div>
                    </>
                  );
                })}
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ViewSingleUser;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  postCountry,
  uploadCountryImage,
  getCountry,
  UpdateCountry,
  clearCountryState,
} from "../../../redux/AdminSlice/CountrySlice";

const AddCountry = () => {
  let initial = {
    name: "",
    image: "",
  };
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [country, setCountry] = useState(initial);

  const { countryImage } = useSelector((state) => state.countryReducer);

  const changehandler = (e) => {
    setCountry((prevDiet) => ({
      ...prevDiet,
      name: e.target.value,
    }));
  };

  const Imagehandler = (e) => {
    let file = e.target.files;

    for (let element of file) {
      let data = new FormData();
      data.append("image", element);
      dispatch(uploadCountryImage(data));
    }
  };

  useEffect(() => {
    if (location.state) {
      setCountry({
        name: location.state.name,
        image: location.state.image,
      });
    }
    if (countryImage) {
      setCountry((prevDiet) => ({
        ...prevDiet,
        image: countryImage,
      }));
    }
  }, [countryImage]);

  const submitHandler = (e) => {
    e.preventDefault();
    if (country.name === "") {
      toast.error("Country Name Is required");
      return false;
    }
    if (country.image === "") {
      toast.error("Country Image Is required");
      return false;
    }
    let data = {
      name: country.name,
      image: country.image ? country.image : countryImage,
    };

    if (location.state) {
      const id = location.state._id;
      dispatch(UpdateCountry({ id, data }));
      setCountry(initial);
      dispatch(clearCountryState());
      dispatch(getCountry());
      navigate("/ManageCountry");
    } else {
      dispatch(postCountry(data));
      setCountry(initial);
      dispatch(clearCountryState());
    }
  };

  return (
    <div className="main_wrap">
      <div className="aboutUs_page">
        <h4 className="Pageheadline">
          {location.state ? "Edit Country" : "Add Country"}
        </h4>
        <form action="" onSubmit={(e) => submitHandler(e)}>
          <div style={{ display: "flex" }}>
            <div className="col-md-5 col-12">
              <label htmlFor="">Country Name</label>
              <input
                type="text"
                placeholder="Enter Country Name"
                className="form-control"
                name="name"
                value={country.name}
                onChange={(e) => changehandler(e)}
              />
            </div>
            <div className="col-md-5 col-12">
              <label htmlFor="">Image</label>
              <input
                type="file"
                className="form-control"
                name="image"
                onChange={(e) => Imagehandler(e)}
                accept="image/*"
              />
            </div>
            <div
              className="col-md-2 col-12"
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "end",
              }}
            >
              {location.state ? (
                <button type="submit" className="btn btn-primary">
                  Submit
                </button>
              ) : (
                <button type="submit" className="btn btn-primary">
                  Add
                </button>
              )}
            </div>
          </div>
          {countryImage ? (
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                marginTop: "5px",
              }}
            >
              <img
                style={{ height: "100px", width: "100px" }}
                src={countryImage}
                alt=""
              />
              <i
                class="fa-solid fa-xmark"
                style={{ cursor: "pointer" }}
                onClick={() => dispatch(UpdateCountry())}
              ></i>
            </div>
          ) : location.state ? (
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                marginTop: "5px",
              }}
            >
              <img
                style={{ height: "100px", width: "100px", marginLeft: "100px" }}
                src={country.image}
                alt=""
              />
            </div>
          ) : (
            ""
          )}
        </form>
      </div>
    </div>
  );
};

export default AddCountry;

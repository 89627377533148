import React from "react";

const Profile = () => {
  return (
    <div className="main_wrap">
      <div className="seller_profile ">
        <h3>Seller’s profile</h3>
        <div className="wrapper">
          <div className="row">
            <div className="col-lg-2 col-md-2 col-12">
              <div className="profileImage">
                <img src="https://bushido.sgp1.digitaloceanspaces.com/Category/image//41693950-2abb-11ee-ac76-617110d0a4f0.jpg" />
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-12">
              <div className="form dashboard_allInput">
                <div className="form-group">
                  <label htmlFor="exampleFormControlInput1">Owner Name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="exampleFormControlInput1"
                    placeholder="Enter owner name..."
                    name="ownerName"
                    defaultValue="ABC"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="exampleFormControlInput1">
                    Business/ company name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="exampleFormControlInput1"
                    placeholder="Enter company name..."
                    name="cmpanyName"
                    defaultValue="XYZ"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="exampleFormControlSelect1">Country</label>
                  <select
                    className="form-control"
                    name="countryID"
                    id="exampleFormControlSelect1"
                  >
                    <option>Select</option>
                    <option value="64b910a6dbbbd26c792c0f1c">India</option>
                    <option value="64b910eddbbbd26c792c0f22">China</option>
                    <option value="64b91105dbbbd26c792c0f28">Nepal</option>
                  </select>
                </div>
                <h4>Your Bank Details</h4>
                <div className="form-group">
                  <label htmlFor="exampleFormControlInput1">Country code</label>
                  <input
                    type="text"
                    className="form-control"
                    id="exampleFormControlInput1"
                    placeholder="Enter country code..."
                    name="countryCode"
                    defaultValue={700039}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="exampleFormControlInput1">
                    Check digitals{" "}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="exampleFormControlInput1"
                    placeholder="Enter check digits..."
                    name="checkDigit"
                    defaultValue={4532}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="exampleFormControlInput1">Bank Code</label>
                  <input
                    type="text"
                    className="form-control"
                    id="exampleFormControlInput1"
                    placeholder="Enter bank code..."
                    name="bankCode"
                    defaultValue="OPi78hgjiui5"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="exampleFormControlInput1">Enter VAT ID</label>
                  <input
                    type="text"
                    className="form-control"
                    id="exampleFormControlInput1"
                    placeholder="Enter vat id..."
                    name="vatID"
                    defaultValue="67fsdfsdfsd6"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="exampleFormControlInput1">Postcode</label>
                  <input
                    type="text"
                    className="form-control"
                    id="exampleFormControlInput1"
                    placeholder="Enter post code..."
                    name="pickupPostcode"
                    defaultValue={13456}
                  />
                </div>
                <button
                  type="button"
                  className="btn btn-primary"
                  style={{ width: "100%" }}
                >
                  Submit
                </button>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-12">
              <div className="product_upload_box">
                <h4>Add Profile Image</h4>
                <div className="product_upload">
                  <label>
                    <img src="https://bushido.sgp1.digitaloceanspaces.com/Category/image//41693950-2abb-11ee-ac76-617110d0a4f0.jpg" />
                    <input
                      type="file"
                      name="image"
                      accept="image/*"
                      style={{ cursor: "pointer" }}
                    />
                  </label>
                  <h6>Profile Image</h6>
                  <span>File Size Should Be Less Then 5 MB</span>
                </div>
                <h4>ID proof</h4>
                <div className="product_upload">
                  <label style={{ cursor: "pointer" }}>
                    <img src="https://bushido.sgp1.digitaloceanspaces.com/profile/image//75206f30-56c5-11ee-8ccd-df88254dc81f.png" />
                    <input type="file" name="idProof" accept="image/*" />
                  </label>
                  <h6>upload passport/birth certificate</h6>
                  <span>File Size Should Be Less Then 5 MB</span>
                </div>
                <h4>Tread License </h4>
                <div className="product_upload">
                  <label>
                    <img src="https://bushido.sgp1.digitaloceanspaces.com/profile/image//862b3a30-56c5-11ee-8ccd-df88254dc81f.png" />
                    <input
                      type="file"
                      name="tradeLicenceNumber"
                      accept="image/*"
                    />
                  </label>
                  <h6>upload passport/birth certificate</h6>
                  <span>File Size Should Be Less Then 5 MB</span>
                </div>
                <h4>Add Your E Signature</h4>
                <div className="product_upload">
                  <label>
                    <img src="https://bushido.sgp1.digitaloceanspaces.com/profile/image//8ad49e00-56c5-11ee-8ccd-df88254dc81f.png" />
                    <input type="file" name="addEsignature" accept="image/*" />
                  </label>
                  <h6>upload passport/birth certificate</h6>
                  <span>File Size Should Be Less Then 5 MB</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;

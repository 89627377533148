import React, { useEffect, useMemo, useState } from "react";
import Table from "../../Table/Table";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  DeletepagesData,
  GetPagesData,
  PagesStatusChange,
} from "../../../redux/AdminSlice/AddPagesSlice";
import { DeleteConfirmModal } from "../../../DeleteAlert/DeleteAlert";
import { getProfileDetails } from "../../../redux/AdminSlice/profileSlice";

const ManagePages = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchText, setsearchText] = useState("");
  const [changeState, setChangeState] = useState(false);

  const token = localStorage.getItem("Authorization");
  const { profile_data } = useSelector((state) => state.profileReducer);

  const PagesDetails = useSelector((state) => state.pagesReducer.PageData);
  console.log("PagesDetails", PagesDetails);

  useEffect(() => {
    dispatch(GetPagesData());
    dispatch(getProfileDetails(token));
  }, []);

  useEffect(() => {
    dispatch(GetPagesData());
    return () => setChangeState(false);
  }, [changeState]);

  const DeletePages = (id) => {
    const del = async () => {
      dispatch(DeletepagesData(id)).then(() => {
        dispatch(GetPagesData());
      });
    };

    DeleteConfirmModal(del);
  };

  const filterData = useMemo(() => {
    if (searchText !== "") {
      return PagesDetails?.filter((item) =>
        JSON.stringify(item).toLowerCase().includes(searchText.toLowerCase())
      );
    }
    return PagesDetails;
  }, [searchText, PagesDetails]);

  const handleView = (e, item) => {
    e.preventDefault();

    navigate(`/addPages`, { state: item });
  };

  const tableData = useMemo(() => {
    return {
      columns: [
        {
          label: "SL",
          field: "sl",
          sort: "asc",
          width: 270,
        },
        {
          label: "Page Title",
          field: "pagetitle",
          sort: "asc",
          width: 150,
        },
        {
          label: "Slug",
          field: "pageslug",
          sort: "asc",
          width: 150,
        },
        {
          label: "Meta Description",
          field: "metadescription",
          sort: "asc",
          width: 150,
        },
        {
          label: "Content",
          field: "pagecontent",
          sort: "asc",
          width: 150,
        },
        {
          label: "Status",
          field: "status",
          sort: "asc",
          width: 150,
        },
        {
          label: "Action",
          field: "action",
          sort: "asc",
          width: 150,
        },
      ],
      rows:
        filterData?.length > 0
          ? filterData.map((item, idx) => {
              return {
                sl: idx + 1,
                pagetitle: (
                  <div className="widget-content p-0" key={idx}>
                    <div className="widget-content-wrapper">
                      <div className="widget-content-left flex2">
                        <div
                          className="widget-heading"
                          style={{ opacity: 1, fontSize: "12px" }}
                        >
                          {item.title}
                        </div>
                      </div>
                    </div>
                  </div>
                ),
                pageslug: (
                  <div className="widget-content p-0" key={idx}>
                    <div className="widget-content-wrapper">
                      <div className="widget-content-left flex2">
                        <div
                          className="widget-heading"
                          style={{ opacity: 1, fontSize: "12px" }}
                        >
                          {item.slug}
                        </div>
                      </div>
                    </div>
                  </div>
                ),

                metadescription: (
                  <div className="widget-content p-0" key={idx}>
                    <div className="widget-content-wrapper">
                      <div className="widget-content-left flex2">
                        <div
                          className="widget-heading"
                          style={{ opacity: 1, fontSize: "12px" }}
                        >
                          {item.meta_description}
                        </div>
                      </div>
                    </div>
                  </div>
                ),
                pagecontent: (
                  <div className="widget-content p-0" key={idx}>
                    <div className="widget-content-wrapper">
                      <div className="widget-content-left flex2">
                        <div
                          className="widget-heading"
                          style={{
                            opacity: 1,
                            fontSize: "12px",
                            width: "250px",
                          }}
                        >
                          {item.content}
                        </div>
                      </div>
                    </div>
                  </div>
                ),
                status: (
                  <div className="widget-content p-0" key={idx}>
                    <div className="widget-content-wrapper">
                      <div className="widget-content-left flex2">
                        <div
                          className="widget-heading"
                          style={{ opacity: 1, fontSize: "12px" }}
                        >
                          {item.status ? (
                            <button
                              className="activebtn"
                              onClick={() => {
                                dispatch(
                                  PagesStatusChange({
                                    id: item._id,
                                    data: { status: false },
                                  })
                                );
                                setChangeState(!changeState);
                              }}
                            >
                              Active
                            </button>
                          ) : (
                            <button
                              className="inactivebtn"
                              onClick={() => {
                                dispatch(
                                  PagesStatusChange({
                                    id: item._id,
                                    data: { status: true },
                                  })
                                );
                                setChangeState(!changeState);
                              }}
                            >
                              Inactive
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                ),
                action: (
                  <div className="widget-content p-0" key={idx}>
                    <div className="widget-content-wrapper">
                      <div className="widget-content-center flex2">
                        {profile_data?.tabPermission?.[0]?.managepagesEdit && (
                          <i
                            class="fa-solid fa-pen"
                            style={{ color: "blue", cursor: "pointer" }}
                            onClick={(e) => handleView(e, item)}
                          ></i>
                        )}

                        {profile_data?.tabPermission?.[0]
                          ?.managepagesDelete && (
                          <i
                            class="fa-solid fa-trash-can"
                            style={{
                              color: "red",
                              marginLeft: "15px",
                              cursor: "pointer",
                            }}
                            onClick={() => DeletePages(item._id)}
                          ></i>
                        )}
                      </div>
                    </div>
                  </div>
                ),
              };
            })
          : [],
    };
  });

  return (
    <>
      <div className="main_wrap">
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <h4 className="Pageheadline">Manage Pages</h4>
          {profile_data?.tabPermission?.[0]?.addpages && (
            <button
              className="btn btn-primary"
              style={{ height: "33px" }}
              onClick={() => navigate("/addPages")}
            >
              Add
            </button>
          )}
        </div>
        <div style={{ overflowX: "auto" }} className="dataTableParDiv">
          <Table
            striped
            tableTitle="All abouts"
            data={tableData}
            onSearch={(e) => setsearchText(e.target.value)}
          />
        </div>
      </div>
    </>
  );
};

export default ManagePages;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

// Helpers
import { ApiHelperFunction } from "../../utils/HttpClient";

let initialState = {
  status: "idle",
  communityReportData: {},
  isLoading: false,
  isSuccess: false,
  isError: false,
};

export const GetcommunityReport = createAsyncThunk(
  "Community_wise_report_get",
  async (data) => {
    let response = await ApiHelperFunction({
      urlPath: "admin/community-wise-reports",
      method: "POST",
      data: data ? data : "",
    });
    console.log("Community_Wise_report_get", response);

    if (response.status === 200) {
      return response.data;
    } else {
      toast.error("Can't get data. Something went wrong");
    }
  }
);

export const communityReportSlice = createSlice({
  name: "Community_report_get_data",
  initialState,
  reducers: {
    clearState: (state) => {
      state.status = "idle";
      state.communityReportData = {};
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetcommunityReport.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(GetcommunityReport.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.isSuccess = true;
        // console.log("payload", payload?.data);
        state.communityReportData = payload?.data;
      })
      .addCase(GetcommunityReport.rejected, (state) => {
        state.status = "failed";
        state.isError = true;
        state.isSuccess = false;
      });
  },
});

export const { clearState } = communityReportSlice.actions;

export default communityReportSlice.reducer;

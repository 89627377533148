import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

// Helpers
import { ApiHelperFunction } from "../../utils/HttpClient";

let initialState = {
  status: "idle",
  AboutUsData: {},
  isLoading: false,
  isSuccess: false,
  isError: false,
};

export const PostAboutUsData = createAsyncThunk(
  "About_Us_post",
  async (data) => {
    let response = await ApiHelperFunction({
      urlPath: "admin/addAboutUs",
      method: "POST",
      data: data,
    });
    console.log("about_details", response);

    if (response.status === 200) {
      toast.success("Company Details add successfully");
      return response.data;
    } else {
      toast.error(response?.response?.data?.message);
    }
  }
);

export const getAboutUsData = createAsyncThunk("About_us_get", async () => {
  let response = await ApiHelperFunction({
    urlPath: "admin/viewAboutUs",
    method: "GET",
  });
  console.log("contact_details", response);

  if (response.status === 200) {
    return response.data;
  } else {
    toast.error(response?.response?.data?.message);
  }
});

export const UpdateAboutUsData = createAsyncThunk(
  "About_us_update",
  async ({ id, data }) => {
    console.log("updatedItem", data);
    let response = await ApiHelperFunction({
      urlPath: `admin/editAbouUs/${id}`,
      method: "PUT",
      data: data,
    });
    console.log("contact_details", response);

    if (response.status === 200) {
      toast.success("Compoany Details Updated Successfully");
      return response.data;
    } else {
      toast.error(response?.response?.data?.message);
    }
  }
);

export const AboutUsSlice = createSlice({
  name: "About_us_data",
  initialState,
  reducers: {
    clearState: (state) => {
      state.status = "idle";
      state.AboutUsData = {};
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(PostAboutUsData.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(PostAboutUsData.fulfilled, (state) => {
        state.status = "success";
        state.isSuccess = true;
        // Optionally, you can update state after a successful post if needed
      })
      .addCase(PostAboutUsData.rejected, (state) => {
        state.status = "failed";
        state.isError = true;
        state.isSuccess = false;
      })
      .addCase(getAboutUsData.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(getAboutUsData.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.status = "success";
        state.isSuccess = true;
        console.log("payload", payload?.data);
        state.AboutUsData = payload?.data;
      })
      .addCase(getAboutUsData.rejected, (state) => {
        state.status = "failed";
        state.isError = true;
        state.isSuccess = false;
      })
      .addCase(UpdateAboutUsData.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(UpdateAboutUsData.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.isSuccess = true;
        // console.log('payload', payload?.data);
        // state.AboutUsData = payload?.data;
      })
      .addCase(UpdateAboutUsData.rejected, (state) => {
        state.status = "failed";
        state.isError = true;
        state.isSuccess = false;
      });
  },
});

export const { clearState } = AboutUsSlice.actions;

export default AboutUsSlice.reducer;

import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { GetQualification, PostCourse, UpdateCourse, clearState } from '../../../redux/AdminSlice/QualificationSlice';
import { toast } from 'react-toastify';
import { ApiHelperFunction } from '../../../utils/HttpClient';
import DataTable from 'react-data-table-component';
import Swal from 'sweetalert2';


const AddCourse = () => {
    let initial = {
        name: "",
        qualificationId: ""
    };

    // >>>Definig coloumn<<<
    const columns = [
        {
            name: 'SL No.',
            selector: row => row.sln,
            sortable: true,
        },
        {
            name: 'Highest Qualification',
            selector: row => row.qualification,
            sortable: true,
        },
        {
            name: 'Course Name',
            selector: row => row.course,
            sortable: true,
        },
        {
            name: 'Action',
            selector: row => row.action,
            sortable: true,
        }
    ];
    const [qualification, setQualification] = useState(initial);
    const [qualificationData, setQualificationData] = useState([])
    const [isEdit, setIsEdit] = useState(false)
    const [cour_ID, setCourseID] = useState("")
    const [selectedCourse, setSelectedCourse] = useState(null);
    const dispatch = useDispatch();
    const QualificationData = useSelector(
        (state) => state.qualificationReducer.qualificationData
    );
    console.log("fgfgf4fdfg565fd6g46f", qualificationData)



    // >>>Fetch All Courses<<<
    const fetchCourse = async () => {
        try {
            const response = await ApiHelperFunction({
                urlPath: "admin/course",
                method: "GET"
            });
            console.log("DatafrohfjfghmAPI:", response?.data?.data);
            if (response?.data && response?.data?.status) {
                let arr = response?.data?.data?.map((ele, ind) => {
                    console.log("hg64684", ele)
                    return {
                        qualification: ele?.highestEducationDetails?.name,
                        course: ele?.name,
                        qualificationId: ele?.highestEducationId,
                        action: (
                            <>
                                <span
                                    title="Edit"
                                    onClick={(e) => {
                                        handleEdit({ e, ele: ele })
                                        // handleUpdate({ e, id: ele?._id })
                                    }}
                                >
                                    <i class="fa-solid fa-pen-to-square"
                                        style={{
                                            cursor: "pointer",
                                            marginRight: "5px",
                                            padding: "5px",
                                            fontSize: "1.5em",
                                            color: "#ff9c00",
                                            backgroundColor: "#fee5d2",
                                        }}
                                    ></i>

                                </span>

                                <span
                                    title="Delete"
                                    onClick={() => handleDelete(ele?._id)}
                                >
                                    <i class="fa-solid fa-trash-can"
                                        style={{
                                            cursor: "pointer",
                                            marginRight: "5px",
                                            padding: "5px",
                                            fontSize: "1.5em",
                                            color: "#C4554D",
                                            backgroundColor: "#FAECEC",
                                        }}
                                    ></i>
                                </span>
                            </>
                        )
                    }
                })
                arr.sort((a, b) => {
                    const nameA = a.qualification.toLowerCase();
                    const nameB = b.qualification.toLowerCase();
                    if (nameA < nameB) return -1;
                    if (nameA > nameB) return 1;
                    return 0;
                });

                const numberedArr = arr.map((ele, ind) => ({
                    sln: ind + 1,
                    ...ele
                }));
                setQualificationData(numberedArr)
            }

            // Handle the data received from the API
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }

    // >>>Handle Edit<<<
    const handleEdit = ({ e, ele }) => {
        console.log("coursfffeIDjko", ele, ele?._id)
        setCourseID(ele?._id)
        setSelectedCourse(ele)
        setIsEdit(true)
        submitHandler({ e, val: "update", id: ele?._id });
        setQualification({
            ...qualification,
            qualificationId: ele?.highestEducationId,
            // qualificationId: ele?.highestEducationDetails?._id,
            name: ele?.name
        });

    }

    // >>>Handle Submit<<<
    const submitHandler = async ({ e, val, id, type }) => {
        e.preventDefault();

        if (val === "submit") {
            // Handle submission for adding a new course
            if (qualification?.qualificationId === "") {
                toast.error("Please select Highest Qualification")
                return false;
            } else if (qualification?.name === "") {
                toast.error("Please enter Course name");
                return false;
            }

            let data = {
                name: qualification.name,
                highestEducationId: qualification?.qualificationId
            }

            const res = await ApiHelperFunction({
                urlPath: "admin/course",
                method: "POST",
                data: data
            })
            if (res && res?.status) {
                toast.success("Course Added Successfully!")
                setQualification(initial)
                document.getElementById("selectOption").selectedIndex = 0;
                fetchCourse()
            } else if (!res?.status) {
                toast.error("You've already added this course!")
            }
        }

        if (val === "update" && type === "updates") {
            if (qualification?.qualificationId === "") {
                toast.error("Please select Highest Qualification")
                return false;
            } else if (qualification?.name === "") {
                toast.error("Please enter Course name");
                return false;
            }
            let data = {
                name: qualification?.name,
                highestEducationId: qualification?.qualificationId
            }
            console.log("ds54f6s8d4fs6z", data, id)
            // return
            const res = await ApiHelperFunction({
                urlPath: `admin/course/${id}`,
                method: "PUT",
                data: data
            })
            if (res && res?.status === 200) {
                fetchCourse()
                toast.success("Course Updated Successfully!")
                setQualification(initial)
                document.getElementById("selectOption").selectedIndex = 0;
                setIsEdit(false)
            } else if (!res?.status) {
                toast.error("Course allready exist!!")
            }
        }
    }

    // >>>HAndle Delete<<<
    const handleDelete = async (courseID) => {
        console.log("courseIDjko", courseID)
        const result = await Swal.fire({
            title: 'Are you sure?',
            text: 'You are about to delete this course!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            confirmButtonColor: '#d33',
            cancelButtonText: 'Cancel',
            reverseButtons: true
        });

        if (result.isConfirmed) {
            try {
                const res = await ApiHelperFunction({
                    urlPath: `admin/course/${courseID}`,
                    method: 'DELETE',
                });
                if (res.status === 200) {
                    Swal.fire('Deleted!', 'Course has been deleted.', 'success');
                    fetchCourse();
                    setQualification(initial)
                } else {
                    Swal.fire('Error!', 'Failed to delete course.', 'error');
                }
            } catch (error) {
                Swal.fire('Error!', 'Failed to delete course.', 'error');
            }
        }
    }

    // useEffect(() => {
    //     if (selectedCourse) {
    //         setQualification({
    //             qualificationId: selectedCourse?.highestEducationDetails?._id,
    //             name: selectedCourse?.name
    //         });
    //     }
    // }, [selectedCourse]);

    useEffect(() => {
        dispatch(GetQualification());
        fetchCourse()
    }, []);
    return (
        <div className="main_wrap">
            <div className="aboutUs_page">
                <h4 className="Pageheadline">
                    {isEdit
                        ? "Edit Course"
                        : "Add Course"}
                </h4>
                <form action=""
                >
                    <div className="row">
                        <div className="col-md-6 col-12">
                            <label htmlFor="">Select Highest Qualification</label>
                            <select name="" id="selectOption" className="form-control"
                                onChange={(e) => setQualification({ ...qualification, qualificationId: e.target.value })}
                            >

                                <option> Select qualification</option>
                                {QualificationData && QualificationData?.length > 0 &&
                                    QualificationData?.map((ele, ind) => {
                                        return (
                                            <option value={ele?._id}>{ele?.name}</option>

                                        )
                                    })
                                }
                            </select>
                        </div>

                        <div className="col-md-6 col-12">
                            <label htmlFor="">Course</label>
                            <input
                                type="text"
                                placeholder="Enter course name"
                                className="form-control"
                                name="name"
                                value={qualification.name}
                                onChange={(e) => setQualification({ ...qualification, name: e.target.value })}
                            />
                        </div>


                        <div
                            className="col-md-6 col-12"
                            style={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "flex-start",
                                alignItems: "end",
                            }}
                        >
                            {isEdit ? (
                                <button type="button" onClick={(e) => submitHandler({ e, val: "update", id: cour_ID, type: "updates" })} className="btn btn-primary mt-2">
                                    Update
                                </button>
                            ) : (
                                <button type="button" onClick={(e) => submitHandler({ e, val: "submit" })} className="btn btn-primary mt-2">
                                    Add
                                </button>
                            )}
                        </div>
                    </div>
                </form>
            </div>

            <div>
                <DataTable
                    columns={columns}
                    data={qualificationData}
                    pagination={true}
                    responsive={true}
                    highlightOnHover={true}
                />
            </div>
        </div>
    )
}

export default AddCourse
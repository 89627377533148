import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  GetHeight,
  PostHeight,
  UpdateHeight,
} from "../../../redux/AdminSlice/HeightSlice";

const AddHeight = () => {
  const initial = {
    name: "",
    feet: "",
    inch: "",
  };
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [height, setHeight] = useState(initial);

  console.log("height", height);
  const changehandler = (e) => {
    setHeight((prev) => ({
      ...prev,
      name: e.target.value,
    }));
    convertToFeet(e.target.value);
  };

  const convertToFeet = (val) => {
    const totalInches = val * 0.393701; // Convert centimeters to inches
    const feetValue = Math.floor(totalInches / 12);
    const inchValue = Math.round(totalInches % 12);

    if (inchValue === 12) {
      setHeight((prev) => ({
        ...prev,
        feet: feetValue + 1,
        inch: 0,
      }));
    } else {
      setHeight((prev) => ({
        ...prev,
        feet: feetValue,
        inch: inchValue,
      }));
    }
  };

  useEffect(() => {
    if (location.state) {
      setHeight({
        name: location.state.name,
        feet: location.state.feet,
        inch: location.state.inch,
      });
    }
  }, [location.state]);

  const submitHandler = (e) => {
    e.preventDefault();
    if (height.name === "") {
      toast.error("height Is required");
      return false;
    }
    let data = {
      name: height.name,
      feet: height.feet,
      inch: height.inch,
    };

    if (location.state) {
      const id = location.state._id;
      dispatch(UpdateHeight({ id, data })).then(() => {
        setHeight(initial);
        dispatch(GetHeight());
        navigate("/manageHeight");
      });
    } else {
      dispatch(PostHeight(data)).then(() => {
        setHeight(initial);
      });
    }
  };
  return (
    <div className="main_wrap">
      <div className="aboutUs_page">
        <h4 className="Pageheadline">
          {location.state ? "Edit Height  " : "Add Height "}
        </h4>
        <form action="" className=" m-0" onSubmit={(e) => submitHandler(e)}>
          <div className="row">
          <div className="form-group col-md-3 col-12">
            <label htmlFor="">Height in cm</label>
            <div className="Height_inpt">
              <input
                type="text"
                placeholder="Enter Height"
                className="form-control"
                name="name"
                value={height.name}
                onChange={(e) => changehandler(e)}
              />
            </div>
            <div className="height_unit">
              <h6>CM</h6>
            </div>
          </div>

          {/* Feet */}
          <div className="form-group col-md-3 col-12">
            <label for="feet">Feet</label>
            <input
              type="text"
              class="form-control"
              id="feet"
              name="feet"
              value={height.feet}
              disabled
            />
          </div>
          {/* Inch */}
          <div className="form-group col-md-3 col-12">
            <label for="inch">Inch</label>
            <input
              type="text"
              class="form-control"
              id="inch"
              name="inch"
              value={height.inch}
              disabled
            />
          </div>

          <div
          className="col-md-3 col-12"
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-start",
              alignItems: 'end'
            }}
          >
            {location.state ? (
              <button type="submit" className="btn btn-primary">
                Submit
              </button>
            ) : (
              <button type="submit" className="btn btn-primary">
                Add
              </button>
            )}
          </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddHeight;

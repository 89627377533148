import React, { useEffect, useMemo, useState } from "react";
import Table from "../../Table/Table";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  DeleteReligian,
  GetReligian,
} from "../../../redux/AdminSlice/ReligionSlice";
import { DeleteConfirmModal } from "../../../DeleteAlert/DeleteAlert";
import { getProfileDetails } from "../../../redux/AdminSlice/profileSlice";

const ManageReligion = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchText, setsearchText] = useState("");
  const token = localStorage.getItem("Authorization");
  const { profile_data } = useSelector((state) => state.profileReducer);
  const religionData = useSelector(
    (state) => state.religionReducer.ReligianData
  );

  // console.log("religionData", religionData);

  useEffect(() => {
    dispatch(GetReligian());
    dispatch(getProfileDetails(token));
  }, []);

  const deleteProfile = (id) => {
    const del = async () => {
      dispatch(DeleteReligian(id)).then(() => {
        dispatch(GetReligian());
      });
    };

    DeleteConfirmModal(del);
  };

  const filterData = useMemo(() => {
    if (searchText !== "") {
      return religionData?.filter((item) =>
        JSON.stringify(item).toLowerCase().includes(searchText.toLowerCase())
      );
    }
    return religionData;
  }, [searchText, religionData]);

  const tableData = useMemo(() => {
    return {
      columns: [
        {
          label: "SL",
          field: "sl",
          sort: "asc",
          width: 270,
        },
        {
          label: "Religion Name",
          field: "religion",
          sort: "asc",
          width: 150,
        },
        {
          label: "Religion Image",
          field: "religionimg",
          sort: "asc",
          width: 150,
        },
        {
          label: "Action",
          field: "action",
          sort: "asc",
          width: 150,
        },
      ],
      rows:
        filterData?.length > 0
          ? filterData.map((item, idx) => {
              console.log("item", item);
              return {
                sl: idx + 1,
                religion: (
                  <div className="widget-content p-0" key={idx}>
                    <div className="widget-content-wrapper">
                      <div className="widget-content-left flex2">
                        <div className="widget-heading" style={{ opacity: 1 }}>
                          {item.name}
                        </div>
                      </div>
                    </div>
                  </div>
                ),
                religionimg: (
                  <div className="widget-content p-0" key={idx}>
                    <div className="widget-content-wrapper">
                      <div className="widget-content-left flex2">
                        <div className="widget-heading" style={{ opacity: 1 }}>
                          <img
                            style={{
                              height: "50px",
                              width: "50px",
                              borderRadius: "50%",
                            }}
                            src={item.image}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ),
                action: (
                  <div className="widget-content p-0" key={idx}>
                    <div className="widget-content-wrapper">
                      <div className="widget-content-center flex2">
                        {profile_data?.tabPermission?.[0]
                          ?.manageReligionEdit && (
                          <i
                            class="fa-solid fa-pencil"
                            style={{ color: "blue", cursor: "pointer" }}
                            onClick={() =>
                              navigate(`/addReligion`, { state: item })
                            }
                          ></i>
                        )}

                        {profile_data?.tabPermission?.[0]
                          ?.manageReligionDelete && (
                          <i
                            class="fa-solid fa-trash-can"
                            style={{
                              color: "red",
                              marginLeft: "15px",
                              cursor: "pointer",
                            }}
                            onClick={() => deleteProfile(item._id)}
                          ></i>
                        )}
                      </div>
                    </div>
                  </div>
                ),
              };
            })
          : [],
    };
  });
  return (
    <>
      <div className="main_wrap">
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <h4 className="Pageheadline">Manage Religion</h4>
          {profile_data?.tabPermission?.[0]?.addReligion && (
            <button
              className="btn btn-primary"
              style={{ height: "33px" }}
              onClick={() => navigate("/addReligion")}
            >
              Add
            </button>
          )}
        </div>
        <div style={{ overflowX: "auto" }} className="dataTableParDiv">
          <Table
            striped
            tableTitle="All abouts"
            data={tableData}
            onSearch={(e) => setsearchText(e.target.value)}
          />
        </div>
      </div>
    </>
  );
};

export default ManageReligion;

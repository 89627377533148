import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Table from "../../Table/Table";
import {
  DeleteProfileIsForData,
  GetProfileIsForData,
} from "../../../redux/AdminSlice/ProfileForSlice";
import { DeleteConfirmModal } from "../../../DeleteAlert/DeleteAlert";
import { getProfileDetails } from "../../../redux/AdminSlice/profileSlice";

const ManageProfileIsFor = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [changeState, setChangeState] = useState(false);
  const [searchText, setsearchText] = useState("");
  const token = localStorage.getItem("Authorization");

  const profileIdForData = useSelector(
    (state) => state.profileIsForReducer.ProfileIsForData
  );
  const { profile_data } = useSelector((state) => state.profileReducer);

  useEffect(() => {
    dispatch(GetProfileIsForData());
    dispatch(getProfileDetails(token));
  }, []);

  const deleteProfile = (id) => {
    const del = async () => {
      dispatch(DeleteProfileIsForData(id)).then(() => {
        dispatch(GetProfileIsForData());
      });
    };

    DeleteConfirmModal(del);
  };

  const filterData = useMemo(() => {
    if (searchText !== "") {
      return profileIdForData?.filter((item) =>
        JSON.stringify(item).toLowerCase().includes(searchText.toLowerCase())
      );
    }
    return profileIdForData;
  }, [searchText, profileIdForData]);

  const handleView = (e, id) => {
    e.preventDefault();
    console.log("id", id);
    navigate(`/addProfileIsFor?id=${id}`);
  };

  const tableData = useMemo(() => {
    return {
      columns: [
        {
          label: "SL",
          field: "sl",
          sort: "asc",
          width: 270,
        },
        {
          label: "Profile Is For",
          field: "profileIsFor",
          sort: "asc",
          width: 150,
        },

        {
          label: "Action",
          field: "action",
          sort: "asc",
          width: 150,
        },
      ],
      rows:
        filterData?.length > 0
          ? filterData.map((item, idx) => {
              console.log("item", item);
              return {
                sl: idx + 1,
                profileIsFor: (
                  <div className="widget-content p-0" key={idx}>
                    <div className="widget-content-wrapper">
                      <div className="widget-content-left flex2">
                        <div className="widget-heading" style={{ opacity: 1 }}>
                          {item.name}
                        </div>
                      </div>
                    </div>
                  </div>
                ),
                action: (
                  <div className="widget-content p-0" key={idx}>
                    <div className="widget-content-wrapper">
                      <div className="widget-content-center flex2">
                        {profile_data?.tabPermission?.[0]
                          ?.managaeProfileEdit && (
                          <i
                            class="fa-solid fa-pencil"
                            style={{ color: "blue", cursor: "pointer" }}
                            onClick={(e) => handleView(e, item._id)}
                          ></i>
                        )}

                        {profile_data?.tabPermission?.[0]
                          ?.managaeProfileDelete && (
                          <i
                            class="fa-solid fa-trash-can"
                            style={{
                              color: "red",
                              marginLeft: "15px",
                              cursor: "pointer",
                            }}
                            onClick={() => deleteProfile(item._id)}
                          ></i>
                        )}
                      </div>
                    </div>
                  </div>
                ),
              };
            })
          : [],
    };
  });
  return (
    <>
      <div className="main_wrap">
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <h4 className="Pageheadline">Manage Profile Is For</h4>
          {profile_data?.tabPermission?.[0]?.addProfile && (
            <button
              className="btn btn-primary"
              style={{ height: "33px" }}
              onClick={() => navigate("/addProfileIsFor")}
            >
              Add
            </button>
          )}
        </div>
        <div style={{ overflowX: "auto" }} className="dataTableParDiv">
          <Table
            striped
            tableTitle="All abouts"
            data={tableData}
            onSearch={(e) => setsearchText(e.target.value)}
          />
        </div>
      </div>
    </>
  );
};

export default ManageProfileIsFor;

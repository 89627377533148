import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify"
import { fileUpload } from "../../utils/HttpClient";

let initialState = {
    status: "idle",
    bannerImg: [],
    bannerTitle:"",
    bannerDesc:"",
    company_logo:"",
    isLoading: false,
    isSuccess: false,
    isError: false,
  };


  export const uploadCompanyLogo = createAsyncThunk(
    "upload_company_logo",
    async (data) => {
      console.log("company_logo", data)
      let response = await fileUpload({
        urlPath: "admin/upload-companyLogo",
        method: "POST",
        data: data,
      });
      console.log("contact_Logo", response);
  
      if (response.status === 200) {
        toast.success("Logo added successfully");
        return response.data;
      } else {
        toast.error("Can't add logo. Something went wrong");
      }
    }
  );
  
  export const uploadBannerImage = createAsyncThunk("Upload_Banner_Image", async (data) => {
    let response = await fileUpload({
      urlPath: "admin/upload-bannerImages",
      method: "POST",
      data: data
    });
    console.log("contact_details", response);
  
    if (response.status === 200) {
        toast.success("Banner Image uploaded successfully")
      return response.data;

    } else {
      toast.error("Can't upload banner. Something went wrong");
    }
  });

  export const uploadBanner = createAsyncThunk("Upload_Banner", async (data) => {
    let response = await fileUpload({
      urlPath: "admin/view-contactUs",
      method: "POST",
      data: data
    });
    console.log("contact_details", response);
  
    if (response.status === 200) {
        toast.success("Banner uploaded successfully")
      return response.data;

    } else {
      toast.error("Can't upload banner. Something went wrong");
    }
  });
  
  export const logoAndBannerSlice = createSlice({
    name: "banner_details_data",
    initialState,

    reducers: {
      clearState: (state) => {
        state.status= "idle";
        state.bannerImg= [];
        state.bannerTitle="";
        state.bannerDesc="";
        state.company_logo="";
        state.isLoading= false;
        state.isSuccess= false;
        state.isError=false;
      }
    },


    extraReducers: (builder) => {
      builder
      .addCase(uploadCompanyLogo.pending, (state) => {
          state.status = "loading";
          state.isLoading = true;
        })
        .addCase(uploadCompanyLogo.fulfilled, (state, { payload }) => {
          state.status = "success";
          state.isSuccess = true;
          console.log("company_logo", payload)
          state.company_logo = payload.data
        })
        .addCase(uploadCompanyLogo.rejected, (state) => {
          state.status = "failed";
          state.isError = true;
          state.isSuccess = false;
        })
        .addCase(uploadBannerImage.pending, (state) => {
          state.status = "loading";
          state.isLoading = true;
        })
        .addCase(uploadBannerImage.fulfilled, (state, { payload }) => {
          state.status = "success";
          state.isSuccess = true;
          console.log('Banner_image', payload);
          state.contactData = payload?.data;
        })
        .addCase(uploadBannerImage.rejected, (state) => {
          state.status = "failed";
          state.isError = true;
          state.isSuccess = false;
        });
    },
  });
  
  export const { clearState } = logoAndBannerSlice.actions;
  
  export default logoAndBannerSlice.reducer;
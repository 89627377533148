import React from "react";
// import { toast } from "react-hot-toast";
import { Link, NavLink, useNavigate } from "react-router-dom";
import logo from "../../src/Images/logo.jpeg";
import { useSelector } from "react-redux";

function Sidebar() {
  const { profile_data } = useSelector((state) => state.profileReducer);

  // console.log("profile_data", profile_data);
  const navigate = useNavigate();
  // const handleClick = () => {
  //   if (document.querySelector("#DarkSwitch").checked) {
  //     document.body.classList.add("drakmode");
  //   } else {
  //     document.body.classList.remove("drakmode");
  //   }
  // };

  const handlClick = () => {
    if (document.querySelector("#backbtnsidebar").click) {
      document.querySelector(".sidebarwrap").classList.remove("show");
    } else {
      document.querySelector(".sidebarwrap").classList.remove("show");
    }
  };

  const handleLogOut = () => {
    localStorage.clear();
    navigate("/login");
  };

  return (
    <>
      <section className="sidebarwrap">
        <div className="top_area">
          <div className="logo_area">
            <div className="logo">
              <img src={logo} className="img-fluid" alt="logo" />
            </div>
          </div>

          <button id="backbtnsidebar" onClick={handlClick}>
            <i className="fa-solid fa-arrow-left"></i>
          </button>
        </div>

        <div className="sidebar_menu">
          <ul className="nav Menu_Nav accordion" id="sidemenu">
            {/***Menu 1***/}

            {profile_data?.tabPermission?.[0]?.dashboard && (
              <li className="menuline">
                <NavLink to="/" className="" onClick={handlClick}>
                  <i className="fa-solid fa-bars"></i>
                  <span>Dashboard</span>
                </NavLink>
              </li>
            )}

            {/*** ========== Tag 1 =============== ***/}
            <span className="sidebartagheding">Master</span>

            {/***Profile is for***/}
            <li className="menuline">
              {profile_data?.tabPermission?.[0]?.profileIdFor && (
                <div className="menu-head" id="sidemenuhead1">
                  <Link
                    to="#"
                    className="btn btn-header-link"
                    data-toggle="collapse"
                    data-target="#sidemenu1"
                    aria-expanded="true"
                    aria-controls="sidemenu1"
                  >
                    <i className="fa-regular fa-user"></i>
                    <span>Profile is for</span>
                  </Link>
                </div>
              )}

              <div
                id="sidemenu1"
                className="collapse"
                aria-labelledby="sidemenuhead1"
                data-parent="#sidemenu"
              >
                <ul className="Submenu">
                  {profile_data?.tabPermission?.[0]?.addProfile && (
                    <li>
                      <NavLink to="/addProfileIsFor" onClick={handlClick}>
                        <i className="fa-solid fa-angles-right mr-2"></i>Add
                        Profile
                      </NavLink>
                    </li>
                  )}
                  {profile_data?.tabPermission?.[0]?.manageProfile && (
                    <li>
                      <NavLink to="/manageProfileIsFor" onClick={handlClick}>
                        <i className="fa-solid fa-angles-right mr-2"></i>Manage
                        Profile
                      </NavLink>
                    </li>
                  )}
                </ul>
              </div>
            </li>

            {/***Religion***/}
            <li className="menuline">
              {profile_data?.tabPermission?.[0]?.religion && (
                <div className="menu-head" id="sidemenuhead2">
                  <Link
                    to="#"
                    className="btn btn-header-link"
                    data-toggle="collapse"
                    data-target="#sidemenu2"
                    aria-expanded="true"
                    aria-controls="sidemenu2"
                  >
                    <i className="fa-regular fa-flag"></i>
                    <span>Religion</span>
                  </Link>
                </div>
              )}

              <div
                id="sidemenu2"
                className="collapse"
                aria-labelledby="sidemenuhead2"
                data-parent="#sidemenu"
              >
                <ul className="Submenu">
                  {profile_data?.tabPermission?.[0]?.addReligion && (
                    <li>
                      <NavLink to="/addReligion" onClick={handlClick}>
                        <i className="fa-solid fa-angles-right mr-2"></i>Add
                        Religion
                      </NavLink>
                    </li>
                  )}

                  {profile_data?.tabPermission?.[0]?.manageReligion && (
                    <li>
                      <NavLink to="/manageReligion" onClick={handlClick}>
                        <i className="fa-solid fa-angles-right mr-2"></i>Manage
                        Religion
                      </NavLink>
                    </li>
                  )}
                </ul>
              </div>
            </li>

            {/***Community***/}
            <li className="menuline">
              {profile_data?.tabPermission?.[0]?.community && (
                <div className="menu-head" id="sidemenuhead3">
                  <Link
                    to="#"
                    className="btn btn-header-link"
                    data-toggle="collapse"
                    data-target="#sidemenu3"
                    aria-expanded="true"
                    aria-controls="sidemenu3"
                  >
                    <i className="fa-regular fa-face-smile"></i>
                    <span>Community</span>
                  </Link>
                </div>
              )}

              <div
                id="sidemenu3"
                className="collapse"
                aria-labelledby="sidemenuhead3"
                data-parent="#sidemenu"
              >
                <ul className="Submenu">
                  {profile_data?.tabPermission?.[0]?.addCommunity && (
                    <li>
                      <NavLink to="/addCommunity" onClick={handlClick}>
                        <i className="fa-solid fa-angles-right mr-2"></i>Add
                        Community
                      </NavLink>
                    </li>
                  )}
                  {profile_data?.tabPermission?.[0]?.manageCommunity && (
                    <li>
                      <NavLink to="/manageCommunity" onClick={handlClick}>
                        <i className="fa-solid fa-angles-right mr-2"></i>Manage
                        Community
                      </NavLink>
                    </li>
                  )}
                </ul>
              </div>
            </li>

            {/*** Sub community ***/}
            <li className="menuline">
              {profile_data?.tabPermission?.[0]?.community && (
                <div className="menu-head" id="sidemenuhead4">
                  <Link
                    to="#"
                    className="btn btn-header-link"
                    data-toggle="collapse"
                    data-target="#sidemenu4"
                    aria-expanded="true"
                    aria-controls="sidemenu4"
                  >
                    <i className="fa-solid fa-people-carry-box"></i>
                    <span>Sub community</span>
                  </Link>
                </div>
              )}

              <div
                id="sidemenu4"
                className="collapse"
                aria-labelledby="sidemenuhead4"
                data-parent="#sidemenu"
              >
                <ul className="Submenu">
                  {profile_data?.tabPermission?.[0]?.addSubCommunity && (
                    <li>
                      <NavLink to="/addSubCommunity" onClick={handlClick}>
                        <i className="fa-solid fa-angles-right mr-2"></i>Add Sub
                        community
                      </NavLink>
                    </li>
                  )}
                  {profile_data?.tabPermission?.[0]?.manageSubCommunity && (
                    <li>
                      <NavLink to="/manageSubCommunity" onClick={handlClick}>
                        <i className="fa-solid fa-angles-right mr-2"></i>Manage
                        Sub community
                      </NavLink>
                    </li>
                  )}
                </ul>
              </div>
            </li>

            {/***Caste***/}
            <li className="menuline">
              {profile_data?.tabPermission?.[0]?.caste && (
                <div className="menu-head" id="sidemenuhead5">
                  <Link
                    to="#"
                    className="btn btn-header-link"
                    data-toggle="collapse"
                    data-target="#sidemenu5"
                    aria-expanded="true"
                    aria-controls="sidemenu5"
                  >
                    <i className="fa-brands fa-fort-awesome"></i>
                    <span>Caste</span>
                  </Link>
                </div>
              )}

              <div
                id="sidemenu5"
                className="collapse"
                aria-labelledby="sidemenuhead5"
                data-parent="#sidemenu"
              >
                <ul className="Submenu">
                  {profile_data?.tabPermission?.[0]?.addcaste && (
                    <li>
                      <NavLink to="/addCast" onClick={handlClick}>
                        <i className="fa-solid fa-angles-right mr-2"></i>Add
                        Caste
                      </NavLink>
                    </li>
                  )}
                  {profile_data?.tabPermission?.[0]?.manageCaste && (
                    <li>
                      <NavLink to="/manageCast" onClick={handlClick}>
                        <i className="fa-solid fa-angles-right mr-2"></i>Manage
                        Caste
                      </NavLink>
                    </li>
                  )}
                </ul>
              </div>
            </li>

            {/***Marital status***/}
            <li className="menuline">
              {profile_data?.tabPermission?.[0]?.maritalStatus && (
                <div className="menu-head" id="sidemenuhead6">
                  <Link
                    to="#"
                    className="btn btn-header-link"
                    data-toggle="collapse"
                    data-target="#sidemenu6"
                    aria-expanded="true"
                    aria-controls="sidemenu6"
                  >
                    <i className="fa-solid fa-restroom"></i>
                    <span>Marital status</span>
                  </Link>
                </div>
              )}

              <div
                id="sidemenu6"
                className="collapse"
                aria-labelledby="sidemenuhead6"
                data-parent="#sidemenu"
              >
                <ul className="Submenu">
                  {profile_data?.tabPermission?.[0]?.addMaritalStatus && (
                    <li>
                      <NavLink to="/addMarital" onClick={handlClick}>
                        <i className="fa-solid fa-angles-right mr-2"></i>Add
                        Marital status
                      </NavLink>
                    </li>
                  )}

                  {profile_data?.tabPermission?.[0]?.manageMaritalStatus && (
                    <li>
                      <NavLink to="/manageMarital" onClick={handlClick}>
                        <i className="fa-solid fa-angles-right mr-2"></i>Manage
                        Marital status
                      </NavLink>
                    </li>
                  )}
                </ul>
              </div>
            </li>

            {/***Height***/}
            {/* <li className="menuline">
              <div className="menu-head" id="sidemenuhead8">
                <Link
                  to="#"
                  className="btn btn-header-link"
                  data-toggle="collapse"
                  data-target="#sidemenu8"
                  aria-expanded="true"
                  aria-controls="sidemenu8"
                >
                  <i className="fa-solid fa-person-half-dress"></i>
                  <span>Height</span>
                </Link>
              </div>
              <div
                id="sidemenu8"
                className="collapse"
                aria-labelledby="sidemenuhead8"
                data-parent="#sidemenu"
              >
                <ul className="Submenu">
                  <li>
                    <NavLink to="/addHeight" onClick={handlClick}>
                      <i className="fa-solid fa-angles-right mr-2"></i>Add
                      Height
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/manageHeight" onClick={handlClick}>
                      <i className="fa-solid fa-angles-right mr-2"></i>Manage
                      Height
                    </NavLink>
                  </li>
                </ul>
              </div>
            </li> */}

            {/***Diet***/}
            <li className="menuline">
              {profile_data?.tabPermission?.[0]?.diet && (
                <div className="menu-head" id="sidemenuhead9">
                  <Link
                    to="#"
                    className="btn btn-header-link"
                    data-toggle="collapse"
                    data-target="#sidemenu9"
                    aria-expanded="true"
                    aria-controls="sidemenu9"
                  >
                    <i className="fa-brands fa-codiepie"></i>
                    <span>Diet</span>
                  </Link>
                </div>
              )}

              <div
                id="sidemenu9"
                className="collapse"
                aria-labelledby="sidemenuhead9"
                data-parent="#sidemenu"
              >
                <ul className="Submenu">
                  {profile_data?.tabPermission?.[0]?.adddiet && (
                    <li>
                      <NavLink to="/addDiet" onClick={handlClick}>
                        <i className="fa-solid fa-angles-right mr-2"></i>Add
                        Diet
                      </NavLink>
                    </li>
                  )}

                  {profile_data?.tabPermission?.[0]?.managediet && (
                    <li>
                      <NavLink to="/manageDiet" onClick={handlClick}>
                        <i className="fa-solid fa-angles-right mr-2"></i>Manage
                        Diet
                      </NavLink>
                    </li>
                  )}
                </ul>
              </div>
            </li>

            {/***Complexion***/}
            <li className="menuline">
              {profile_data?.tabPermission?.[0]?.complexion && (
                <div className="menu-head" id="sidemenuhead10">
                  <Link
                    to="#"
                    className="btn btn-header-link"
                    data-toggle="collapse"
                    data-target="#sidemenu10"
                    aria-expanded="true"
                    aria-controls="sidemenu10"
                  >
                    <i className="fa-solid fa-list"></i>
                    <span>Complexion</span>
                  </Link>
                </div>
              )}

              <div
                id="sidemenu10"
                className="collapse"
                aria-labelledby="sidemenuhead10"
                data-parent="#sidemenu"
              >
                <ul className="Submenu">
                  {profile_data?.tabPermission?.[0]?.addcomplexion && (
                    <li>
                      <NavLink to="/addComplexion" onClick={handlClick}>
                        <i className="fa-solid fa-angles-right mr-2"></i>Add
                        Complexion
                      </NavLink>
                    </li>
                  )}

                  {profile_data?.tabPermission?.[0]?.managecomplexion && (
                    <li>
                      <NavLink to="/manageComplexion" onClick={handlClick}>
                        <i className="fa-solid fa-angles-right mr-2"></i>Manage
                        Complexion
                      </NavLink>
                    </li>
                  )}
                </ul>
              </div>
            </li>

            {/***Highest qualification***/}
            <li className="menuline">
              {profile_data?.tabPermission?.[0]?.highestQualification && (
                <div className="menu-head" id="sidemenuhead11">
                  <Link
                    to="#"
                    className="btn btn-header-link"
                    data-toggle="collapse"
                    data-target="#sidemenu11"
                    aria-expanded="true"
                    aria-controls="sidemenu11"
                  >
                    <i className="fa-solid fa-medal"></i>
                    <span>Highest qualification</span>
                  </Link>
                </div>
              )}

              <div
                id="sidemenu11"
                className="collapse"
                aria-labelledby="sidemenuhead11"
                data-parent="#sidemenu"
              >
                <ul className="Submenu">
                  {profile_data?.tabPermission?.[0]
                    ?.addhighestQualification && (
                      <li>
                        <NavLink to="/addQualification" onClick={handlClick}>
                          <i className="fa-solid fa-angles-right mr-2"></i>Add
                          Highest Qualification
                        </NavLink>
                      </li>
                    )}

                  {profile_data?.tabPermission?.[0]
                    ?.managehighestQualification && (
                      <li>
                        <NavLink to="/manageQualification" onClick={handlClick}>
                          <i className="fa-solid fa-angles-right mr-2"></i>Manage
                          Highest Qualification
                        </NavLink>
                      </li>
                    )}

                  <li>
                    <NavLink to="/add-course" onClick={handlClick}>
                      <i className="fa-solid fa-angles-right mr-2"></i>Add
                      Course
                    </NavLink>
                  </li>
                  {/* <li>
                    <NavLink to="/manage-course" onClick={handlClick}>
                      <i className="fa-solid fa-angles-right mr-2"></i>Manage
                      Course
                    </NavLink>
                  </li> */}
                </ul>
              </div>
            </li>


            {/***Occupation***/}
            <li className="menuline">
              {profile_data?.tabPermission?.[0]?.occupation && (
                <div className="menu-head" id="sidemenuhead12">
                  <Link
                    to="#"
                    className="btn btn-header-link"
                    data-toggle="collapse"
                    data-target="#sidemenu12"
                    aria-expanded="true"
                    aria-controls="sidemenu12"
                  >
                    <i className="fa-solid fa-briefcase"></i>
                    <span>Occupation</span>
                  </Link>
                </div>
              )}

              <div
                id="sidemenu12"
                className="collapse"
                aria-labelledby="sidemenuhead12"
                data-parent="#sidemenu"
              >
                <ul className="Submenu">
                  {profile_data?.tabPermission?.[0]?.addoccupation && (
                    <li>
                      <NavLink to="/addOcupation" onClick={handlClick}>
                        <i className="fa-solid fa-angles-right mr-2"></i>Add
                        Occupation
                      </NavLink>
                    </li>
                  )}

                  {profile_data?.tabPermission?.[0]?.manageoccupation && (
                    <li>
                      <NavLink to="/manageOcupation" onClick={handlClick}>
                        <i className="fa-solid fa-angles-right mr-2"></i>Manage
                        Occupation
                      </NavLink>
                    </li>
                  )}
                </ul>
              </div>
            </li>

            {/***Annual income***/}
            <li className="menuline">
              <div className="menu-head" id="sidemenuhead13">
                <Link
                  to="#"
                  className="btn btn-header-link"
                  data-toggle="collapse"
                  data-target="#sidemenu13"
                  aria-expanded="true"
                  aria-controls="sidemenu13"
                >
                  <i className="fa-solid fa-sack-dollar"></i>
                  <span>Annual income</span>
                </Link>
              </div>
              <div
                id="sidemenu13"
                className="collapse"
                aria-labelledby="sidemenuhead13"
                data-parent="#sidemenu"
              >
                <ul className="Submenu">
                  <li>
                    <NavLink to="/addAnualIncome" onClick={handlClick}>
                      <i className="fa-solid fa-angles-right mr-2"></i>Add
                      Annual income
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/manageAnualIncome" onClick={handlClick}>
                      <i className="fa-solid fa-angles-right mr-2"></i>Manage
                      Annual income
                    </NavLink>
                  </li>
                </ul>
              </div>
            </li>

            {/***Zodiac sign***/}
            <li className="menuline">
              {profile_data?.tabPermission?.[0]?.zodiacSign && (
                <div className="menu-head" id="sidemenuhead14">
                  <Link
                    to="#"
                    className="btn btn-header-link"
                    data-toggle="collapse"
                    data-target="#sidemenu14"
                    aria-expanded="true"
                    aria-controls="sidemenu14"
                  >
                    <i className="fa-solid fa-sack-dollar"></i>
                    <span>Zodiac sign</span>
                  </Link>
                </div>
              )}

              <div
                id="sidemenu14"
                className="collapse"
                aria-labelledby="sidemenuhead14"
                data-parent="#sidemenu"
              >
                <ul className="Submenu">
                  {profile_data?.tabPermission?.[0]?.addzodiacSign && (
                    <li>
                      <NavLink to="/addZodiacSign" onClick={handlClick}>
                        <i className="fa-solid fa-angles-right mr-2"></i>Add
                        Zodiac sign
                      </NavLink>
                    </li>
                  )}

                  {profile_data?.tabPermission?.[0]?.managezodiacSign && (
                    <li>
                      <NavLink to="/manageZodiacSign" onClick={handlClick}>
                        <i className="fa-solid fa-angles-right mr-2"></i>Manage
                        Zodiac sign
                      </NavLink>
                    </li>
                  )}
                </ul>
              </div>
            </li>

            {/* country */}
            <li className="menuline">
              {profile_data?.tabPermission?.[0]?.country && (
                <div className="menu-head" id="sidemenuhead21">
                  <Link
                    to="#"
                    className="btn btn-header-link"
                    data-toggle="collapse"
                    data-target="#sidemenu21"
                    aria-expanded="true"
                    aria-controls="sidemenu21"
                  >
                    <i className="fa-solid fa-sack-dollar"></i>
                    <span>Country</span>
                  </Link>
                </div>
              )}

              <div
                id="sidemenu21"
                className="collapse"
                aria-labelledby="sidemenuhead21"
                data-parent="#sidemenu"
              >
                <ul className="Submenu">
                  {profile_data?.tabPermission?.[0]?.addcountry && (
                    <li>
                      <NavLink to="/addCountry" onClick={handlClick}>
                        <i className="fa-solid fa-angles-right mr-2"></i>Add
                        Country
                      </NavLink>
                    </li>
                  )}

                  {profile_data?.tabPermission?.[0]?.managecountry && (
                    <li>
                      <NavLink to="/ManageCountry" onClick={handlClick}>
                        <i className="fa-solid fa-angles-right mr-2"></i>Manage
                        Country
                      </NavLink>
                    </li>
                  )}
                </ul>
              </div>
            </li>

            {/* State */}
            <li className="menuline">
              {profile_data?.tabPermission?.[0]?.state && (
                <div className="menu-head" id="sidemenuhead22">
                  <Link
                    to="#"
                    className="btn btn-header-link"
                    data-toggle="collapse"
                    data-target="#sidemenu22"
                    aria-expanded="true"
                    aria-controls="sidemenu22"
                  >
                    <i className="fa-solid fa-sack-dollar"></i>
                    <span>State</span>
                  </Link>
                </div>
              )}

              <div
                id="sidemenu22"
                className="collapse"
                aria-labelledby="sidemenuhead22"
                data-parent="#sidemenu"
              >
                <ul className="Submenu">
                  {profile_data?.tabPermission?.[0]?.addstate && (
                    <li>
                      <NavLink to="/addState" onClick={handlClick}>
                        <i className="fa-solid fa-angles-right mr-2"></i>Add
                        State
                      </NavLink>
                    </li>
                  )}

                  {profile_data?.tabPermission?.[0]?.managestate && (
                    <li>
                      <NavLink to="/manageState" onClick={handlClick}>
                        <i className="fa-solid fa-angles-right mr-2"></i>Manage
                        State
                      </NavLink>
                    </li>
                  )}
                </ul>
              </div>
            </li>

            {/* city */}
            <li className="menuline">
              {profile_data?.tabPermission?.[0]?.city && (
                <div className="menu-head" id="sidemenuhead23">
                  <Link
                    to="#"
                    className="btn btn-header-link"
                    data-toggle="collapse"
                    data-target="#sidemenu23"
                    aria-expanded="true"
                    aria-controls="sidemenu23"
                  >
                    <i className="fa-solid fa-sack-dollar"></i>
                    <span>City</span>
                  </Link>
                </div>
              )}

              <div
                id="sidemenu23"
                className="collapse"
                aria-labelledby="sidemenuhead23"
                data-parent="#sidemenu"
              >
                <ul className="Submenu">
                  {profile_data?.tabPermission?.[0]?.addcity && (
                    <li>
                      <NavLink to="/addCity" onClick={handlClick}>
                        <i className="fa-solid fa-angles-right mr-2"></i>Add
                        City
                      </NavLink>
                    </li>
                  )}

                  {profile_data?.tabPermission?.[0]?.managecity && (
                    <li>
                      <NavLink to="/manageCity" onClick={handlClick}>
                        <i className="fa-solid fa-angles-right mr-2"></i>Manage
                        City
                      </NavLink>
                    </li>
                  )}
                </ul>
              </div>
            </li>

            {/***=========================Tag 2 ========================***/}
            {profile_data?.tabPermission?.[0]?.manageUser && (
              <>
                <span className="sidebartagheding">User Management</span>

                <li className="menuline">
                  <div className="menu-head" id="sidemenuhead14.5">
                    <NavLink
                      to="/manageUsers"
                      className="btn btn-header-linktxt"
                      data-toggle="collapse"
                      data-target="#sidemenu14.5"
                      aria-expanded="true"
                      aria-controls="sidemenu15"
                    >
                      <i className="fa-solid fa-user"></i>
                      <span>Manage User</span>
                    </NavLink>
                  </div>
                </li>

                <li className="menuline">
                  <div className="menu-head" id="sidemenuhead14.6">
                    <NavLink
                      to="/manage-deletd-users"
                      className="btn btn-header-linktxt"
                      data-toggle="collapse"
                      data-target="#sidemenu14.6"
                      aria-expanded="true"
                      aria-controls="sidemenu16"
                    >
                      <i className="fa-solid fa-trash-can"></i>
                      <span>Deleted User</span>
                    </NavLink>
                  </div>
                </li>

                <li className="menuline">
                  <div className="menu-head" id="sidemenuhead14.6">
                    <NavLink
                      to="/all-updated-user"
                      className="btn btn-header-linktxt"
                      data-toggle="collapse"
                      data-target="#sidemenu14.6"
                      aria-expanded="true"
                      aria-controls="sidemenu16"
                    >
                      <i class="fa-solid fa-user-pen"></i>
                      <span>Updated User</span>
                    </NavLink>
                  </div>
                </li>

              </>
            )}

            {/***=========================Tag 3 ========================***/}
            {profile_data?.tabPermission?.[0]?.accessManagement && (
              <span className="sidebartagheding">Access Management</span>
            )}

            {/***Access management***/}
            <li className="menuline">
              {profile_data?.tabPermission?.[0]?.addaccessManagement && (
                <div className="menu-head" id="sidemenuhead33">
                  <NavLink
                    to="/addEmployee"
                    className="btn btn-header-link"
                    data-toggle="collapse"
                    data-target="#sidemenu33"
                    aria-expanded="true"
                    aria-controls="sidemenu33"
                  >
                    <i className="fa-solid fa-sack-dollar"></i>
                    <span>Add Employee</span>
                  </NavLink>
                </div>
              )}
              {profile_data?.tabPermission?.[0]?.manageaccessManagement && (
                <div className="menu-head" id="sidemenuhead33">
                  <NavLink
                    to="/manageEmployee"
                    className="btn btn-header-link"
                    data-toggle="collapse"
                    data-target="#sidemenu33"
                    aria-expanded="true"
                    aria-controls="sidemenu33"
                  >
                    <i className="fa-solid fa-sack-dollar"></i>
                    <span>Manage Employee</span>
                  </NavLink>
                </div>
              )}
            </li>

            {/***=========================Tag 4 =========================***/}
            {/***View payment history ***/}
            {profile_data?.tabPermission?.[0]?.paymentHistory && (
              <>
                <span className="sidebartagheding">Payment History</span>

                <li className="menuline">
                  <div className="menu-head" id="sidemenuhead15">
                    <NavLink
                      to="/viewpaymenthistory"
                      className="btn btn-header-link"
                      data-toggle="collapse"
                      data-target="#sidemenu15"
                      aria-expanded="true"
                      aria-controls="sidemenu15"
                    >
                      <i className="fa-regular fa-clipboard"></i>
                      <span>View Payment History</span>
                    </NavLink>
                  </div>
                </li>
              </>
            )}

            {/***=========================Tag 5 =========================***/}

            {profile_data?.tabPermission?.[0]?.accountViewPrice && (
              <span className="sidebartagheding">Settings</span>
            )}

            {/*** Add Account view price***/}
            {profile_data?.tabPermission?.[0]?.addaccountViewPrice && (
              <li className="menuline">
                <div className="menu-head" id="sidemenuhead17">
                  <NavLink
                    to="/addPayment"
                    className="btn btn-header-link"
                    data-toggle="collapse"
                    data-target="#sidemenu17"
                    aria-expanded="true"
                    aria-controls="sidemenu17"
                  >
                    <i className="fa-regular fa-clipboard"></i>
                    <span>Add Account View Price</span>
                  </NavLink>
                </div>
              </li>
            )}

            {/***manage Account View Price***/}
            {profile_data?.tabPermission?.[0]?.manageaccountViewPrice && (
              <li className="menuline">
                <div className="menu-head" id="sidemenuhead18">
                  <NavLink
                    to="/managePayment"
                    className="btn btn-header-link"
                    data-toggle="collapse"
                    data-target="#sidemenu18"
                    aria-expanded="true"
                    aria-controls="sidemenu18"
                  >
                    <i className="fa-regular fa-clipboard"></i>
                    <span>Manage Account View Price</span>
                  </NavLink>
                </div>
              </li>
            )}

            {/*** ======================== Tag 6 =================== ***/}
            {profile_data?.tabPermission?.[0]?.contactUs ||
              profile_data?.tabPermission?.[0]?.pages ||
              profile_data?.tabPermission?.[0]?.faq ? (
              <span className="sidebartagheding">CMS</span>
            ) : null}

            {/* ---- contact us ---- */}
            {profile_data?.tabPermission?.[0]?.contactUs && (
              <li className="menuline">
                <div className="menu-head" id="sidemenuhead15">
                  <NavLink
                    to="/addcontactus"
                    className="btn btn-header-link"
                    data-toggle="collapse"
                    data-target="#sidemenu15"
                    aria-expanded="true"
                    aria-controls="sidemenu15"
                  >
                    <i className="fa-regular fa-user"></i>
                    <span>Contact Us</span>
                  </NavLink>
                </div>
              </li>
            )}

            {/*------ about us -------*/}
            {/* <li className="menuline">
              <div className="menu-head" id="sidemenuhead15">
                <Link
                  to="#"
                  className="btn btn-header-link"
                  data-toggle="collapse"
                  data-target="#sidemenu16"
                  aria-expanded="true"
                  aria-controls="sidemenu16"
                >
                  <i className="fa-regular fa-user"></i>
                  <span>About Us</span>
                </Link>
              </div>
              <div
                id="sidemenu16"
                className="collapse"
                aria-labelledby="sidemenuhead16"
                data-parent="#sidemenu16"
              >
                <ul className="Submenu">
                  <li>
                    <NavLink to="/addaboutus" onClick={handlClick}>
                      <i className="fa-solid fa-angles-right mr-2"></i>Add About
                      Us
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/manageaboutus" onClick={handlClick}>
                      <i className="fa-solid fa-angles-right mr-2"></i>Manage
                      About Us
                    </NavLink>
                  </li>
                </ul>
              </div>
            </li> */}

            {/*------ privacy policy -------*/}
            {/* <li className="menuline">
              <div className="menu-head" id="sidemenuhead15">
                <Link
                  to="#"
                  className="btn btn-header-link"
                  data-toggle="collapse"
                  data-target="#sidemenu17"
                  aria-expanded="true"
                  aria-controls="sidemenu17"
                >
                  <i className="fa-regular fa-user"></i>
                  <span>Privacy Policy</span>
                </Link>
              </div>
              <div
                id="sidemenu17"
                className="collapse"
                aria-labelledby="sidemenuhead16"
                data-parent="#sidemenu17"
              >
                <ul className="Submenu">
                  <li>
                    <NavLink to="/addPrivacy" onClick={handlClick}>
                      <i className="fa-solid fa-angles-right mr-2"></i>Add
                      Privacy Policy
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/managePrivacy" onClick={handlClick}>
                      <i className="fa-solid fa-angles-right mr-2"></i>Manage
                      Privacy Policy
                    </NavLink>
                  </li>
                </ul>
              </div>
            </li> */}
            {/*------ pages -------*/}
            <li className="menuline">
              {profile_data?.tabPermission?.[0]?.pages && (
                <div className="menu-head" id="sidemenuhead99">
                  <Link
                    to="#"
                    className="btn btn-header-link"
                    data-toggle="collapse"
                    data-target="#sidemenu99"
                    aria-expanded="true"
                    aria-controls="sidemenu99"
                  >
                    <i className="fa-regular fa-user"></i>
                    <span>Pages</span>
                  </Link>
                </div>
              )}

              <div
                id="sidemenu99"
                className="collapse"
                aria-labelledby="sidemenuhead99"
                data-parent="#sidemenu99"
              >
                <ul className="Submenu">
                  {profile_data?.tabPermission?.[0]?.addpages && (
                    <li>
                      <NavLink to="/addPages" onClick={handlClick}>
                        <i className="fa-solid fa-angles-right mr-2"></i>Add
                        Pages
                      </NavLink>
                    </li>
                  )}

                  {profile_data?.tabPermission?.[0]?.managepages && (
                    <li>
                      <NavLink to="/managePages" onClick={handlClick}>
                        <i className="fa-solid fa-angles-right mr-2"></i>Manage
                        Pages
                      </NavLink>
                    </li>
                  )}
                </ul>
              </div>
            </li>
            {/*------ Faq -------*/}
            <li className="menuline">
              {profile_data?.tabPermission?.[0]?.faq && (
                <div className="menu-head" id="sidemenuhead18">
                  <Link
                    to="#"
                    className="btn btn-header-link"
                    data-toggle="collapse"
                    data-target="#sidemenu18"
                    aria-expanded="true"
                    aria-controls="sidemenu18"
                  >
                    <i className="fa-regular fa-user"></i>
                    <span>FAQ</span>
                  </Link>
                </div>
              )}

              <div
                id="sidemenu18"
                className="collapse"
                aria-labelledby="sidemenuhead18"
                data-parent="#sidemenu18"
              >
                <ul className="Submenu">
                  {profile_data?.tabPermission?.[0]?.addFaq && (
                    <li>
                      <NavLink to="/addFaq" onClick={handlClick}>
                        <i className="fa-solid fa-angles-right mr-2"></i>Add Faq
                      </NavLink>
                    </li>
                  )}

                  {profile_data?.tabPermission?.[0]?.manageFaq && (
                    <li>
                      <NavLink to="/manageFaq" onClick={handlClick}>
                        <i className="fa-solid fa-angles-right mr-2"></i>Manage
                        Faq
                      </NavLink>
                    </li>
                  )}
                </ul>
              </div>
            </li>

            {/*** ===================== Tag 6 =============== ***/}
            {profile_data?.tabPermission?.[0]?.userReport ||
              profile_data?.tabPermission?.[0]?.demographyReport ||
              profile_data?.tabPermission?.[0]?.acccountPurchaseReport ? (
              <span className="sidebartagheding">Reports</span>
            ) : null}

            {/* Users reports */}
            {profile_data?.tabPermission?.[0]?.userReport && (
              <li className="menuline">
                <div className="menu-head" id="sidemenuhead0.1">
                  <NavLink
                    to="/userReport"
                    className="btn btn-header-link"
                    data-toggle="collapse"
                    // data-target="#sidemenu18"
                    aria-expanded="true"
                    aria-controls="sidemenu18"
                  >
                    <i className="fa-regular fa-user"></i>
                    <span>User Report</span>
                  </NavLink>
                </div>
              </li>
            )}

            {/* gender wise report */}
            {/* <li className="menuline">
              <div className="menu-head" id="sidemenuhead0.2">
                <Link
                  to="/genderReport"
                  className="btn btn-header-link"
                  data-toggle="collapse"
                  // data-target="#sidemenu18"
                  aria-expanded="true"
                  // aria-controls="sidemenu18"
                >
                  <i className="fa-regular fa-user"></i>
                  <span>Gender Wise Reports</span>
                </Link>
              </div>
            </li> */}
            {/* Demography reports */}
            {profile_data?.tabPermission?.[0]?.demographyReport && (
              <li className="menuline">
                <div className="menu-head" id="sidemenuhead0.3">
                  <NavLink
                    to="/demographyReport"
                    className="btn btn-header-link"
                    data-toggle="collapse"
                    // data-target="#sidemenu18"
                    aria-expanded="true"
                  // aria-controls="sidemenu18"
                  >
                    <i className="fa-regular fa-user"></i>
                    <span>Demography Report</span>
                  </NavLink>
                </div>
              </li>
            )}

            {/* religion report */}
            {/* <li className="menuline">
              <div className="menu-head" id="sidemenuhead0.4">
                <Link
                  to="religionReport"
                  className="btn btn-header-link"
                  data-toggle="collapse"
                  // data-target="#sidemenu18"
                  aria-expanded="true"
                  // aria-controls="sidemenu18"
                >
                  <i className="fa-regular fa-user"></i>
                  <span>Religion Reports</span>
                </Link>
              </div>
            </li> */}
            {/* community report */}
            {/* <li className="menuline">
              <div className="menu-head">
                <Link
                  to="/communityReport"
                  className="btn btn-header-link"
                  data-toggle="collapse"
                  // data-target="#sidemenu18"
                  aria-expanded="true"
                  // aria-controls="sidemenu18"
                >
                  <i className="fa-regular fa-user"></i>
                  <span>Community Reports</span>
                </Link>
              </div>
            </li> */}

            {/* sub community reports */}
            {/* <li className="menuline">
              <div className="menu-head" id="">
                <Link
                  to="/subCommunityreport"
                  className="btn btn-header-link"
                  data-toggle="collapse"
                  // data-target="#sidemenu18"
                  aria-expanded="true"
                  // aria-controls="sidemenu18"
                >
                  <i className="fa-regular fa-user"></i>
                  <span>Sub Community Reports</span>
                </Link>
              </div>
            </li> */}
            {/* cast reports */}
            {/* <li className="menuline">
              <div className="menu-head" id="sidemenuhead0.7">
                <Link
                  to="/castReport"
                  className="btn btn-header-link"
                  data-toggle="collapse"
                  // data-target="#sidemenu18"
                  aria-expanded="true"
                  // aria-controls="sidemenu18"
                >
                  <i className="fa-regular fa-user"></i>
                  <span>Cast Reports</span>
                </Link>
              </div>
            </li> */}
            {/* account purchase reports */}
            {profile_data?.tabPermission?.[0]?.acccountPurchaseReport && (
              <li className="menuline">
                <div className="menu-head" id="sidemenuhead0.8">
                  <NavLink
                    to="accountPurchaseReport"
                    className="btn btn-header-link"
                    data-toggle="collapse"
                    // data-target="#sidemenu18"
                    aria-expanded="true"
                  // aria-controls="sidemenu18"
                  >
                    <i className="fa-regular fa-user"></i>
                    <span className="text-left">Account Purchase Report</span>
                  </NavLink>
                </div>
              </li>
            )}
          </ul>

          {/* <ul className="nav Account_Nav">
            <div onClick={handleLogOut} style={{ width: "100%" }}>
              <Link>
                <i className="fa-solid fa-right-from-bracket mr-2"></i>
                <span>Logout</span>
              </Link>
            </div>
          </ul> */}
        </div>
      </section>
    </>
  );
}

export default Sidebar;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

// Helpers
import { ApiHelperFunction } from "../../utils/HttpClient";

let initialState = {
  status: "idle",
  CastData: null,
  CastReportData: null,
  casteCount: null,
  isLoading: false,
  isSuccess: false,
  isError: false,
};

export const PostCastData = createAsyncThunk("cast_post", async (data) => {
  let response = await ApiHelperFunction({
    urlPath: "admin/add-cast",
    method: "POST",
    data: data,
  });
  console.log("profile_isFor_details", response);

  if (response.status === 200) {
    toast.success("Caste Added Successfully");
    return response.data;
  } else {
    toast.error(response?.response?.data?.message);
  }
});

export const GetCastData = createAsyncThunk("Cast_get", async () => {
  let response = await ApiHelperFunction({
    urlPath: "admin/view-cast",
    method: "GET",
  });
  // console.log("Comminuty_details_get", response);

  if (response.status === 200) {
    return response.data;
  } else {
    toast.error(response?.response?.data?.message);
  }
});

export const UpdateCastData = createAsyncThunk(
  "Cast_update",
  async ({ id, data }) => {
    console.log("updatedItem", data);
    let response = await ApiHelperFunction({
      urlPath: `admin/edit-cast/${id}`,
      method: "PUT",
      data: data,
    });
    console.log("Community_Details", response);

    if (response.status === 200) {
      toast.success("Caste Updated Successfully");
      return response.data;
    } else {
      toast.error(response?.response?.data?.message);
    }
  }
);

export const DeleteCastData = createAsyncThunk("Cast_Delete", async (id) => {
  let response = await ApiHelperFunction({
    urlPath: `admin/delete-cast/${id}`,
    method: "PUT",
  });
  console.log("contact_details", response);

  if (response.status === 200) {
    toast.success("Caste Deleted Successfully");
    return response.data;
  } else {
    toast.error(response?.response?.data?.message);
  }
});

export const getCastReportData = createAsyncThunk(
  "get_cast_wise_report",
  async (data) => {
    let response = await ApiHelperFunction({
      urlPath: "admin/cast-wise-reports",
      method: "POST",
      data: data ? data : "",
    });
    console.log("cast_report_details", response);

    if (response.status === 200) {
      return response?.data?.data;
    } else {
      toast.error(response?.response?.data?.message);
    }
  }
);

export const GetCastCountData = createAsyncThunk(
  "Cast_count_data_get",
  async () => {
    let response = await ApiHelperFunction({
      urlPath: "admin/total-cast-statistic-reports",
      method: "GET",
    });
    // console.log("Comminuty_details_get", response);

    if (response.status === 200) {
      return response.data;
    } else {
      toast.error(response?.response?.data?.message);
    }
  }
);

export const CastSlice = createSlice({
  name: "Cast_data",
  initialState,
  reducers: {
    clearState: (state) => {
      state.status = "idle";
      state.CastData = null;
      state.CastReportData = null;
      state.casteCount = null;
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(PostCastData.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(PostCastData.fulfilled, (state) => {
        state.status = "success";
        state.isSuccess = true;
        // Optionally, you can update state after a successful post if needed
      })
      .addCase(PostCastData.rejected, (state) => {
        state.status = "failed";
        state.isError = true;
        state.isSuccess = false;
      })
      .addCase(GetCastData.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(GetCastData.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.status = "success";
        state.isSuccess = true;
        // console.log("payload", payload?.data);
        state.CastData = payload?.data;
      })
      .addCase(GetCastData.rejected, (state) => {
        state.isLoading = false;
        state.status = "failed";
        state.isError = true;
        state.isSuccess = false;
      })
      .addCase(UpdateCastData.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(UpdateCastData.fulfilled, (state) => {
        state.status = "success";
        state.isSuccess = true;
      })
      .addCase(UpdateCastData.rejected, (state) => {
        state.status = "failed";
        state.isError = true;
        state.isSuccess = false;
      })
      .addCase(DeleteCastData.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(DeleteCastData.fulfilled, (state) => {
        state.status = "success";
        state.isSuccess = true;
      })
      .addCase(DeleteCastData.rejected, (state) => {
        state.status = "failed";
        state.isError = true;
        state.isSuccess = false;
      })
      .addCase(getCastReportData.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(getCastReportData.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.status = "success";
        state.isSuccess = true;
        console.log("payload", payload);
        state.CastReportData = payload;
      })
      .addCase(getCastReportData.rejected, (state) => {
        state.isLoading = false;
        state.status = "failed";
        state.isError = true;
        state.isSuccess = false;
      })
      .addCase(GetCastCountData.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(GetCastCountData.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.status = "success";
        state.isSuccess = true;
        // console.log("payload", payload?.data);
        state.casteCount = payload?.data;
      })
      .addCase(GetCastCountData.rejected, (state) => {
        state.isLoading = false;
        state.status = "failed";
        state.isError = true;
        state.isSuccess = false;
      });
  },
});

export const { clearState } = CastSlice.actions;

export default CastSlice.reducer;

import React, { useMemo, useState } from 'react'
import Table from '../../Table/Table';
import { useDispatch, useSelector } from 'react-redux';

const ManageLogo = () => {
    const dispatch = useDispatch();
    const [changeState, setChangeState] = useState(false);
    const [searchText, setsearchText] = useState("");
  
    const contactDetails = useSelector((state) => state);
  
    // useEffect(() => {
    //   dispatch(getContactData());
    //   return () => setChangeState(false);
    // }, [changeState]);
  
    const filterData = useMemo(() => {
      // if (searchText !== "") {
      //   return buddingTallent?.allUsers?.filter((item) =>
      //     JSON.stringify(item).toLowerCase().includes(searchText.toLowerCase())
      //   );
      // }
      // return buddingTallent;
    }, [searchText]);
  
    const tableData = useMemo(() => {
      return {
        columns: [
          // {
          //   label: "SL",
          //   field: "sl",
          //   sort: "asc",
          //   width: 270,
          // },
          {
            label: "Address 1",
            field: "address1",
            sort: "asc",
            width: 150,
          },
          {
            label: "Address 2",
            field: "address2",
            sort: "asc",
            width: 150,
          },
          {
            label: "Country",
            field: "country",
            sort: "asc",
            width: 150,
          },
  
          {
            label: "City",
            field: "city",
            sort: "asc",
            width: 200,
          },
          {
            label: "State",
            field: "state",
            sort: "asc",
            width: 150,
          },
          {
            label: "Status",
            field: "status",
            sort: "asc",
            width: 150,
          },
        ],
        rows:
          filterData?.length > 0
            ? filterData.map((item, idx) => {
                // console.log("item", item);
                return {
                  sl: idx + 1,
                  address1: (
                    <div className="widget-content p-0" key={idx}>
                      <div className="widget-content-wrapper">
                        <div className="widget-content-left flex2">
                          <div className="widget-heading" style={{ opacity: 1 }}>
                            {item.firstName + " " + item.lastName}
                          </div>
                        </div>
                      </div>
                    </div>
                  ),
                  address2: (
                    <div className="widget-content p-0" key={idx}>
                      <div className="widget-content-wrapper">
                        <div className="widget-content-left flex2">
                          <div className="widget-heading" style={{ opacity: 1 }}>
                            {item?.email}
                          </div>
                          {/* <div className="widget-subheading opacity-7">UI Designer</div> */}
                        </div>
                      </div>
                    </div>
                  ),
                  country: (
                    <div className="widget-content p-0" key={idx}>
                      <div className="widget-content-wrapper">
                        <div className="widget-content-left flex2">
                          <div className="widget-heading" style={{ opacity: 1 }}>
                            {item?.phone}
                          </div>
                          {/* <div className="widget-subheading opacity-7">UI Designer</div> */}
                        </div>
                      </div>
                    </div>
                  ),
  
                  city: (
                    <div className="widget-content p-0" key={idx}>
                      <div className="widget-content-wrapper">
                        {item.countryName}
                      </div>
                    </div>
                  ),
                  state: (
                    <div key={idx}>
                      {item.Skill.map((skill) => {
                        return (
                          <p style={{ fontSize: "15px" }}>{skill.skillName}</p>
                        );
                      })}
                    </div>
                  ),
  
                  status: (
                    <div className="widget-content p-0" key={idx}>
                      <div className="widget-content-wrapper">
                        <div className="widget-content-center flex2">
                          <i class="fa-solid fa-eye"></i>
                          <i class="fa-solid fa-trash-can"></i>
                        </div>
                      </div>
                    </div>
                  ),
                };
              })
            : [],
      };
    });
    return (
      <>
        <div className="">
          <h4 className="Pageheadline">Manage AboutUS</h4>
          <div style={{ overflowX: "auto" }} className="dataTableParDiv">
            <Table
              striped
              tableTitle="All abouts"
              data={tableData}
              onSearch={(e) => setsearchText(e.target.value)}
            />
          </div>
        </div>
      </>
    );
}

export default ManageLogo
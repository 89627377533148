import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

// Helpers
import { ApiHelperFunction } from "../../utils/HttpClient";

let initialState = {
  status: "idle",
  privacyPolicyData: {},
  isLoading: false,
  isSuccess: false,
  isError: false,
};

export const PostPrivacyPolicy = createAsyncThunk(
  "Privacy_Policy_post",
  async (data) => {
    let response = await ApiHelperFunction({
      urlPath: "admin/add-terms",
      method: "POST",
      data: data,
    });
    console.log("profile_isFor_details", response);

    if (response.status === 200) {
      toast.success("Privacy Policy added successfully");
      return response.data;
    } else {
      toast.error("Can't add Privacy Policy. Something went wrong");
    }
  }
);

export const GetPrivacyPolicy = createAsyncThunk(
  "Privacy_policy_get",
  async () => {
    let response = await ApiHelperFunction({
      urlPath: "admin/view-terms",
      method: "GET",
    });
    console.log("privacy_policy_get", response);

    if (response.status === 200) {
      return response.data;
    } else {
      toast.error("Can't get data. Something went wrong");
    }
  }
);

export const UpdatePrivacyPolicy = createAsyncThunk(
  "privacy_policy_update",
  async ({ id, data }) => {
    console.log("updatedItem", data);
    let response = await ApiHelperFunction({
      urlPath: `admin/edit-terms/${id}`,
      method: "PUT",
      data: data,
    });
    console.log("Community_Details", response);

    if (response.status === 200) {
      toast.success("Privacy Policy Updated Successfully");
      return response.data;
    } else {
      toast.error("Can't Update data. Something went wrong");
    }
  }
);

export const DeletePrivacyPolicy = createAsyncThunk(
  "Privacy_policy_Delete",
  async (id) => {
    let response = await ApiHelperFunction({
      urlPath: `admin/delete-terms/${id}`,
      method: "PUT",
    });
    console.log("contact_details", response);

    if (response.status === 200) {
      toast.success("Privacy policy Deleted Successfully");
      return response.data;
    } else {
      toast.error("Can't Deleted data. Something went wrong");
    }
  }
);

export const PrivacyPolicySlice = createSlice({
  name: "Privacy_policy_data",
  initialState,
  reducers: {
    clearState: (state) => {
      state.status = "idle";
      state.privacyPolicyData = {};
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(PostPrivacyPolicy.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(PostPrivacyPolicy.fulfilled, (state) => {
        state.status = "success";
        state.isSuccess = true;
        // Optionally, you can update state after a successful post if needed
      })
      .addCase(PostPrivacyPolicy.rejected, (state) => {
        state.status = "failed";
        state.isError = true;
        state.isSuccess = false;
      })
      .addCase(GetPrivacyPolicy.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(GetPrivacyPolicy.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.isSuccess = true;
        // console.log("payload", payload?.data);
        state.privacyPolicyData = payload?.data;
      })
      .addCase(GetPrivacyPolicy.rejected, (state) => {
        state.status = "failed";
        state.isError = true;
        state.isSuccess = false;
      })
      .addCase(UpdatePrivacyPolicy.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(UpdatePrivacyPolicy.fulfilled, (state) => {
        state.status = "success";
        state.isSuccess = true;
      })
      .addCase(UpdatePrivacyPolicy.rejected, (state) => {
        state.status = "failed";
        state.isError = true;
        state.isSuccess = false;
      })
      .addCase(DeletePrivacyPolicy.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(DeletePrivacyPolicy.fulfilled, (state) => {
        state.status = "success";
        state.isSuccess = true;
      })
      .addCase(DeletePrivacyPolicy.rejected, (state) => {
        state.status = "failed";
        state.isError = true;
        state.isSuccess = false;
      });
  },
});

export const { clearState } = PrivacyPolicySlice.actions;

export default PrivacyPolicySlice.reducer;

import React, { useMemo, useState } from 'react'
import Table from '../Table/Table';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const ManageChat = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [changeState, setChangeState] = useState(false);
    const [searchText, setsearchText] = useState("");
  
    const contactDetails = useSelector((state) => state);
  
    // useEffect(() => {
    //   dispatch(getContactData());
    //   return () => setChangeState(false);
    // }, [changeState]);
  
    const filterData = useMemo(() => {
      // if (searchText !== "") {
      //   return buddingTallent?.allUsers?.filter((item) =>
      //     JSON.stringify(item).toLowerCase().includes(searchText.toLowerCase())
      //   );
      // }
      // return buddingTallent;
    }, [searchText]);
  
    const tableData = useMemo(() => {
      return {
        columns: [
          {
            label: "SL",
            field: "sl",
            sort: "asc",
            width: 270,
          },
          {
            label: "User Id",
            field: "userId",
            sort: "asc",
            width: 150,
          },
          {
            label: "User Name",
            field: "username",
            sort: "asc",
            width: 150,
          },
          {
            label: "Status",
            field: "status",
            sort: "asc",
            width: 150,
          },
          {
              label: "Action",
              field: "action",
              sort: "asc",
              width: 150,
            },
        ],
        rows:
          filterData?.length > 0
            ? filterData.map((item, idx) => {
                // console.log("item", item);
                return {
                  sl: idx + 1,
                  userId: (
                    <div className="widget-content p-0" key={idx}>
                      <div className="widget-content-wrapper">
                        <div className="widget-content-left flex2">
                          <div className="widget-heading" style={{ opacity: 1 }}>
                            {item.firstName + " " + item.lastName}
                          </div>
                        </div>
                      </div>
                    </div>
                  ),
                  username: (
                    <div className="widget-content p-0" key={idx}>
                      <div className="widget-content-wrapper">
                        <div className="widget-content-left flex2">
                          <div className="widget-heading" style={{ opacity: 1 }}>
                            {item?.email}
                          </div>
                          {/* <div className="widget-subheading opacity-7">UI Designer</div> */}
                        </div>
                      </div>
                    </div>
                  ),
                  status: (
                    <div className="widget-content p-0" key={idx}>
                      <div className="widget-content-wrapper">
                        <div className="widget-content-center flex2">
                       <button className="btn btn-success">Active</button>
                       <button className="btn btn-warning">Inactive</button>
                        </div>
                      </div>
                    </div>
                  ),
                  action: (
                      <div className="widget-content p-0" key={idx}>
                        <div className="widget-content-wrapper">
                          <div className="widget-content-center flex2">
                            <i class="fa-solid fa-eye" onClick={() => navigate(`/viewUser/${idx}`) }></i>
                            {/* <i class="fa-solid fa-trash-can"></i> */}
                          </div>
                        </div>
                      </div>
                    ),
                };
              })
            : [],
      };
    });
  
    return (
      <div className="">
        <h4 className="Pageheadline">Manage Chats</h4>
        <div style={{ overflowX: "auto" }}>
          <Table
            striped
            tableTitle="View All Users "
            data={tableData}
            //   onSearch={(e) => setsearchText(e.target.value)}
          />
        </div>
      </div>
    );
}

export default ManageChat
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

// Helpers
import { ApiHelperFunction, fileUpload } from "../../utils/HttpClient";

let initialState = {
  status: "idle",
  admin: {},
  admin_details: {},
  subAdmin_details: null,
  profileImg: "",
  isLoading: false,
  isSuccess: false,
  isError: false,
};

export const adminSignup = createAsyncThunk("admin_signup", async (data) => {
  let response = await ApiHelperFunction({
    urlPath: "/admin/register",
    method: "POST",
    data: JSON.stringify(data),
  });

  if (response.status === 200) {
    localStorage.setItem("user_email", response.data.data.email);
    localStorage.setItem("Authorization", response.data.data.token);
    localStorage.setItem("userType", "admin");
    toast.success("Registered successfully!");
    return response.data;
  } else {
    toast.error("Something went wrong.");

    return data.rejectedWithValue();
  }
});

export const adminSignin = createAsyncThunk("admin_login", async (data) => {
  let response = await ApiHelperFunction({
    urlPath: "admin/login",
    method: "POST",
    data: data,
  });

  console.log("asdr", response);

  if (response.status === 200) {
    console.log("response.data.data.token", response.data.data.token);
    localStorage.setItem("Authorization", response.data.data.token);
    localStorage.setItem("userType", "Admin");
    toast.success("Login successful!");
    return response.data;
  } else {
    toast.error("Wrong email or password");
    return data.rejectedWithValue();
  }
});

export const UpdateAdmin = createAsyncThunk(
  "update_main_Admin",
  async (data) => {
    let response = await ApiHelperFunction({
      urlPath: `admin/update`,
      method: "POST",
      data: data,
    });

    if (response.status === 200) {
      toast.success("Profile Updated Successfully");
      return response.data;
    } else {
      toast.error(response?.response?.data?.message);
    }
  }
);

export const adminRegisterSubAdmin = createAsyncThunk(
  "admin_Register_subadmin",
  async (data) => {
    let response = await ApiHelperFunction({
      urlPath: "admin/admin-register-subadmin",
      method: "POST",
      data: data,
    });

    console.log("response", response);

    if (response.status === 200) {
      localStorage.setItem("user_email", response.data.data.email);
      localStorage.setItem("Authorization", response.data.data.token);
      localStorage.setItem("userType", "admin");
      toast.success("Employee Created successfully!");
      return response.data;
    } else {
      toast.error(response?.response?.data?.message);
    }
  }
);

export const getAllSubAdmin = createAsyncThunk("get_all_subadmin", async () => {
  let response = await ApiHelperFunction({
    urlPath: "/admin/sub-admin-list",
    method: "GET",
  });

  console.log("response", response);

  if (response.status === 200) {
    return response.data;
  } else {
    toast.error(response?.response?.data?.message);
  }
});

export const UpdateSubAdmin = createAsyncThunk(
  "update_subadmin",
  async ({ data, id }) => {
    console.log("updated_data", data, id);
    let response = await ApiHelperFunction({
      urlPath: `admin/subadmin-update/${id}`,
      method: "POST",
      data: data,
    });

    console.log("response", response);

    if (response.status === 200) {
      toast.success("Employee Updated Successfully");
      return response.data;
    } else {
      toast.error(response?.response?.data?.message);
    }
  }
);

export const deleteSubAdmin = createAsyncThunk(
  "delete_subadmin",
  async (id) => {
    let response = await ApiHelperFunction({
      urlPath: `/admin/subadmin-delete/${id}`,
      method: "PUT",
    });

    // console.log("response", response);

    if (response.status === 200) {
      toast.success("Employee Deleted successfully!");
      return response.data;
    } else {
      toast.error(response?.response?.data?.message);
    }
  }
);

export const uploadAdminProfileImage = createAsyncThunk(
  "upload_Admin_profile_image",
  async (data) => {
    let response = await fileUpload({
      urlPath: "admin/uploadimage",
      method: "POST",
      data: data,
    });
    console.log("religion_image", response);

    if (response.status === 200) {
      toast.success("Profile Image Uploaded successfully");
      return response.data;
    } else {
      toast.error(response?.response?.data?.message);
    }
  }
);

export const AuthSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    clearState: (state) => {
      state.admin = {};
      state.admin_details = {};
      state.subAdmin_details = null;
      state.profileImg = "";
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(adminSignin.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(adminSignin.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.isSuccess = true;
        state.admin_details = payload.data.name;
        // console.log('apa', payload.data.name);
      })
      .addCase(adminSignin.rejected, (state) => {
        state.status = "failed";
        state.isError = true;
        state.isSuccess = false;
      })

      /*------------------ update admin  --------------- */
      .addCase(UpdateAdmin.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(UpdateAdmin.fulfilled, (state) => {
        state.status = "success";
        state.isSuccess = true;
        state.isLoading = false;
      })
      .addCase(UpdateAdmin.rejected, (state) => {
        state.status = "failed";
        state.isError = true;
        state.isSuccess = false;
        state.isLoading = false;
      })

      /*------------------ delete admin  --------------- */
      .addCase(deleteSubAdmin.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(deleteSubAdmin.fulfilled, (state) => {
        state.status = "success";
        state.isSuccess = true;
        state.isLoading = false;
      })
      .addCase(deleteSubAdmin.rejected, (state) => {
        state.status = "failed";
        state.isError = true;
        state.isSuccess = false;
        state.isLoading = false;
      })
      /*------------------ get sub admin  --------------- */
      .addCase(adminRegisterSubAdmin.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(adminRegisterSubAdmin.fulfilled, (state) => {
        state.status = "success";
        state.isSuccess = true;
      })
      .addCase(adminRegisterSubAdmin.rejected, (state) => {
        state.status = "failed";
        state.isError = true;
        state.isSuccess = false;
      })
      /*------------------ get sub admin  --------------- */
      .addCase(getAllSubAdmin.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(getAllSubAdmin.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.isSuccess = true;
        state.isLoading = false;
        console.log("subadmin_list", payload?.data);
        state.subAdmin_details = payload?.data;
      })
      .addCase(getAllSubAdmin.rejected, (state) => {
        state.status = "failed";
        state.isError = true;
        state.isSuccess = false;
        state.isLoading = false;
      })
      /*------------------ update sub admin  --------------- */
      .addCase(UpdateSubAdmin.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(UpdateSubAdmin.fulfilled, (state) => {
        state.status = "success";
        state.isSuccess = true;
        state.isLoading = false;
      })
      .addCase(UpdateSubAdmin.rejected, (state) => {
        state.status = "failed";
        state.isError = true;
        state.isSuccess = false;
        state.isLoading = false;
      })
      .addCase(uploadAdminProfileImage.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(uploadAdminProfileImage.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.isSuccess = true;
        state.profileImg = payload?.url;
      })
      .addCase(uploadAdminProfileImage.rejected, (state) => {
        state.status = "failed";
        state.isError = true;
        state.isSuccess = false;
      });
  },
});

export const { clearState } = AuthSlice.actions;

export default AuthSlice.reducer;

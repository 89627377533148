import React, { useEffect, useState } from "react";
import ManagePrivacyPolicy from "./ManagePrivacyPolicy";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import {
  GetPrivacyPolicy,
  PostPrivacyPolicy,
  UpdatePrivacyPolicy,
} from "../../../redux/AdminSlice/PrivacyPolicySlice";

const AddPrivacyPolicy = () => {
  const initial = {
    title: "",
    description: "",
  };
  const navigate = useNavigate();
  const [privacy, setPrivacy] = useState(initial);
  const dispatch = useDispatch();
  const location = useLocation();

  const handleChange = (e) => {
    setPrivacy({
      ...privacy,
      [e.target.name]: e.target.value,
    });
  };

  const validation = () => {
    if (privacy.title === "") {
      toast.error("Privacy Title Is required");
      return false;
    }
    if (privacy.description === "") {
      toast.error("Privacy Description Is required");
      return false;
    }

    return true;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (validation()) {
      let data = {
        title: privacy.title,
        description: privacy.description,
      };

      if (location.state) {
        const id = location.state._id;
        dispatch(UpdatePrivacyPolicy({ id, data }));
        dispatch(GetPrivacyPolicy());
        setPrivacy(initial);
        navigate("/managePrivacy");
      } else {
        dispatch(PostPrivacyPolicy(data));
        setPrivacy(initial);
      }
    }
  };

  useEffect(() => {
    if (location.state) {
      setPrivacy({
        title: location.state.title,
        description: location.state.description,
      });
    }
  }, []);

  return (
    <>
      <div className="main_wrap">
        <div className="contauctUs_page">
          <h4 className="Pageheadline">
            {location.state ? "Edit Privacy Policy" : "Add Privacy Policy"}
          </h4>
          <form className="row m-0" onSubmit={(e) => handleSubmit(e)}>
            <div class="form-group col-md-12 col-12">
              <label for="AddressLine1">Privacy Policy Title</label>
              <input
                type="text"
                class="form-control"
                id="AddressLine1"
                placeholder="Enter Privacy Title"
                name="title"
                value={privacy.title}
                onChange={(e) => handleChange(e)}
              />
            </div>
            <div class="form-group col-md-12 col-12">
              <label for="AddressLine2">Privacy Policy Details</label>
              <textarea
                name="description"
                id=""
                cols="30"
                rows="5"
                className="form-control"
                placeholder="Enter Privacy Details"
                value={privacy.description}
                onChange={(e) => handleChange(e)}
              ></textarea>
            </div>

            <div className="col-md-6 col-12 m-auto">
              {location.state ? (
                <button className="btn btn-primary">Submit </button>
              ) : (
                <button className="btn btn-primary">Add </button>
              )}
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default AddPrivacyPolicy;

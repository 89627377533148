import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  GetCommunity,
  ReligianIdbasedCommunity,
} from "../../../redux/AdminSlice/CommunitySlice";
import {
  GetSubCommunity,
  PostSubCommunity,
  UpdateSubCommunity,
} from "../../../redux/AdminSlice/SubCommunitySlice";
import { GetReligian } from "../../../redux/AdminSlice/ReligionSlice";

const AddSubcommunity = () => {
  const initial = {
    name: "",
    religionId: "",
    communityId: "",
  };
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [community, setCommunity] = useState(initial);

  //   const queryParams = new URLSearchParams(location.search);
  //   const id = queryParams.get("id");
  //   console.log("sdid", id);

  const CommunityData = useSelector(
    (state) => state.communityReducer.CommunityData
  );
  const { ReligianData, isLoading } = useSelector(
    (state) => state.religionReducer
  );

  const SubCommunityDetails = useSelector(
    (state) => state.subCommunityReducer.SubCommunityData
  );
  console.log("isLoading", isLoading);

  const changehandler = (e) => {
    if (e.target.name === "religionId") {
      const selectedReligionId = e.target.value;
      if (selectedReligionId) {
        dispatch(ReligianIdbasedCommunity(selectedReligionId));
      }
    }
    setCommunity({
      ...community,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    if (location.state) {
      setCommunity({
        name: location.state.name,
        religionId: location.state.religionId,
        communityId: location.state.communityId,
      });

      dispatch(ReligianIdbasedCommunity(location.state.religionId));
    }
  }, []);
  useEffect(() => {
    dispatch(GetReligian());
    dispatch(GetCommunity());
  }, []);

  const submitHandler = (e) => {
    e.preventDefault();
    if (community.religionId === "") {
      toast.error("Religion Name Is required");
      return false;
    }
    if (community.communityId === "") {
      toast.error("Community Name Is required");
      return false;
    }
    if (community.name === "") {
      toast.error("Sub Community Name Is required");
      return false;
    }
    let data = {
      name: community.name,
      communityId: community.communityId,
    };

    if (location.state) {
      const id = location.state._id;
      dispatch(UpdateSubCommunity({ id, data }));
      setCommunity(initial);
      dispatch(GetSubCommunity());
      navigate("/manageSubCommunity");
    } else {
      dispatch(PostSubCommunity(data));
      setCommunity(initial);
    }
  };
  return (
    <div className="main_wrap">
      <div className="aboutUs_page">
        <h4 className="Pageheadline">
          {location.state ? "Edit Sub Community" : "Add Sub Community"}
        </h4>
        <form action="" onSubmit={(e) => submitHandler(e)}>
          <div className="row">
            <div className="col-md-3 col-sm-12">
              <label htmlFor="">Select Religion</label>
              <br />
              <select
                class="form-select form-select-lg form-control"
                aria-label=".form-select-lg example"
                name="religionId"
                value={community.religionId}
                onChange={(e) => changehandler(e)}
              >
                <option value="">Select Religion</option>
                {ReligianData?.length > 0 &&
                  ReligianData?.map((item) => {
                    return (
                      <option
                        selected={item._id === community.religionId}
                        value={item._id}
                      >
                        {item.name}
                      </option>
                    );
                  })}
              </select>
            </div>
            <div className="col-md-3 col-sm-12">
              <label htmlFor="">Select Community</label>
              <br />
              <select
                class="form-select form-select-lg form-control"
                aria-label=".form-select-lg example"
                name="communityId"
                value={community.communityId}
                onChange={(e) => changehandler(e)}
                //   disabled={!community.religionId}
              >
                <option value="">Select Community</option>
                {CommunityData?.length > 0 &&
                  CommunityData?.map((item) => {
                    console.log("dede", item._id, community.communityId, item);
                    return (
                      <option
                        selected={item._id === community.communityId}
                        value={item._id}
                      >
                        {item.name}
                      </option>
                    );
                  })}
              </select>
            </div>
            <div className="col-md-3 col-sm-12">
              <label htmlFor="">Sub Community</label>
              <input
                type="text"
                placeholder="Enter Sub Community"
                className="form-control"
                name="name"
                value={community.name}
                onChange={(e) => changehandler(e)}
              />
            </div>
            <div
              className="col-md-3 col-sm-12"
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "end",
              }}
            >
              {location.state ? (
                <button type="submit" className="btn btn-primary">
                  Submit
                </button>
              ) : (
                <button type="submit" className="btn btn-primary">
                  Add
                </button>
              )}
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddSubcommunity;

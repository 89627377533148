import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

// Helpers
import { ApiHelperFunction } from "../../utils/HttpClient";

let initialState = {
  status: "idle",
  usersData: null,
  userTotalCount: null,
  userProfilePhotoList: null,
  UserTotalLength: "",
  isActive: "",
  isVerified: "",
  isPhotoVerified: "",
  isLoading: false,
  isSuccess: false,
  isError: false,
};

export const getAllUser = createAsyncThunk(
  "All_User_get",
  async ({ data, no, limit }) => {
    console.log("xxx", data, no, limit);
    let response = await ApiHelperFunction({
      urlPath:
        no || limit
          ? `admin/all-user-list/${no}/${limit}`
          : "admin/all-user-list",
      method: "POST",
      data: data,
    });
    console.log("user_details", response);

    if (response.status === 200) {
      return response.data;
    } else {
      toast.error(response?.response?.data?.message);
    }
  }
);

export const userStatusChange = createAsyncThunk(
  "user_status_update",
  async ({ id, data }) => {
    console.log("updatedItem", data);
    let response = await ApiHelperFunction({
      urlPath: `admin/update-user-activeStatus/${id}`,
      method: "PUT",
      data: data,
    });
    console.log("Ststus_change_details", response);

    if (response.status === 200) {
      toast.success("Status Changed Successfully");
      return response.data;
    } else {
      toast.error(response?.response?.data?.mesage);
    }
  }
);

export const userIdProffChange = createAsyncThunk(
  "user_idProff_update",
  async ({ id, data }) => {
    console.log("update_id_proff", id, data);
    let response = await ApiHelperFunction({
      urlPath: `admin/update-user-document-approve-status/${id}`,
      method: "POST",
      data: data,
    });
    console.log("Ststus_change_details", response);

    if (response.status === 200) {
      toast.success("Id Approval Status Changed Successfully ");
      // return response.data;
    } else {
      toast.error(response?.response?.data?.message);
    }
  }
);

export const getAllUserCount = createAsyncThunk("All_User_Count", async () => {
  let response = await ApiHelperFunction({
    urlPath: "admin/user-registration-analize-reports",
    method: "GET",
  });
  console.log("user_details", response);

  if (response.status === 200) {
    return response.data;
  } else {
    toast.error(response?.response?.data?.message);
  }
});

export const getProfilePhotoList = createAsyncThunk(
  "All_User_profile_photo_get",
  async ({ no, limit }) => {
    console.log("xxx", no, limit);
    let response = await ApiHelperFunction({
      urlPath:
        no || limit
          ? `admin/user-profile-photo-list/${no}/${limit}`
          : "admin/user-profile-photo-list",
      method: "GET",
    });
    console.log("Profile_all_photo", response);

    if (response.status === 200) {
      return response.data;
    } else {
      toast.error(response?.response?.data?.message);
    }
  }
);

export const userPhotoApproved = createAsyncThunk(
  "user_Photo_update",
  async ({ id, data }) => {
    // console.log("update_id_proff", id, data);
    let response = await ApiHelperFunction({
      urlPath: `admin/update-user-profile-photo-verification-status/${id}`,
      method: "POST",
      data: data,
    });
    console.log("photo_approved_details", response);

    if (response.status === 200) {
      toast.success("Profile Photo approval Status Changed Successfully");
      // return response.data;
    } else {
      toast.error(response?.response?.data?.message);
    }
  }
);

export const AboutUsSlice = createSlice({
  name: "users_details_data",
  initialState,
  reducers: {
    clearState: (state) => {
      state.status = "idle";
      state.usersData = null;
      state.userTotalCount = null;
      state.userProfilePhotoList = null;
      state.UserTotalLength = "";
      state.isPhotoVerified = "";
      state.isActive = "";
      state.isVerified = "";
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
    },
  },
  extraReducers: (builder) => {
    builder

      .addCase(getAllUser.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(getAllUser.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.status = "success";
        state.isSuccess = true;
        console.log("user_payload", payload);
        state.usersData = payload?.data;
        state.UserTotalLength = payload?.totalLength;
      })
      .addCase(getAllUser.rejected, (state) => {
        state.isLoading = false;
        state.status = "failed";
        state.isError = true;
        state.isSuccess = false;
      })
      /**--------------------------------- */
      .addCase(userStatusChange.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(userStatusChange.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.isSuccess = true;
        // console.log('payload', payload?.data);
        state.isActive = payload?.data;
      })
      .addCase(userStatusChange.rejected, (state) => {
        state.status = "failed";
        state.isError = true;
        state.isSuccess = false;
      })
      /**----------------------------------------- */
      .addCase(userIdProffChange.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(userIdProffChange.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.isSuccess = true;
        state.isVerified = payload?.data;
      })
      .addCase(userIdProffChange.rejected, (state) => {
        state.status = "failed";
        state.isError = true;
        state.isSuccess = false;
      })
      /*----------------------------------------------*/
      .addCase(getAllUserCount.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(getAllUserCount.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.status = "success";

        state.isSuccess = true;
        state.userTotalCount = payload?.data;
      })
      .addCase(getAllUserCount.rejected, (state) => {
        state.isLoading = false;
        state.status = "failed";
        state.isError = true;
        state.isSuccess = false;
      })
      /**---------------------------------- */
      .addCase(getProfilePhotoList.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(getProfilePhotoList.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.status = "success";
        state.isSuccess = true;
        console.log("user_payload", payload?.data);
        state.userProfilePhotoList = payload?.data;
      })
      .addCase(getProfilePhotoList.rejected, (state) => {
        state.isLoading = false;
        state.status = "failed";
        state.isError = true;
        state.isSuccess = false;
      })
      /*-----------------------------------*/
      .addCase(userPhotoApproved.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(userPhotoApproved.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.isSuccess = true;
        // console.log('payload', payload?.data);
        state.isPhotoVerified = payload?.data;
      })
      .addCase(userPhotoApproved.rejected, (state) => {
        state.status = "failed";
        state.isError = true;
        state.isSuccess = false;
      });
  },
});

export const { clearState } = AboutUsSlice.actions;

export default AboutUsSlice.reducer;

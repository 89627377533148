import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Table from "../../Table/Table";
import { useNavigate } from "react-router-dom";
import { getAboutUsData } from "../../../redux/AdminSlice/AboutSlice";

const ManageAboutUs = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [changeState, setChangeState] = useState(false);
  const [searchText, setsearchText] = useState("");

  const aboutUsDetails = useSelector(
    (state) => state.aboutUsReducer.AboutUsData
  );

  // useEffect(() => {
  //   dispatch(getAboutUsData());
  //   return () => setChangeState(false);
  // }, [changeState]);

  useEffect(() => {
    dispatch(getAboutUsData());
  }, []);

  const deleteAboutUs = (id) => {
    // dispatch(DeleteAccountViewPrice(id)).then(() => {
    //   dispatch(getAboutUsData());
    // });
  };

  const filterData = useMemo(() => {
    if (searchText !== "") {
      return aboutUsDetails?.filter((item) =>
        JSON.stringify(item).toLowerCase().includes(searchText.toLowerCase())
      );
    }
    return aboutUsDetails;
  }, [searchText, aboutUsDetails]);

  const tableData = useMemo(() => {
    return {
      columns: [
        {
          label: "SL",
          field: "sl",
          sort: "asc",
          width: 270,
        },
        {
          label: "Company Bio",
          field: "companybio",
          sort: "asc",
          width: 150,
        },
        {
          label: "Company Description",
          field: "companydescription",
          sort: "asc",
          width: 150,
        },
        {
          label: "Action",
          field: "action",
          sort: "asc",
          width: 150,
        },
      ],
      rows:
        filterData?.length > 0
          ? filterData.map((item, idx) => {
              console.log("gth", item);
              return {
                sl: idx + 1,
                companybio: (
                  <div className="widget-content p-0" key={idx}>
                    <div className="widget-content-wrapper">
                      <div className="widget-content-left flex2">
                        <div
                          className="widget-heading"
                          style={{ opacity: 1, fontSize: "13px" }}
                        >
                          {item.title}
                        </div>
                      </div>
                    </div>
                  </div>
                ),
                companydescription: (
                  <div className="widget-content p-0" key={idx}>
                    <div className="widget-content-wrapper">
                      <div className="widget-content-left flex2">
                        <div
                          className="widget-heading"
                          style={{ opacity: 1, fontSize: "13px" }}
                        >
                          {item?.description}
                        </div>
                      </div>
                    </div>
                  </div>
                ),
                action: (
                  <div className="widget-content p-0" key={idx}>
                    <div className="widget-content-wrapper">
                      <div className="widget-content-center flex2">
                        <i
                          class="fa-solid fa-pen"
                          style={{ color: "blue", cursor: "pointer" }}
                          onClick={(e) => {
                            e.preventDefault();
                            navigate(`/addaboutus`, { state: item });
                          }}
                        ></i>
                        <i
                          class="fa-solid fa-trash-can"
                          style={{
                            color: "red",
                            cursor: "pointer",
                            marginLeft: "10px",
                          }}
                          onClick={() => deleteAboutUs(item._id)}
                        ></i>
                      </div>
                    </div>
                  </div>
                ),
              };
            })
          : [],
    };
  });
  return (
    <>
      <div className="main_wrap">
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <h4 className="Pageheadline">Manage About Us</h4>
          <button
            className="btn btn-primary"
            style={{ height: "33px" }}
            onClick={() => navigate("/addaboutus")}
          >
            Add
          </button>
        </div>
        <div style={{ overflowX: "auto" }} className="dataTableParDiv">
          <Table
            striped
            tableTitle="All abouts"
            data={tableData}
            onSearch={(e) => setsearchText(e.target.value)}
          />
        </div>
      </div>
    </>
  );
};

export default ManageAboutUs;

import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Table from "../Table/Table";
import {
  deleteSubAdmin,
  getAllSubAdmin,
} from "../../redux/authSlice/AuthSlice";
import { getProfileDetails } from "../../redux/AdminSlice/profileSlice";
import { DeleteConfirmModal } from "../../DeleteAlert/DeleteAlert";
import CustomLoader from "../../Loader/CustomLoader";

const ManageEmployee = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchText, setsearchText] = useState("");
  const token = localStorage.getItem("Authorization");
  const { profile_data } = useSelector((state) => state.profileReducer);

  const { subAdmin_details, isLoading } = useSelector(
    (state) => state.authReducer
  );

  console.log("profile_data", profile_data);

  useEffect(() => {
    dispatch(getAllSubAdmin());
    dispatch(getProfileDetails(token));
  }, []);

  const handledeleteSubAdmin = (id) => {
    const del = async () => {
      dispatch(deleteSubAdmin(id));
      dispatch(getAllSubAdmin());
    };

    DeleteConfirmModal(del);
  };

  const filterData = useMemo(() => {
    if (searchText !== "") {
      return subAdmin_details.filter((item) =>
        JSON.stringify(item).toLowerCase().includes(searchText.toLowerCase())
      );
    }
    return subAdmin_details;
  }, [searchText, subAdmin_details]);

  const tableData = useMemo(() => {
    return {
      columns: [
        {
          label: "SL",
          field: "sl",
          sort: "asc",
          width: 270,
        },
        {
          label: "Employee Id",
          field: "employeeid",
          sort: "asc",
          width: 150,
        },
        {
          label: "Employee Name",
          field: "employeeName",
          sort: "asc",
          width: 150,
        },
        {
          label: "Email",
          field: "email",
          sort: "asc",
          width: 150,
        },
        {
          label: "Phone",
          field: "phone",
          sort: "asc",
          width: 150,
        },
        {
          label: "UserType",
          field: "usertype",
          sort: "asc",
          width: 150,
        },
        {
          label: "Action",
          field: "action",
          sort: "asc",
          width: 150,
        },
      ],
      rows:
        filterData?.length > 0
          ? filterData.map((item, idx) => {
              console.log("item", item);
              return {
                sl: idx + 1,
                employeeid: (
                  <div className="widget-content p-0" key={idx}>
                    <div className="widget-content-wrapper">
                      <div className="widget-content-left flex2">
                        <div
                          className="widget-heading"
                          style={{ opacity: 1, fontSize: "13px" }}
                        >
                          {item?.employeId}
                        </div>
                      </div>
                    </div>
                  </div>
                ),
                employeeName: (
                  <div className="widget-content p-0" key={idx}>
                    <div className="widget-content-wrapper">
                      <div className="widget-content-left flex2">
                        <div
                          className="widget-heading"
                          style={{ opacity: 1, fontSize: "13px" }}
                        >
                          {item?.firstName + " " + item?.lastName}
                        </div>
                      </div>
                    </div>
                  </div>
                ),
                email: (
                  <div className="widget-content p-0" key={idx}>
                    <div className="widget-content-wrapper">
                      <div className="widget-content-left flex2">
                        <div
                          className="widget-heading"
                          style={{ opacity: 1, fontSize: "13px" }}
                        >
                          {item?.email}
                        </div>
                      </div>
                    </div>
                  </div>
                ),
                phone: (
                  <div className="widget-content p-0" key={idx}>
                    <div className="widget-content-wrapper">
                      <div className="widget-content-left flex2">
                        <div
                          className="widget-heading"
                          style={{ opacity: 1, fontSize: "13px" }}
                        >
                          {item?.mobile}
                        </div>
                      </div>
                    </div>
                  </div>
                ),
                usertype: (
                  <div className="widget-content p-0" key={idx}>
                    <div className="widget-content-wrapper">
                      <div className="widget-content-left flex2">
                        <div
                          className="widget-heading"
                          style={{ opacity: 1, fontSize: "13px" }}
                        >
                          {item?.userType}
                        </div>
                      </div>
                    </div>
                  </div>
                ),
                action: (
                  <div className="widget-content p-0" key={idx}>
                    <div className="widget-content-wrapper">
                      <div className="widget-content-center flex2">
                        {profile_data?.tabPermission?.[0]
                          ?.manageaccessManagementEdit && (
                          <i
                            class="fa-solid fa-pen"
                            style={{ color: "blue", cursor: "pointer" }}
                            onClick={() => {
                              navigate("/addEmployee", { state: item });
                            }}
                          ></i>
                        )}

                        {profile_data?.tabPermission?.[0]
                          ?.manageaccessManagementDelete && (
                          <i
                            class="fa-solid fa-trash-can"
                            style={{
                              color: "red",
                              marginLeft: "15px",
                              cursor: "pointer",
                            }}
                            onClick={() => handledeleteSubAdmin(item._id)}
                          ></i>
                        )}
                      </div>
                    </div>
                  </div>
                ),
              };
            })
          : [],
    };
  });

  return (
    <>
      <div className="main_wrap">
        <CustomLoader loading={isLoading} />
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <h4 className="Pageheadline">Manage Employee</h4>
          {profile_data?.tabPermission?.[0]?.addaccessManagement && (
            <button
              className="btn btn-primary"
              style={{ height: "33px" }}
              onClick={() => navigate("/addEmployee")}
            >
              Add
            </button>
          )}
        </div>
        <div style={{ overflowX: "auto" }} className="dataTableParDiv">
          <Table
            striped
            tableTitle="All abouts"
            data={tableData}
            onSearch={(e) => setsearchText(e.target.value)}
          />
        </div>
      </div>
    </>
  );
};

export default ManageEmployee;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

// Helpers
import { ApiHelperFunction, fileUpload } from "../../utils/HttpClient";

let initialState = {
  status: "idle",
  CityData: null,
  cityimage: "",
  isLoading: false,
  isSuccess: false,
  isError: false,
};

export const PostCity = createAsyncThunk("City_post", async (data) => {
  let response = await ApiHelperFunction({
    urlPath: "admin/add-city",
    method: "POST",
    data: data,
  });
  console.log("State", response);

  if (response.status === 200) {
    toast.success("City added successfully");
    return response.data;
  } else {
    // toast.error("Can't add Religion. Something went wrong");
    toast.error(response?.response?.data?.message);
  }
});

export const GetCity = createAsyncThunk("City_data_get", async () => {
  let response = await ApiHelperFunction({
    urlPath: "admin/view-city",
    method: "GET",
  });
  console.log("State_details_get", response);

  if (response.status === 200) {
    return response.data;
  } else {
    toast.error(response?.response?.data?.message);
  }
});

export const GetCityByStateName = createAsyncThunk(
  "City_data_get_by_state_name",
  async (name) => {
    let response = await ApiHelperFunction({
      urlPath: `admin/view-city-byStateName/${name}`,
      method: "GET",
    });
    console.log("State_details_get", response);

    if (response.status === 200) {
      return response.data;
    } else {
      toast.error(response?.response?.data?.message);
    }
  }
);

export const UpdateCity = createAsyncThunk(
  "city_details_update",
  async ({ id, data }) => {
    let response = await ApiHelperFunction({
      urlPath: `admin/edit-city/${id}`,
      method: "PUT",
      data: data,
    });
    console.log("State_update", response);

    if (response.status === 200) {
      toast.success("City Updated Successfully");
      return response.data;
    } else {
      toast.error(response?.response?.data?.message);
    }
  }
);

export const DeleteCity = createAsyncThunk(
  "City_details_Delete",
  async (id) => {
    let response = await ApiHelperFunction({
      urlPath: `admin/delete-city/${id}`,
      method: "PUT",
    });
    console.log("contact_details", response);

    if (response.status === 200) {
      toast.success("City Deleted Successfully");
      return response.data;
    } else {
      toast.error(response?.response?.data?.message);
    }
  }
);

export const UploadCityImage = createAsyncThunk(
  "upload_City_image",
  async (data) => {
    console.log("state", data);
    let response = await fileUpload({
      urlPath: "admin/upload-cityImage",
      method: "POST",
      data: data,
    });
    console.log("State_image", response);

    if (response.status === 200) {
      toast.success("City Image Uploaded successfully");
      return response.data;
    } else {
      toast.error(response?.response?.data?.message);
    }
  }
);

export const CitySlice = createSlice({
  name: "State_data",
  initialState,
  reducers: {
    clearState: (state) => {
      state.status = "idle";
      state.CityData = null;
      state.cityimage = "";
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
    },
  },
  extraReducers: (builder) => {
    builder
      /**---------------------------------- */
      .addCase(PostCity.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(PostCity.fulfilled, (state) => {
        state.status = "success";
        state.isSuccess = true;
        state.isLoading = false;
        // Optionally, you can update state after a successful post if needed
      })
      .addCase(PostCity.rejected, (state) => {
        state.status = "failed";
        state.isError = true;
        state.isSuccess = false;
        state.isLoading = false;
      })
      /**---------------------------------- */
      .addCase(GetCity.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(GetCity.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.isSuccess = true;
        state.isLoading = false;
        console.log("payload", payload?.data);
        state.CityData = payload?.data;
      })
      .addCase(GetCity.rejected, (state) => {
        state.status = "failed";
        state.isError = true;
        state.isSuccess = false;
        state.isLoading = false;
      })

       /**---------------------------------- */
       .addCase(GetCityByStateName.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(GetCityByStateName.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.isSuccess = true;
        state.isLoading = false;
        console.log("payload", payload?.data);
        state.CityData = payload?.data;
      })
      .addCase(GetCityByStateName.rejected, (state) => {
        state.status = "failed";
        state.isError = true;
        state.isSuccess = false;
        state.isLoading = false;
      })

      /**---------------------------------- */
      .addCase(UpdateCity.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(UpdateCity.fulfilled, (state) => {
        state.status = "success";
        state.isSuccess = true;
      })
      .addCase(UpdateCity.rejected, (state) => {
        state.status = "failed";
        state.isError = true;
        state.isSuccess = false;
      })
      /**---------------------------------- */
      .addCase(DeleteCity.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(DeleteCity.fulfilled, (state) => {
        state.status = "success";
        state.isSuccess = true;
      })
      .addCase(DeleteCity.rejected, (state) => {
        state.status = "failed";
        state.isError = true;
        state.isSuccess = false;
      })
      /**---------------------------------- */
      .addCase(UploadCityImage.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(UploadCityImage.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.isSuccess = true;
        state.cityimage = payload?.url;
      })
      .addCase(UploadCityImage.rejected, (state) => {
        state.status = "failed";
        state.isError = true;
        state.isSuccess = false;
      })
  },
});

export const { clearState } = CitySlice.actions;

export default CitySlice.reducer;
